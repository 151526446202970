import {
  Avatar,
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setCurrentActiveChat } from "../../../store/slices/ChatSlice";
import { recentChatRowInterface } from "./RecentChatWrapper";

interface recentChatRow {
  data: recentChatRowInterface;
}

function RecentChatRow({ data }: recentChatRow) {
  const dispatch = useAppDispatch();
  const chatSocket = useAppSelector((state) => state.chatReducer.chatSocket);

  const handleCurrentActiveChat = () => {
    const currentActiveChatData = {
      channelId: data.channelId,
      type: data.type,
      name: data.name,
      unreadMessageCount: data.unreadMessageCount,
      participants: data.participants,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
    chatSocket.emit("updateLastRead", {
      channelId: data.channelId,
    });
    dispatch(setCurrentActiveChat(currentActiveChatData));
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      // sx: {
      // 	bgcolor: stringToColor(name),
      // },
      children: name
        ? name.split(" ").length === 1
          ? `${name.split(" ")[0][0]}`
          : `${name.split(" ")[0][0]}${
              name.split(" ")[1] ? name.split(" ")[1][0] : ""
            }`
        : "",
    };
  };

  return (
    <ListItem button onClick={handleCurrentActiveChat} key="CindyBaker">
      <ListItemIcon>
        <Badge badgeContent={data.unreadMessageCount} color="error">
          <Avatar {...stringAvatar(data.name)} />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={data.name}>{data.name}</ListItemText>
    </ListItem>
  );
}

export default RecentChatRow;

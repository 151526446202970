import React, { useState } from "react";
import CreateCampaign from "../CreateCampaign";
import AdditionalInfo from "../AdditionalInfo/AdditionalInfo";
import GoalPage from "../GoalPage/GoalPage";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../../wallets/algorand";

import {
  bindNftToCampaign,
  createCampaignBlockchain,
} from "../../../../blockchain";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import algosdk from "algosdk";
import moment from "moment";
import {
  STAGING_FUNDING_CATEGORY,
  TEST_FUNDING_CATEGORY,
  PRODUCTION_FUNDING_CATEGORY,
} from "../constant";
import { createCampaign } from "../../../../requests/authRequest";
import { useNavigate } from "react-router-dom";
import { InputFiles } from "typescript";
import { Box } from "@mui/system";
import { Backdrop, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

function CampaignMainPage() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const USDC_ASSET_ID = 10458941;
  const { t } = useTranslation();
  const campaignState = useAppSelector((state) => state.campaignPreviewReducer);

  const [campaignSteps, setCampaignSteps] = React.useState<string>("Create");
  const [currentCampaignId, setCurrentCampaignId] = React.useState<string>("");
  const [preLoading, setPreLoading] = React.useState<boolean>(false);

  interface milestone {
    title: string;
    defination: string;
    deadline: Date | string;
    delivereble: any;
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let blockchainCampaignAppId = "";
  let milestoneOneAppId = "";
  let milestoneTwoAppId = "";

  // ---- create campaign -------

  const [title, setTitle] = useState<string>(campaignState.title);
  const [YearlyOutput, setYearlyOutput] = useState<number>(
    campaignState.YearlyOutput
  );
  const [Results, setResults] = useState<string>(campaignState.Results);
  const [youTubeLink, setyouTubeLink] = useState<string>(
    campaignState.youTubeLink
  );
  const [CostPerUnit, setCostPerUnit] = useState<number>(
    campaignState.CostPerUnit
  );
  const [description, setDescription] = useState<string>(
    campaignState.description
  );
  const [category, setCategory] = useState<string>(
    campaignState.campaignCategory
  );
  const [fundingCategory, setFundingCategory] = useState<string>(
    campaignState.fundingCategory
  );
  const [paymentType, setPaymentType] = useState<string>(
    campaignState.paymentType
  );

  const [endDate, setEndDate] = React.useState<Date | string>(
    new Date(campaignState.endDate)
  );
  const [country, setCountry] = useState(campaignState.country);
  const [location, setLocation] = useState(campaignState.location);

  const [goalAmount, setGoalAmount] = useState<string>(
    campaignState.goalAmount
  );
  const [image, setImage] = useState<any | undefined>([]);
  const [imageName, setImageName] = useState<any>([]);
  const [video, setVideo] = useState<InputFiles | undefined>(
    campaignState.video
  );
  const [videoName, setVideoName] = useState<string>(campaignState.videoUrl);
  const [videoSelected, setVideoSelected] = useState<boolean>(
    campaignState.videoSelected
  );
  const selectedNft = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardNftId
  );
  const rewardType = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardType
  );

  const [ESG_SDG_status, setESG_SDG_status] = useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });
  const [monitoringStatus, setMonitoringStatus] = useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });

  const [localMilestoneData, setLocalMilestoneData] = useState(
    localStorage.getItem("milestones")
  );

  const [milestoneOne, setMilestoneOne] = useState<milestone>(
    !localMilestoneData
      ? {
          title: "",
          defination: "",
          deadline: "",
          delivereble: undefined,
        }
      : {
          title: JSON.parse(localMilestoneData).one.title,
          defination: JSON.parse(localMilestoneData).one.definition,
          deadline: JSON.parse(localMilestoneData).one.deadline,
          delivereble: JSON.parse(localMilestoneData).one.deliverable,
        }
  );
  const [milestoneTwo, setMilestoneTwo] = useState<milestone>(
    !localMilestoneData
      ? {
          title: "",
          defination: "",
          deadline: "",
          delivereble: undefined,
        }
      : {
          title: JSON.parse(localMilestoneData).two.title,
          defination: JSON.parse(localMilestoneData).two.definition,
          deadline: JSON.parse(localMilestoneData).two.deadline,
          delivereble: JSON.parse(localMilestoneData).two.deliverable,
        }
  );
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });
  // goal page states ----

  const [goalIds, setGoalIds] = React.useState<number[]>([]);

  const [matchFundStatus, setMatchFundStatus] = React.useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });
  const [additionalInfoStatus, setAdditionalInfoStatus] = React.useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });

  // additional info page states ---

  const [additionalData, setAdditionalData] = React.useState<any>({
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    companyName: "",
    website: "",
    projectPitch: "",
    problems: "",
    previousSuccessfulProject: "",
    Hq: "",
    country: "",
    impact: "",
    partnership: "",
    yearOfEstablishment: new Date(""),
    stageDesc: "",
    infoImage: "",
    additionalInfo: "",
  });

  const [consentStatus, setConsentStatus] = React.useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });
  const [reachoutStatus, setReachoutStatus] = React.useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });

  //-----

  const assignNftToCampaign = async () => {
    const assignInfo = {
      NFT_asset_id: selectedNft,
      campaign_app_id: blockchainCampaignAppId,
    };
    const unsignedAssignNftTxn = await bindNftToCampaign(assignInfo);
    if (!unsignedAssignNftTxn.success) {
      const toastPaylaod = {
        text: t("unableToBindNftWithCampaign"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      // dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedAssignNftTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));
      let signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      signed.push(signedTxn[2]);
      // dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(setAppLoadingText(t("waitingForConfirmation")));

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      dispatch(setAppLoadingText(""));
      return {
        success: true,
      };
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleCreateCampaignBlockchain = async () => {
    const campaignInfo = {
      creator_wallet_address: activeAccount?.address,
      //key
      paymentType: paymentType, // Adding payment_type field for grant-creators
      title: title,
      CostPerUnit: CostPerUnit,
      YearlyOutput: YearlyOutput,
      Results: Results,
      location: location,
      youTubeLink: youTubeLink,
      // description: description,
      category: category,
      end_time: moment(new Date(endDate)).startOf("day").unix(),
      fund_category: fundingCategory,
      fund_limit: parseInt(goalAmount),
      reward_type:
        fundingCategory === TEST_FUNDING_CATEGORY ||
        fundingCategory === STAGING_FUNDING_CATEGORY ||
        fundingCategory === PRODUCTION_FUNDING_CATEGORY
          ? rewardType
          : "0",
      country: country,
      ESG: ESG_SDG_status.status ? 1 : 0,
      monitoring: monitoringStatus ? 1 : 0,
      milestone: {
        milestone_title: {
          "1": milestoneOne.title,
          "2": milestoneTwo.title,
          // "3": milestoneThree.title,
        },
        milestone_number: { "1": 1, "2": 2 },
        end_time_milestone: {
          "1": moment(new Date(milestoneOne.deadline)).startOf("day").unix(),
          "2": moment(new Date(milestoneTwo.deadline)).startOf("day").unix(),
          // "3": moment(milestoneThree.deadline).unix(),
        },
      },
    };

    const unsignedTxn = await createCampaignBlockchain(campaignInfo);
    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      // dispatch(setAppLoadingText("signing transaction"));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));

      const signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      signed.push(signedTxn[2]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      let milestoneOneDataBlockchain = await algosdk.waitForConfirmation(
        client,
        transactionArr[1].txn.txID(),
        4
      );
      let milestoneTwoDataBlockchain = await algosdk.waitForConfirmation(
        client,
        transactionArr[2].txn.txID(),
        4
      );

      dispatch(setAppLoadingText(""));

      blockchainCampaignAppId = confirmedTxn["application-index"];
      milestoneOneAppId = milestoneOneDataBlockchain["application-index"];
      milestoneTwoAppId = milestoneTwoDataBlockchain["application-index"];

      if (
        (fundingCategory === TEST_FUNDING_CATEGORY ||
          fundingCategory === STAGING_FUNDING_CATEGORY ||
          fundingCategory === PRODUCTION_FUNDING_CATEGORY) &&
        rewardType === "NFTs" &&
        blockchainCampaignAppId
      ) {
        // i need to bind a nft here
        const bindingRes = await assignNftToCampaign();
        if (bindingRes.success) {
          return {
            success: true,
          };
        } else {
          return {
            success: false,
          };
        }
      } else {
        return {
          success: true,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));

      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleCreateCampaign = async (
    mile1: string,
    mile2: string,
    currentCampaignId: string
  ) => {
    setPreLoading(true);
    setCurrentCampaignId(currentCampaignId);
    const blockchainRes = await handleCreateCampaignBlockchain();
    if (!blockchainRes.success) {
      setPreLoading(false);
      return;
    }

    let payload: any = {};

    if (
      fundingCategory === TEST_FUNDING_CATEGORY ||
      fundingCategory === STAGING_FUNDING_CATEGORY ||
      fundingCategory === PRODUCTION_FUNDING_CATEGORY
    ) {
      payload = {
        campaignId: currentCampaignId,
        // title: title,
        // description: description,
        // category: category,
        // fundingCategory: fundingCategory,
        // endDate: moment(endDate).format("YYYY-MM-DD"),
        // goalAmount: goalAmount,
        // country: country,
        // images: images,
        // video: video,
        // videoUrl: "https://youtu.be/ouncVBiye_M",
        rewardBased: true,
        rewardType: rewardType,
        reward: selectedNft,
        campaignAppId: blockchainCampaignAppId,
        milestones: [
          {
            milestoneId: mile1,
            title: milestoneOne.title,
            definition: milestoneOne.defination,
            deadline: milestoneOne.deadline,
            deliverable: "non",
            milestoneAppId: milestoneOneAppId,
          },
          {
            milestoneId: mile2,
            title: milestoneTwo.title,
            definition: milestoneTwo.defination,
            deadline: milestoneTwo.deadline,
            deliverable: "non",
            milestoneAppId: milestoneTwoAppId,
          },
        ],
      };
    } else {
      payload = {
        campaignId: currentCampaignId,
        paymentType: paymentType,
        // title: title,
        // description: description,
        // category: category,
        // fundingCategory: fundingCategory,
        // endDate: moment(endDate).format("YYYY-MM-DD"),
        // goalAmount: goalAmount,
        // country: country,
        // images: images,
        // video: video,
        // videoUrl: "https://youtu.be/ouncVBiye_M",
        campaignAppId: blockchainCampaignAppId,
        milestones: [
          {
            milestoneId: mile1,
            title: milestoneOne.title,
            definition: milestoneOne.defination,
            deadline: milestoneOne.deadline,
            deliverable: "anything",
            milestoneAppId: milestoneOneAppId,
          },
          {
            milestoneId: mile2,
            title: milestoneTwo.title,
            definition: milestoneTwo.defination,
            deadline: milestoneTwo.deadline,
            deliverable: "anything",
            milestoneAppId: milestoneTwoAppId,
          },
        ],
      };
    }
    const res = await createCampaign(payload);
    setPreLoading(false);
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPaylaod = {
        text: t("campaignCreatedSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      setPreLoading(false);
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      navigate("/user/my-campaign");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      setPreLoading(false);
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
    }
    dispatch(toggleAppLoading(false));
    localStorage.removeItem("milestones");
  };

  // ---- create campaign -------

  return (
    <Box>
      {campaignSteps === "Create" ? (
        <CreateCampaign
          handleSteps={() => setCampaignSteps("Goal")}
          currentCampaignId={currentCampaignId}
          setCurrentCampaignId={setCurrentCampaignId}
          title={title}
          CostPerUnit={CostPerUnit}
          YearlyOutput={YearlyOutput}
          Results={Results}
          handleTitle={(vl: string) => setTitle(vl)}
          handleCostPerUnit={(vl: number) => setCostPerUnit(vl)}
          handleYearlyOutput={(vl: number) => setYearlyOutput(vl)}
          handleResults={(vl: string) => setResults(vl)}
          description={description}
          handleDescription={(vl: string) => setDescription(vl)}
          category={category}
          handleCategory={(vl: string) => setCategory(vl)}
          paymentType={paymentType}
          handlePaymentType={(vl: string) => setPaymentType(vl)}
          fundingCategory={fundingCategory}
          handleFundingCategory={(vl: string) => setFundingCategory(vl)}
          endDate={endDate}
          handleEndDate={(vl: Date | string) => setEndDate(vl)}
          country={country}
          handleCountry={(vl: any) => setCountry(vl)}
          location={location}
          handleLocation={(vl: any) => setLocation(vl)}
          goalAmount={goalAmount}
          handleGoalAmount={(vl: string) => setGoalAmount(vl)}
          image={image}
          imageName={imageName}
          handleImage={(vl: any | undefined) => setImage(vl)}
          handleImageName={(vl: any | undefined) => setImageName(vl)}
          youTubeLink={youTubeLink}
          handleYoutubelink={(vl: string) => setyouTubeLink(vl)}
          video={video}
          videoName={videoName}
          handleVideo={(vl: any | undefined) => setVideo(vl)}
          handleVideoName={(vl: any | undefined) => setVideoName(vl)}
          videoSelected={videoSelected}
          handleVideoSelected={(vl: boolean) => setVideoSelected(vl)}
          milestoneOne={milestoneOne}
          handleMilestoneOne={(vl: milestone) => setMilestoneOne(vl)}
          milestoneTwo={milestoneTwo}
          handleMilestoneTwo={(vl: milestone) => setMilestoneTwo(vl)}
          ESG_SDG_status={ESG_SDG_status}
          handleESG_SDG_status={(vl: any) => setESG_SDG_status(vl)}
          monitoringStatus={monitoringStatus}
          handleMonitoringStatus={(vl: any) => setMonitoringStatus(vl)}
          handleCampaignCreate={handleCreateCampaign}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      ) : campaignSteps === "Additional" ? (
        <AdditionalInfo
          paymentType={paymentType}
          handleSteps={() => setCampaignSteps("Goal")}
          currentCampaignId={currentCampaignId}
          youTubeLink={youTubeLink}
          handleYoutubelink={(vl: string) => setyouTubeLink(vl)}
          handleCampaignCreate={handleCreateCampaign}
          contactName={additionalData.contactName}
          handleContactName={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, contactName: vl }))
          }
          contactEmail={additionalData.contactEmail}
          handleContactEmail={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, contactEmail: vl }))
          }
          contactPhone={additionalData.contactPhone}
          handleContactPhone={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, contactPhone: vl }))
          }
          companyName={additionalData.companyName}
          handleCompanyName={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, companyName: vl }))
          }
          website={additionalData.website}
          handleWebsite={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, website: vl }))
          }
          projectPitch={additionalData.projectPitch}
          handleProjectPitch={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, projectPitch: vl }))
          }
          problems={additionalData.problems}
          handleProblems={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, problems: vl }))
          }
          previousSuccessfulProject={additionalData.previousSuccessfulProject}
          handlePreviousSuccessfulProject={(vl: any) =>
            setAdditionalData((prev: any) => ({
              ...prev,
              previousSuccessfulProject: vl,
            }))
          }
          Hq={additionalData.Hq}
          handleHq={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, Hq: vl }))
          }
          country={additionalData.country}
          handleCountry={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, country: vl }))
          }
          location={additionalData.location}
          handleLocation={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, location: vl }))
          }
          impact={additionalData.impact}
          handleImpact={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, impact: vl }))
          }
          partnership={additionalData.partnership}
          handlePartnership={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, partnership: vl }))
          }
          yearOfEstablishment={additionalData.yearOfEstablishment}
          handleYearOfEstablishment={(vl: any) =>
            setAdditionalData((prev: any) => ({
              ...prev,
              yearOfEstablishment: vl,
            }))
          }
          stageDesc={additionalData.stageDesc}
          handleStageDesc={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, stageDesc: vl }))
          }
          infoImage={additionalData.infoImage}
          handleInfoImage={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, infoImage: vl }))
          }
          additionalInfo={additionalData.additionalInfo}
          handleAdditionalInfo={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, additionalInfo: vl }))
          }
          consentStatus={consentStatus}
          handleConsentStatus={(vl: any) => setConsentStatus(vl)}
          reachoutStatus={reachoutStatus}
          handleReachoutStatus={(vl: any) => setReachoutStatus(vl)}
          title={title}
          CostPerUnit={CostPerUnit}
          YearlyOutput={YearlyOutput}
          Results={Results}
          description={description}
          category={category}
          fundingCategory={fundingCategory}
          endDate={endDate}
          campaigncountry={country}
          goalAmount={goalAmount}
          image={image}
          video={video}
          videoSelected={videoSelected}
          videoName={videoName}
          imageName={imageName}
          ESG_SDG_status={ESG_SDG_status}
          monitoringStatus={monitoringStatus}
          goalIds={goalIds}
        />
      ) : campaignSteps === "Goal" ? (
        <GoalPage
          paymentType={paymentType}
          handleSteps={(vl: string) => setCampaignSteps(vl)}
          currentCampaignId={currentCampaignId}
          title={title}
          CostPerUnit={CostPerUnit}
          YearlyOutput={YearlyOutput}
          Results={Results}
          description={description}
          category={category}
          fundingCategory={fundingCategory}
          endDate={endDate}
          country={country}
          goalAmount={goalAmount}
          image={image}
          video={video}
          videoSelected={videoSelected}
          videoName={videoName}
          youTubeLink={youTubeLink}
          handleYoutubelink={(vl: string) => setyouTubeLink(vl)}
          imageName={imageName}
          ESG_SDG_status={ESG_SDG_status}
          monitoringStatus={monitoringStatus}
          handleCampaignCreate={handleCreateCampaign}
          matchFundStatus={matchFundStatus}
          handleMatchFundStatus={(vl: any) => setMatchFundStatus(vl)}
          additionalInfoStatus={additionalInfoStatus}
          handleAdditionalInfoStatus={(vl: any) => setAdditionalInfoStatus(vl)}
          goalIds={goalIds}
          handleGoalIds={(item: any) =>
            goalIds.some((el) => el === item?.goalId)
              ? setGoalIds(goalIds.filter((el) => el !== item?.goalId))
              : setGoalIds((prev) => [...prev, item?.goalId])
          }
        />
      ) : (
        <CreateCampaign
          handleSteps={() => setCampaignSteps("Goal")}
          currentCampaignId={currentCampaignId}
          // handleCurrentCampaignId={(vl: string) => setCurrentCampaignId(vl)}
          title={title}
          CostPerUnit={CostPerUnit}
          YearlyOutput={YearlyOutput}
          Results={Results}
          handleTitle={(vl: string) => setTitle(vl)}
          handleCostPerUnit={(vl: number) => setCostPerUnit(vl)}
          handleYearlyOutput={(vl: number) => setYearlyOutput(vl)}
          handleResults={(vl: string) => setResults(vl)}
          description={description}
          handleDescription={(vl: string) => setDescription(vl)}
          category={category}
          handleCategory={(vl: string) => setCategory(vl)}
          fundingCategory={fundingCategory}
          handleFundingCategory={(vl: string) => setFundingCategory(vl)}
          endDate={endDate}
          handleEndDate={(vl: Date | string) => setEndDate(vl)}
          country={country}
          handleCountry={(vl: any) => setCountry(vl)}
          location={location}
          handleLocation={(vl: any) => setLocation(vl)}
          paymentType={paymentType}
          handlePaymentType={(vl: string) => setPaymentType(vl)}
          goalAmount={goalAmount}
          handleGoalAmount={(vl: string) => setGoalAmount(vl)}
          image={image}
          youTubeLink={youTubeLink}
          handleYoutubelink={(vl: string) => setyouTubeLink(vl)}
          handleImage={(vl: any | undefined) => setImage(vl)}
          video={video}
          handleVideo={(vl: any | undefined) => setVideo(vl)}
          videoSelected={videoSelected}
          handleVideoSelected={(vl: boolean) => setVideoSelected(vl)}
          milestoneOne={milestoneOne}
          handleMilestoneOne={(vl: milestone) => setMilestoneOne(vl)}
          milestoneTwo={milestoneTwo}
          handleMilestoneTwo={(vl: milestone) => setMilestoneTwo(vl)}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: "99999999999999",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          flexDirection: "column",
        }}
        open={preLoading}
      >
        <CircularProgress color="inherit" />
        <h3>{t("creatingCampaign")}</h3>
      </Backdrop>
    </Box>
  );
}

export default CampaignMainPage;

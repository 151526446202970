const MilestoneGrid = {
  height: "200px",
  width: "100%",
  marginLeft: "4%",
  "& .MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      bgcolor: "primary.buttongrey",
      borderBottom: "none",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "18px",
        fontFamily: "TTNormsMedium",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "18px",
      fontFamily: "TTNormsRegular",
      padding: "16px",
      wordBreak: "break-word",
      whiteSpace: "normal",
      maxHeight: "inherit !important",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "inherit !important",
      "&:nth-child(odd)": {
        bgcolor: "transparent",
      },
      "&:nth-child(even)": {
        bgcolor: "primary.buttongrey",
      },
    },
  },
};

export { MilestoneGrid };

import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { resetPasswordWapper, resetPasswordForm } from "./styles";
import { useAppDispatch } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { resetPassword } from "../../../requests/simpleRequest";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleResetPassword = async () => {
    // for passwordd
    let res = true;
    if (
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[^A-Za-z0-9]/.test(password) &&
      password.trim().length >= 8
    ) {
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
      res = false;
    }

    if (!res) {
      return;
    } else {
      // change the password
      dispatch(toggleAppLoading(true));
      const obj = {
        resetToken: query.get("token"),
        userId: query.get("id"),
        password: password,
      };
      const res = await resetPassword(obj);
      if (res.success) {
        const toastPlayload = {
          active: true,
          text: t("passwordChangedSuccessfully"),
          success: true,
        };
        dispatch(setToaster(toastPlayload));
        navigate("/");
      } else {
        const toastPlayload = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toastPlayload));
        // add navigate
      }
      dispatch(toggleAppLoading(false));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleResetPassword();
    }
  };

  useEffect(() => {
    if (!query.get("token") || !query.get("id")) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <Box sx={resetPasswordWapper}>
      <Box sx={resetPasswordForm}>
        <h2>{t("resetPassword")}</h2>
        <p>{t("pleaseEnterYourNewPasswordHere")}</p>

        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            error={invalidPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{
                    color: "#d3d3d3",
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            placeholder={t("password") || ""}
            onKeyDown={handleKeyDown}
          />
          {invalidPassword && (
            <FormHelperText error={invalidPassword}>
              {t("passwordValidation")}
            </FormHelperText>
          )}
        </FormControl>

        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          onClick={handleResetPassword}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
}

export default ResetPassword;

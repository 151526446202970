const DataGridWrap = {
  marginTop: "30px",
  height: "500px",
  width: "100%",
  "& .MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      bgcolor: "primary.buttongrey",
      borderBottom: "none",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "18px",
        fontFamily: "TTNormsMedium",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "18px",
      fontFamily: "TTNormsRegular",
      padding: "16px",
      wordBreak: "break-word",
      whiteSpace: "normal",
      maxHeight: "inherit !important",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "inherit !important",
      "&:nth-child(odd)": {
        bgcolor: "transparent",
      },
      "&:nth-child(even)": {
        bgcolor: "primary.buttongrey",
      },
    },
  },
};

const footer = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const DetailUser = {
  display: "flex",
  alignItems: "baseline",
  "& .label": {
    fontSize: "24px",
    fontFamily: "TTNormsMedium",
    maxWidth: "312px",
    textAlign: "left",
    width: "100%",
    "@media (max-width:1600px) ": {
      fontSize: "18px",
      maxWidth: "240px",
    },
  },
  "& .value": {
    fontSize: "24px",
    fontFamily: "TTNormsRegular",
    textAlign: "left",
    width: "auto",
    padding: "0px 10px",
    "@media (max-width:1600px) ": {
      fontSize: "18px",
    },
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0",
  },
  "& .border-value": {
    border: "1px solid #c7c9c9",
  },
};

export { DataGridWrap, footer, DetailUser };

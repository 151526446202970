const style = {
  maxWidth: "512px",
  width: "98%",
  bgcolor: "#eef0f0",
  borderRadius: "12px",
  margin: "32px",
  position: "relative",
  overflowY: "auto",
  display: "inline-block",
  verticalAlign: "middle",
  textAlign: "left",
  padding: 4,
};

const titleStyle = {
  "& .MuiButton-text": {
    padding: "0px",
    fontSize: "14px",
    color: "green.main",
    fontFamily: "TTNormsRegular",
    height: "auto",
    minWidth: "auto",
  },
};

const roles = {
  "& p": {
    border: "white 2px solid",
    padding: "10px",
    cursor: "pointer",
    borderRadius: "10px",
    fontSize: "18px",
    "& span": {
      color: "#c6c6c6",
      marginLeft: "4px",
    },
  },
  "& .selected": {
    backgroundColor: "#ffffff",
  },
};
const facebook = {
  color: "rgb(25, 118, 210)",
  marginTop: "1px",
};

const bordered = {
  border: "1px solid #dfdddd",
  borderRadius: "3px",
  padding: "6px",
  display: "inline-flex",
  alignItems: "center",
};
const borderedLogin = {
  border: "1px solid #dfdddd",
  borderRadius: "3px",
  padding: "6px",
  display: "inline-flex",
  alignItems: "center",
};

const FooterBtn = {
  margin: "60px auto 0px",
  "& .MuiButton-contained": {
    backgroundColor: "green.main",
    borderRadius: "20px",
    fontSize: "16px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "green.dark",
      color: "black.main",
    },
  },
  "& .MuiButton-text": {
    padding: "0px",
    fontSize: "16px",
    fontFamily: "TTNormsRegular",
    height: "auto",
    minWidth: "auto",
    display: "block",
    margin: "25px auto 0px",
  },
};

export {
  style,
  titleStyle,
  roles,
  FooterBtn,
  facebook,
  bordered,
  borderedLogin,
};

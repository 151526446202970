import React, { useEffect, useRef, useState } from "react";
import { Stack, Button, Box, IconButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { PhotoUpload } from "./styles";
import { InputFiles } from "typescript";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setToaster } from "../../../store/slices/LoadinAndNotifSlice";
import { useTranslation } from "react-i18next";

interface inputProps {
  video: InputFiles | undefined;
  setVideo: React.Dispatch<React.SetStateAction<InputFiles | undefined>>;
  videoSelected: boolean;
  setVideoSelected: React.Dispatch<React.SetStateAction<boolean>>;
  videoName: string;
  setVideoName: React.Dispatch<React.SetStateAction<string>>;
  videoRef: React.RefObject<HTMLVideoElement>;
  inputRef: React.RefObject<HTMLInputElement>;
}

function UploadVideo({
  video,
  setVideo,
  videoSelected,
  setVideoSelected,
  videoName,
  setVideoName,
  videoRef,
  inputRef,
}: inputProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const videoTempUrl = useAppSelector(
    (state) => state.campaignPreviewReducer.videoUrl
  );

  const handleUploadChange = (e: any) => {
    const file = e.target.files;
    if (
      file[0] !== undefined &&
      file[0].name.length > 0 &&
      Math.floor(file[0].size / 1000000) <= 15
    ) {
      setVideoSelected(true);
      setVideo(file[0]);
      if (videoRef && videoRef.current) {
        videoRef.current.src = URL.createObjectURL(e.target.files[0]);
        setVideoName(URL.createObjectURL(e.target.files[0]));
      }
    }

    let fileType = file[0].name.split(".")[file[0].name.split(".").length - 1];

    if (fileType.toLowerCase() !== "mp4") {
      setVideoSelected(false);
      setVideoName("");
      setVideo(undefined);
      const toastPaylaod = {
        text: t("pleaseSelectMpFourVideoFile"),
        success: false,
        active: true,
      };
      if (videoRef && videoRef.current) {
        videoRef.current.src = "none";
      }
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    }

    if (file[0].size / 1000000 > 15) {
      setVideoSelected(false);
      setVideoName("");
      setVideo(undefined);
      const toastPaylaod = {
        text: t("pleaseSelectVideoFileSmallerThanFiveMb"),
        success: false,
        active: true,
      };
      if (videoRef && videoRef.current) {
        videoRef.current.src = "none";
      }
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    }
  };

  const clearvideoData = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setVideo(undefined);
    setVideoName("");
    setVideoSelected(false);
    if (videoRef && videoRef.current) {
      videoRef.current.src = "none";
    }
  };

  useEffect(() => {
    if (
      videoTempUrl &&
      videoTempUrl.length > 0 &&
      videoRef &&
      videoRef.current
    ) {
      videoRef.current.src = videoTempUrl;
    }
  }, []);

  useEffect(() => {
    if (video && videoName.length > 0 && videoRef && videoRef.current) {
      setVideoSelected(true);
      videoRef.current.src =
        videoName?.search("blob:") === -1
          ? `${process.env.REACT_APP_URL}/api/file/video/${videoName}`
          : videoName;
    }
  }, [videoName]);

  return (
    <>
      <Box sx={PhotoUpload} style={{ position: "relative" }}>
        {videoSelected && (
          <IconButton onClick={clearvideoData}>
            <CancelIcon />
          </IconButton>
        )}
        <video
          style={videoSelected ? {} : { display: "none" }}
          ref={videoRef}
          controls
          width={"100%"}
          height={"100%"}
          autoPlay={false}
        />
      </Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <label htmlFor="contained-button-video">
          <input
            className="inputfile"
            accept="video/mp4,video/x-m4v,video/*"
            id="contained-button-video"
            onChange={handleUploadChange}
            multiple={false}
            ref={inputRef}
            type="file"
          />
          <Button
            variant="text"
            component="span"
            className="uploadButton"
            startIcon={<FileUploadIcon />}
          >
            {t("videoUpload")}
          </Button>
        </label>
      </Stack>
    </>
  );
}

export default UploadVideo;

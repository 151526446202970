import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const FundCampaign = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
        {t("fundCampaign")}
      </Typography>
    </Box>
  );
};

export default FundCampaign;

import { Grid, Typography, Stack, Button, Tooltip, Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { goal, grant, grantType } from "./HomeGrantContainer";
import moment from "moment";
import { Link } from "react-router-dom";
import { Range, UploadBox } from "./style";
import algo from "../../../assets/images/algo.png";
import { useTranslation } from "react-i18next";
import { LinkBox } from "../campaigns/style";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const URL = process.env.REACT_APP_URL;
interface grantCardProps {
  data: grant;
}
const GrantCard = ({ data }: grantCardProps) => {
  const { t } = useTranslation();
  const [daysRemaining, setDaysRemaining] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [bgImg, setBgImg] = useState<string>("");
  const card = useRef<HTMLDivElement>(null);
  const [isEsgEnabled, setIsEsgEnabled] = useState<boolean>(false);
  const loadBackground = () => {
    if (data.images.length > 0) {
      const src = `${URL}/api/file/image/${data?.images[0]}`;

      const imageLoader = new Image();
      imageLoader.src = src;

      imageLoader.onload = () => {
        card.current?.classList.remove("animate");
        setBgImg(src);
      };
    }
  };
  useEffect(() => {
    loadBackground();
    const today = moment();
    const endDate = moment(data.endDate);
    setDaysRemaining(endDate.diff(today, "days"));
    const per = ((data.totalAcceptedFund / data.totalBudget) * 100).toPrecision(
      2
    );
    setIsEsgEnabled(data.grantType === grantType.STANDARD ? true : false);
    setPercentage(parseFloat(per));
  }, []);
  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Box sx={LinkBox}>
        <Link
          className="LinkBox"
          style={{ textDecoration: "none", color: "inherit" }}
          to={`/grant/${data.grantId}`}
        >
          <Box
            sx={UploadBox}
            component={"div"}
            className="animate"
            ref={card}
            style={
              bgImg.length > 1
                ? {
                    backgroundImage: `url('${bgImg}')`,
                    // backgroundImage: `url('${URL}/api/file/image/${data.images[0]}')`,
                    display: "flex",
                    justifyContent: "end",
                  }
                : {}
            }
          >
            {isEsgEnabled && data?.rating >= 0 && (
              <span
                style={{
                  height: "36px",
                  width: "42px",
                  backgroundColor: "#4caf50",
                  borderRadius: "50%",
                  fontSize: "17px",
                  marginTop: "17px",
                  marginRight: "17px",
                  color: "#ffffff",
                  lineHeight: "36px",
                }}
                title={t("cashdilloSdgCampaignRatingScore") || ""}
              >
                {data.rating}
              </span>
            )}
          </Box>
          <Box sx={{ height: "35px" }}>
            {isEsgEnabled && (
              <Stack direction="row" spacing={2}>
                {data?.goals.filter((goal: goal) => {
                  return goal.category === "environmental";
                }).length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      height: "30px",
                      backgroundColor: "#8bc34a7d",
                      fontSize: "10px",
                      padding: "10px 38px",
                      margin: "0 3px",
                    }}
                  >
                    {t("environmental")}
                  </Button>
                )}
                {data?.goals.filter((goal: any) => {
                  return goal.category === "social";
                }).length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      height: "30px",
                      backgroundColor: "#ffeb3b99",
                      fontSize: "10px",
                      padding: "10px 38px",
                      margin: "0 3px",
                    }}
                  >
                    {t("social")}
                  </Button>
                )}
                {data?.goals.filter((goal: any) => {
                  return goal.category === "governance";
                }).length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      height: "30px",
                      backgroundColor: "#2196f3a3",
                      fontSize: "10px",
                      padding: "10px 38px",
                      margin: "0 3px",
                    }}
                  >
                    {t("governance")}
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          <Typography
            sx={{
              color: "initial",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginTop: "20px",
              textTransform: "uppercase",
            }}
            variant="h6"
            align="left"
            mb={0}
            className="bold"
          >
            {data.title}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            mb={1}
            sx={{
              color: "#9d9d9c",
              wordBreak: "break-word",
              minHeight: "52px",
            }}
          >
            <Grid container spacing={3} style={{ textAlign: "left" }}>
              <Grid item xs={12} md={4}>
                <p>{t("noOfGrants")}</p>
                <Typography variant="body1">
                  {data?.totalGrants || 0}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <p>{t("endDate")}</p>
                <Typography variant="body1">
                  {moment(data.endDate).format("DD-MM-YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <p>{t("territories")}</p>
                <Typography variant="body1">
                  {data?.preferredLocation.charAt(0).toUpperCase() +
                    data?.preferredLocation.slice(1)}
                </Typography>
              </Grid>
              <br></br>
              <Grid item xs={12} md={4}>
                <p>{t("minGrantRange")}</p>
                <Typography variant="body1">
                  {data?.minimumGrant}
                  {data?.paymentTypeBlockchain === "ALGO" && (
                    <img
                      style={{ width: "11px", marginBottom: "-1px" }}
                      src={algo}
                    ></img>
                  )}
                  {data?.paymentTypeBlockchain === "USDC" && (
                    <MonetizationOnIcon
                      style={{
                        marginBottom: "-5px",
                        fontSize: "20px",
                        color: "black",
                      }}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <p>{t("maxGrantRange")}</p>
                <Typography variant="body1">
                  {data?.maximumGrant}
                  {data?.paymentTypeBlockchain === "ALGO" && (
                    <img
                      style={{ width: "11px", marginBottom: "-1px" }}
                      src={algo}
                    ></img>
                  )}
                  {data?.paymentTypeBlockchain === "USDC" && (
                    <MonetizationOnIcon
                      style={{
                        marginBottom: "-5px",
                        fontSize: "20px",
                        color: "black",
                      }}
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Typography>
          <Stack
            direction="row"
            spacing={20}
            style={{
              marginTop: "0",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#000000e3",
                    fontSize: "16px",
                    padding: "18px",
                    maxWidth: "320px",
                    maxHeight: "420px",
                    textAlign: "justify",
                    whiteSpace: "pre-line",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "& .MuiTooltip-arrow": {
                      color: "#ffffff",
                    },
                  },
                },
              }}
              title={data.shortDescription}
              placement="top-start"
            >
              <Button
                variant="contained"
                style={{
                  height: "30px",
                  backgroundColor: "#61f359",
                  fontSize: "10px",
                  padding: "10px 20px",
                  margin: "0px 2px",
                }}
              >
                {t("quickInfo")}
              </Button>
            </Tooltip>
            <Button
              variant="contained"
              style={{
                height: "30px",
                backgroundColor: "#61f359",
                fontSize: "10px",
                padding: "10px 20px",
                margin: "0px 2px",
              }}
            >
              {t("viewDetails")}
            </Button>
          </Stack>
          <Box sx={Range}>
            <Box className="RangeBox">
              <div
                className="rangecolor"
                style={{
                  width: `${
                    isNaN(percentage) ? 0 : percentage > 100 ? 100 : percentage
                  }%`,
                }}
              ></div>
            </Box>
            <Box className="valuebox">
              <Typography variant="body2" className="percentage semibold">
                {isNaN(percentage) ? 0 : percentage > 100 ? 100 : percentage}%
              </Typography>
              <Typography variant="body2" className="days semibold">
                {daysRemaining} {t("days")}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
    </Grid>
  );
};

export default GrantCard;

import React, { useEffect } from "react";
import "@smile_identity/smart-camera-web";

const SmartWebCamera = ({ setImageSelected, setSelectedImageData }) => {
  useEffect(() => {
    const app = document.querySelector("smart-camera-web");
    app.addEventListener("imagesComputed", (e) => {
      setImageSelected(true);
      setSelectedImageData({ ...e.detail });
    });
  }, []);

  return <smart-camera-web capture-id />;
};

export default SmartWebCamera;

const DataGridWrap = {
  height: "500px",
  width: "100%",
  "& .MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      bgcolor: "primary.buttongrey",
      borderBottom: "none",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "18px",
        fontFamily: "TTNormsMedium",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "18px",
      fontFamily: "TTNormsRegular",
      padding: "16px",
      wordBreak: "break-word",
      whiteSpace: "normal",
      maxHeight: "inherit !important",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "inherit !important",
      "&:nth-child(odd)": {
        bgcolor: "transparent",
      },
      "&:nth-child(even)": {
        bgcolor: "primary.buttongrey",
      },
    },
  },
};

const footer = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const grantApplicationView = {
  "& .container-fluid": {
    width: "100%",
    padding: "0px 15px",
  },
  "& .container": {
    width: "1140px",
    "margin-right": "auto",
    "margin-left": "auto",
  },
  "& .row": {
    " --bs-gutter-x": "1.5rem",
    " --bs-gutter-y": " 0",
    display: "flex",
    "flex-wrap": "wrap",
    "margin-top": "calc(-1 * var(--bs-gutter-y))",
    "margin-right": "-15px",
    "margin-left": "15px",
  },
  "& .text-center": {
    "text-align": "center !important",
  },
  "& .grant-form-label": {
    color: " #344054",
    "font-size": "18px",
    "font-weight": "700",
    "text-align": "left",
  },
  "& .grant-form-data": {
    color: "#667085",
    "font-size": "16px",
    width: "98%",
    "text-align": "left",
    "word-break": "break-word",
  },
  "& .grant-form-1": {
    width: "48%",
  },
  "& .grant-form-data-image": {
    "background-image":
      "url(https://di-sitebuilder-assets.s3.amazonaws.com/generic-placeholder.png)",
    "background-size": "200px",
    height: "110px",
    " background-repeat": " no-repeat",
  },
  "& .main-application": {
    "background-color": "#F9FAFB",
  },
  "& .grant-form-label span img": {
    width: "18px",
    " vertical-align": "sub",
  },
  "& .Milestone-box1": {
    width: "50%",
  },
  "& .Milestone-box2": {
    width: "50%",
  },
};

export const MilestoneForm = {
  marginTop: "-24px",
  paddingBottom: "20px",
  "& .inputfile": {
    display: "none",
  },
  "& .uploadButton": {
    color: "#000",
    fontFamily: "TTNormsRegular",
    marginTop: 1,
    paddingLeft: 0,
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontFamily: "TTNormsMedium",
      borderRadius: "6px",
      textAlign: "left",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
  "& .MuiFormLabel-root": {
    textAlign: "left",
    marginBottom: 2,
  },
  "& ol": {
    "& li": {
      textAlign: "left",
      margin: "20px 0 10px",
    },
  },
};

export const PhotoUpload = {
  maxWidth: "300px",
  maxHeight: "300px",
  width: "100%",
  height: "300px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
  marginTop: "20px",
  display: "flex",

  backgroundColor: "#fff",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: "0px",
    top: "0px",
    zIndex: "2",
  },
};

export { DataGridWrap, footer, grantApplicationView };

import React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Menu,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { getAllGoals, saveToDraft } from "../../../../requests/authRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { useTranslation } from "react-i18next";
import Wallets from "../../../../wallets";
import { useState } from "react";

function EditGoalPage({
  handleSteps,
  goalIds,
  handleGoalIds,
  isAdditionalInfo,
  handleCreateCampaign,
  isMatchFund,
}: any) {
  const { t } = useTranslation();
  const kyc = useAppSelector((state) => state.userReducer.kyc);
  const [allGoals, setAllGoals] = React.useState<
    { category: string; goalId: string; goalName: string; status: string }[]
  >([]);

  const dispatch = useAppDispatch();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  /**
   *
   * menu
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * menu
   */

  /**
   *
   * menu2
   */

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  /**
   *
   * menu
   */

  const fetchGoals = async () => {
    const res = await getAllGoals();
    setAllGoals(res?.data?.goals);
  };

  React.useEffect(() => {
    fetchGoals();
  }, []);

  return (
    <div>
      <Typography style={{ textAlign: "left", fontSize: "2rem" }} variant="h3">
        {t("chooseYourCampaignsEsgOrSdgGoals")}
      </Typography>
      <Box sx={{ p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Grid>
          <Box sx={{ mt: 2 }} style={{ textAlign: "left" }}>
            <Typography
              style={{ textAlign: "left", fontSize: "17px", fontWeight: 700 }}
            >
              {t("pleaseChooseAtleastOneGoalAndUptoSixGoals")}
            </Typography>

            <Box sx={{ marginTop: "20px" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("doYouWantToReceiveMatchFunding")}
              </InputLabel>
              <Button
                disabled
                sx={{ color: "#000", border: "1px solid gray" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {isMatchFund ? "Yes" : "No"}
              </Button>
            </Box>

            <FormHelperText>
              {t("thisWillIncreaseYourChancesOfReceivingAdditionalFunding")}
            </FormHelperText>
          </Box>
        </Grid>
        {allGoals?.filter((el) => el?.category === "environmental").length !==
          0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("environmental")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "environmental")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
        {allGoals?.filter((el) => el?.category === "social").length !== 0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("social")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "social")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
        {allGoals?.filter((el) => el?.category === "governance").length !==
          0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("governance")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "governance")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
      </Box>

      <Box sx={{ p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Typography
          style={{ textAlign: "left", fontSize: "1rem" }}
          variant="h5"
        >
          {t("doYouWantToShareMoreInformationAboutYourCampaignWithUs")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("pleaseSelectOption")}
          </InputLabel>
          <Button
            sx={{ color: "#000", border: "1px solid gray" }}
            disabled
            endIcon={<KeyboardArrowDownIcon />}
          >
            {isAdditionalInfo ? t("yes") : t("no")}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => handleSteps("MAIN")}
        >
          {t("back")}
        </Button>

        {kyc === "approved" && (
          <>
            <Button
              onClick={() => setShowWalletButtons(true)}
              variant="contained"
              color="primary"
              className="round-button"
            >
              {t("submit")}
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={() => {
                  if (goalIds.length === 0 || goalIds.length > 6) {
                    const toastPaylaod = {
                      text: t("pleaseSelectAtleastOneGoalOrMaxSixGoals"),
                      success: true,
                      active: true,
                    };
                    dispatch(setToaster(toastPaylaod));
                  } else {
                    handleCreateCampaign();
                  }
                }}
              />
            )}
          </>
        )}

        {isAdditionalInfo && (
          <Button
            variant="contained"
            color="primary"
            className="round-button"
            onClick={() => {
              if (goalIds.length === 0 || goalIds.length > 6) {
                const toastPaylaod = {
                  text: t("pleaseSelectAtleastOneGoalOrMaxSixGoals"),
                  success: true,
                  active: true,
                };
                dispatch(setToaster(toastPaylaod));
              } else {
                handleSteps("INFO");
              }
            }}
          >
            {t("next")}
          </Button>
        )}
      </Box>
    </div>
  );
}
export default EditGoalPage;

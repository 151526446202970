import React, { useEffect } from "react";
import { io } from "socket.io-client";
import { useAppDispatch, useAppSelector } from "../store";
import { setNotifSocket } from "../store/slices/SocketSlice";
const URL = process.env.REACT_APP_URL;

function NotificationSocket() {
  const token = useAppSelector((state) => state.userReducer.userToken);
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.userReducer.role);

  const handleNotifSocket = async () => {
    // console.log("handling the notification socket", token, token.length, role);
    if (token && token.length > 0) {
      // console.log("handling the notification socket i am in");
      const notifSocket = io(`${URL}/notifications`, {
        auth: {
          token: token,
        },
        path: "",
        transports: ["websocket", "polling"],

        transportOptions: {
          polling: {
            extraHeaders: {},
          },
        },
        reconnectionAttempts: 3,
      });

      notifSocket.on("connect", function () {
        dispatch(setNotifSocket(notifSocket));
      });
    } else {
      dispatch(setNotifSocket(undefined));
    }
  };

  useEffect(() => {
    // console.log("handle notif socket plese");
    handleNotifSocket();
  }, [token]);
  return <></>;
}

export default NotificationSocket;

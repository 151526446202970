import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { userRole } from "../../../constants/common-enum";
import { useAppSelector } from "../../../store";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const GrantOrganizationSideNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const navigations = [
    "grant-managers",
    "grant-list",
    "",
    "",
    "",
    "",
    "",
    "chat",
  ];

  const location = useLocation();
  const userslice = useAppSelector((state) => state.userReducer);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/user/${navigations[newValue]}`);
  };

  useEffect(() => {
    if (location.pathname === "/user") {
      setValue(0);

      navigate(`/user/${navigations[0]}`);
    } else {
      setValue(
        navigations.indexOf(location.pathname.split("/user")[1].split("/")[1])
      );
    }
  }, [location.pathname]);

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: "divider", marginBottom: "40px" }}
    >
      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab label={t("grantManagers")} {...a11yProps(0)} />
      )}

      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab label={t("manageGrants")} {...a11yProps(1)} />
      )}
      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab disabled label={t("reviewData")} {...a11yProps(2)} />
      )}
      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab disabled label={t("manageVotes")} {...a11yProps(3)} />
      )}
      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab disabled label={t("matchFunds")} {...a11yProps(4)} />
      )}
      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab disabled label={t("esgSuite")} {...a11yProps(5)} />
      )}
      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab disabled label={t("projectTools")} {...a11yProps(6)} />
      )}
      {userslice.role === userRole.GRANT_CREATOR && (
        <Tab label={t("chat")} {...a11yProps(7)} />
      )}
    </Tabs>
  );
};

export default GrantOrganizationSideNav;

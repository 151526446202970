import {
  Avatar,
  Badge,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu";
import { Messagerow } from "./styles";
import React, { useEffect, useRef, useState } from "react";
import SenderMessage from "./SenderMessage";
import MyMessage from "./MyMessage";
import { useAppSelector } from "../../../store";
import {
  getChatMessage,
  initiateChat,
  sendMessage,
} from "../../../requests/authRequest";
import { currentActiveChatInterface } from "../../../store/slices/ChatSlice";

interface investorCreatorChat {
  openSideNav: () => void;
}

export interface messageInterface {
  channelId: string;
  content: string;
  createdAt: string | Date;
  messageId: string;
  sentBy: string;
  type: string;
}

function InvestorCreatorChat({ openSideNav }: investorCreatorChat) {
  const [message, setMessage] = useState<string>("");
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [messageList, setMessageList] = useState<Array<messageInterface>>([]);
  const currentActiveChat = useAppSelector(
    (state) => state.chatReducer.currentActiveChat
  );
  const userId = useAppSelector((state) => state.userReducer.userId);
  const chatSocket = useAppSelector((state) => state.chatReducer.chatSocket);
  const renderArea = useRef<HTMLElement>();

  // these are refs
  const messageListRef = useRef<Array<messageInterface>>();
  const currentActiveChatRef = useRef<currentActiveChatInterface | undefined>();

  const loadChat = async () => {
    setMessageList([]);
    if (currentActiveChat && currentActiveChat && currentActiveChat.channelId) {
      const info = {
        channelId: currentActiveChat.channelId,
      };
      const res = await getChatMessage(info);
      if (res.success && renderArea && renderArea.current) {
        setMessageList([...res.data]);
        renderArea.current.scrollTop = renderArea.current.scrollHeight;
      }
    }
  };

  const handleMessageSend = async () => {
    if (message.trim().length > 0 && currentActiveChat) {
      const info = {
        channelId: currentActiveChat.channelId,
        content: message,
      };
      setLocalLoading(true);
      const res = await sendMessage(info);
      setLocalLoading(false);
      if (res.success && renderArea && renderArea.current) {
        const tempAry = [...messageList];
        tempAry.push(res.data);
        setMessageList([...tempAry]);
        setMessage("");
        renderArea.current.scrollTop = renderArea.current.scrollHeight;
        chatSocket.emit("updateLastRead", {
          channelId: currentActiveChatRef.current?.channelId,
        });
      }
    }
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${
        name.split(" ")[1] ? name.split(" ")[1][0] : ""
      }`,
    };
  };

  useEffect(() => {
    if (chatSocket) {
      chatSocket.on("newMessage", (data: messageInterface) => {
        const participant =
          currentActiveChatRef.current?.participants[0].userId === userId
            ? currentActiveChatRef.current?.participants[1].userId
            : currentActiveChatRef.current?.participants[0].userId;
        if (
          data.sentBy === participant &&
          renderArea &&
          renderArea.current &&
          messageListRef &&
          messageListRef.current
        ) {
          chatSocket.emit("updateLastRead", {
            channelId: currentActiveChatRef.current?.channelId,
          });
          const tempAry = [...messageListRef.current];
          tempAry.push(data);
          setMessageList([...tempAry]);
          renderArea.current.scrollTop = renderArea.current.scrollHeight;
        }
      });
    }
  }, [chatSocket]);

  useEffect(() => {
    loadChat();
    currentActiveChatRef.current = currentActiveChat;
  }, [currentActiveChat]);

  useEffect(() => {
    messageListRef.current = messageList;
  }, [messageList]);
  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#fff",
          p: 3,
          borderRadius: "8px",
          minHeight: "600px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{
              "@media(min-width:900px)": {
                display: "none",
              },
            }}
            onClick={openSideNav}
          >
            <MenuIcon />
          </IconButton>
          <ListItem key="CindyBaker">
            {currentActiveChat && currentActiveChat.name && (
              <ListItemIcon>
                <Avatar {...stringAvatar(currentActiveChat.name)} />
              </ListItemIcon>
            )}
            <ListItemText primary={currentActiveChat?.name}></ListItemText>
          </ListItem>
          <IconButton sx={{ marginLeft: "auto" }}>
            <MoreVertIcon />
          </IconButton>
        </Box>
        <hr />
        {/* render area for message start */}
        <Box ref={renderArea} sx={Messagerow}>
          {messageList.length > 0 &&
            messageList.map((message) => {
              if (userId === message.sentBy) {
                return <MyMessage data={message} />;
              } else {
                return <SenderMessage data={message} />;
              }
            })}
        </Box>
        {/* render area for message end */}
        <Box>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              endAdornment={
                !localLoading && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleMessageSend}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
              aria-describedby="outlined-weight-helper-text"
              multiline
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </Box>
    </div>
  );
}

export default InvestorCreatorChat;

const DetailUser = {
  display: "flex",
  alignItems: "baseline",
  "& .label": {
    fontSize: "24px",
    fontFamily: "TTNormsMedium",
    maxWidth: "312px",
    textAlign: "left",
    width: "100%",
    "@media (max-width:1600px) ": {
      fontSize: "18px",
      maxWidth: "240px",
    },
  },
  "& .value": {
    fontSize: "24px",
    fontFamily: "TTNormsRegular",
    textAlign: "left",
    width: "auto",
    padding: "0px 10px",
    "@media (max-width:1600px) ": {
      fontSize: "18px",
    },
  },
  "& .border-value": {
    border: "1px solid #c7c9c9",
  },
};

const CampaigngForm = {
  // marginTop: "-24px",
  "& .inputfile": {
    display: "none",
  },
  "& .uploadButton": {
    color: "#000",
    fontFamily: "TTNormsRegular",
    marginTop: 1,
    marginBottom: 2,
    paddingLeft: 0,
  },
  "& .MuiInputBase-root": {
    marginBottom: "24px",
  },
  "& .MuiOutlinedInput-root": {
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontFamily: "TTNormsMedium",
      borderRadius: "6px",
      textAlign: "left",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
  "& .MuiFormLabel-root": {
    textAlign: "left",
    marginBottom: 2,
  },
};
const PhotoUpload = {
  width: "300px",
  height: "300px",

  backgroundColor: "#fff",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
};
const grantInfoBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "80%",
  // boxShadow: "0px 0px 5px #55d54e",
  padding: "10px",
  margin: "auto",
  border: "1.5px solid #55d54e",
};

export { DetailUser, CampaigngForm, PhotoUpload, grantInfoBoxStyles };

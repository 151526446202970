import { Box, Typography } from "@mui/material";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { markNotificationRead } from "../../../requests/authRequest";
import algo from "../../../assets/images/algo.png";
import { useTranslation } from "react-i18next";

interface notifRow {
  isRead: boolean;
  to: string;
  type: string;
  campaign: {
    title: string;
    campaignId: string;
  };
  investor: {
    name: string;
    investorId: string;
  };
  donatedAmount: number;
  createdAt: string | Date;
  notificationId: string;
  donationId: string;
}

interface notificationRow {
  data: notifRow;
}

function NotificationRow({ data }: notificationRow) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const markAsRead = async () => {
    const info = {
      notificationId: data.notificationId,
    };
    markNotificationRead(info);
    if (data?.campaign?.campaignId) {
      navigate(`/campaign/${data.campaign.campaignId}`);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: "8px",
        width: "100%",
        marginBottom: 2,
        textAlign: "left",
        // backgroundColor: "primary.main",
        backgroundColor: data.isRead ? "#e1e5e5" : "primary.main",
        display: "flex",
        alignItems: "center",
        gap: "20px",
        cursor: "pointer",
      }}
      onClick={markAsRead}
    >
      <CreditScoreIcon fontSize="large" />
      <Box>
        <Typography variant="h6">
          <span style={{ fontFamily: "TTNormsBold" }}>
            {" "}
            {t("congratulations")}{" "}
          </span>
          {data?.investor?.name} funded {data?.donatedAmount}{" "}
          <img style={{ width: "14px", marginBottom: "-1px" }} src={algo}></img>{" "}
          to your {data?.campaign?.title}
        </Typography>
        <Typography variant="body2">
          {moment(data?.createdAt).fromNow()}
        </Typography>
      </Box>
    </Box>
  );
}

export default NotificationRow;

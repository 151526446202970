import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchCampaignReward } from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setRewardType } from "../../../store/slices/CreateCampaignSlice";
import { setToaster } from "../../../store/slices/LoadinAndNotifSlice";
import { UploadBoxRewardNft } from "./style";

interface modalNftCard {
  data: any;
}

function RewardNftCard({ data }: modalNftCard) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [rewardNft, setRewardNft] = useState<any>("");

  const fetchRewardNFT = async () => {
    const res = await fetchCampaignReward(Number(data));

    if (res.success) {
      setRewardNft(res.data);
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  useEffect(() => {
    fetchRewardNFT();
  }, [data]);

  return (
    <Box>
      <Typography variant="h5" className="semibold" align="left" mb={2}>
        {data.isAdmin ? "Cashdillo Reward" : "Cashdillo Reward"}
      </Typography>
      <div
        style={{
          background: "#d4d4d4",
          padding: "16px",
          borderRadius: "10px",
          cursor: "pointer",
          maxWidth: "300px",
          textAlign: "left",
        }}
        onClick={() => {}}
      >
        <Box sx={UploadBoxRewardNft}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              backgroundImage: `url('${rewardNft?.url}')`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          {/* <video
            style={{ objectFit: "cover" }}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            poster={data.media}
          >
            <source src={data.media} type="video/mp4" />
            <source src={data.media} type="video/ogg" />
            Your browser does not support the video tag.
          </video> */}
        </Box>
        <Typography
          sx={{
            color: "initial",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          variant="h6"
          align="left"
          mb={0}
          className="bold"
        >
          {t("unitName")} - {rewardNft?.unit_name}
        </Typography>
      </div>
    </Box>
  );
}

export default RewardNftCard;

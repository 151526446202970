import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMyGrantApplication } from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { grantApplicationView } from "./style";
import moment from "moment";
import fileIcon from "../../../assets/images/fileIcon.png";
import { userRole } from "../../../constants/common-enum";
import { useTranslation } from "react-i18next";
const URL = process.env.REACT_APP_URL;
export interface milestone {
  amount: number;
  deadline: string;
  description: string;
  status: string;
  title: string;
  milestoneId: string;
  _id: string;
}
export interface grantApplicationRes {
  document: string;
  donorsGoals: string;
  donorsObjective: string;
  facebookURL: string;
  impact: string;
  instagramURL: string;
  milestones: Array<milestone>;
  previousExperience: string;
  status: string;
  twitterURL: string;
  link: Array<string>;
  grantId?: {
    status: string;
    _id: string;
  };
}
const GrantApplicationView = () => {
  const { t } = useTranslation();
  const getInitialValues = () => {
    return {
      document: "",
      donorsGoals: "",
      donorsObjective: "",
      facebookURL: "",
      impact: "",
      instagramURL: "",
      milestones: [],
      previousExperience: "",
      status: "",
      twitterURL: "",
      link: [],
    };
  };
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const userSlice = useAppSelector((state) => state.userReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [grantApplication, setGrantApplication] = useState<grantApplicationRes>(
    getInitialValues()
  );
  const [milestoneDisable, setMilestoneDisable] = useState({
    milestoneOne: true,
    milestoneTwo: true,
  });
  const dispatch = useAppDispatch();

  const fetchApplicationDetails = async () => {
    try {
      setLoading(true);
      const payload = {
        grantApplicationId: applicationId,
      };
      const res = await getMyGrantApplication(payload);

      if (res.success) {
        setGrantApplication(res.data.application);
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toastPayload));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitMilestone = (index: any) => {
    navigate(
      `/user/view-application/${applicationId}/submit-report/${grantApplication.milestones[index]["_id"]}`
    );
  };

  useEffect(() => {
    fetchApplicationDetails();
  }, []);

  useEffect(() => {
    if (grantApplication) {
      if (
        grantApplication?.status === "accepted" &&
        grantApplication?.milestones[0]?.status === "in-progress"
      ) {
        setMilestoneDisable({
          ...milestoneDisable,
          milestoneOne: false,
        });
      }

      if (
        grantApplication?.status === "accepted" &&
        grantApplication?.milestones[0]?.status === "emergency" &&
        moment(grantApplication?.milestones[0]?.deadline).add(3, "months") >
          moment()
      ) {
        setMilestoneDisable({
          ...milestoneDisable,
          milestoneOne: false,
        });
      }

      if (
        grantApplication?.milestones[0]?.status !== "in-progress" &&
        grantApplication?.milestones[1]?.status === "in-progress"
      ) {
        setMilestoneDisable({
          ...milestoneDisable,
          milestoneTwo: false,
        });
      }

      if (
        grantApplication?.milestones[0]?.status !== "in-progress" &&
        grantApplication?.milestones[1]?.status === "emergency" &&
        moment(grantApplication.milestones[0].deadline).add(3, "months") >
          moment()
      ) {
        setMilestoneDisable({
          ...milestoneDisable,
          milestoneTwo: false,
        });
      }
    }
  }, [grantApplication]);

  useEffect(() => {
    dispatch(toggleAppLoading(loading));
  }, [loading]);

  return (
    <>
      <Container sx={grantApplicationView}>
        <div className="container-fluid">
          <div className="main-view-div">
            <div className="row">
              <h1>{t("grantApplicationDetails")}</h1>
            </div>
            <div className="main-application">
              <div className="row">
                <div
                  className="grant-form-1"
                  style={{
                    minHeight: "50%",
                    fontSize: "20px",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                >
                  <p className="grant-form-label">
                    {t(
                      "howDoesYourApplicationOrSolutionAddressTheDonorsObjectives"
                    )}
                  </p>
                  <p className="grant-form-data">
                    {grantApplication.donorsObjective || t("notProvided")}
                  </p>
                </div>
                <div
                  className="grant-form-1"
                  style={{
                    minHeight: "50%",
                    fontSize: "20px",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                >
                  <p className="grant-form-label">
                    {t("describeTheImpactYourApplicationOrSolutionWillHave")}
                  </p>
                  <p className="grant-form-data">
                    {grantApplication.impact || t("notProvided")}
                  </p>
                </div>
              </div>

              <div className="row">
                <div
                  className="grant-form-1"
                  style={{
                    minHeight: "50%",
                    fontSize: "20px",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                >
                  <p className="grant-form-label">
                    {t(
                      "howWillYourApplicationOrSolutionEngageWithTheSustainableDevelopmentGoalsTheDonosGrantAspiresTo"
                    )}
                  </p>
                  <p className="grant-form-data">
                    {grantApplication.donorsGoals || t("notProvided")}
                  </p>
                </div>
                <div
                  className="grant-form-1"
                  style={{
                    minHeight: "50%",
                    fontSize: "20px",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                >
                  <p className="grant-form-label">
                    {t(
                      "pleaseDetailAnyRelevantPreviousExperienceTheGrantManagerShouldBeAwareOf"
                    )}
                  </p>
                  <p className="grant-form-data">
                    {grantApplication.previousExperience || t("notProvided")}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="grant-form-1">
                  <p className="grant-form-label">
                    {t("previousExperienceLink")}
                  </p>

                  {grantApplication?.link[0] ? (
                    <Link
                      href={
                        grantApplication?.link[0] &&
                        grantApplication?.link[0].includes("http")
                          ? grantApplication?.link[0]
                          : `https://${grantApplication?.link[0]}`
                      }
                      target={"_blank"}
                      underline="none"
                      rel="noreferrer"
                    >
                      {" "}
                      <p className="grant-form-data-data">
                        {grantApplication?.link[0]}
                      </p>
                    </Link>
                  ) : (
                    <p className="grant-form-data">{t("notProvided")}</p>
                  )}
                </div>
                <div className="grant-form-1">
                  <p className="grant-form-label">
                    {t("facebookUrl")} ({t("optional")})
                  </p>

                  {grantApplication.facebookURL ? (
                    <Link
                      href={
                        grantApplication.facebookURL &&
                        grantApplication.facebookURL.includes("http")
                          ? grantApplication.facebookURL
                          : `https://${grantApplication.facebookURL}`
                      }
                      target={"_blank"}
                      underline="none"
                      rel="noreferrer"
                    >
                      {" "}
                      <p className="grant-form-data-data">
                        {grantApplication.facebookURL}
                      </p>
                    </Link>
                  ) : (
                    <p className="grant-form-data">{t("notProvided")}</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="grant-form-1">
                  <p className="grant-form-label">
                    {t("instagramUrl")} ({t("optional")})
                  </p>
                  {grantApplication.instagramURL ? (
                    <Link
                      href={
                        grantApplication.instagramURL &&
                        grantApplication.instagramURL.includes("http")
                          ? grantApplication.instagramURL
                          : `https://${grantApplication.instagramURL}`
                      }
                      underline="none"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      {" "}
                      <p className="grant-form-data-data">
                        {grantApplication.instagramURL}
                      </p>
                    </Link>
                  ) : (
                    <p className="grant-form-data">{t("notProvided")}</p>
                  )}
                </div>
                <div className="grant-form-1">
                  <p className="grant-form-label">
                    {t("twitterUrl")} ({t("optional")})
                  </p>
                  {grantApplication.twitterURL ? (
                    <Link
                      href={
                        grantApplication.twitterURL &&
                        grantApplication.twitterURL.includes("http")
                          ? grantApplication.twitterURL
                          : `https://${grantApplication.twitterURL}`
                      }
                      target={"_blank"}
                      rel="noreferrer"
                      underline="none"
                    >
                      <p className="grant-form-data-data">
                        {grantApplication.twitterURL}
                      </p>
                    </Link>
                  ) : (
                    <p className="grant-form-data">{t("notProvided")}</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="grant-form-1">
                  <p className="grant-form-label">
                    {t(
                      "pleaseProvideAnyAdditionalDocumentRequestedByTheGrantManager"
                    )}
                  </p>

                  <p>
                    {grantApplication?.document ? (
                      <a
                        href={`${URL}/api/file/${grantApplication.document}`}
                        target="_blank"
                      >
                        <img
                          style={{
                            width: "16px",
                            marginBottom: "-3px",
                          }}
                          src={fileIcon}
                        ></img>
                      </a>
                    ) : (
                      <p className="grant-form-data">{t("notProvided")}</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {grantApplication.milestones &&
          grantApplication.milestones.length > 0 ? (
            <Grid item xs={12}>
              <Typography variant="h4" align="left" mb={4} mt={4}>
                {t("milestoneInfo")}
              </Typography>
              {grantApplication.milestones.map((item: any, index: any) => {
                return (
                  <Stack spacing={2} key={item.milestoneId}>
                    <Box mb={1}>
                      <Typography
                        variant="h5"
                        fontWeight={"600"}
                        align="left"
                        sx={{
                          marginBottom: "20px",
                        }}
                      >
                        {t("milestone")} {index + 1}
                      </Typography>
                      <Typography variant="h6" mb={1} align="left">
                        <b>{t("title")} - </b>
                        {item.title}
                      </Typography>
                      <Typography variant="h6" mb={1} align="left">
                        <b>{t("amount")} - </b>
                        {item.amount}
                      </Typography>
                      <Typography variant="h6" mb={1} align="left">
                        <b>{t("description")} - </b>
                        {item.description}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        style={{ display: "flex" }}
                      >
                        <b>{t("submissionStatus")} - </b>
                        <Box
                          sx={{
                            paddingLeft: "0.175rem",
                            color:
                              item.status === "pending" ||
                              item.status === "in-progress"
                                ? "#FFA500"
                                : item.status === "completed" ||
                                  item.status === "report-submitted"
                                ? "#00FF00"
                                : item.status === "rejected"
                                ? "#ff0000"
                                : "#000",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.status}
                        </Box>
                      </Typography>
                      {item.status === "rejected" && (
                        <Typography variant="h6" mb={1} align="left">
                          <b>{t("comment")} - </b>
                          {item.comments}
                        </Typography>
                      )}
                      <Typography variant="h6" mb={1} align="left">
                        <b>{t("submissionDeadline")} - </b>
                        {moment(item.deadline).format("MMM DD YYYY")}
                      </Typography>
                      {userSlice.role &&
                        userSlice.role === userRole.GRANT_APPLICANT && (
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "primary",
                              mt: 4,
                              mr: 2,
                              "&:hover": {
                                backgroundColor: "primary",
                              },
                              "&:focus": {
                                backgroundColor: "primary",
                              },
                            }}
                            disabled={
                              Object.values(milestoneDisable)[index] ||
                              grantApplication?.grantId?.status === "blocked"
                            }
                            onClick={() => handleSubmitMilestone(index)}
                          >
                            {t("submitMilestone")} {index + 1} {t("report")}
                          </Button>
                        )}
                    </Box>
                  </Stack>
                );
              })}
            </Grid>
          ) : (
            ""
          )}
        </div>
      </Container>
    </>
  );
};

export default GrantApplicationView;

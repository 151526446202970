const DataGridWrap = {
  height: "500px",
  width: "100%",
  "& .MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      bgcolor: "primary.buttongrey",
      borderBottom: "none",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "18px",
        fontFamily: "TTNormsMedium",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "18px",
      fontFamily: "TTNormsRegular",
      padding: "16px",
      wordBreak: "break-word",
      whiteSpace: "normal",
      maxHeight: "inherit !important",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "inherit !important",
      "&:nth-child(odd)": {
        bgcolor: "transparent",
      },
      "&:nth-child(even)": {
        bgcolor: "primary.buttongrey",
      },
    },
  },
};
export const CampaigngForm = {
  marginTop: "-24px",
  "& .inputfile": {
    display: "none",
  },
  "& .uploadButton": {
    color: "#000",
    fontFamily: "TTNormsRegular",
    marginTop: 1,
    marginBottom: 2,
    paddingLeft: 0,
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontFamily: "TTNormsMedium",
      borderRadius: "6px",
      textAlign: "left",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
  "& .MuiFormLabel-root": {
    textAlign: "left",
    marginBottom: 2,
  },
};

const footer = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

export const MilestoneForm = {
  marginTop: "-24px",
  paddingBottom: "20px",
  "& .inputfile": {
    display: "none",
  },
  "& .uploadButton": {
    color: "#000",
    fontFamily: "TTNormsRegular",
    marginTop: 1,
    paddingLeft: 0,
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontFamily: "TTNormsMedium",
      borderRadius: "6px",
      textAlign: "left",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
  "& .MuiFormLabel-root": {
    textAlign: "left",
    marginBottom: 2,
  },
  "& ol": {
    "& li": {
      textAlign: "left",
      margin: "20px 0 10px",
    },
  },
};

export const blackBoxHeading = {
  marginRight: "auto",
  backgroundColor: "#000000de",
  color: "#61f359",
  cursor: "default",
  borderRadius: "10px",
  marginBottom: "20px",
  padding: "10px",
  fontWeight: "bold",
  letterSpacing: "1px",
};

export const PhotoUpload = {
  maxWidth: "300px",
  maxHeight: "300px",
  width: "100%",
  height: "300px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
  marginTop: "20px",
  display: "flex",

  backgroundColor: "#fff",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: "0px",
    top: "0px",
    zIndex: "2",
  },
};

export { DataGridWrap, footer };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface managerState {
  fullName: string;
  contactEmail: string;
  facebookUrl: string;
  displayName: string;
  contactPhone: string;
  instagramUrl: string;
  description: string;
  website: string;
  twitterUrl: string;
  videoUrl: string;
  imageUrl: any | undefined;
  video: any | undefined;
  image: any | undefined;
  clubhouseUrl: string;
  youtubeUrl: string;
  videoSelected: boolean;
  imageSelected: boolean;
}

const initialState: managerState = {
  fullName: "",
  description: "",
  contactEmail: "",
  displayName: "",
  facebookUrl: "",
  contactPhone: "",
  instagramUrl: "",
  website: "",
  videoUrl: "",
  imageUrl: "",
  twitterUrl: "",
  video: undefined,
  image: null,
  clubhouseUrl: "",
  videoSelected: false,
  imageSelected: false,
  youtubeUrl: "",
};

export const managerPreviewSlice = createSlice({
  name: "ManagerPreviewSlice",
  initialState,
  reducers: {
    setManagerPreview: (state, action: PayloadAction<managerState>) => {
      state.fullName = action.payload.fullName;
      state.description = action.payload.description;
      state.contactEmail = action.payload.contactEmail;
      state.displayName = action.payload.displayName;
      state.facebookUrl = action.payload.facebookUrl;
      state.contactPhone = action.payload.contactPhone;
      state.instagramUrl = action.payload.instagramUrl;
      state.website = action.payload.website;
      state.videoUrl = action.payload.videoUrl;
      state.imageUrl = action.payload.imageUrl;
      state.twitterUrl = action.payload.twitterUrl;
      state.video = action.payload.video;
      state.videoSelected = action.payload.videoSelected;
      state.image = action.payload.image;
      state.imageSelected = action.payload.imageSelected;
      state.clubhouseUrl = action.payload.clubhouseUrl;
      state.youtubeUrl = action.payload.youtubeUrl;
    },
  },
});

export const { setManagerPreview } = managerPreviewSlice.actions;

export default managerPreviewSlice.reducer;

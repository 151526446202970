const PreviewWrap = {
  padding: "10px 0px",
};
const UploadBox = {
  height: "534px",
  width: "100%",
  bgcolor: "white.main",
  marginBottom: 3,
  display: "flex",
};
const Range = {
  "& .RangeBox": {
    height: "27px",
    width: "100%",
    border: "1px solid",
    borderColor: "secondary.main",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& .rangecolor": {
      height: "20px",
      width: "40%",
      bgcolor: "green.main",
      maxWidth: "calc(100% - 6px)",
      marginLeft: "3px",
    },
  },
  "& .valuebox": {
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
const DescriptionTabs = {
  marginTop: 3,
  minHeight: "400px",
  "& .MuiTab-root": {
    fontSize: "16px",
    textTransform: "none",
    bgcolor: "primary.buttongrey",
    marginRight: "24px",
    borderRadius: "16px",
    minHeight: "42px",
    color: "black.main",
    "&:last-child": {
      marginRight: "0px",
    },
    "&.Mui-selected": {
      bgcolor: "primary.buttongreydark",
      color: "black.main",
    },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabPanel-root": {
    padding: "16px 0px 16px 0px",
  },
};
const SocialIcon = {
  bgcolor: "black.main",
  color: "primary.main",
  width: "34px",
  height: "34px",
  marginRight: 1,
  "& .MuiSvgIcon-root": {
    color: "primary.main",
  },
  "& a": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "&:hover": {
    bgcolor: "#1c1c1c",
    color: "primary.main",
  },
  "&:focus": {
    bgcolor: "#1c1c1c",
    color: "primary.main",
  },
  "&:last-child": {
    marginRight: 0,
  },
};

const thumbnailImg = {
  width: "100px",
  height: "100px",
  cursor: "pointer",
};
export {
  PreviewWrap,
  UploadBox,
  Range,
  DescriptionTabs,
  SocialIcon,
  thumbnailImg,
};

import React, { useEffect } from "react";
import { io } from "socket.io-client";
import { useAppDispatch, useAppSelector } from "../store";
import { setChatSocket, setCurrentActiveChat } from "../store/slices/ChatSlice";
const URL = process.env.REACT_APP_URL;

function ChatSocket() {
  const token = useAppSelector((state) => state.userReducer.userToken);
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const currentActiveChatRedux = useAppSelector(
    (state) => state.chatReducer.currentActiveChat
  );
  const chatSocketInRedux = useAppSelector(
    (state) => state.chatReducer.chatSocket
  );

  const handleChatSocket = async () => {
    if (loggedIn && token && token.length > 0) {
      const chatSocket = io(`${URL}/chat`, {
        auth: {
          token: token,
        },
        path: "",
        transports: ["websocket", "polling"],

        transportOptions: {
          polling: {
            extraHeaders: {},
          },
        },
        reconnection: true,
        reconnectionDelay: 500,
        // reconnectionAttempts: 3,
      });
      chatSocket.connect();
      chatSocket.on("connect", function () {
        dispatch(setChatSocket(chatSocket));
      });
    } else {
      if (chatSocketInRedux && chatSocketInRedux.connected) {
        chatSocketInRedux.disconnect();
      }
      dispatch(setCurrentActiveChat(undefined));
      dispatch(setChatSocket(undefined));
    }
  };

  useEffect(() => {
    handleChatSocket();
  }, [loggedIn]);

  return <></>;
}

export default ChatSocket;

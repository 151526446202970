import React, { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Button,
  Container,
  Grid,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store";
import Logo from "../../assets/images/logo.svg";
import {
  setLoginModal,
  setRegisterModal,
  setSelectRoleModal,
} from "../../store/slices/ModalSlice";
import {
  loggedInUser,
  setUserData,
  setUserToken,
} from "../../store/slices/UserSlice";
import { logoutUser, saveEditedProfile } from "../../requests/authRequest";
import { colors } from "../../theme/theme";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { SessionWallet } from "algorand-session-wallet";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { FlexBox, FooterBox } from "./style";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  zIndex: 2,
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    zIndex: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
  "& .MuiButton-text": {
    fontSize: "16px",
    color: colors.black.main,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const langList = ["en"];

function MainSiteNav() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isDashboard, setIsDasboard] = React.useState(false);

  const { loggedIn, userId } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLoginModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(setLoginModal(true));
  };

  const handleRegisterModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(setSelectRoleModal(true));
  };

  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");
  const { t } = useTranslation();

  const handleLogout = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(toggleAppLoading(true));
    const res: any = await logoutUser();
    if (res.success) {
      const payload = {
        loggedIn: false,
        userToken: "",
        loginType: "",
        registerUserType: "",
        userAppId: "",
        kyc: "",
      };
      dispatch(setUserToken(payload));
      const userPayload: loggedInUser = {
        status: "",
        name: "",
        email: "",
        userId: "",
        createdAt: "",
        address: "",
        gender: "Other",
        phone: "",
        country: "",
        role: "",
        userAppId: "",
        organisationOwner: "",
        organisationName: "",
        orgnisationRole: "",
      };
      dispatch(setUserData(userPayload));
      dispatch(toggleAppLoading(false));
      const sw = new SessionWallet("TestNet", undefined, "my-algo-connect");
      sw.disconnect();
      navigate("/");
      const toasterData = {
        active: true,
        text: "User logged out successfully",
        success: true,
      };

      dispatch(setToaster(toasterData));
    } else {
      dispatch(toggleAppLoading(false));

      const toasterData = {
        active: true,
        text: res.data?.message,
        success: false,
      };

      dispatch(setToaster(toasterData));
    }
  };

  const handleChangeLang = (e: SelectChangeEvent) => {
    setSelectedLanguage(e.target.value);
    i18next.changeLanguage(e.target.value);
    loggedIn && saveEditedProfile({ language: e.target.value, userId });
  };

  useEffect(() => {
    if (location.pathname.startsWith("/user")) {
      setIsDasboard(true);
    }
  }, [location.pathname]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            bgcolor: "background.paper",
            boxShadow: "1px 1px 20px 2px #ece9e9",
            "@media(max-width:900px)": {
              marginRight: "0px",
              width: "100%",
            },
          }}
        >
          <Container fixed>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "75px",
              }}
            >
              {/* <Typography
              variant="h2"
              noWrap
              sx={{ flexGrow: 1, color: "black.main", textAlign: "left" }}
              color="black"
            >
              Cashdillo
            </Typography> */}
              <Link to={"/"}>
                <img src={Logo} alt="logo" height={60} />
              </Link>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  display: { md: "flex", xs: "none" },
                }}
              >
                <Button
                  onClick={() =>
                    window.open("https://www.site.cashdillo.com/what-we-do")
                  }
                  variant="text"
                  sx={{ textTransform: "capitalize", padding: "12px" }}
                >
                  {t("about")}
                </Button>

                <Button
                  onClick={() => navigate("/esg-client")}
                  variant="text"
                  sx={{ textTransform: "capitalize", padding: "12px" }}
                >
                  {t("esgClients")}
                </Button>

                <Button
                  onClick={() => navigate("/how-its-works")}
                  variant="text"
                  sx={{ textTransform: "capitalize", padding: "12px" }}
                >
                  {t("howItWorks")}
                </Button>
                <Button
                  onClick={() => navigate("/faq")}
                  variant="text"
                  sx={{ textTransform: "capitalize", padding: "12px" }}
                >
                  {t("faq")}
                </Button>
                {loggedIn ? (
                  <>
                    <Link to="/user" style={{ textDecoration: "none" }}>
                      <Button
                        variant="text"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {t("dashboard")}
                      </Button>
                    </Link>
                    <Button
                      variant="text"
                      sx={{ textTransform: "capitalize", padding: "12px" }}
                      onClick={handleLogout}
                    >
                      {t("logout")}
                    </Button>
                    <Button
                      onClick={() => navigate("/whale-tank")}
                      variant="text"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#61f359",
                      }}
                    >
                      {t("whaleTank")}
                    </Button>
                    <Select
                      value={selectedLanguage}
                      onChange={handleChangeLang}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {langList.map((item: any) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </>
                ) : (
                  <>
                    <Button
                      variant="text"
                      sx={{ textTransform: "capitalize" }}
                      onClick={handleRegisterModal}
                    >
                      {t("register")}
                    </Button>
                    <Button
                      variant="text"
                      sx={{ textTransform: "capitalize" }}
                      onClick={handleLoginModal}
                    >
                      {t("signIn")}
                    </Button>
                    <Button
                      onClick={() => navigate("/whale-tank")}
                      variant="text"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#61f359",
                      }}
                    >
                      {t("whaleTank")}
                    </Button>
                    <Select
                      value={selectedLanguage}
                      onChange={handleChangeLang}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {langList.map((item: any) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </>
                )}
              </Stack>
              <IconButton
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{
                  color: "black.main",
                  ...(open && { display: "none" }),
                  "@media(min-width:900px)": {
                    display: "none",
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Container>
        </AppBar>

        <Main
          open={open}
          sx={{
            marginTop: "92px",
            minHeight: "calc(100vh - 75px)",
            bgcolor: "primary.background",
            "@media(max-width:900px)": {
              marginRight: `-${drawerWidth}px`,
              width: "100%",
            },
          }}
        >
          <Outlet />
          {/* <Box
          sx={{
            backgroundColor: "#50c749",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            bottom: "0px",
          }}
        >
          <Link to={"/terms-of-use"}>Terms of  </Link>&
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </Box> */}
        </Main>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[
              "About",
              "How it Works",
              "FAQ",
              `${loggedIn ? "User" : "Register"}`,
              `${loggedIn ? "Logout" : "Sign In"}`,
            ].map((text, index) => {
              if (text === "Register") {
                return (
                  <ListItem onClick={handleRegisterModal} button key={text}>
                    <ListItemText primary={text} />
                  </ListItem>
                );
              } else if (text === "User") {
                return (
                  <Link to="/user" style={{ textDecoration: "none" }}>
                    <ListItem button key={text}>
                      <ListItemText primary={text} />
                    </ListItem>
                  </Link>
                );
              } else if (text === "Sign In") {
                return (
                  <ListItem onClick={handleLoginModal} button key={text}>
                    <ListItemText primary={text} />
                  </ListItem>
                );
              } else if (text === "Logout") {
                return (
                  <ListItem onClick={handleLogout} button key={text}>
                    <ListItemText primary={text} />
                  </ListItem>
                );
              } else {
                return (
                  <ListItem button key={text}>
                    <ListItemText primary={text} />
                  </ListItem>
                );
              }
            })}
          </List>
        </Drawer>
      </Box>
      <Box sx={FooterBox} mt={2}>
        <Container>
          <Grid item container spacing={4} sx={FlexBox}>
            <Grid item xs={4} lg={4} md={4}>
              <Box textAlign="start">
                <Typography variant="h3">
                  About <span>us</span>
                </Typography>
                <Typography variant="body1">
                  Every future is fabulous. That's why at Cashdillo we are
                  transforming how clients create social impact through
                  streamlined management of aid, corporate giving and
                  scholarship programs.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} lg={4} md={4} sx={FlexBox}>
              <Box textAlign="start">
                <Typography variant="h3">
                  Get <span>Help</span>
                </Typography>
                <Typography>
                  {" "}
                  <Link to={"/terms-of-use"}>Terms of Use</Link>
                </Typography>
                <Typography>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </Typography>
                <Typography>
                  <Link to={"/faq"}>FAQ</Link>
                </Typography>
                <Typography>
                  <Link to={"/how-its-works"}>How It Work</Link>
                </Typography>
              </Box>
            </Grid>
            {/* 
              
              */}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default MainSiteNav;

import { Box, Typography, Container, Grid, Divider, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: "#f1f2f3", marginTop: "30px" }}>
      <Container fixed>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Terms of Use
          </Typography>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            USE OF CROWDFUNDING PLATFORM & MAKING CRYPTOCURRENCY DONATIONS
          </p>
          <Divider />
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Welcome to Cashdillo, the crowdfunding platform owned by Innofund
            Limited. We are excited to offer you the opportunity to participate
            in and contribute to our crowdfunding platform (the “Platform”) by
            creating or supporting various campaigns posted on the Platform.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Please read these Conditions carefully before using our Platform, as
            they contain important information about your rights and
            obligations.
          </p>
          <Divider />
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            By using this website (the “Site”) and services (together with the
            Site, the “Services”) offered by Innofund Limited (a Hong Kong
            registered company), you’re agreeing to these legally binding rules
            (the “Terms of Use”). You’re also agreeing to our Privacy Policy,
            and agreeing to follow any other rules on the Site{" "}
            <a href="" target="_blank">
              www.cashdillo.com
            </a>{" "}
            and any subdomains associated to it.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            By using this website (the “Site”) and services (together with the
            Site, the “Services”) offered by Innofund Limited (a Hong Kong
            registered company), you’re agreeing to these legally binding rules
            (the “Terms of Use”). You’re also agreeing to our Privacy Policy,
            and agreeing to follow any other rules on the Site www.cashdillo.com
            and any subdomains associated to it.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We reserve the right to make changes to these terms at any time in
            the future. The most current terms and effective date can be found
            on this page. Continuing to use Cashdillo after a change means that
            you accept the new terms.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            The current version of the Site as of March 15 2023 is considered a
            prototype release (the “Alpha Release”) and does not contain many of
            the features and content that the Company intends to implement in
            future release
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            All similarities between the non-functional, demonstration campaigns
            on the site and any past, present, or future crowdfunding projects
            are unintended and coincidental; if you believe any of the content
            or assets contained within the Alpha Release may be in violation of
            your intellectual properly rights, please contact us at
            hello@cashdillo.com{" "}
            {/* <a href="" target="_blank">
              hello@cashdillo.com
            </a> */}{" "}
            and we will promptly address your concerns.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            User Accounts
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You must be old enough to enter into a binding agreement in your
            jurisdiction with these Terms of Use in order to create an account.
            You are responsible for your account and all of the activity on it
          </p>{" "}
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Certain features and functionality of the platform will not be
            available to you without registering for an account. Do not supply
            false information, impersonate others, or choose usernames that
            intend to harm or violate another user’s rights. We reserve the
            right to suspend or cancel your account for any violations or
            behavior harmful to other users or the platform.
          </p>{" "}
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You are responsible for all activity on your account, and for
            keeping your password safe and confidential. We are not responsible
            for breaches or lockouts to your account due to phishing attacks or
            user negligence
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Our Service does not address anyone under the age of 18
            (“Children”).
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from children without verification of parental consent, we take
            steps to remove that information from our servers
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Our Rules
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>
              <p
                style={{
                  fontWeight: "600",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                Do no harm.
              </p>
              <p style={{ textAlign: "left", fontSize: "20px" }}>
                Be respectful of all other people regardless of race, religion,
                status, culture, preferences, or any other difference that makes
                us all unique and important. We are all humans. If you have
                nothing nice or constructive to say, don’t say it at all.{" "}
              </p>
            </li>
            <li>
              <p
                style={{
                  fontWeight: "600",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                Do nothing illegal.
              </p>
              <p style={{ textAlign: "left", fontSize: "20px" }}>
                Do not make threats, trade in illegal or prohibited items or
                services, commit fraud, steal intellectual property, attempt to
                or perform cyberattacks, damage property, or any other act that
                violates the law.{" "}
              </p>
            </li>
            <li>
              <p
                style={{
                  fontWeight: "600",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                Do not interfere.
              </p>
              <p style={{ textAlign: "left", fontSize: "20px" }}>
                Do not attempt to bypass measures in place to secure the
                Services, do not gain unauthorized access to any information on
                the Site, do not use automated software or devices on the Site,
                do not cause unreasonable load, in our estimation, on our
                infrastructure, and do not cause any other harm or interference
                with the proper workings of the Site and Services.{" "}
              </p>
            </li>
          </ul>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Contributions
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            a. Currency: We accept Algorand (ALGO) as valid cryptocurrencies for
            contributions to campaigns on the Platform.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            b. Minimum Contribution: The minimum contribution amount for a
            campaign is $5 in ALGO
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            c. Refunds: All contributions made on the Platform are final and
            non-refundable. However, in the event that a campaign does not meet
            its funding goal, we will refund your contribution in full.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            d. Use of Funds: The funds contributed to a campaign will be used
            solely for the purpose described in the campaign description. We
            cannot guarantee that the campaign will achieve its goals or that
            the funds will be used as described.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            e. Cryptocurrency volatility and risks: Cryptocurrencies are known
            for their high volatility and the risk of hacking. It is important
            to be aware of these risks before engaging in cryptocurrency
            activities. The following information serves as a general
            understanding and does not constitute financial advice. Please
            consult with a professional financial advisor before making any
            investment decisions.
          </p>
          <p
            style={{
              fontWeight: "600",
              textAlign: "left",
              fontSize: "20px",
              textDecoration: "underline",
            }}
          >
            Innofund Limited and the Cashdillo Platform shall not be liable for
            any loss of funds or loss incurred due to:
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Volatility: Cryptocurrencies, such as ALGO, Bitcoin, Ethereum, and
            others, are known for their price volatility. Their values can
            fluctuate significantly within short periods, and sudden price
            movements are common. This volatility can result in substantial
            gains but also significant losses. It is important to thoroughly
            research and understand the risks involved before investing in or
            using cryptocurrencies.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Market Factors: Cryptocurrency prices can be influenced by various
            market factors, including supply and demand dynamics, regulatory
            announcements, market sentiment, technological advancements, and
            economic events. These factors can cause rapid price swings and
            unpredictability in the cryptocurrency market.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Hacking and Security issues: Cryptocurrencies are digital assets
            that rely on blockchain technology for secure transactions. However,
            they are not immune to hacking attempts and security breaches.
            Cryptocurrency exchanges, wallets, and other platforms can be
            vulnerable to cyberattacks, resulting in the loss or theft of
            digital assets. It is crucial to use reputable and secure platforms,
            enable strong security measures (such as two-factor authentication),
            and keep your private keys and passwords safe.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Regulatory and Legal Risks: Cryptocurrencies operate in a complex
            regulatory landscape. Regulations can vary across jurisdictions, and
            new regulations may be introduced that impact the use, trading, and
            taxation of cryptocurrencies. Changes in regulations or government
            actions can have significant effects on cryptocurrency markets and
            investments.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Lack of Consumer Protection: Unlike traditional banking systems,
            cryptocurrencies generally do not offer the same level of consumer
            protection and dispute resolution mechanisms. Transactions are often
            irreversible, and if funds are lost or stolen, it may be challenging
            to recover them. It is essential to exercise caution and take
            responsibility for securing your digital assets.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Market Manipulation: The cryptocurrency market can be susceptible to
            manipulation and fraudulent activities. Practices like pump-and-dump
            schemes, wash trading, and false information spreading can
            artificially inflate or deflate prices, leading to losses for
            unsuspecting investors. It is crucial to be cautious of such
            activities and rely on credible sources of information.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Investment Risk: Investing in cryptocurrencies carries inherent
            risks. It is important to only invest funds that you can afford to
            lose entirely. Diversification of investments and thorough research
            into projects, teams, and technologies can help mitigate some of the
            risks associated with cryptocurrency investments.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Taxation: Any user who gives or accepts donations in ALGO on
            Cashdillo and/or other cryptocurrencies is fully liable for any tax,
            compliance or disclosure obligations in their own jurisdiction.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Campaigns
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            a. Posting Campaigns: Anyone can post a campaign on the Platform,
            subject to our approval. We reserve the right to reject any campaign
            that we determine, in our sole discretion, to be inappropriate,
            misleading, or fraudulent.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            b. Campaign Content: You are solely responsible for the content of
            your campaign, including all text, images, videos, and other
            materials. You represent and warrant that your campaign does not
            infringe any third-party intellectual property rights or violate any
            applicable laws.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            c. Campaign Goals: Your campaign should have a clear and realistic
            funding goal, as well as a description of how the funds will be
            used.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            d. Campaign Duration: The duration of a campaign is determined by
            the campaign creator.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            e. Campaign Updates: You must keep your campaign backers informed
            about the progress of your campaign and any developments that may
            affect it.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            f. Campaign Completion: If your campaign is successfully funded, you
            must fulfill all obligations to your backers as described in your
            campaign.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            g. Campaign Fees: We charge a fee of 7% on all funds raised on the
            Platform.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            h. We reserve the right to de-list your campaign or not approve it{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            i. We reserve the right to ask all creators of campaigns on our
            platform, Cashdillo, to undergo biometric identification and/or
            other KYC procedures with Innofund Limited preferred third party
            provider{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}></p>
          <p style={{ textAlign: "left", fontSize: "20px" }}></p>
          <p style={{ textAlign: "left", fontSize: "20px" }}></p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Intellectual Property
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Platform Content: All content on the Platform, including text,
            images, videos, and other materials, is the property of the Platform
            or its licensors and is protected by copyright, trademark, and other
            intellectual property laws.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Campaign Content: we retain ownership of the content you post on the
            Platform as part of your campaign. By posting your campaign, you
            grant us a non-exclusive, worldwide, royalty-free, sublicensable,
            transferable, and irrevocable license to use, reproduce, modify,
            adapt, publish, translate, create derivative works from, distribute,
            and display such content for the purpose of promoting your campaign
            and the Platform..{" "}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Disclaimers
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            No Endorsement: We do not endorse, guarantee, or assume
            responsibility for any campaign or the content of any campaign..{" "}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Links to Other Sites
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            thirdparty’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.{" "}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Intellectual Property
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You agree to respect all copyrights, legal notices, and restrictions
            contained in any content accessed through the Site and Services, and
            agree not to copy or recreate any such content for commercial use.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            The Digital Millennium Copyright Act lays out a system of legal
            requirements for dealing with allegations of copyright infringement.
            We comply with the DMCA, and will respond to notices of alleged
            infringement if they comply with the law. We reserve the right to
            delete or disable content at our sole discretion, and to suspend
            terminate accounts for violators.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Our designated agent for notice of alleged copyright infringement
            is:{" "}
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>Innofund Limited</li>
            <li>
              {" "}
              <a href="" target="_blank">
                hello@cashdillo.com
              </a>{" "}
              You may request the deletion of your account by giving us written
              notice. We may retain certain information as required by law or as
              necessary for our legitimate business purposes. All provisions of
              this agreement survive termination of an account, including our
              rights regarding any content you’ve already submitted to the Site.
              (For instance, if you’ve launched a project, deleting your account
              will not automatically remove the project from the Site.)
            </li>
          </ul>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Our Rights
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>
              We reserve the right to make changes to the Cashdillo Site and
              Services without notice or liability.{" "}
            </li>
            <li>
              We reserve the right to make changes to our Terms of Use criteria
              at any time without notice or liability. Innofund Limited. is not
              liable for any damages as a result of any of these actions, and it
              is our policy not to comment on the reasons for any such action.{" "}
            </li>
          </ul>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            The Cashdillo Site and Services are presented as-is, without
            warranties.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You use our Services solely at your own risk. They are provided to
            you “as is” and “as available” and without warranty of any kind,
            express or implied.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            INNOFUND LIMITED SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND
            CONDITIONS OF MERCHANTABILITY, NON- INFRINGEMENT, AND FITNESS FOR A
            PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
            DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. NO ADVICE OR
            INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM INNOFUND LIMITED
            SHALL CREATE ANY WARRANTY.{" "}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Changes to This Terms of Use
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may update our Terms of Use from time to time. We will notify you
            of any changes by posting the new Terms of Use on this page.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming e ective and update the “e
            ective date” at the top of this Terms of Use.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You are advised to review this Terms of Use periodically for any
            changes. Changes to this Terms of Use are e ective when they are
            posted on this page.{" "}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Indemnfication
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You agree to defend, indemnify, and hold us harmless from all
            liabilities, claims, and expenses (including reasonable attorneys’
            fees and other legal costs) that arise from or relate to your use
            ormisuse of Innofund Limited’s technologies, speci cally Cashdillo.
            We reserve the right to assume the exclusive defense and control of
            any matter otherwise subject to this indemni cation clause, in which
            case you agree that you will cooperate and help us in asserting any
            defenses.{" "}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Limitation of Liability
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            To the fullest extent permitted by law, in no event will Innofund
            Limited, its directors, employees, partners, suppliers, or content
            providers be liable for any indirect, incidental, punitive,
            consequential, special, or exemplary damages of any kind, including
            but not limited to damages (i) resulting from your access to, use
            of, or inability to access or use the Services; (ii) for any lost
            pro ts, data loss, or cost of procurement or substitute goods or
            services; or (iii) for any conduct of content of any third party on
            the Site. In no event shall Innofund Limited liability for direct
            damages be in excess of (in the aggregate) two hundred U.S. dollars
            ($200.00).{" "}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Governing Law
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            These Terms (and all other rules, policies, or guidelines
            incorporated by reference) will be governed by and construed in
            accordance with the laws of SAR Hong Kong, without giving e ect to
            any principles of con icts of law, and without application of the
            Uniform Computer Information Transaction Act or the United Nations
            Convention of Controls for International Sale of Goods. You agree
            that Innofund Limited and its Services are deemed a passive website
            that does not give rise to jurisdiction over Innofund Limited or its
            parents, subsidiaries, a liates, assigns, employees, agents,
            directors, o cers, or shareholders, either speci c or general, in
            any jurisdiction other than the SAR Hong Kong. You agree that any
            action at law or in equity arising out of or relating to these
            Terms, or your use or non-use of the Services, shall be led only in
            the state or federal courts located in the state of Delaware, and
            you hereby consent and submit to the personal jurisdiction of these
            courts for the purposes of litigating any such action. You hereby
            irrevocably waive any right you may have to trial by jury in any
            dispute, action, or proceeding.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            These Terms comprise the entire agreement between you and Innofund
            Limited with respect to the Services. They supersede all other
            communications and proposals (whether oral, written, or electronic)
            between you and Innofund Limited with respect to the Services and
            govern our future relationship. If any provision of these Terms is
            found to be invalid under the law, that provision will be limited or
            eliminated to the minimum extent necessary so that the Terms
            otherwise will remain in full force and e ect and enforceable. The
            failure of either you or Innofund Limited to exercise any right
            provided for in these Terms in any way won’t be deemed a waiver of
            any other rights.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            These Terms are personal to you. You can’t assign them, transfer
            them, or sublicense them without prior written consent. Innofund
            Limited has the right to assign, transfer, or delegate any of its
            rights and obligations under these Terms without your consent.{" "}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            These terms were last updated March 15, 2023, at 6 p.m.{" "}
          </p>
        </Grid>
      </Container>
    </Box>
  );
};

export default TermsConditions;

export const FooterBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "black.main",
  color: "secondary.light",
  fontSize: "18px",
  fontWeight: "900",
  padding: "50px",

  "& .MuiTypography-root": {
    color: "secondary.light",
    a: {
      color: "secondary.light",
      textDecoration: "none",
      marginBottom: "5px",
      display: "block",
      ":hover": {
        color: "green.main",
      },
    },
  },
};
export const FlexBox = {
  display: "flex",
  justifyContent: "center",
  "& .MuiTypography-h3": {
    fontSize: "22px",
    color: "secondary.light",
    marginBottom: "10px",
    textTransform: "uppercase",
    ":after": {
      content: '""',
      display: "block",
      borderBottom: "3px solid #61f359",
      borderColor: "green.main",
      width: "70px",
      borderRadius: "8px",
      margin: "5px 0 0 0",
    },
  },
  "& span": {
    color: "green.main",
  },
};

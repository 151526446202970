import { Box, Typography } from "@mui/material";
import React from "react";
import { messageInterface } from "./InvestorCreatorChat";
import { MessageColWrap, MessageSent } from "./styles";
import moment from "moment";

interface myMessage {
  data: messageInterface;
}

function MyMessage({ data }: myMessage) {
  return (
    <Box sx={MessageColWrap}>
      <Box sx={MessageSent} style={{ position: "relative" }}>
        {/* <IconButton color="error" className="delete">
								<DeleteIcon fontSize="small" />
							</IconButton> */}
        <Box className="messageSent">
          <Typography align="left">{data.content}</Typography>
        </Box>
        <Box className="time">
          <Typography align="left" variant="body2">
            {moment(data.createdAt).format("lll")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default MyMessage;

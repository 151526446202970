import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  deleteInvestorReview,
  editInvestorReview,
} from "../../../requests/authRequest";
import { useAppDispatch } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";

function InvestorComment({ review }: any) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [invalidComment, setInvalidComment] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");

  const { campaignId } = useParams();
  const dispatch = useAppDispatch();

  const validateComment = () => {
    let res = true;
    if (comment.trim().length <= 0 || comment.trim().length > 3000) {
      res = false;
      setInvalidComment(true);
      if (comment.trim().length <= 0) {
        setWarningMessage("Please enter some text before posting review");
      } else {
        setWarningMessage("Text must be of lenght 1 to 3000");
      }
    } else {
      setInvalidComment(false);
    }
    return res;
  };

  useEffect(() => {
    if (review && review.comment) {
      setComment(review.comment);
    }
  }, [review]);

  const cancelEdit = () => {
    setIsEditing(false);
    if (review && review.comment) {
      setComment(review.comment);
    }
    setInvalidComment(false);
  };

  const saveReview = async () => {
    const valid = validateComment();
    if (!valid) {
      return;
    }
    const info = {
      reviewId: review.reviewId,
      comment: comment,
    };
    dispatch(toggleAppLoading(true));
    const res = await editInvestorReview(info);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPayload = {
        active: true,
        success: true,
        text: t("reviewUpdatedSuccessfully"),
      };
      dispatch(setToaster(toastPayload));
      setIsEditing(false);
    } else {
      const toastPayload = {
        active: true,
        success: false,
        text: res.data.message,
      };
      dispatch(setToaster(toastPayload));
    }
  };

  const deleteReview = async () => {
    const info = {
      reviewId: review.reviewId,
    };
    dispatch(toggleAppLoading(true));
    const res = await deleteInvestorReview(info);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPayload = {
        active: true,
        success: true,
        text: t("reviewDeletedSuccessfully"),
      };
      dispatch(setToaster(toastPayload));
      setIsEditing(false);
      setComment("");
    } else {
      const toastPayload = {
        active: true,
        success: false,
        text: res.data.message,
      };
      dispatch(setToaster(toastPayload));
    }
  };

  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={12}>
        <Typography variant="h4" align="left" mb={4}>
          {t("myReview")}
        </Typography>
        {isEditing ? (
          <TextField
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            multiline
            minRows={4}
            type={"text"}
            placeholder={t("comment") || ""}
            variant="outlined"
            defaultValue={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={comment}
            error={invalidComment}
            helperText={invalidComment && warningMessage}
          />
        ) : (
          <Typography variant="h6" align="left">
            {comment && comment.length > 0
              ? comment
              : "You havent added any review yet"}
          </Typography>
        )}

        {review && review.comment && review.comment.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ textAlign: "left" }}>
              {isEditing ? (
                <>
                  <Button
                    variant="contained"
                    color={"error"}
                    sx={{ mt: 4 }}
                    onClick={cancelEdit}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={{ mt: 4, ml: 2 }}
                    onClick={saveReview}
                  >
                    {t("save")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color={"error"}
                    sx={{ mt: 4 }}
                    onClick={deleteReview}
                  >
                    {t("delete")}
                  </Button>
                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={{ mt: 4, ml: 2 }}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    {t("edit")}
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default InvestorComment;

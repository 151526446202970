import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
  Button,
  Menu,
  FormLabel,
  Stack,
  SelectChangeEvent,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { saveToDraft } from "../../../../requests/authRequest";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PhotoUpload } from "../../createCampaign/styles";
import countryList from "../../createCampaign/countrylist";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import Wallets from "../../../../wallets";

function EditAdditionalInfo({
  handleSteps,
  additionalData,
  handleCountry,
  handleImage,
  handleContactName,
  handleCompanyName,
  handleContactEmail,
  handleContactPhone,
  handleWebsite,
  handleProjectPitch,
  handleProblems,
  handlePreviousSuccessfulProject,
  handleHq,
  handleImpact,
  handlePartnership,
  handleYearOfEstablishment,
  handleStageDesc,
  handleAdditionalInfo,
  handleCreateCampaign,
  consentStatus,
  reachoutStatus,
}: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const kyc = useAppSelector((state) => state.userReducer.kyc);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const imageRef = React.useRef<HTMLElement>();
  const [imageValid, setImageValid] = React.useState<boolean>(false);
  const [imageName, setImageName] = React.useState<string>(
    additionalData.infoImage
  );

  const handleCountryChange = (e: SelectChangeEvent) => {
    handleCountry(e.target.value);
  };

  /**
   * handle image upload
   */

  const handleUploadChange = (e: any) => {
    const file = e.target.files;
    let fileType = file[0].name.split(".")[file[0].name.split(".").length - 1];
    if (
      fileType.toLowerCase() !== "png" &&
      fileType.toLowerCase() !== "jpeg" &&
      fileType.toLowerCase() !== "jpg"
    ) {
      setImageValid(false);

      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectPngJpgOrJpegImageFile"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (file[0].size / 1000000 > 5) {
      setImageValid(false);

      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectImageFileSmallerThanFiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (
      file[0] !== undefined &&
      file[0].name.length > 0 &&
      Math.floor(file[0].size / 1000000) < 5
    ) {
      setImageValid(true);

      handleImage(file[0]);
      setImageName(`url(${URL.createObjectURL(e.target.files[0])})`);
      // if (imageRef && imageRef.current) {
      //   // imageRef.current.style.backgroundImage = imageName[imageId];
      //   imageRef.current.style.backgroundImage = `url(${URL.createObjectURL(
      //     e.target.files[0]
      //   )})`;
      // }
    }
  };

  React.useEffect(() => {
    if (
      additionalData.infoImage !== "" ||
      additionalData.infoImage !== undefined ||
      additionalData.infoImage !== null
    ) {
      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage =
          imageName?.search("blob:") === -1
            ? `url(${process.env.REACT_APP_URL}/api/file/image/${imageName})`
            : imageName;
      }
    }
  }, [imageName]);

  /**
   *
   * menu
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * menu
   */

  /**
   *
   * menu2
   */

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  /**
   *
   * menu
   */

  const handleChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      handleYearOfEstablishment(newValue);
    }
  };

  /**
   * handle save to draft function
   */

  /**
   * handle save to draft function end
   */

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "15px",
        marginBottom: "20px",
        borderRadius: "5px",
      }}
    >
      <Typography style={{ textAlign: "left", fontSize: "2rem" }} variant="h3">
        {t("additionalCampaignInformation")}
      </Typography>
      <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Typography
          style={{ textAlign: "left", fontSize: "1rem" }}
          variant="h5"
        >
          {t("privacyAndDataCollection")}
        </Typography>
        <Typography variant="body1" style={{ textAlign: "left" }}>
          {t("additionalInfoTextOne")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("pleaseGiveYourConsent")}
          </InputLabel>
          <Button
            disabled
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {consentStatus ? t("yes") : t("no")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{ bgcolor: "#fff", p: 2, mt: 2, width: "100%" }}
        style={{ textAlign: "left" }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Contact name"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.contactName}
                onChange={(e) => handleContactName(e.target.value)}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Contact email"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.contactEmail}
                onChange={(e) => handleContactEmail(e.target.value)}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Contact phone"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.contactPhone}
                onChange={(e) => handleContactPhone(e.target.value)}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Company / Project / Organisation name"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.companyName}
                onChange={(e) => handleCompanyName(e.target.value)}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Website "
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.website}
                onChange={(e) => handleWebsite(e.target.value)}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <p style={{ marginTop: "10px" }}>{t("projectPitch")}</p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("enterProjectPitch") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.projectPitch}
                onChange={(e) => handleProjectPitch(e.target.value)}
              />
              <p style={{ marginTop: "10px" }}>{t("problemsYouAreSolving")}</p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("problems") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.problems}
                onChange={(e) => handleProblems(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Previous Successful Projects (Optional)"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.previousSuccessfulProject}
                onChange={(e) =>
                  handlePreviousSuccessfulProject(e.target.value)
                }
                inputProps={{ maxLength: 500 }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="HQ (Optional)"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.Hq}
                onChange={(e) => handleHq(e.target.value)}
                inputProps={{ maxLength: 500 }}
              />
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">{t("country")}</FormLabel>
                <Select
                  fullWidth
                  disabled={!consentStatus}
                  value={additionalData.country}
                  onChange={handleCountryChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {countryList.map((country) => {
                    return (
                      <MenuItem
                        value={country.toLocaleLowerCase()}
                        key={country}
                      >
                        {country}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <p style={{ marginTop: "10px" }}>
                {t("whatDoYouHopeToAchieveWithThisProject")}
              </p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("impact") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.impact}
                onChange={(e) => handleImpact(e.target.value)}
              />
              <p style={{ marginTop: "10px" }}>
                {t("partnerships")} ({t("optional")})
              </p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("enterPartnershipsDetails") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.partnership}
                onChange={(e) => handlePartnership(e.target.value)}
              />
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">
                  {t("yearOfEstablishment")} ({t("optional")})
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      disabled={!consentStatus}
                      inputFormat="YYYY-MM-DD"
                      value={additionalData.yearOfEstablishment}
                      onChange={handleChange}
                      renderInput={(params: any) => <TextField {...params} />}
                      disableFuture
                    />
                  </Stack>
                </LocalizationProvider>
                {/* {invalidEndDate && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {errorEndDate}
                  </FormHelperText>
                )} */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <p style={{ marginTop: "10px" }}>{t("stageDescription")}</p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("stageDescription") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.stageDesc}
                onChange={(e) => handleStageDesc(e.target.value)}
              />

              <FormLabel component="legend">
                {t("imageUpload")} ({t("optional")})
              </FormLabel>
              <Box
                ref={imageRef}
                sx={PhotoUpload}
                style={{
                  position: "relative",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="contained-button-file">
                    <input
                      className="inputfile"
                      accept=".jpeg, .png"
                      id="contained-button-file"
                      onChange={handleUploadChange}
                      multiple={false}
                      type="file"
                      style={{ display: "none" }}
                    />
                  </label>
                </Stack>
              </Box>
              <p style={{ marginTop: "10px" }}>
                {t("additionalInformationOptional")}
              </p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("additionalInformationOptional") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.additionalInfo}
                onChange={(e) => handleAdditionalInfo(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("additionalInfoTextTwo")}
          </InputLabel>
          <Button
            disabled
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {reachoutStatus ? "yes" : "No"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => {
            handleSteps("GOAL");
          }}
        >
          {t("back")}
        </Button>

        {kyc === "approved" && (
          <>
            <Button
              onClick={() => setShowWalletButtons(true)}
              variant="contained"
              color="primary"
              className="round-button"
            >
              {t("submit")}
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={() => handleCreateCampaign()}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
export default EditAdditionalInfo;

import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setRewardType } from "../../../store/slices/CreateCampaignSlice";
import {
  setSelectCashdilloNftModal,
  setSelectCreatorNftModal,
  setSelectRewardTypeModal,
} from "../../../store/slices/ModalSlice";
import {
  FooterBtn,
  rewardTypes,
  style,
  titleStyle,
} from "./selectRewardTypeModalStyle";

function SelectRewardTypeModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector(
    (state) => state.modalReducer.selectRewardTypeModal
  );

  // const rewardTypeArray = ["cashdillo"];

  const handleClose = () => {
    dispatch(setSelectRewardTypeModal(false));
  };

  const clearSelection = () => {
    const nftData = {
      rewardNftId: "",
      rewardType: "",
    };
    dispatch(setRewardType(nftData));
    handleClose();
  };

  const handleRewardTypeSelect = (num: number) => {
    // open second modal
    if (num === 0) {
      // open the select nft modal
      dispatch(setSelectCashdilloNftModal(true));
      const nftData = {
        rewardType: "NFTs",
      };
      dispatch(setRewardType(nftData));
    } else if (num === 1) {
      dispatch(setSelectCreatorNftModal(true));
      const nftData = {
        rewardType: "NFTs",
      };
      dispatch(setRewardType(nftData));
    }
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: "100%",
          outline: " 0px",
          overflow: "hidden auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media(max-height:700px)": {
            alignItems: "baseline",
          },
        }}
        onClick={handleClose}
      >
        <Box
          sx={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box sx={titleStyle}>
            <Typography variant="h4" align="center" mb={2}>
              {t("selectReward")}
            </Typography>
          </Box>
          <Box sx={rewardTypes}>
            <p
              onClick={() => {
                handleRewardTypeSelect(0);
              }}
            >
              {t("useCashdillosNftAsReward")}
            </p>
            <p
              onClick={() => {
                handleRewardTypeSelect(1);
              }}
            >
              {t("selectFromRewardTemplates")}
            </p>
            <p
              onClick={() => {
                handleRewardTypeSelect(2);
              }}
            >
              {t("manageOrCreateYourownRewards")}
            </p>
          </Box>
          <Box sx={FooterBtn}>
            <Button
              sx={{ color: "initial", margin: "0 auto" }}
              variant="text"
              onClick={clearSelection}
            >
              {t("doItLater")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default SelectRewardTypeModal;

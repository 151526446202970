import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { buyDummyNft } from "../../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { UploadBox } from "../selectRewardTypeModalStyle";
import { buyNft } from "../../../../blockchain";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../../wallets/algorand";
import algosdk from "algosdk";
import { useTranslation } from "react-i18next";

interface modalNftCardBuy {
  // i am using any here because its a dummy integration
  data: any;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

function ModalNftCardBuy({ data, setValue }: modalNftCardBuy) {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.userReducer);
  const handleBuy = async () => {
    const info = {
      nftId: data.nftId,
    };
    setLoading(true);
    const res = await buyDummyNft(info);
    setLoading(false);
    if (res.success) {
      // got ot bought nft
      const toastPayload = {
        active: true,
        text: t("nftBoughtSuccessfully"),
        success: true,
      };
      dispatch(setToaster(toastPayload));
      setValue(0);
    }
  };

  const handleBuyNft = async (id: any) => {
    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("sendingDataToBlockchain")));
    const info = {
      nft_id: id,
      user_app_id: JSON.parse(user.userAppId),
      nft_price: 5000000,
      wallet_address: activeAccount?.address,
    };
    const unsignedTxnRes = await buyNft(info);

    if (unsignedTxnRes.success) {
      try {
        dispatch(setAppLoadingText(t("signingTransaction")));
        dispatch(toggleAppLoading(false));
        const transactionArr = unsignedTxnRes.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));

        const signed = [];
        signed.push(signedTxn[0]);
        signed.push(signedTxn[1]);
        signed.push(signedTxn[2]);

        dispatch(setAppLoadingText(t("waitingForConfirmation")));
        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();
        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );

        // dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        const toastPaylaod = {
          text: t("nftLinkedSuccessfully"),
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        setValue(0);
        return;
      } catch (err: any) {
        const toastPaylaod = {
          text: err.message,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
      }
    } else {
      const toastPaylaod = {
        text: unsignedTxnRes.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
  };

  return (
    <Grid item xl={3} lg={4} sm={6} xs={12}>
      <div
        style={{
          background: "#d4d4d4",
          padding: "16px",
          borderRadius: "10px",
          cursor: "pointer",
          textAlign: "left",
          minHeight: "300px",
          height: "350px",
        }}
      >
        <Box sx={UploadBox}>
          <video
            style={{ objectFit: "cover" }}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            poster={data.url}
            controls={false}
          >
            <source src={data.url} type="video/mp4" />
            <source src={data.url} type="video/ogg" />
            {t("yourBrowserDoesNotSupportTheVideoTag")}
          </video>
        </Box>
        <Typography
          sx={{
            color: "initial",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          variant="h6"
          align="left"
          mb={2}
          className="bold"
          fontWeight={600}
        >
          {data["unit-name"]}
        </Typography>
        <Typography height={"50px"} mb={2}>
          {data["asset-name"].length > 85
            ? `${data["asset-name"].slice(0, 85)}...`
            : data["asset-name"]}
        </Typography>
        <Stack
          direction="row"
          spacing={20}
          style={{
            marginTop: "0",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => handleBuyNft(data["asset-id"])}
            variant="contained"
            style={{
              height: "30px",
              backgroundColor: "#FF4033",
              fontSize: "10px",
              padding: "10px 20px",
              margin: "0px 2px",
            }}
          >
            {t("buy")}
          </Button>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#000000e3",
                  fontSize: "16px",
                  padding: "18px",
                  maxWidth: "320px",
                  maxHeight: "420px",
                  textAlign: "justify",
                  whiteSpace: "pre-line",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "& .MuiTooltip-arrow": {
                    color: "#ffffff",
                  },
                },
              },
            }}
            title={
              "Asset Id: " +
              data["asset-id"] +
              "\nUnit Name: " +
              data["unit-name"] +
              "\nAsset Name: " +
              data["asset-name"] +
              "\nURL: " +
              data["url"]
            }
            placement="top-start"
          >
            <Button
              variant="contained"
              style={{
                height: "30px",
                backgroundColor: "#61f359",
                fontSize: "10px",
                padding: "10px 20px",
                margin: "0px 2px",
              }}
            >
              {t("quickInfo")}
            </Button>
          </Tooltip>
        </Stack>
      </div>
    </Grid>
  );
}

export default ModalNftCardBuy;

import React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  FormLabel,
  Checkbox,
  Button,
  FormGroup,
  Menu,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Modal from "@mui/material/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  donateMatchFundCampaign,
  submitMatchFunds,
} from "../../../requests/authRequest";
import {
  setAppLoadingText,
  toggleAppLoading,
  setToaster,
} from "../../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useWallet } from "@txnlab/use-wallet";
import { peraWallet, client } from "../../../wallets/algorand";
import { payMatchFund, transferToCampaign } from "../../../blockchain";
import algosdk from "algosdk";
import NotificationToast from "../../../loaders/NotificationToast";
import algo from "../../../assets/images/algo.png";
import { useTranslation } from "react-i18next";
import Wallets from "../../../wallets";
import { PeraOnramp } from "@perawallet/onramp";
import Permission from "../../../views/auth/register/permission";
import { useState } from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];

function MatchFundFilter({ handleClose }: any) {
  const { activeAccount, providers, signTransactions } = useWallet();
  const [checked, setChecked] = useState<boolean>(false);
  // const paymentType = useAppSelector(
  //   (state) => state.campaignViewReducer.paymentType
  // );
  const [paymentTypeBlockchain, setPaymentTypeBlockchain] =
    useState<string>("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const peraOnramp = new PeraOnramp();
  const [showWalletButtons, setShowWalletButtons] =
    React.useState<boolean>(false);

  const [goalCategories, setGoalCategories] = React.useState<string[]>([]);
  const [paymentCategories, setPaymentCategories] = React.useState<string[]>(
    []
  );
  const [ratingRange, setRatingRange] = React.useState<number[]>([0, 100]);
  const [payment, setPayment] = React.useState<string>("");
  const [currentContributionPercent, setCurrentContributionPercent] =
    React.useState<number | undefined>();
  const [contributionStatus, setContributionStatus] =
    React.useState<string>("");
  const [consentStatus, setConsentStatus] = React.useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });

  const [filterData, setFilterData] = React.useState<{
    payableAmount: number;
    totalCampaign: number;
    totalOutstanding: number;
    totalCampaignCount: number;
  }>({
    payableAmount: 0,
    totalCampaign: 0,
    totalOutstanding: 0,
    totalCampaignCount: 0,
  });

  const [transferCampaignData, setTransferCampaignData] = React.useState<
    any | undefined
  >();

  const [blockchainData, setBlockChainData] = React.useState<
    | {
        investments: object;
        fee: number;
      }
    | undefined
  >();

  /**
   *
   * menu
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * menu
   */

  const goalFieldsList = ["Environmental", "Social", "Governance"];
  const paymentList = ["USDC", "ALGO"];
  const payArr = [
    {
      title: "Make Full Payment",
      value: "full",
    },
    {
      title: "Make Custom Payment",
      value: "custom",
    },
  ];
  const perArr = [
    {
      title: "25%",
      value: 25,
    },
    {
      title: "50%",
      value: 50,
    },
    {
      title: "75%",
      value: 75,
    },
    {
      title: "100%",
      value: 100,
    },
  ];

  const handleChange = (event: Event, newValue: number | number[]) => {
    setRatingRange(newValue as number[]);
    // handleSubmit();
    handleResetFilter();
  };

  const handleSubmit = async (
    currentContributionPercent: number,
    contributeStatus: string,
    paymentType: string
  ) => {
    dispatch(toggleAppLoading(true));

    dispatch(setAppLoadingText(""));

    let payload = {
      goalCategories: JSON.stringify(goalCategories),
      paymentCategories: JSON.stringify(paymentCategories),
      ratingFrom: ratingRange[0],
      ratingTo: ratingRange[1],
      payment: paymentType,
      action:
        contributeStatus === "complete"
          ? "completeCampaignGoal"
          : contributeStatus === "current"
          ? "matchCurrentContribution"
          : "filter",
      currentContributionPercent,
    };

    const res = await submitMatchFunds(payload);
    if (res.success) {
      dispatch(toggleAppLoading(true));
      setFilterData((prev) => ({
        ...prev,
        ...(payload.action === "filter"
          ? {
              totalCampaign: res.data.totalCampaign,
              totalOutstanding: res.data.totalOutstanding,
            }
          : {
              payableAmount: res.data.payableAmount,
              totalCampaignCount: res.data.totalCampaignCount,
            }),
      }));
      setBlockChainData(res.data?.blockchainData);
      setPaymentTypeBlockchain(res.data?.paymentTypeBlockchain[0]);

      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      // if (res.data.totalCampaign === 0) {
      //   const toastPaylaod = {
      //     text: t("noRecordsFound"),
      //     success: false,
      //     active: true,
      //   };
      //   dispatch(setToaster(toastPaylaod));
      // }
    } else {
      dispatch(toggleAppLoading(false));
      const toastPaylaod = {
        text: t("noRecordsFound"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  const handleResetFilter = () => {
    setPayment("");
    setCurrentContributionPercent(undefined);
    setContributionStatus("");
  };

  /**
   * handle donate in match fund
   */

  const userslice = useAppSelector((state) => state.userReducer);

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleDonate = async () => {
    // dispatch(toggleAppLoading(true));
    // dispatch(setAppLoadingText(t("signingTransaction")));
    let payload = {
      investments_details: blockchainData,
      investor_wallet_address: activeAccount?.address,
      paymentType: paymentTypeBlockchain,
      meta_data: {
        userAppId: userslice.userAppId,
      },
    };
    const res = await payMatchFund(payload);

    if (res.success) {
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(t("signingTransaction")));
      const transactionArr = res.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });

      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });

      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));
      dispatch(setAppLoadingText(t("submitingTransaction")));
      // Submit the transaction
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      // Wait for confirmation
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      if (confirmedTxn) {
        dispatch(setAppLoadingText(t("pleaseDoNotCloseOrRefresh")));
        let transferPayload = {
          investments_details: blockchainData,
          paymentType: paymentTypeBlockchain,
          investor_wallet_address: activeAccount?.address,
          meta_data: { userAppId: userslice.userAppId },
        };

        const transferRes = await transferToCampaign(transferPayload);

        if (transferRes.success) {
          //campaign_transaction
          setTransferCampaignData(transferRes.data);
          let matchFundTransPayload = {
            campaigns: transferRes.data,
            paymentType: paymentTypeBlockchain,
          };
          const matchFundTransRes = await donateMatchFundCampaign(
            matchFundTransPayload
          );

          if (matchFundTransRes.success) {
            dispatch(toggleAppLoading(false));

            dispatch(setAppLoadingText(""));
            const toastPaylaod = {
              text: t("successfullyTransferYourFundToCampaigns"),
              success: true,
              active: true,
            };
            dispatch(setToaster(toastPaylaod));
            handleClose();
            window.location.reload();
          } else {
            dispatch(toggleAppLoading(false));

            dispatch(setAppLoadingText(""));
            const toastPaylaod = {
              text: matchFundTransRes.data,
              success: false,
              active: true,
            };
            dispatch(setToaster(toastPaylaod));
          }
        } else {
          dispatch(toggleAppLoading(false));

          dispatch(setAppLoadingText(""));
          const toastPaylaod = {
            text: t("unableToTransferYourFundToCampaigns"),
            success: false,
            active: true,
          };
          dispatch(setToaster(toastPaylaod));
        }
      }
    } else {
      dispatch(toggleAppLoading(false));

      dispatch(setAppLoadingText(""));
      const toastPaylaod = {
        text: res.data.message || t("unableToInvest"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  const handlePayWithCreditOrrDebitCard = () => {
    const accountAddress = "";
    peraOnramp
      .addFunds({
        accountAddress, // A valid Algorand account address
      })
      .then((res: any) => {
        console.log(accountAddress, res, "good");
        // Successfully added funds
      })
      .catch(() => {
        console.log("bad");
        // Failed to add funds
      });
  };

  // React.useEffect(() => {
  //   if (goalCategories.length !== 0) {
  //     handleSubmit();
  //   }
  // }, [payment, currentContributionPercent, contributionStatus]);

  return (
    <div>
      <Box>
        <div>
          <Modal
            sx={{ overflowY: "scroll", height: "auto" }}
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              style={{
                width: "50%",
                borderRadius: "16px",
                padding: "2.5rem",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              <Typography
                style={{ textAlign: "left", fontSize: "2rem" }}
                variant="h3"
              >
                {t("matchFundEsgCampaigns")}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 0, mb: 4, fontSize: "13px" }}
              >
                {t("pleaseAdjustTheFiltersBelowToGetResults")}
              </Typography>
              <Grid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    backgroundColor: "#d7d7d761",
                    borderRadius: "16px",
                    maxWidth: "47%",
                    margin: "0 auto",
                    padding: "25px",
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    {t("filters")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginY: "10px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 600 }}
                      component="legend"
                    >
                      {t("selectCategory")}
                    </Typography>

                    {goalFieldsList.map((item: any) => (
                      <FormControlLabel
                        key={item}
                        control={
                          <Checkbox
                            checked={Boolean(
                              goalCategories.some((el) => el === item)
                            )}
                            onChange={() => {
                              goalCategories.some((el) => el === item)
                                ? setGoalCategories(
                                    goalCategories.filter((el) => el !== item)
                                  )
                                : setGoalCategories((prev) => [...prev, item]);
                              handleResetFilter();
                            }}
                            name={item}
                            color="primary"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "15px", fontWeight: 500 }}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginY: "10px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 600 }}
                      component="legend"
                    >
                      {t("selectpaymentCategory")}
                    </Typography>

                    {paymentList.map((item: any) => (
                      <FormControlLabel
                        key={item}
                        control={
                          <Radio
                            checked={Boolean(
                              paymentCategories.some((el) => el === item)
                            )}
                            onChange={() => {
                              setPaymentCategories([item]); // Radio buttons allow only one selection, so we set the selected category directly
                              handleResetFilter();
                            }}
                            name={item}
                            color="primary"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "15px", fontWeight: 500 }}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    ))}
                  </Box>

                  <Box sx={{ width: 300 }}>
                    <Typography
                      style={{ textAlign: "left", fontSize: "1rem" }}
                      variant="h5"
                    >
                      {t("selectEsggScoreRangeToMatchFundCampaigns")}
                    </Typography>
                    <Slider
                      getAriaLabel={() => "Temperature range"}
                      value={ratingRange}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      marks={marks}
                      disableSwap={true}
                    />
                  </Box>
                  <Button
                    disabled={
                      !goalCategories.length && !paymentCategories.length
                    }
                    onClick={() => {
                      handleResetFilter();
                      handleSubmit(0, "filter", "");
                    }}
                    style={{ marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    className="round-button"
                  >
                    {t("submitFilter")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    backgroundColor: "#d7d7d761",
                    borderRadius: "16px",
                    maxWidth: "47%",
                    margin: "0 auto",
                    padding: "25px",
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    {t("result")}
                  </Typography>
                  <p style={{ marginBottom: "0" }}>{t("totalCampaign")}(s)</p>
                  <Typography
                    style={{ textAlign: "left", fontSize: "1rem" }}
                    variant="h5"
                  >
                    {filterData?.totalCampaign ? filterData?.totalCampaign : 0}
                    {""}
                    {paymentTypeBlockchain === "ALGO" && (
                      <img
                        style={{ width: "16px", marginBottom: "-3px" }}
                        src={algo}
                        alt="ALGO"
                      />
                    )}
                    {paymentTypeBlockchain === "USDC" && (
                      <MonetizationOnIcon
                        style={{
                          marginBottom: "-6px",
                          fontSize: "22px",
                          color: "black",
                        }}
                      />
                    )}
                    {/* <img
                      style={{ width: "15px", marginBottom: "-1px" }}
                      src={algo}
                    ></img> */}
                  </Typography>
                  <p style={{ marginBottom: "0" }}>
                    {t("totalOutstandingReachTarget")}(s)
                  </p>
                  <Typography
                    style={{ textAlign: "left", fontSize: "1rem" }}
                    variant="h5"
                  >
                    {filterData?.totalOutstanding
                      ? filterData?.totalOutstanding
                      : 0}{" "}
                    {paymentTypeBlockchain === "ALGO" && (
                      <img
                        style={{ width: "16px", marginBottom: "-3px" }}
                        src={algo}
                        alt="ALGO"
                      />
                    )}
                    {paymentTypeBlockchain === "USDC" && (
                      <MonetizationOnIcon
                        style={{
                          marginBottom: "-6px",
                          fontSize: "22px",
                          color: "black",
                        }}
                      />
                    )}
                    {/* <img
                      style={{ width: "15px", marginBottom: "-1px" }}
                      src={algo}
                    ></img> */}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "#d7d7d761",
                    borderRadius: "16px",
                    maxWidth: "97%",
                    margin: "0 auto",
                    padding: "25px",
                    marginTop: "25px",
                  }}
                >
                  <Typography
                    style={{ textAlign: "left", fontSize: "1rem" }}
                    variant="h5"
                  >
                    {t("payment")}
                  </Typography>

                  <Box>
                    {payArr.map((item: any) => (
                      <FormControlLabel
                        checked={payment === item.value}
                        onChange={() => {
                          setPayment(item.value);
                          if (item.value === "full") {
                            setContributionStatus("");
                            handleSubmit(0, "filter", item.value);
                          }
                        }}
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{ fontSize: "15px", fontWeight: 600 }}
                          >
                            {item.title}
                          </Typography>
                        }
                      />
                    ))}
                  </Box>
                </Grid>
                {payment === "custom" && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "#d7d7d761",
                      borderRadius: "16px",
                      maxWidth: "97%",
                      margin: "0 auto",
                      padding: "25px",
                      marginTop: "25px",
                    }}
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value={contributionStatus === "complete"}
                          onChange={() => {
                            setContributionStatus("complete");
                            handleSubmit(0, "complete", "custom");
                          }}
                          control={
                            <Radio
                              checked={contributionStatus === "complete"}
                            />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: "15px", fontWeight: 600 }}
                            >
                              {t("completeCampaignGoal")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      <p style={{ marginTop: "0" }}>
                        {t(
                          "yourFundsWillBeAllocatedToFulfillFinalCampaignGoals"
                        )}
                      </p>
                    </FormControl>
                  </Grid>
                )}
                {contributionStatus === "complete" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "90%",
                      marginX: "auto",
                      marginY: "20px",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Box
                      style={{
                        width: "50%",
                      }}
                    >
                      <p style={{ marginBottom: "0" }}>{t("totalCampaigns")}</p>
                      <Typography
                        style={{ textAlign: "left", fontSize: "1rem" }}
                        variant="h5"
                      >
                        {filterData?.totalCampaignCount
                          ? filterData?.totalCampaignCount
                          : 0}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        width: "50%",
                      }}
                    >
                      <p style={{ marginBottom: "0" }}>{t("payableAmount")}</p>
                      <Typography
                        style={{ textAlign: "left", fontSize: "1rem" }}
                        variant="h5"
                      >
                        {filterData?.payableAmount
                          ? filterData?.payableAmount?.toFixed(6)
                          : 0}{" "}
                        {paymentTypeBlockchain === "ALGO" && (
                          <img
                            style={{ width: "16px", marginBottom: "-3px" }}
                            src={algo}
                            alt="ALGO"
                          />
                        )}
                        {paymentTypeBlockchain === "USDC" && (
                          <MonetizationOnIcon
                            style={{
                              marginBottom: "-6px",
                              fontSize: "22px",
                              color: "black",
                            }}
                          />
                        )}
                        {/* <img
                          style={{ width: "15px", marginBottom: "-1px" }}
                          src={algo}
                        ></img> */}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {payment === "custom" && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "#d7d7d761",
                      borderRadius: "16px",
                      maxWidth: "97%",
                      margin: "0 auto",
                      padding: "25px",
                      marginTop: "35px",
                    }}
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value={contributionStatus === "current"}
                          onChange={() => {
                            setContributionStatus("current");
                            // handleSubmit();
                          }}
                          control={
                            <Radio checked={contributionStatus === "current"} />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: "15px", fontWeight: 600 }}
                            >
                              {t("matchCurrentContribution")}(s)
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      <p style={{ marginTop: "0" }}>
                        {t(
                          "yourFundsWillBeAllocatedToMatchCurrentContributions"
                        )}
                      </p>
                    </FormControl>
                    {contributionStatus === "current" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "120px",
                        }}
                      >
                        {perArr.map((item: any) => (
                          <Button
                            onClick={() => {
                              setCurrentContributionPercent(item.value);
                              handleSubmit(item.value, "current", "custom");
                            }}
                            variant="contained"
                            color={
                              currentContributionPercent === item.value
                                ? "primary"
                                : "inherit"
                            }
                            className="round-button"
                          >
                            {item.title}
                          </Button>
                        ))}
                      </Box>
                    )}
                  </Grid>
                )}

                {contributionStatus === "current" &&
                  currentContributionPercent && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "90%",
                        marginX: "auto",
                        marginY: "20px",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      <Box
                        style={{
                          width: "50%",
                        }}
                      >
                        <p style={{ marginBottom: "0" }}>
                          {t("totalCampaigns")}
                        </p>
                        <Typography
                          style={{ textAlign: "left", fontSize: "1rem" }}
                          variant="h5"
                        >
                          {filterData?.totalCampaignCount
                            ? filterData?.totalCampaignCount
                            : 0}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          width: "50%",
                        }}
                      >
                        <p style={{ marginBottom: "0" }}>
                          {t("payableAmount")}
                        </p>
                        <Typography
                          style={{ textAlign: "left", fontSize: "1rem" }}
                          variant="h5"
                        >
                          {filterData?.payableAmount
                            ? filterData?.payableAmount?.toFixed(6)
                            : 0}{" "}
                          {paymentTypeBlockchain === "ALGO" && (
                            <img
                              style={{ width: "16px", marginBottom: "-3px" }}
                              src={algo}
                              alt="ALGO"
                            />
                          )}
                          {paymentTypeBlockchain === "USDC" && (
                            <MonetizationOnIcon
                              style={{
                                marginBottom: "-6px",
                                fontSize: "22px",
                                color: "black",
                              }}
                            />
                          )}
                          {/* <img
                            style={{ width: "15px", marginBottom: "-1px" }}
                            src={algo}
                          ></img> */}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "#d7d7d761",
                    borderRadius: "16px",
                    maxWidth: "97%",
                    margin: "0 auto",
                    padding: "25px",
                    marginTop: "25px",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox disabled />}
                      label={
                        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                          {t("requestCashdilloEsgNftSocialImpactReportCard")}(
                          {t("comingSoon")})
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "#d7d7d761",
                    borderRadius: "16px",
                    maxWidth: "97%",
                    margin: "0 auto",
                    padding: "25px",
                    marginTop: "25px",
                  }}
                >
                  <FormControl
                    sx={{ mt: 2 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel
                      component="legend"
                      sx={{ mb: 2, fontSize: "16px", fontWeight: 600 }}
                    >
                      {t(
                        "youHerebyGiveCashdilloTheConsentToDistributeTheAboveAmountAutomatically"
                      )}{" "}
                    </FormLabel>
                    <Box sx={{ marginTop: "20px" }}>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{ color: "#000", border: "1px solid gray" }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {consentStatus.title !== ""
                          ? consentStatus.title
                          : t("pleaseSelectOption")}
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <Box sx={{ width: "200px" }}>
                          <MenuItem
                            onClick={() => {
                              setConsentStatus({
                                title: "Yes",
                                status: true,
                              });
                              handleMenuClose();
                            }}
                          >
                            {t("yes")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setConsentStatus({
                                title: "No",
                                status: false,
                              });
                              handleMenuClose();
                            }}
                          >
                            {t("no")}
                          </MenuItem>
                        </Box>
                      </Menu>
                    </Box>
                  </FormControl>
                  <Permission checked={checked} setChecked={setChecked} />
                  {/* <a href="#">{t("termsAndConditionsApply")}</a> */}
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "120px",
                  marginTop: 3,
                  marginBottom: 3,
                  marginLeft: "10%",
                }}
              >
                <Button
                  onClick={() => handleClose()}
                  variant="contained"
                  color="error"
                  className="round-button"
                >
                  {t("close")}
                </Button>
                <Button
                  disabled={
                    consentStatus.title === "" ||
                    consentStatus.title === "No" ||
                    !checked
                      ? true
                      : false
                  }
                  variant="contained"
                  color="primary"
                  className="round-button"
                  onClick={handlePayWithCreditOrrDebitCard}
                >
                  {t("payWithCcOrDc")}
                </Button>
                <>
                  <Button
                    disabled={
                      consentStatus.title === "" ||
                      consentStatus.title === "No" ||
                      !checked
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (payment === "") {
                        const toastPaylaod = {
                          text: t("pleaseSelectPaymentMethod"),
                          success: false,
                          active: true,
                        };
                        dispatch(setToaster(toastPaylaod));
                      } else {
                        if (payment !== "full") {
                          if (contributionStatus === "") {
                            const toastPaylaod = {
                              text: t("pleaseSelectAnyContributionProcess"),
                              success: false,
                              active: true,
                            };
                            dispatch(setToaster(toastPaylaod));
                          } else {
                            if (contributionStatus === "current") {
                              if (currentContributionPercent) {
                                handleShowWalletOptions();
                              } else {
                                const toastPaylaod = {
                                  text: t("pleaseSelectContributionPercentage"),
                                  success: false,
                                  active: true,
                                };
                                dispatch(setToaster(toastPaylaod));
                              }
                            } else {
                              handleShowWalletOptions();
                            }
                          }
                        } else {
                          handleShowWalletOptions();
                        }
                      }
                    }}
                    variant="contained"
                    color="primary"
                    className="round-button"
                  >
                    {t("payWithWallet")}
                  </Button>
                  {showWalletButtons && (
                    <Wallets
                      open={showWalletButtons}
                      handleClose={() => setShowWalletButtons(false)}
                      handleSubmit={handleDonate}
                    />
                  )}
                </>
              </Box>
            </Box>
          </Modal>
        </div>
      </Box>
      <NotificationToast />
    </div>
  );
}
export default MatchFundFilter;

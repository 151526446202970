import { Button } from "@mui/material";
import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useAppDispatch, useAppSelector } from "../../store";
// import FacebookIcon from "../../assets/images/facebookicon.png";
import { handleFacebookLogin } from "../../requests/simpleRequest";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import {
  loggedInUser,
  setUserData,
  setUserToken,
} from "../../store/slices/UserSlice";
import { setLoginModal, setRegisterModal } from "../../store/slices/ModalSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { facebook, bordered, borderedLogin } from "./selectRoleStyle"
import Box from '@mui/material/Box'
import FacebookIcon from '@mui/icons-material/Facebook';
// import FacebookLogin from 'react-facebook-login';

function FacebookLoginButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userslice = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const handleLogin = async (res: any) => {
    dispatch(toggleAppLoading(true));
    if (!res.accessToken) {
      dispatch(toggleAppLoading(false));
      return;
    }

    const payload = {
      userID: res.userID,
      accessToken: res.accessToken,
    };
    const backendRes: any = await handleFacebookLogin(payload);
    if (backendRes.success) {
      const payload = {
        loggedIn: true,
        userToken: backendRes.data.token,
        loginType: "facebook",
        registerUserType: userslice.registerUserType,
        userAppId: backendRes.data.userAppId ? backendRes.data.userAppId : "",
        kyc: backendRes.data.kyc,
        userId: backendRes.data.userId,
      };
      dispatch(setUserToken(payload));
      const toastPaylaod = {
        text: t("loginWithFacebookSuccess"),
        success: true,
        active: true,
      };
      const userPayload: loggedInUser = {
        status: backendRes.data.status ? backendRes.data.status : "",
        name: backendRes.data.name ? backendRes.data.name : "",
        email: backendRes.data.email ? backendRes.data.email : "",
        userId: backendRes.data.userId ? backendRes.data.userId : "",
        createdAt: backendRes.data.createdAt ? backendRes.data.createdAt : "",
        address: backendRes.data.address ? backendRes.data.address : "",
        phone: backendRes.data.phone ? backendRes.data.phone : "",
        country: backendRes.data.country ? backendRes.data.country : "",
        gender: backendRes.data.gender ? backendRes.data.gender : "",
        role: backendRes.data.role ? backendRes.data.role : "",
        userAppId: backendRes.data.userAppId ? backendRes.data.userAppId : "",
        organisationName: backendRes.data.organisationName
          ? backendRes.data.organisationName
          : "",
        organisationOwner: backendRes.data.organisationOwner
          ? backendRes.data.organisationOwner
          : "",
        orgnisationRole: backendRes.data.orgnisationRole
          ? backendRes.data.orgnisationRole
          : "",
      };
      dispatch(setUserData(userPayload));
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      // navigate("/user");
    } else {
      const toastPaylaod = {
        text: backendRes.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    }
    dispatch(setRegisterModal(false));
    dispatch(setLoginModal(false));
  };

  return (
    <FacebookLogin
      appId="1362211264642889"
      autoLoad={false}
      fields="name, email, picture"
      scope="public_profile,email"
      callback={handleLogin}
      render={(renderProps) => {
        return (
          <Button
            variant="contained"
            onClick={renderProps.onClick}
            sx={{ fontSize: "0px", whiteSpace: "nowrap" }}
          >
            <Box sx={borderedLogin}>
              <FacebookIcon sx={facebook} />
              <div style={{ color: "#4c4b4b", marginLeft: "5px", fontSize: "14px" }}>{t("facebook")}</div>
            </Box>
          </Button>
        );
      }}
    />
  );
}

export default FacebookLoginButton;

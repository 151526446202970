import zIndex from "@mui/material/styles/zIndex";

export const Messagerow = {
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: " 20px 24px 20px 0px",
  height: "calc(600px - 160px)",
  width: "calc(100% + 24px)",
  overflowY: "auto",
};
export const MessageColWrap = {
  display: "block",
  width: "100%",
  marginBottom: "10px",
};
export const MessageRecieve = {
  display: "flex",
  maxWidth: "100%",
  width: "80%",
  justifyContent: " flex-start",
  "& .messageSent": {
    backgroundColor: "#e5e0e0",
    borderRadius: "20px",
    padding: "10px 20px",
    marginBottom: "35px",
  },
  "& .time": {
    color: "rgb(183 178 178)",
    position: "absolute",
    bottom: "15px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "100px",
    left: "0px",
  },
};
export const MessageSent = {
  display: "flex",
  maxWidth: "100%",
  width: "80%",
  justifyContent: " flex-end",
  marginLeft: "auto",
  "& .messageSent": {
    backgroundColor: "#bfbbbb",
    borderRadius: "20px",
    padding: "10px 20px",
    marginBottom: "35px",
  },
  "& .delete": {
    position: "absolute",
    bottom: "30px",
    left: "-25px",
    padding: "0px",
  },
  "& .time": {
    color: "rgb(183 178 178)",
    position: "absolute",
    bottom: "15px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    minWidth: "100px",
    right: "0px",
  },
};

// this is for the chat
export const chatSection = {
  width: "100%",
  height: "auto",
  minHeight: "600px",
  marginBottom: "20px",
  position: "relative",
  overflow: "hidden",
};

export const recentChats = {
  "@media(max-width:900px)": {
    width: "100%",
    position: "absolute",
    left: "-100%",
    top: 0,
    height: "100%",
    zIndex: 3,
    opacity: 1,
    maxWidth: "100%",
    backgroundColor: "#fff",
    transition: "all 0.2s",
  },
  borderRight: "1px solid #e0e0e0",
};

export const messageAreaWrapper = {
  "@media(max-width:900px)": {
    width: "100%",
    flex: 1,
    maxWidth: "100%",
  },
};

export const recentChatWrapper = {
  maxHeight: "464px",
  overflowY: "auto",
};

import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Menu,
  MenuItem,
  FormLabel,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CampaigngForm } from "./style";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { blackBoxHeading, MilestoneForm, PhotoUpload } from "./style";
import { InputFiles } from "typescript";
import { useAppDispatch } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate, useParams } from "react-router-dom";
import { submitMilestoneReport } from "../../../requests/authRequest";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.min.js";

function SubmitCreatorMilestoneReport() {
  const { t } = useTranslation();
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const dispatch = useAppDispatch();
  const resultsRef = useRef(null);
  const YearlyOutputRef = useRef(null);
  const CostPerUnitRef = useRef(null);
  const locationRef = useRef(null);
  // related to object met
  const [objectiveMet, setObjectiveMet] = useState<string>("true");

  // related to nft description
  const [description, setDescription] = useState<string>("");
  const [invalidDescription, setInvalidDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");

  // related to image upload
  const [file, setFile] = useState<InputFiles | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const fileRef = useRef<HTMLElement>();
  const [evidenceOneError, setEvidenceOneError] = useState<boolean>(false);

  // related to pdf upload
  const [filePdf, setFilePdf] = useState<InputFiles | undefined>();
  const [filePdfName, setFilePdfName] = useState<string>("");
  const [filePdfSelected, setFilePdfSelected] = useState<boolean>(false);
  const [filePdfUrl, setFilePdfUrl] = useState<string>("");
  const [evidenceTwoError, setEvidenceTwoError] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [pdfError, setPdfError] = useState<boolean>(false);

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [monitoring_status, setMonitoring_status] = useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });
  const [invalidMonitoringOption, setInvalidMonitoringOption] =
    useState<boolean>(false);
  const [errorInvalidMonitoringTitle, setErrorInvalidMonitoringTitle] =
    useState<string>("");
  const [invalidYearlyOutput, setinvalidYearlyOutput] =
    useState<boolean>(false);
  const [errorYearlyOutput, setErrorYearlyOutput] = useState<string>("");
  const [invalidCostPerUnit, setinvalidCostPerUnit] = useState<boolean>(false);
  const [errorCostPerUnit, setErrorCostPerUnit] = useState<string>("");
  const [invalidResults, setinvalidResults] = useState<boolean>(false);
  const [errorResults, setResults] = useState<string>("");
  const [invalidLocation, setInvalidLocation] = useState<boolean>(false);
  const [errorLocation, setErrorLocation] = useState<string>("");
  const [YearlyOutput, setYearlyOutput] = useState<number>(0);
  const [Results, setresults] = useState<string>("");
  const [location, setLocation] = useState("");

  const [CostPerUnit, setCostPerUnit] = useState<number>(0);

  const navigate = useNavigate();
  const { campaignId, milestoneId } = useParams();

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    // setPageNumber(1);
  };

  const previousPage = (e: any) => {
    e.preventDefault();
    if (pageNumber === 1) {
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const nextPage = (e: any) => {
    e.preventDefault();
    if (pageNumber === numPages) {
      return;
    }
    setPageNumber(pageNumber + 1);
  };
  const handleMonitoring_status = (value: any) => {
    setMonitoring_status(value);
  };
  const handleCostPerUnit = (vl: any) => {
    setCostPerUnit(vl);
  };

  const handleYearlyOutput = (vl: any) => {
    setYearlyOutput(vl);
  };

  const handleResults = (vl: string) => {
    setresults(vl);
  };
  const handleLocation = (vl: any) => {
    setLocation(vl);
  };

  const [coordinates, setCoordinates] = useState<{
    lat: null | number;
    lng: null | number;
  }>({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    handleLocation(value);
    setCoordinates(ll);
  };

  const handleUploadChangePdf = (e: any) => {
    clearPdfData();
    if (!evidenceTwoError) {
      setEvidenceTwoError(true);
    }
    const tempFile = e.target.files;
    let tempFileType: string = tempFile[0].name
      .split(".")
      [tempFile[0].name.split(".").length - 1].toLowerCase();

    // this part set the file type to display
    if (tempFileType && tempFileType.length > 0) {
      if (tempFileType !== "pdf") {
        clearPdfData();
        const toastPaylaod = {
          text: t("pleaseSelectPdfFile"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        return;
      }
    }
    // this part actually accpet and set the file data for the form
    if (
      tempFile[0] !== undefined &&
      tempFile[0].name.length > 0 &&
      Math.floor(tempFile[0].size / 1000000) < 25
    ) {
      setPdfError(false);
      setFilePdfSelected(true);
      setFilePdf(tempFile[0]);
      setFilePdfName(tempFile[0].name);
      setFilePdfUrl(`${URL.createObjectURL(tempFile[0])}`);
    }

    // this one checks for a valid size 25mb
    if (tempFile[0].size / 1000000 > 25) {
      clearPdfData();
      const toastPaylaod = {
        text: t("pleaseSelectFileLessThanTwentyfiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }
  };

  const handleUploadChangeImage = (e: any) => {
    clearImageData();
    const tempFile = e.target.files;
    let tempFileType: string = tempFile[0].name
      .split(".")
      [tempFile[0].name.split(".").length - 1].toLowerCase();
    let isImage: boolean = false;
    // this part set the file type to display
    if (tempFileType && tempFileType.length > 0) {
      if (
        tempFileType === "jpeg" ||
        tempFileType === "jpg" ||
        tempFileType === "png" ||
        tempFileType === "gif" ||
        tempFileType === "svg"
      ) {
        setFileType("image");
        isImage = true;
      } else if (tempFileType === "mp4" || tempFileType === "webm") {
        setFileType("video");
        isImage = false;
      } else {
        clearImageData();
        const toastPaylaod = {
          text: t("pleaseSelectJpegJpgPngGifSvgMp4WebmFile"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        return;
      }
    }
    // this part actually accpet and set the file data for the form
    if (
      tempFile[0] !== undefined &&
      tempFile[0].name.length > 0 &&
      Math.floor(tempFile[0].size / 1000000) < 25
    ) {
      setFileError(false);
      setFileSelected(true);
      setFile(tempFile[0]);
      setFileName(tempFile[0].name);
      if (isImage && fileRef && fileRef.current) {
        fileRef.current.style.backgroundImage = `url(${URL.createObjectURL(
          tempFile[0]
        )})`;
      } else {
        setVideoUrl(URL.createObjectURL(tempFile[0]));
      }
    }

    // this one checks for a valid size 25mb
    if (tempFile[0].size / 1000000 > 25) {
      clearImageData();
      const toastPaylaod = {
        text: t("pleaseSelectFileLessThanTwentyfiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }
  };

  const clearImageData = () => {
    setFile(undefined);
    setFileName("");
    setFileSelected(false);
    setFileType("");
    if (fileRef && fileRef.current) {
      fileRef.current.style.backgroundImage = "none";
    }
  };
  const clearPdfData = () => {
    setFilePdf(undefined);
    setFilePdfName("");
    setFilePdfSelected(false);
    setFilePdfUrl("");
  };
  if (!evidenceOneError) {
    setEvidenceOneError(true);
  }
  const handleRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    setObjectiveMet(e.target.value);
  };

  const validateForm = () => {
    let validity = true;

    if (!fileSelected && evidenceOneError) {
      setFileError(true);
    }
    if (!filePdfSelected) {
      setPdfError(true);
    }
    // for description
    if (description.trim().length <= 0 || description.trim().length > 3000) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription("Please enter the description of length 1 to 3000");
    } else {
      setInvalidDescription(false);
      setErrorDescription("");
    }

    //for results
    if (monitoring_status.title === "Yes") {
      if (Results.trim().length <= 0) {
        validity = false;
        setinvalidResults(true);
        setResults(t("pleaseEnterTheResults") || "");
      } else {
        setinvalidResults(false);
        setResults("");
      }
      if (YearlyOutput <= 0) {
        validity = false;
        setinvalidYearlyOutput(true);
        setErrorYearlyOutput(t("pleaseEnterYearlyOutput") || "");
      } else {
        setinvalidYearlyOutput(false);
        setErrorYearlyOutput("");
      }
      //CostPerUnit
      if (CostPerUnit <= 0) {
        validity = false;
        setinvalidCostPerUnit(true);
        setErrorCostPerUnit(t("pleaseEnterCostPerUnit") || "");
      } else {
        setinvalidCostPerUnit(false);
        setErrorCostPerUnit("");
      }
    }
    // location

    // if (!location.trim().length) {
    //   validity = false;
    //   setInvalidLocation(true);
    //   setErrorLocation("pleaseEnterLocation");
    // } else {
    //   setInvalidLocation(false);
    //   setErrorLocation("");
    // }

    // for file selected
    if (!fileSelected || file === undefined) {
      validity = false;
    }

    // for pdf file selected
    if (!filePdfSelected || filePdf === undefined) {
      validity = false;
    }

    return validity;
  };

  const submitReport = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    const info = {
      milestoneId: milestoneId,
      objectiveMet: objectiveMet,
      imageFile: file,
      description: description,
      pdfFile: filePdf,
      isReforestationProject: monitoring_status.status,
      expectedResults: Results,
      expectedYearlyoutput: YearlyOutput,
      // location: location,
      // lat: coordinates.lat,
      // lng: coordinates.lng,
      expectedCostPerUnit: CostPerUnit,
    };

    const res = await submitMilestoneReport(info);

    if (res.success) {
      const toastPaylaod = {
        text: t("milestoneReportSubmitted"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      handleGoBack();
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
      return;
    }
  };

  const handleGoBack = () => {
    navigate(`/user/my-campaign/${campaignId}`);
  };
  return (
    <Box sx={MilestoneForm}>
      <Typography variant="h4" align="center" mb={2}>
        {t("milestonesStandardReport")}
      </Typography>
      <Grid container sx={{ marginTop: "20px" }} spacing={2}>
        <ol>
          <li>{t("didYouMeetYourMilestoneObjective")}</li>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={objectiveMet}
            name="radio-buttons-group"
            onChange={handleRadioButton}
          >
            <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
            <FormControlLabel value={"false"} control={<Radio />} label="No" />
          </RadioGroup>
          <li>{t("pleaseUploadPhotographicOrVideoEvidence")}</li>
          <Box ref={fileRef} sx={PhotoUpload} style={{ position: "relative" }}>
            {fileSelected && (
              <IconButton onClick={clearImageData}>
                <CancelIcon />
              </IconButton>
            )}
            {fileType === "video" && file !== undefined && (
              <video width={"100%"} src={videoUrl} controls />
            )}
          </Box>
          {fileError && (
            <FormHelperText
              sx={{ marginBottom: 3 }}
              className="css-l8pll-MuiFormHelperText-root Mui-error"
            >
              {t("pleaseSelectImageOrVideoToUpload")}
            </FormHelperText>
          )}
          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="evidence-one">
              <input
                className="inputfile"
                accept="video/mp4,video/x-m4v,video/*, .jpeg, .png"
                id="evidence-one"
                onChange={handleUploadChangeImage}
                multiple={false}
                // ref={inputRef}
                type="file"
              />
              <Button
                variant="text"
                component="span"
                className="uploadButton"
                startIcon={<FileUploadIcon />}
              >
                {t("upload")}
              </Button>
            </label>
          </Stack>
          <li>{t("comments")}</li>
          <TextField
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            multiline
            minRows={4}
            type={"text"}
            placeholder={t("description") || ""}
            variant="outlined"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            error={invalidDescription}
            helperText={invalidDescription && errorDescription}
          />

          <li>{t("additionalSupportingDocs")}</li>
          {filePdfSelected && (
            <>
              <Document
                file={{
                  url: filePdfUrl,
                  // url: "https://4.tcp.ngrok.io:13022/api/file/62b9b624e507ba1c4e4495c2",
                }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <div>
                <p>
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </p>
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  style={{ marginRight: "20px" }}
                >
                  {t("previous")}
                </button>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  {t("next")}
                </button>
              </div>
            </>
          )}

          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="evidence-two">
              <input
                className="inputfile"
                accept=".pdf"
                id="evidence-two"
                onChange={handleUploadChangePdf}
                multiple={false}
                // ref={inputRef}
                type="file"
              />
              <Button
                variant="text"
                component="span"
                className="uploadButton"
                startIcon={<FileUploadIcon />}
              >
                {t("upload")}
              </Button>
            </label>
          </Stack>
          {pdfError && (
            <FormHelperText
              sx={{ marginBottom: 3 }}
              className="css-l8pll-MuiFormHelperText-root Mui-error"
            >
              {t("pleaseSelectPdfToUpload")}
            </FormHelperText>
          )}
        </ol>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: "50px",
          marginBottom: "60px",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
          {t("isCampaignaReforestationProject")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <Button
            id="basic-button2"
            aria-controls={open2 ? "basic-menu2" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {monitoring_status.title !== ""
              ? monitoring_status.title
              : t("pleaseSelectOption")}
          </Button>
          <Menu
            id="basic-menu2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button2",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  handleMonitoring_status({ title: "Yes", status: true });
                  handleClose2();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMonitoring_status({ title: "No", status: false });
                  handleClose2();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
        {invalidMonitoringOption && (
          <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
            {errorInvalidMonitoringTitle}
          </FormHelperText>
        )}
      </Box>
      {monitoring_status.title === "Yes" && (
        <Box sx={CampaigngForm}>
          <Typography variant="h4" mt={3} align="center" mb={3}>
            {t("monitoringDetails")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel component="legend">Project Output</FormLabel>
              <TextField
                value={YearlyOutput}
                ref={YearlyOutputRef}
                // label="YearlyOutput"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                sx={{ marginBottom: 3 }}
                hiddenLabel
                type="number"
                placeholder={t("yearlyOutput") || ""}
                onChange={(e) => {
                  handleYearlyOutput(e.target.value);
                }}
                error={invalidYearlyOutput}
                helperText={invalidYearlyOutput && errorYearlyOutput}
              />
              <FormLabel component="legend">Cost Per Unit($)</FormLabel>
              <TextField
                ref={CostPerUnitRef}
                // label="Cost Per Unit($)"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                sx={{ marginBottom: 3 }}
                hiddenLabel
                type="number"
                placeholder={t("costPerUnit($)") || ""}
                onChange={(e) => {
                  handleCostPerUnit(e.target.value);
                }}
                value={CostPerUnit}
                error={invalidCostPerUnit}
                helperText={invalidCostPerUnit && errorCostPerUnit}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">Expected Results</FormLabel>
                <TextField
                  ref={resultsRef}
                  multiline
                  rows={7}
                  type={"text"}
                  // label="Expected Results"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  sx={{ marginBottom: 3 }}
                  hiddenLabel
                  placeholder={t("expectedResults") || ""}
                  onChange={(e) => {
                    handleResults(e.target.value);
                  }}
                  value={Results}
                  error={invalidResults}
                  helperText={invalidResults && errorResults}
                />
                {/* <FormLabel component="legend">Location</FormLabel>
                <div>
                  <PlacesAutocomplete
                    value={location}
                    onChange={handleLocation}
                    onSelect={handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                      <div>
                        <TextField
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input',
                          })}
                          error={invalidLocation}
                          helperText={invalidLocation && errorLocation}
                          ref={locationRef}
                          sx={{ marginBottom: 3 }}
                          fullWidth
                          hiddenLabel
                          type={"text"}
                          variant="outlined"
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion: any) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>

                      </div>
                    )}
                  </PlacesAutocomplete>

                </div> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "40px",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={handleGoBack}
        >
          {t("back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={submitReport}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
}

export default SubmitCreatorMilestoneReport;

import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  IconButton,
  Tooltip,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useRef, useState } from "react";
import MatchFundFilter from "./MatchFundFilter";
import CampaignCard from "../../home/campaigns/CampaignCard";
import { campaign } from "../../home/campaigns/HomeCampaignContainer";
import {
  getAllCampaign,
  getCampaignCategories,
} from "../../../requests/simpleRequest";
import { useAppDispatch } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import FilterModal from "../../home/campaigns/filterModal/FilterModal";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useTranslation } from "react-i18next";

interface category {
  title: string;
  slug: string;
  categoryId: string;
}

let filterData: any = {
  limit: 0,
  page: 0,
  campaign: "",
  goal: "",
  categories: [],
  funding: [],
  goalAmountMin: "",
  goalAmountMax: "",
  country: "",
  endDate: "",
  campaignNoOlderThan: "",
};

function MatchFund() {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Array<category>>([]);
  const [modalStatus, setModalStatus] = React.useState<boolean>(false);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [campaignList, setCampaignList] = useState<Array<campaign>>([]);
  const [page, setPage] = useState<number>(1);
  const [loadmore, setLoadmore] = useState<boolean>(true);
  const [filterStatus, setFilterStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const fetchEsgCampaign = async () => {
    const data = {
      limit: 8,
      page: 1,
      campaign: "matchFunding&matchFunding=true",
    };
    setPage(1);
    dispatch(toggleAppLoading(true));
    const res = await getAllCampaign(data);
    if (res.success) {
      if (res.data.totalPages > page) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      setCampaignList([...res.data.campaigns]);
    } else {
      setLoadmore(false);
    }
    setTotalCount(res.totalCount);
    dispatch(toggleAppLoading(false));
  };

  const filter = async (filters: any) => {
    const data = {
      limit: 8,
      page: 1,
      campaign: "matchFunding&matchFunding=true",
      goal: filters.goal,
      categories: filters.categories,
      funding: filters.funding,
      goalAmountMin: filters.goalAmountMin,
      goalAmountMax: filters.goalAmountMax,
      country: filters.country,
      endDate: filters.endDate,
      campaignNoOlderThan: filters.campaignNoOlderThan,
    };
    setPage(1);
    filterData = data;
    const res = await getAllCampaign(data);
    if (res.success) {
      if (res.data.totalPages > 1) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      setCampaignList([...res.data.campaigns]);
      setTotalCount(res.totalCount);
    }
    setFilterStatus(false);
  };

  const fetchCategories = async () => {
    const res: any = await getCampaignCategories();
    if (res.success) {
      setCategories(res.data);
    } else {
      const toastPaylaod = {
        text: t("unableToFetchCategories"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  const handleLoadMore = async () => {
    let nextPage = page + 1;
    setPage(nextPage);
    const data = {
      limit: 8,
      page: nextPage,
      campaign: "matchFunding&matchFunding=true",
      goal: filterData?.goal ? filterData.goal : "",
      categories: filterData?.categories ? filterData.categories : [],
      funding: filterData?.funding ? filterData.funding : [],
      goalAmountMin: filterData?.goalAmountMin ? filterData.goalAmountMin : "",
      goalAmountMax: filterData?.goalAmountMax ? filterData.goalAmountMax : "",
      country: filterData?.country ? filterData.country : "",
      endDate: filterData?.endDate ? filterData.endDate : "",
      campaignNoOlderThan: filterData?.campaignNoOlderThan
        ? filterData.campaignNoOlderThan
        : "",
    };
    const res = await getAllCampaign(data);
    if (res.success) {
      if (res.data.totalPages > nextPage) {
        setLoadmore(true);
        setTotalCount(res.totalCount);
      } else {
        setLoadmore(false);
      }
      setCampaignList([...campaignList, ...res.data.campaigns]);
    }
  };

  const handleClearFilter = () => {
    filterData = {
      limit: 8,
      page: 1,
      campaign: "",
      goal: "",
      categories: [],
      funding: [],
      goalAmountMin: "",
      goalAmountMax: "",
      country: "",
      endDate: "",
      campaignNoOlderThan: "",
    };
    filter(filterData);
  };

  useEffect(() => {
    fetchCategories();
    fetchEsgCampaign();
    setFilterStatus(false);
  }, []);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <Typography
          style={{ textAlign: "left", fontSize: "1rem", padding: "10px" }}
          variant="h5"
        >
          {totalCount} Record(s)
        </Typography>
      </Grid>
      <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={() => setFilterStatus(true)}
        >
          filters
        </Button>
        {((filterData?.categories && filterData?.categories.length > 0) ||
          (filterData?.funding && filterData?.funding.length > 0) ||
          filterData?.goal ||
          filterData?.goalAmountMin ||
          filterData?.goalAmountMax ||
          filterData?.country ||
          filterData?.endDate ||
          filterData?.campaignNoOlderThan) && (
          <Tooltip title="Reset filter">
            <FilterAltOffIcon
              color="primary"
              onClick={() => handleClearFilter()}
            >
              <FilterAltIcon />
            </FilterAltOffIcon>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          onClick={() => setModalStatus(true)}
          color="primary"
          style={{
            fontSize: "13px",
          }}
        >
          {t("matchFundEsgCampaign")}
        </Button>
      </Grid>
      <Container fixed>
        <Grid mt={3} mb={3} container spacing={4}>
          {campaignList &&
            campaignList.length > 0 &&
            campaignList.map((camp) => {
              return <CampaignCard data={camp} />;
            })}
        </Grid>
        {loadmore && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className="round-button"
              onClick={handleLoadMore}
            >
              {t("loadMore")}..
            </Button>
          </Grid>
        )}
      </Container>
      {/* <Grid item xs={4} >
        <Box
          style={{ backgroundColor: '#d7d7d7', height: '250px', borderRadius: '16px' }}
        >
          <span style={{ padding: '12px', position: 'relative', top: '15px', left: '41%', height: '36px', width: '42px', backgroundColor: '#4caf50', borderRadius: '50%', fontSize: '17px', marginTop: '17px', marginRight: '17px', color: '#ffffff', lineHeight: '36px' }}>80</span>
        </Box>
        <Stack direction="row" spacing={2} style={{ marginTop: '20px', padding: '10px' }}>
          <Button variant="contained" style={{ height: '30px', backgroundColor: '#8bc34a7d', fontSize: '12px', padding: '10px 50px' }}>Environmental</Button>
          <Button variant="contained" style={{ height: '30px', backgroundColor: '#ffeb3b99', fontSize: '12px', padding: '10px 50px' }}>Social</Button>
          <Button variant="contained" style={{ height: '30px', backgroundColor: '#2196f3a3', fontSize: '12px', padding: '10px 50px' }}>Governance</Button>
        </Stack>
        <Typography style={{ textAlign: "left", fontSize: "1rem", padding: '10px' }} variant="h5">
          Campaign Name
        </Typography>
        <Typography variant="body1" style={{ textAlign: "left", padding: '10px' }}>
          We collect company specific information for the Cashdillo analysis,
          for our SDG/ESG vetting ). Private information will remain private.
        </Typography>
      </Grid> */}
      {/* ------ filter modal ----------- */}

      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        open={filterStatus}
        onClose={() => setFilterStatus(false)}
      >
        <FilterModal
          filterCategories={categories}
          filterData={filterData}
          handleClose={() => setFilterStatus(false)}
          handleFilter={(el: any) => filter(el)}
        />
      </Modal>

      <Modal open={modalStatus}>
        <MatchFundFilter handleClose={() => setModalStatus(false)} />
      </Modal>
    </Grid>
  );
}
export default MatchFund;

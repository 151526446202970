import { Button, Modal, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setRewardType } from "../../../../store/slices/CreateCampaignSlice";
import { setSelectCashdilloNftModal } from "../../../../store/slices/ModalSlice";
import BoughtNftContainer from "./BoughtNftContainer";
import BuyNftContainer from "./BuyNftContainer";
import { FooterBtn, style, titleStyle } from "../selectRewardTypeModalStyle";
import { useTranslation } from "react-i18next";

function SelectCashdilloNftModal() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const dispatch = useAppDispatch();
  const open = useAppSelector(
    (state) => state.modalReducer.selectCashdilloNftModal
  );
  const selectedNftId = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardNftId
  );

  const handleClose = () => {
    dispatch(setSelectCashdilloNftModal(false));
  };

  const clearSelection = () => {
    const nftData = {
      rewardNftId: "",
      rewardType: "",
    };
    dispatch(setRewardType(nftData));
    handleClose();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const nftData = {
      rewardNftId: "",
      rewardType: "",
    };
    dispatch(setRewardType(nftData));
    setValue(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: "100%",
          outline: " 0px",
          overflow: "hidden auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media(max-height:700px)": {
            alignItems: "baseline",
          },
        }}
        onClick={handleClose}
      >
        <Box
          sx={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box sx={titleStyle}>
            <Typography variant="h4" align="center" mb={2}>
              {t("selectCashdilloNft")}
            </Typography>
            <Box sx={{ textAlign: "center" }} mb={3}>
              {value === 0 ? (
                <Typography variant="caption">
                  {t("pleaseSelectOneNft")}
                </Typography>
              ) : value === 1 ? (
                <Typography variant="caption">{t("buyNft")}</Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: "calc(100vh - 300px)",
              overflowX: "auto",
            }}
          >
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="My NFT" />
                <Tab label="Marketplace" />
              </Tabs>
            </Box>
            {value === 0 ? (
              <BoughtNftContainer />
            ) : value === 1 ? (
              <BuyNftContainer setValue={setValue} />
            ) : (
              <></>
            )}
          </Box>
          <Box sx={FooterBtn}>
            <Button
              sx={{ color: "initial" }}
              variant="text"
              onClick={clearSelection}
            >
              {t("doItLater")}
            </Button>
            {selectedNftId && (
              <Button variant="contained" onClick={handleClose} color="primary">
                {t("continue")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default SelectCashdilloNftModal;

import React, { useEffect } from "react";
import "./App.css";
import RequestResetPassword from "./components/auth/RequestResetPassword";
import SelectRoleModal from "./components/auth/SelectRoleModal";
import {
  AlgoDonationModal,
  PreviewImageModal,
} from "./components/common/modals";
import DeleteCampaignModal from "./components/common/modals/DeleteCampaignModal";
import DeleteGrantModal from "./components/common/modals/DeleteGrantModal";
import DeleteGrantManagerModal from "./components/common/modals/DeleteGrantManagerModal";
import UserVerificationModal from "./components/common/modals/UserVerificationModal";
import SelectCashdilloNftModal from "./components/user/createCampaign/adminCreatedNft/SelectCashdilloNftModal";
import SelectCreatorNftModal from "./components/user/createCampaign/creatorCreatedNft/SelectCreatorNftModal";
import SelectRewardTypeModal from "./components/user/createCampaign/SelectRewardTypeModal";
import LoadingScreen from "./loaders/LoadingScreen";
import NotificationToast from "./loaders/NotificationToast";
import AuthRequestInterceptor from "./requests/AuthRequestInterceptor";
import RouteConfig from "./routeConfig";
import ChatSocket from "./socket/ChatSocket";
import NotificationSocket from "./socket/NotificationSocket";
import { useAppDispatch, useAppSelector } from "./store";
import {
  setToaster,
  toggleAppLoading,
} from "./store/slices/LoadinAndNotifSlice";
import { Login } from "./views/auth/login";
import { Register } from "./views/auth/register";
import {
  reconnectProviders,
  initializeProviders,
  WalletProvider,
  PROVIDER_ID,
} from "@txnlab/use-wallet";

function App() {
  const dispatch = useAppDispatch();
  const walletProviders = initializeProviders([
    PROVIDER_ID.PERA,
    PROVIDER_ID.DEFLY,
    // PROVIDER_ID.WALLETCONNECT,
  ]);
  const notifSocket = useAppSelector(
    (state) => state.socketReducer.notifSocket
  );

  useEffect(() => {
    dispatch(toggleAppLoading(false));
  }, []);

  useEffect(() => {
    if (notifSocket && notifSocket.connected) {
      notifSocket.on("notification", (data: any) => {
        const toastPayload = {
          active: true,
          success: true,
          text: `Congratulations! ${data.investor.name} funded ${data.donatedAmount} Algo to your ${data.campaign.title}`,
        };
        dispatch(setToaster(toastPayload));
      });
    }
  }, [notifSocket]);

  return (
    <WalletProvider value={walletProviders}>
      <div className="App">
        <AuthRequestInterceptor />
        <LoadingScreen />
        <NotificationToast />
        <RouteConfig />
        <Login />
        <Register />
        <SelectRoleModal />
        <PreviewImageModal />
        <RequestResetPassword />
        <DeleteCampaignModal />
        <NotificationSocket />
        <ChatSocket />
        <SelectRewardTypeModal />
        <SelectCashdilloNftModal />
        <SelectCreatorNftModal />
        <AlgoDonationModal />
        <UserVerificationModal />
        <DeleteGrantModal />
        <DeleteGrantManagerModal />
      </div>
    </WalletProvider>
  );
}

export default App;

import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";

import { useAppDispatch, useAppSelector } from "../../store";
// import FacebookIcon from "../../assets/images/facebookicon.png";
import {
  checkEmailExistance,
  handleFacebookRegister,
} from "../../requests/simpleRequest";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { setUserToken } from "../../store/slices/UserSlice";
import { setLoginModal, setRegisterModal } from "../../store/slices/ModalSlice";
import axios from "axios";
import { client, peraWallet } from "../../wallets/algorand";
import { createAccountBlockchain } from "../../blockchain";
import algosdk from "algosdk";
import { useTranslation } from "react-i18next";
import { useWallet } from "@txnlab/use-wallet";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Wallets from "../../wallets";
import FacebookIcon from '@mui/icons-material/Facebook';
import { facebook, bordered } from "./selectRoleStyle"
import Box from '@mui/material/Box'

function FacebookRegisterButton() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [loginResp, setLoginResp] = useState<any>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userslice = useAppSelector((state) => state.userReducer);

  const handleBlockchainSubmit = async (fbInfo: any) => {
    // here we first chechk if email exist
    const checkEmailObj = {
      email: fbInfo.email,
    };
    const emailExistance = await checkEmailExistance(checkEmailObj);
    if (!emailExistance.success) {
      const toastPaylaod = {
        text: t("unableToVerifyIfEmailAlreadExist"),
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      const returnObj = {
        success: false,
        userAppId: "",
      };
      return returnObj;
    } else if (emailExistance.data.emailExists) {
      const toastPaylaod = {
        text: emailExistance.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      const returnObj = {
        success: false,
        userAppId: "",
      };
      return returnObj;
    }

    const info = {
      // name: fbInfo.name,
      user_type: userslice.registerUserType,
      // email: fbInfo.email,
      wallet_address: activeAccount?.address,
    };

    const res = await createAccountBlockchain(info);
    try {
      if (res.success) {
        const transactionArr = res.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);

        // Submit the transaction
        dispatch(setAppLoadingText(t("signingTransaction")));
        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();

        // Wait for confirmation
        dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );

        //Get the completed Transaction

        // get the transaction res
        dispatch(setAppLoadingText(t("fetchingTransactionData")));
        let transactionResponse = await client
          .pendingTransactionInformation(transationRes.txId)
          .do();

        dispatch(setAppLoadingText(""));

        let appId = transactionResponse["application-index"];
        if (transactionResponse["application-index"]) {
          const returnObj = {
            success: true,
            userAppId: appId,
          };
          return returnObj;
        } else {
          const returnObj = {
            success: false,
            userAppId: "",
          };

          const toastPaylaod = {
            text: t("unableToSendDataToBlockchain"),
            success: false,
            active: true,
          };
          dispatch(toggleAppLoading(false));
          dispatch(setToaster(toastPaylaod));
          return returnObj;
        }
      }
      dispatch(toggleAppLoading(false));
    } catch (err) {
      const returnObj = {
        success: false,
        userAppId: "",
      };

      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      return returnObj;
    }
  };

  const handleLogin = async () => {
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    if (!loginResp.accessToken) {
      dispatch(toggleAppLoading(false));
      return;
    }

    const fbUserData = await axios.get(
      `https://graph.facebook.com/v2.11/${loginResp.userID}/?fields=id,name,email&access_token=${loginResp.accessToken}`
    );

    const blockchainRes = await handleBlockchainSubmit(fbUserData.data);
    if (!blockchainRes?.success) {
      return;
    }

    const payload = {
      userID: loginResp.userID,
      accessToken: loginResp.accessToken,
      role: userslice.registerUserType,
      userAppId: blockchainRes.userAppId,
    };
    const backendRes: any = await handleFacebookRegister(payload);

    if (backendRes.success) {
      const payload = {
        loggedIn: true,
        userToken: backendRes.data.token,
        loginType: "facebook",
        registerUserType: userslice.registerUserType,
        userAppId: backendRes.data.userAppId ? backendRes.data.userAppId : "",
        kyc: backendRes.data.kyc,
        userId: backendRes.data.userId,
        role: backendRes.data.role,
      };

      dispatch(setUserToken(payload));
      const toastPaylaod = {
        text: t("registerWithFacebookSuccess"),
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    } else {
      const toastPaylaod = {
        text: backendRes.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    }
    dispatch(setRegisterModal(false));
    dispatch(setLoginModal(false));
  };

  return (
    <>
      <FacebookLogin
        appId="1362211264642889"
        autoLoad={false}
        fields="name, email, picture"
        scope="public_profile,email"
        callback={(res: any) => {
          setLoginResp(res);
          setShowWalletButtons(true);
        }}

        render={(renderProps) => {
          return (
            <Button
              variant="contained"
              onClick={renderProps.onClick}
              sx={{ fontSize: "0px", whiteSpace: "nowrap" }}
            >
              <Box sx={bordered}>
                <FacebookIcon sx={facebook} />
                <div style={{ color: "#4c4b4b", marginLeft: "5px" }}>{t("facebook")}</div>
              </Box>
            </Button>
          );
        }}
      />
      {showWalletButtons && (
        <Wallets
          open={showWalletButtons}
          handleClose={() => setShowWalletButtons(false)}
          handleSubmit={handleLogin}
        />
      )}
    </>
  );
}

export default FacebookRegisterButton;

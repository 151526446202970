import React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Menu,
  Modal,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { getAllGoals, saveToDraft } from "../../../../requests/authRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import NotificationToast from "../../../../loaders/NotificationToast";
import {
  campaignState,
  setCampaignPreview,
  setPreview,
} from "../../../../store/slices/CreateCampaignSlice";
import CampaignMainPage from "../campaignMainPage/CampaignMainPage";
import Preview from "../../preview/preview";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Wallets from "../../../../wallets";

function GoalPage({
  handleSteps,
  currentCampaignId,
  handleCampaignCreate,
  goalIds,
  handleGoalIds,
  matchFundStatus,
  handleMatchFundStatus,
  additionalInfoStatus,
  handleAdditionalInfoStatus,
  title,
  description,
  youTubeLink,
  category,
  fundingCategory,
  endDate,
  country,
  goalAmount,
  image,
  video,
  videoSelected,
  videoName,
  imageName,
  ESG_SDG_status,
  paymentType,
}: any) {
  const { t } = useTranslation();
  const kyc = useAppSelector((state) => state.userReducer.kyc);
  const [allGoals, setAllGoals] = React.useState<
    { category: string; goalId: string; goalName: string; status: string }[]
  >([]);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  /**
   *
   * menu
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * menu
   */

  /**
   *
   * menu2
   */

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  /**
   *
   * menu
   */

  /**
   * handle save to draft function
   */

  const handleSaveToDraft = async (
    nextStatus: boolean,
    submitStatus: boolean
  ) => {
    if (matchFundStatus.title === "") {
      const toastPaylaod = {
        text: t("pleaseSelectDoYouWantToRecieveMatchFundingOrNot"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }

    if (goalIds.length === 0 || goalIds.length > 6) {
      const toastPaylaod = {
        text: t("pleaseSelectAtleastOneGoalOrMaxSixGoals"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }

    if (additionalInfoStatus.title === "") {
      const toastPaylaod = {
        text: t("pleaseSelectDoYouWantToShareMoreInfoOrNot"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }

    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("savingToDraft")));

    let payload: any = {};
    if (currentCampaignId === "") {
      dispatch(toggleAppLoading(false));
      return;
    } else {
      payload = {
        campaignId: currentCampaignId,
        paymentType: paymentType,
        matchFunding: matchFundStatus.status,
        goalIds: goalIds,
        additionalInfo: additionalInfoStatus.status,
      };
    }

    const res = await saveToDraft(payload);

    if (res.success) {
      const toastPaylaod = {
        text: t("savedToDraftSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setAppLoadingText(t("")));
      dispatch(setToaster(toastPaylaod));
      nextStatus && handleSteps("Additional");
      if (submitStatus) {
        dispatch(toggleAppLoading(true));
        dispatch(setAppLoadingText(t("creatingCampaign")));
        handleCampaignCreate(
          res.data?.campaign?.milestones[0]?.milestoneId,
          res.data?.campaign?.milestones[1]?.milestoneId,
          res.data?.campaign?._id
        );
      }
      // cleanFormState();
      // navigationStatus && navigate("/user/my-campaign");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
    dispatch(toggleAppLoading(false));
    localStorage.removeItem("milestones");
  };

  const handlePreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let goals: any = [];
    if (goalIds.length) {
      goalIds.forEach((id: any) => {
        allGoals.forEach((goal) => {
          if (goal.goalId === id) goals.push(goal);
        });
      });
    }
    const payload: any = {
      title: title,
      description: description,
      paymentType: paymentType,
      campaignCategory: category,
      fundingCategory: fundingCategory,
      endDate: endDate,
      country: country,
      goalAmount: goalAmount,
      socialVideoUrl: "",
      youTubeLink: youTubeLink,
      videoUrl: videoName,
      imageUrl: imageName,
      previewEnabled: true,
      video: video,
      image: image,
      imageNames: imageName,
      imageSelected: true,
      videoSelected: videoSelected,
      goalIds: goals,
      isEsgEnabled: ESG_SDG_status.status,
    };
    dispatch(setCampaignPreview(payload));
    dispatch(setPreview(true));
  };

  const previewCampaign = useAppSelector(
    (state) => state.campaignPreviewReducer.previewEnabled
  );
  /**
   * handle save to draft function end
   */

  const fetchGoals = async () => {
    const res = await getAllGoals();
    setAllGoals(res?.data?.goals);
  };

  React.useEffect(() => {
    fetchGoals();
  }, []);

  return (
    <div>
      <Typography style={{ textAlign: "left", fontSize: "2rem" }} variant="h3">
        {t("chooseYourCampaignsEsgOrSdgGoals")}
      </Typography>
      <Box sx={{ p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Grid>
          <Box sx={{ mt: 2 }} style={{ textAlign: "left" }}>
            <Typography
              style={{ textAlign: "left", fontSize: "17px", fontWeight: 700 }}
            >
              {t("pleaseChooseAtleastOneGoalAndUptoSixGoals")}
            </Typography>

            <Box sx={{ marginTop: "20px" }}>
              <InputLabel
                sx={{ fontSize: "15px" }}
                id="demo-simple-select-helper-label"
              >
                {t("doYouWantToReceiveMatchFunding")}
              </InputLabel>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  color: "#000",
                  border: "1px solid gray",
                  marginTop: "10px",
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {matchFundStatus.title !== ""
                  ? matchFundStatus.title
                  : "Please Select Option"}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Box sx={{ width: "200px" }}>
                  <MenuItem
                    onClick={() => {
                      handleMatchFundStatus({ title: "Yes", status: true });
                      handleClose();
                    }}
                  >
                    {t("yes")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMatchFundStatus({ title: "No", status: false });
                      handleClose();
                    }}
                  >
                    {t("no")}
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
            {/* {invalidEsgOption && (
          <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
            {errorInvalidEsgTitle}
          </FormHelperText>
        )} */}
            <FormHelperText>
              {t("thisWillIncreaseYourChancesOfReceivingAdditionalFunding")}
            </FormHelperText>
          </Box>
        </Grid>
        {allGoals?.filter((el) => el?.category === "environmental").length !==
          0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("environmental")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "environmental")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
        {allGoals?.filter((el) => el?.category === "social").length !== 0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("social")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "social")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
        {allGoals?.filter((el) => el?.category === "governance").length !==
          0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("governance")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "governance")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
      </Box>

      <Box sx={{ p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Typography
          style={{ textAlign: "left", fontSize: "16px", fontWeight: 700 }}
        >
          {t("doYouWantToShareMoreInformationAboutYourCampaignWithUs")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Please Choose Option
          </InputLabel>
          <Button
            id="basic-button2"
            aria-controls={open2 ? "basic-menu2" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            sx={{ color: "#000", border: "1px solid gray", marginTop: "10px" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {additionalInfoStatus.title !== ""
              ? additionalInfoStatus.title
              : t("pleaseSelectOption")}
          </Button>
          <Menu
            id="basic-menu2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button2",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  handleAdditionalInfoStatus({ title: "Yes", status: true });
                  handleClose2();
                }}
              >
                Yes
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAdditionalInfoStatus({ title: "No", status: false });
                  handleClose2();
                }}
              >
                No
              </MenuItem>
            </Box>
          </Menu>
        </Box>
        {/* {invalidEsgOption && (
          <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
            {errorInvalidEsgTitle}
          </FormHelperText>
        )} */}
        <FormHelperText>
          {t("thisWillIncreaseYourChancesOfReceivingAdditionalFunding")}
        </FormHelperText>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => handleSteps("Create")}
        >
          {t("back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={handlePreview}
        >
          {t("preview")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={() => handleSaveToDraft(false, false)}
        >
          {t("saveToDraft")}
        </Button>
        {kyc === "approved" && additionalInfoStatus.title === "No" && (
          <>
            <Button
              variant="contained"
              color="primary"
              className="round-button"
              onClick={() => setShowWalletButtons(true)}
            >
              {t("submit")}
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={() => handleSaveToDraft(false, true)}
              />
            )}
          </>
        )}

        {additionalInfoStatus.title === "Yes" && (
          <Button
            variant="contained"
            color="primary"
            className="round-button"
            onClick={() => {
              handleSaveToDraft(true, false);
            }}
          >
            {t("next")}
          </Button>
        )}
      </Box>
      <NotificationToast />
      <Modal open={previewCampaign}>
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "80%",
            height: "98vh",
            marginX: "auto",
          }}
        >
          <Preview />
        </Box>
      </Modal>
    </div>
  );
}
export default GoalPage;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  SelectChangeEvent,
  FormHelperText,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
  Menu,
} from "@mui/material";
import { CampaigngForm } from "./style";
import moment from "moment";
import countryList from "../createCampaign/countrylist";
import { useAppDispatch, useAppSelector } from "../../../store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  getEditCampaignDetails,
  updateDraft,
  updateMilestone,
  updatePendingCampaignData,
  uploadCampaignImage,
} from "../../../requests/authRequest";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import {
  getCampaignCategories,
  getFundingCategories,
} from "../../../requests/simpleRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../wallets/algorand";
import { updateCampaignBlockchain } from "../../../blockchain";
import MilestoneSet from "../../home/campaigns/MilestoneSet";
import algosdk from "algosdk";
import EditGoalPage from "./EditCampaignComp/EditGoalPage";
import EditAdditionalInfo from "./EditCampaignComp/EditAdditionalInfo";
import { setRewardType } from "../../../store/slices/CreateCampaignSlice";
import Wallets from "../../../wallets";
import {
  STAGING_FUNDING_CATEGORY,
  TEST_FUNDING_CATEGORY,
  PRODUCTION_FUNDING_CATEGORY,
} from "../createCampaign/constant";
import algo from "../../../assets/images/algo.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

interface milestone {
  title: string;
  defination: string;
  deadline: Date | string;
  delivereble: any;
}

function EditCampaign() {
  const { activeAccount, providers, signTransactions } = useWallet();

  const { t } = useTranslation();
  const { campaignId } = useParams();
  // using "any" here because this is a garbage dump object and i need it to initialize
  // values in the form fields from this object
  const [campaignInitialData, setCampaignInitialData] = useState<any>({});
  const selectedNft = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardNftId
  );

  const rewardType = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardType
  );

  const navigate = useNavigate();
  const campaignState = useAppSelector((state) => state.campaignPreviewReducer);

  const [title, setTitle] = useState<string>(campaignState.title);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    campaignState.description
  );
  const [category, setCategory] = useState<string>(
    campaignState.campaignCategory
  );
  const [fundingCategory, setFundingCategory] = useState<string>(
    campaignState.fundingCategory
  );

  const [paymentTypeBlockchain, setPaymentTypeBlockchain] =
    useState<string>("ALGO");
  const handlePaymentType = (vl: string) => setPaymentTypeBlockchain(vl);

  const [endDate, setEndDate] = React.useState<Date | string>(
    new Date(campaignState.endDate)
  );
  const [location, setLocation] = useState<any>(campaignState.location);
  const [YearlyOutput, setYearlyOutput] = useState<any>(
    campaignState.YearlyOutput
  );
  const [Results, setResults] = useState<string>(campaignState.Results);

  const [CostPerUnit, setCostPerUnit] = useState<any>(
    campaignState.CostPerUnit
  );
  const [country, setCountry] = useState(campaignState.country);
  const [goalAmount, setGoalAmount] = useState<string>(
    campaignState.goalAmount
  );
  const [youTubeLink, setyouTubeLink] = useState<string>(
    campaignState.youTubeLink
  );
  const [monitoringStatus, setMonitoringStatus] = useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  //this is for categories
  const [campCategoryList, setCampCategoryList] = useState([]);
  const [fundCategoryList, setFundCategoryList] = useState([]);
  const [paymentList, setPaymentList] = useState(["ALGO", "USDC"]);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState("0");
  // const [paymentTypeBlockchain, setPaymentTypeBlockchain] = useState<string>("ALGO");

  const [selectedImageId, setSelectedImageId] = useState<number>(0);

  // *******************************
  // here i have the logic for the milestone start`
  // *******************************

  const [milestoneOne, setMilestoneOne] = useState<milestone>({
    title: "",
    defination: "",
    deadline: "",
    delivereble: undefined,
  });
  const [milestoneTwo, setMilestoneTwo] = useState<milestone>({
    title: "",
    defination: "",
    deadline: "",
    delivereble: undefined,
  });
  // const [milestoneThree, setMilestoneThree] = useState<milestone>({
  // 	title: "",
  // 	defination: "",
  // 	deadline: "",
  // 	delivereble: undefined,
  // });
  const [invalidlatitude, setInvalidlatitude] = useState<boolean>(false);

  const [invalidlongitude, setInvalidlongitude] = useState<boolean>(false);
  const [invalidDateOne, setInvalidDateOne] = useState<boolean>(false);
  const [invalidDateTwo, setInvalidDateTwo] = useState<boolean>(false);
  const [invalidTitleOne, setInvalidTitleOne] = useState<boolean>(false);
  const [invalidTitleTwo, setInvalidTitleTwo] = useState<boolean>(false);
  const [invalidMonitoringOption, setInvalidMonitoringOption] =
    useState<boolean>(false);
  const [errorInvalidMonitoringTitle, setErrorInvalidMonitoringTitle] =
    useState<string>("");
  const [invalidYearlyOutput, setinvalidYearlyOutput] =
    useState<boolean>(false);
  const [errorYearlyOutput, setErrorYearlyOutput] = useState<string>("");
  const [invalidCostPerUnit, setinvalidCostPerUnit] = useState<boolean>(false);
  const [errorCostPerUnit, setErrorCostPerUnit] = useState<string>("");
  const [invalidResults, setinvalidResults] = useState<boolean>(false);
  const [errorResults, setErrorResults] = useState<string>("");
  const [invalidLocation, setInvalidLocation] = useState<boolean>(false);
  const [errorLocation, setErrorLocation] = useState<string>("");
  const [invalidDefinitionOne, setInvalidDefinitionOne] =
    useState<boolean>(false);
  const [invalidDefinitionTwo, setInvalidDefinitionTwo] =
    useState<boolean>(false);
  // const [invalidDateThree, setInvalidDateThree] = useState<boolean>(false);
  // const [coordinates, setCoordinates] = useState<{
  //   lat: null | number;
  //   lng: null | number;
  // }>({
  //   lat: null,
  //   lng: null,
  // });
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: campaignState.lat || 0,
    lng: campaignState.lng || 0,
  });

  // const [locOption, setLocOption] = React.useState("location");
  // const [locOption, setLocOption] = React.useState(
  //   campaignInitialData.location === null ? "coordinates" : "location"
  // );

  const [locOption, setLocOption] = React.useState(
    campaignInitialData.location === null ? "coordinates" : "location"
  );
  // const [locOption, setLocOption] = React.useState(
  //   campaignInitialData.location === null ? false : true
  // );

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setLocation(value);
    setCoordinates(ll);
  };

  const [goalIds, setGoalIds] = React.useState<number[]>([]);
  const [additionalData, setAdditionalData] = React.useState<any>({
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    companyName: "",
    website: "",
    projectPitch: "",
    problems: "",
    previousSuccessfulProject: "",
    Hq: "",
    country: "",
    impact: "",
    partnership: "",
    yearOfEstablishment: "",
    stageDesc: "",
    infoImage: "",
    additionalInfo: "",
  });
  const [preLoading, setPreLoading] = React.useState<boolean>(false);

  const validateMonitoringData = () => {
    let validity = true;
    if (monitoringStatus.title === "") {
      validity = false;
      setErrorInvalidMonitoringTitle("Please select any option");
      setInvalidMonitoringOption(true);
    } else {
      setErrorInvalidMonitoringTitle("");
      setInvalidMonitoringOption(false);
    }

    if (monitoringStatus.title === "Yes") {
      // setInvalidMonitoringOption(false);
      // setErrorInvalidMonitoringTitle("");

      if (!Results?.trim().length) {
        validity = false;
        setinvalidResults(true);
        setErrorResults(t("pleaseEnterTheResults") || "");
      } else {
        setinvalidResults(false);
        setErrorResults("");
      }
      //yearly output
      if (isNaN(parseInt(YearlyOutput)) || parseInt(YearlyOutput) <= 0) {
        validity = false;
        setinvalidYearlyOutput(true);
        setErrorYearlyOutput(t("pleaseEnterYearlyOutput") || "");
      } else {
        setinvalidYearlyOutput(false);
        setErrorYearlyOutput("");
      }
      //CostPerUnit
      if (isNaN(parseInt(CostPerUnit)) || parseInt(CostPerUnit) <= 0) {
        validity = false;
        setinvalidCostPerUnit(true);
        setErrorCostPerUnit(t("pleaseEnterCostPerUnit") || "");
      } else {
        setinvalidCostPerUnit(false);
        setErrorCostPerUnit("");
      }

      // location

      if (locOption === "location" && !location?.trim().length) {
        validity = false;
        setInvalidLocation(true);
        setErrorLocation(t("pleaseEnterLocation") || "");
      } else {
        setInvalidLocation(false);
        setErrorLocation("");
      }

      if (locOption === "coordinates" && !coordinates.lat) {
        validity = false;
        setInvalidlatitude(true);
      } else {
        setInvalidlatitude(false);
      }

      if (locOption === "coordinates" && !coordinates.lng) {
        validity = false;
        setInvalidlongitude(true);
      } else {
        setInvalidlongitude(false);
      }
    } else {
      // setInvalidMonitoringOption(true);
      // setErrorInvalidMonitoringTitle("Please Select any Option");
    }
    return validity;
  };

  const validateMilestoneData = () => {
    let validity = true;

    // for milestone one
    if (!moment(milestoneOne.deadline).isAfter(endDate)) {
      validity = false;
      setInvalidDateOne(true);
    } else {
      setInvalidDateOne(false);
    }

    if (milestoneOne.title.trim().length <= 0) {
      validity = false;
      setInvalidTitleOne(true);
    } else if (milestoneOne.title.length > 100) {
      validity = false;
      setInvalidTitleOne(true);
    } else {
      setInvalidTitleOne(false);
    }

    if (milestoneOne.defination.trim().length <= 0) {
      validity = false;
      setInvalidDefinitionOne(true);
    } else if (milestoneOne.defination.trim().length > 3000) {
      validity = false;
      setInvalidDefinitionOne(true);
    } else {
      setInvalidDefinitionOne(false);
    }

    // for milestone Two
    if (!moment(milestoneTwo.deadline).isAfter(milestoneOne.deadline)) {
      validity = false;
      setInvalidDateTwo(true);
    } else {
      setInvalidDateTwo(false);
    }

    if (milestoneTwo.title.trim().length <= 0) {
      validity = false;
      setInvalidTitleTwo(true);
    } else if (milestoneTwo.title.length > 100) {
      validity = false;
      setInvalidTitleTwo(true);
    } else {
      setInvalidTitleTwo(false);
    }

    if (milestoneTwo.defination.trim().length <= 0) {
      validity = false;
      setInvalidDefinitionTwo(true);
    } else if (milestoneTwo.defination.trim().length > 3000) {
      validity = false;
      setInvalidDefinitionTwo(true);
    } else {
      setInvalidDefinitionTwo(false);
    }

    // // for milestone three
    // if (!moment(milestoneThree.deadline).isAfter(milestoneTwo.deadline)) {
    // 	validity = false;
    // 	setInvalidDateThree(true);
    // } else {
    // 	setInvalidDateThree(false);
    // }

    // if (milestoneThree.title.trim().length <= 0) {
    // 	validity = false;
    // } else if (milestoneThree.title.length > 100) {
    // 	validity = false;
    // }

    // if (milestoneThree.defination.trim().length <= 0) {
    // 	validity = false;
    // } else if (milestoneThree.defination.trim().length > 3000) {
    // 	validity = false;
    // }

    return validity;
  };

  // *******************************
  // here i have the logic for the milestone end
  // *******************************

  const dispatch = useAppDispatch();

  const handleCategoryChange = (e: SelectChangeEvent) => {
    setCategory(e.target.value);
  };

  const handleFundingCategoryChange = (e: SelectChangeEvent) => {
    setFundingCategory(e.target.value);
  };

  const handleCountryChange = (e: SelectChangeEvent) => {
    setCountry(e.target.value);
  };

  const handleChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      setEndDate(new Date(newValue));
    }
  };

  // these are for form validations
  const [invalidTitle, setInvalidTitle] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");

  const [invalidDescription, setInvalidDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");

  const [invalidEndDate, setInvalidEndDate] = useState<boolean>(false);
  const [errorEndDate, setErrorEndDate] = useState<string>("");

  const [invalidGoalAmount, setInvalidGoalAmount] = useState<boolean>(false);
  const [errorGoalAmount, setErrorGoalAmount] = useState<string>("");

  const [invalidCategory, setInvalidCategory] = useState<boolean>(false);
  const [invalidFundingCategory, setInvalidFundingCategory] =
    useState<boolean>(false);
  const [invalidPaymentCategory, setInvalidPaymentCategory] =
    useState<boolean>(false);
  const [invalidCountry, setInvalidCountry] = useState<boolean>(false);

  const [isMilestoneOneUpdated, setIsMilestoneOneUpdated] =
    useState<boolean>(false);
  const [isMilestoneTwoUpdated, setIsMilestoneTwoUpdated] =
    useState<boolean>(false);
  // const [isMilestoneThreeUpdated, setIsMilestoneThreeUpdated] =
  // 	useState<boolean>(false);

  const [formSteps, setFormSteps] = useState<string>("MAIN");

  const validateForm = () => {
    let validity = true;
    // for title
    if (title.trim().length <= 0) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle("Please enter the title");
    } else if (title.length > 100) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle("Title must be 100 char or smaller");
    } else {
      setInvalidTitle(false);
      setErrorTitle("");
    }
    // if (monitoringStatus.title === "Yes") {
    //   if (!Results?.trim().length) {
    //     validity = false;
    //     setinvalidResults(true);
    //     seterrorResults(t("pleaseEnterTheResults") || "");
    //   } else {
    //     setinvalidResults(false);
    //     seterrorResults("");
    //   }
    //   //yearly output
    //   if (isNaN(parseInt(YearlyOutput)) || parseInt(YearlyOutput) <= 0) {
    //     validity = false;
    //     setinvalidYearlyOutput(true);
    //     setErrorYearlyOutput(t("pleaseEnterYearlyOutput") || "");
    //   } else {
    //     setinvalidYearlyOutput(false);
    //     setErrorYearlyOutput("");
    //   }
    //   //CostPerUnit
    //   if (isNaN(parseInt(CostPerUnit)) || parseInt(CostPerUnit) <= 0) {
    //     validity = false;
    //     setinvalidCostPerUnit(true);
    //     setErrorCostPerUnit(t("pleaseEnterCostPerUnit") || "");
    //   } else {
    //     setinvalidCostPerUnit(false);
    //     setErrorCostPerUnit("");
    //   }

    //   // location

    //   if (!location?.trim().length) {
    //     validity = false;
    //     setInvalidLocation(true);
    //     setErrorLocation(t("pleaseEnterLocation") || " ");
    //   } else {
    //     setInvalidLocation(false);
    //     setErrorLocation("");
    //   }
    // }

    // for description

    // const MAX_WORD_LIMIT = 500;
    // const wordCount = description.trim().split(/\s+/).length;
    if (description.trim().length <= 0) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription(t("pleaseEnterTheDescription") || "");
    } else if (description.trim().length > 30000) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription("Length Exceeds Limit" || "");
    } else {
      setInvalidDescription(false);
      setErrorDescription("");
    }

    // for end Date
    if (
      moment(new Date(endDate)).startOf("day").unix() <=
      moment().startOf("day").unix()
    ) {
      validity = false;
      setInvalidEndDate(true);
      setErrorEndDate("Please select a valid future date");
    } else {
      setInvalidEndDate(false);
      setErrorEndDate("");
    }

    // for goal amount
    if (
      isNaN(parseInt(goalAmount)) ||
      parseInt(goalAmount) < 1 ||
      parseInt(goalAmount) > 1000000
    ) {
      validity = false;
      setInvalidGoalAmount(true);
      setErrorGoalAmount("Please enter a amount between 1 and 1000000");
    } else {
      setInvalidGoalAmount(false);
      setErrorGoalAmount("");
    }

    // for dropdowns
    if (category.length === 0) {
      validity = false;
      setInvalidCategory(true);
    } else {
      setInvalidCategory(false);
    }
    // if (paymentTypeBlockchain.length === 0) {
    //   validity = false;
    //   setInvalidPaymentCategory(true);
    // } else {
    //   setInvalidPaymentCategory(false);
    // }
    if (country.length === 0) {
      validity = false;
      setInvalidCountry(true);
    } else {
      setInvalidCountry(false);
    }

    if (fundingCategory.length === 0) {
      validity = false;
      setInvalidFundingCategory(true);
    } else {
      setInvalidFundingCategory(false);
    }

    return validity;
  };

  const changeValidateForm = () => {
    if (
      campaignInitialData?.title !== title ||
      campaignInitialData?.expectedResults !== Results ||
      campaignInitialData?.expectedYearlyoutput !== YearlyOutput ||
      campaignInitialData?.expectedCostPerUnit !== CostPerUnit ||
      campaignInitialData?.lng !== coordinates.lng ||
      campaignInitialData?.lat !== coordinates.lat ||
      // campaignInitialData?.paymentType !== paymentTypeBlockchain ||
      campaignInitialData?.location !== location ||
      campaignInitialData?.category?.categoryId !== category ||
      campaignInitialData?.endDate !== endDate ||
      campaignInitialData?.goalAmount !== goalAmount ||
      campaignInitialData?.country !== country ||
      campaignInitialData.milestones[0].title !== milestoneOne.title ||
      campaignInitialData.milestones[0].definition !==
        milestoneOne.defination ||
      campaignInitialData.milestones[0].deadline !== milestoneOne.deadline ||
      campaignInitialData.milestones[0].deliverable !==
        milestoneOne.delivereble ||
      campaignInitialData.milestones[1].title !== milestoneTwo.title ||
      campaignInitialData.milestones[1].definition !==
        milestoneTwo.defination ||
      campaignInitialData.milestones[1].deadline !== milestoneTwo.deadline ||
      campaignInitialData.milestones[1].deliverable !== milestoneTwo.delivereble
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const handleImageUpload = async () => {
  //   if (additionalData?.infoImage) {
  //     const res: any = await uploadCampaignImage([additionalData?.infoImage]);
  //     if (res.success) {
  //       return res?.data;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const handleUpdateCampaignBlockchain = async () => {
    const info = {
      creator_wallet_address: activeAccount?.address,
      campaign_app_id: campaignInitialData.campaignAppId,
      title: title,
      expectedResults: Results,
      expectedYearlyoutput: YearlyOutput,
      expectedCostPerUnit: CostPerUnit,
      location: location,
      lat: coordinates.lat,
      lng: coordinates.lng,
      isReforestationProject: monitoringStatus.status,
      category: category,
      end_time: moment(new Date(endDate)).startOf("day").unix(),
      fund_category: fundingCategory,
      paymentType: paymentTypeBlockchain,
      fund_limit: goalAmount,
      country: country,
      milestone: {
        milestone_app_id: {
          "1": campaignInitialData.milestones[0].milestoneAppId,
          "2": campaignInitialData.milestones[1].milestoneAppId,
          // "3": campaignInitialData.milestones[2].milestoneAppId,
        },
        milestone_title: {
          "1": milestoneOne.title,
          "2": milestoneTwo.title,
          // "3": milestoneThree.title,
        },
        milestone_number: { "1": 1, "2": 2 },
        end_time_milestone: {
          "1": moment(new Date(milestoneOne.deadline)).unix(),
          "2": moment(new Date(milestoneTwo.deadline)).unix(),
          // "3": moment(milestoneThree.deadline).unix(),
        },
      },
    };

    const unsignedTxn = await updateCampaignBlockchain(info);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));

      const signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      signed.push(signedTxn[2]);
      // signed.push(signedTxns[3].blob);
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => {
            return Buffer.from(sign, "base64");
          })
        )
        .do();

      // let tx = await client.sendRawTransaction(signed).do();
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      // dispatch(setAppLoadingText(""));

      return {
        success: true,
      };
    } catch (e) {
      const toastPaylaod = {
        text: t("unableToSignTransaction"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleImageUpload = async () => {
    if (additionalData?.infoImage) {
      const res: any = await uploadCampaignImage([additionalData?.infoImage]);
      if (res.success) {
        return res?.data;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const handleCreateCampaign = async () => {
    const valid = validateForm();
    const validMilestone = validateMilestoneData();
    const changeValidation = changeValidateForm();
    const validMonitoring = validateMonitoringData();

    if (!valid || !validMilestone || !validMonitoring) {
      return;
    }

    if (
      campaignInitialData?.additionalInfo &&
      campaignInitialData?.additionalInfoId?.consent
    ) {
      if (
        additionalData.contactName === "" ||
        additionalData.contactEmail === "" ||
        additionalData.contactPhone === "" ||
        additionalData.companyName === "" ||
        additionalData.website === "" ||
        additionalData.projectPitch === "" ||
        additionalData.problems === "" ||
        additionalData.impact === "" ||
        additionalData.stageDesc === "" ||
        additionalData.country === ""
      ) {
        const toastPaylaod = {
          text: t("pleaseFillAllTheRequiredFields"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          additionalData.contactEmail
        )
      ) {
        const toastPaylaod = {
          text: t("pleaseFillValidEmailAddress"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      // if (additionalData.contactPhone.length > 10) {
      //   const toastPaylaod = {
      //     text: t("contactNumberShouldNotBeMoreThanTenDigits"),
      //     success: false,
      //     active: true,
      //   };
      //   dispatch(setToaster(toastPaylaod));
      //   dispatch(setAppLoadingText(""));
      //   dispatch(toggleAppLoading(false));
      //   return;
      // }

      if (isNaN(Number(additionalData.contactPhone))) {
        const toastPaylaod = {
          text: t("contactNumberShouldOnlyContainsDigits"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
    }

    // setPreLoading(true);
    dispatch(toggleAppLoading(true));
    if (changeValidation) {
      const blockchainRes = await handleUpdateCampaignBlockchain();
      if (!blockchainRes.success) {
        return;
      }
    }

    const image = await handleImageUpload();

    const payload = {
      campaignId: campaignId,
      campaign: {
        title: title,
        description: description,
        expectedResults: Results,
        expectedYearlyoutput: YearlyOutput,
        expectedCostPerUnit: CostPerUnit,
        location: location,
        lat: coordinates.lat,
        lng: coordinates.lng,
        isReforestationProject: monitoringStatus?.status,
        youTubeLink: youTubeLink,
        category: category,
        paymentType: campaignInitialData?.paymentType,
        fundingCategory:
          campaignInitialData?.fundingCategory?.fundingCategoryId,
        endDate: moment(endDate).format("YYYY-MM-DD"),
        goalAmount: goalAmount,
        country: country,
        images: campaignInitialData?.images,
        video: campaignInitialData?.video,
        videoUrl: "",
        milestones: [
          {
            milestoneId: campaignInitialData?.milestones[0]?.milestoneId,
            title: milestoneOne.title,
            definition: milestoneOne.defination,
            deadline: milestoneOne.deadline,
            deliverable: "non",
            milestoneAppId: campaignInitialData?.milestones[0]?.milestoneAppId,
          },
          {
            milestoneId: campaignInitialData?.milestones[1]?.milestoneId,
            title: milestoneTwo.title,
            definition: milestoneTwo.defination,
            deadline: milestoneTwo.deadline,
            deliverable: "non",
            milestoneAppId: campaignInitialData?.milestones[1]?.milestoneAppId,
          },
        ],
        matchFunding: campaignInitialData?.matchFunding,
        goalIds: goalIds,
        rewardBased: campaignInitialData?.rewardBased,
        reward: campaignInitialData?.reward,
        rewardType: campaignInitialData?.rewardType,
      },

      isEsgEnabled: campaignInitialData?.isEsgEnabled,
      additionalInfo: campaignInitialData?.additionalInfo,
      additionalInfoData: {
        additionalInfoId:
          campaignInitialData?.additionalInfoId?.additionalInfoId,
        consent: campaignInitialData?.additionalInfoId?.consent,
        contactName: additionalData.contactName,
        contactEmail: additionalData.contactEmail,
        contactPhone: additionalData.contactPhone,
        companyOrProjectName: additionalData.companyName,
        website: additionalData.website,
        projectPitch: additionalData.projectPitch,
        problems: additionalData.problems,
        previousSuccessfulProjects: additionalData.previousSuccessfulProject,
        hq: additionalData.Hq,
        impact: additionalData.impact,
        partnerships: additionalData.partnership,
        yearOfEstablishment: additionalData.yearOfEstablishment,
        image: image ? image[0] : "",
        companyBio: additionalData.stageDesc,
        reachoutRegardingSubmission:
          campaignInitialData?.additionalInfoId?.reachoutRegardingSubmission,
        geographies: additionalData.country,
        additionalInformation: additionalData.additionalInfo,
      },
    };

    const res = await updateDraft(payload);

    if (res.success) {
      setPreLoading(false);
      const toastPaylaod = {
        text: t("campaignUpdatedSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      setPreLoading(false);
    }
    setPreLoading(false);
  };

  const fetchCategories = async () => {
    const res1 = await getFundingCategories();
    if (res1.success) {
      setFundCategoryList(res1.data);
    } else {
      const toastPaylaod = {
        text: res1.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
    const res2 = await getCampaignCategories();
    if (res2.success) {
      setCampCategoryList(res2.data);
    } else {
      const toastPaylaod = {
        text: res2.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowWalletButtons(true);
  };

  const fetchInitialValues = async () => {
    dispatch(toggleAppLoading(true));
    const res = await getEditCampaignDetails(campaignId);

    dispatch(toggleAppLoading(false));
    if (res.success) {
      setCampaignInitialData(res.data.campaign);
      // const paymentType = res.data.campaign.paymentType;
      // setPaymentType(paymentType);

      // const paymentTypeIndex = paymentList.indexOf(paymentType);
      // setSelectedPaymentIndex(selectedIndex.toString());
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchCategories();

    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    fetchInitialValues();
  }, []);
  useEffect(() => {
    if (campaignInitialData?.paymentType) {
      setPaymentTypeBlockchain(campaignInitialData.paymentType);
    }
  }, [campaignInitialData?.paymentType]);

  // useEffect(() => {
  //   if (campaignInitialData.paymentType === "ALGO") {
  //     setPaymentType("ALGO");
  //   } else {
  //     setPaymentType("USDC");
  //   }
  // }, [campaignInitialData.paymentType]);

  useEffect(() => {
    if (campaignInitialData.title) {
      setTitle(campaignInitialData.title);
      setDescription(campaignInitialData.description);
      setCategory(campaignInitialData.category.categoryId);
      setEndDate(campaignInitialData.endDate);
      setFundingCategory(campaignInitialData.fundingCategory.fundingCategoryId);
      setPaymentTypeBlockchain(campaignInitialData.paymentType);
      setGoalAmount(campaignInitialData.goalAmount);
      setyouTubeLink(campaignInitialData.youTubeLink);
      setYearlyOutput(campaignInitialData.expectedYearlyoutput);
      setCostPerUnit(campaignInitialData.expectedCostPerUnit);
      setResults(campaignInitialData.expectedResults);
      setLocation(campaignInitialData.location);
      setCoordinates(campaignInitialData.lat);
      setCoordinates(campaignInitialData.lng);
      setMonitoringStatus(
        campaignInitialData.isReforestationProject !== undefined
          ? {
              title: campaignInitialData.isReforestationProject ? "Yes" : "No",
              status: campaignInitialData.isReforestationProject,
            }
          : { title: "", status: false }
      );
      setCountry(campaignInitialData.country.toLocaleLowerCase());
      const nftData = {
        rewardNftId: campaignInitialData?.reward,
        rewardType: campaignInitialData?.rewardType,
      };
      dispatch(setRewardType(nftData));
      campaignInitialData?.goalIds?.map((item: any) => {
        goalIds.some((el) => el === item?.goalId)
          ? setGoalIds(goalIds.filter((el) => el !== item?.goalId))
          : setGoalIds((prev) => [...prev, item?.goalId]);
      });
      setAdditionalData((prev: any) => ({
        ...prev,
        contactName: campaignInitialData?.additionalInfoId?.contactName,
        contactEmail: campaignInitialData?.additionalInfoId?.contactEmail,
        contactPhone: campaignInitialData?.additionalInfoId?.contactPhone,
        companyName:
          campaignInitialData?.additionalInfoId?.companyOrProjectName,
        website: campaignInitialData?.additionalInfoId?.website,
        projectPitch: campaignInitialData?.additionalInfoId?.projectPitch,
        problems: campaignInitialData?.additionalInfoId?.problems,
        previousSuccessfulProject:
          campaignInitialData?.additionalInfoId?.previousSuccessfulProjects,
        Hq: campaignInitialData?.additionalInfoId?.hq,
        country: campaignInitialData?.additionalInfoId?.geographies,
        impact: campaignInitialData?.additionalInfoId?.impact,
        partnership: campaignInitialData?.additionalInfoId?.partnerships,
        yearOfEstablishment:
          campaignInitialData?.additionalInfoId?.yearOfEstablishment,
        stageDesc: campaignInitialData?.additionalInfoId?.companyBio,
        infoImage: campaignInitialData?.additionalInfoId?.image,
        additionalInfo:
          campaignInitialData?.additionalInfoId?.additionalInformation,
      }));
    }
    if (
      campaignInitialData.milestones &&
      campaignInitialData.milestones.length > 0
    ) {
      let tempMilestoneOne = {
        title: campaignInitialData.milestones[0].title,
        defination: campaignInitialData.milestones[0].definition,
        deadline: campaignInitialData.milestones[0].deadline,
        delivereble: campaignInitialData.milestones[0].deliverable,
      };
      setMilestoneOne(tempMilestoneOne);
      let tempMilestoneTwo = {
        title: campaignInitialData.milestones[1].title,
        defination: campaignInitialData.milestones[1].definition,
        deadline: campaignInitialData.milestones[1].deadline,
        delivereble: campaignInitialData.milestones[1].deliverable,
      };
      setMilestoneTwo(tempMilestoneTwo);
    }
  }, [campaignInitialData]);

  useEffect(() => {
    if (!campaignInitialData || !campaignInitialData.milestones) {
      return;
    }
    const previousCampaignOneData = campaignInitialData.milestones[0];

    if (
      previousCampaignOneData.title !== milestoneOne.title ||
      previousCampaignOneData.definition !== milestoneOne.defination ||
      previousCampaignOneData.deliverable !== milestoneOne.delivereble ||
      !moment(previousCampaignOneData.deadline).isSame(milestoneOne.deadline)
    ) {
      setIsMilestoneOneUpdated(true);
    } else {
      setIsMilestoneOneUpdated(false);
    }
  }, [milestoneOne]);

  useEffect(() => {
    if (!campaignInitialData || !campaignInitialData?.milestones) {
      return;
    }
    const previousCampaignTwoData = campaignInitialData?.milestones[1];

    if (
      previousCampaignTwoData.title !== milestoneTwo.title ||
      previousCampaignTwoData.definition !== milestoneTwo.defination ||
      previousCampaignTwoData.deliverable !== milestoneTwo.delivereble ||
      !moment(previousCampaignTwoData.deadline).isSame(milestoneTwo.deadline)
    ) {
      setIsMilestoneTwoUpdated(true);
    } else {
      setIsMilestoneTwoUpdated(false);
    }
  }, [milestoneTwo]);

  return (
    <Box>
      {formSteps === "MAIN" ? (
        <Box sx={CampaigngForm}>
          <h1>{t("editCampaign")}</h1>
          <Grid container spacing={2}>
            <Grid
              item
              lg={4}
              md={6}
              sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    textAlign: "start",
                    marginBottom: "5px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {t("title")}
                </Typography>
                <TextField
                  sx={{ marginBottom: 3 }}
                  fullWidth
                  hiddenLabel
                  type={"text"}
                  placeholder={t("title") || ""}
                  variant="outlined"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  value={title}
                  error={invalidTitle}
                  helperText={invalidTitle && errorTitle}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    textAlign: "start",
                    marginBottom: "5px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {t("description")}
                </Typography>
                <TextField
                  sx={{ marginBottom: 3 }}
                  fullWidth
                  hiddenLabel
                  multiline
                  minRows={4}
                  type={"text"}
                  placeholder={t("description") || ""}
                  variant="outlined"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                  error={invalidDescription}
                  helperText={invalidDescription && errorDescription}
                />
              </Box>
              <Box>
                <FormLabel component="legend">{t("image")}</FormLabel>
                <Box
                  sx={{
                    maxWidth: "300px",
                    maxHeight: "300px",
                    width: "100%",
                    height: "300px",
                    backgroundImage: `url(${
                      process.env.REACT_APP_URL
                    }/api/file/image/${
                      campaignInitialData?.images &&
                      campaignInitialData?.images[selectedImageId]
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundColor: "#fff",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "300px",
                    width: "100%",
                  }}
                >
                  {campaignInitialData?.images?.length > 1 &&
                    campaignInitialData?.images?.map(
                      (item: any, index: any) => (
                        <IconButton
                          key={index}
                          onClick={() => setSelectedImageId(index)}
                        >
                          <Box
                            sx={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "5px",
                              backgroundColor:
                                index === selectedImageId ? "#000" : "gray",
                              marginX: "2.5px",
                            }}
                          />
                        </IconButton>
                      )
                    )}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
            >
              <Box sx={{ marginBottom: 3 }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    textAlign: "start",
                    marginBottom: "5px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {t("campaignCategory")}
                </Typography>
                <Select
                  fullWidth
                  value={category}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleCategoryChange}
                >
                  {campCategoryList.length > 0 &&
                    campCategoryList.map((catg: any) => {
                      return (
                        <MenuItem value={catg.categoryId}>
                          {catg.title}
                        </MenuItem>
                      );
                    })}
                </Select>
                {invalidCategory && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {t("pleaseSelectCategory")}
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">
                  {t("campaignEndingDate")}
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      value={endDate}
                      onChange={handleChange}
                      renderInput={(params: any) => <TextField {...params} />}
                      disablePast
                    />
                  </Stack>
                </LocalizationProvider>
                {invalidEndDate && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {errorEndDate}
                  </FormHelperText>
                )}
              </Box>

              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">{t("fundingCategory")}</FormLabel>
                <Select
                  disabled
                  fullWidth
                  value={fundingCategory}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleFundingCategoryChange}
                >
                  {fundCategoryList.length > 0 &&
                    fundCategoryList.map((catg: any) => {
                      return (
                        <MenuItem value={catg.fundingCategoryId}>
                          {catg.title}
                        </MenuItem>
                      );
                    })}
                </Select>
                {invalidFundingCategory && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {t("pleaseSelectFundingCategory")}
                  </FormHelperText>
                )}
              </Box>

              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">{t("paymentType")}</FormLabel>
                <Select
                  disabled={campaignInitialData?.paymentType ? true : false}
                  fullWidth
                  value={paymentTypeBlockchain}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    handlePaymentType(e.target.value);
                  }}
                >
                  {paymentList.length > 0 &&
                    paymentList.map((paymentItem, index) => {
                      return (
                        <MenuItem key={index} value={paymentItem}>
                          {paymentItem}
                        </MenuItem>
                      );
                    })}
                </Select>

                {invalidPaymentCategory && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {t("pleaseSelectPaymentType")}
                  </FormHelperText>
                )}
              </Box>

              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">
                  Goal
                  {paymentTypeBlockchain === "ALGO" && (
                    <img
                      style={{ width: "16px", marginBottom: "-3px" }}
                      src={algo}
                      alt="ALGO"
                    />
                  )}
                  {paymentTypeBlockchain === "USDC" && (
                    <MonetizationOnIcon
                      style={{
                        marginBottom: "-3px",
                        fontSize: "20px",
                        color: "black",
                      }}
                    />
                  )}
                  * between 5 and 1000000
                </FormLabel>
                <TextField
                  fullWidth
                  hiddenLabel
                  // type={"number"}
                  placeholder={t("enterCamppaignGoal") || ""}
                  variant="outlined"
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 1000000) {
                      setGoalAmount("1000000");
                    } else {
                      setGoalAmount(e.target.value);
                    }
                  }}
                  value={goalAmount}
                  error={invalidGoalAmount}
                  helperText={invalidGoalAmount && errorGoalAmount}
                />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">{t("country")}</FormLabel>
                <Select
                  fullWidth
                  value={country}
                  onChange={handleCountryChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {countryList.map((country) => {
                    return (
                      <MenuItem
                        value={country.toLocaleLowerCase()}
                        key={country}
                      >
                        {country}
                      </MenuItem>
                    );
                  })}
                </Select>
                {invalidCountry && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {t("pleaseSelectCountry")}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              sx={{
                width: { md: "auto", sm: "50%", xs: "100%" },
              }}
            >
              <FormLabel component="legend">
                {t("video")} ({t("optional")})
              </FormLabel>
              <Box
                sx={{
                  maxWidth: "300px",
                  maxHeight: "300px",
                  width: "100%",
                  height: "300px",

                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundColor: "#fff",
                }}
                style={{ position: "relative" }}
              >
                <video
                  style={campaignInitialData?.video ? {} : { display: "none" }}
                  src={`${process.env.REACT_APP_URL}/api/file/video/${campaignInitialData?.video}`}
                  controls
                  width={"100%"}
                  height={"100%"}
                  autoPlay={false}
                />
              </Box>
              <Typography
                style={{
                  fontFamily: "bold",
                  fontSize: "15px",
                  marginBottom: "3px",
                }}
              >
                OR{" "}
              </Typography>
              <FormLabel component="legend">
                {t("youtubelink")} ({t("optional")})
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("youtubelink") || ""}
                variant="outlined"
                name="youTubeLink"
                value={youTubeLink}
                onChange={(e) => {
                  setyouTubeLink(e.target.value);
                }}
              />
              {
                // this is the id of type reward
                (fundingCategory === TEST_FUNDING_CATEGORY ||
                  fundingCategory === STAGING_FUNDING_CATEGORY ||
                  fundingCategory === PRODUCTION_FUNDING_CATEGORY) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                    }}
                  >
                    {rewardType === "NFTs" && (
                      <FormLabel component="legend">
                        {t("selectedNft")} - {selectedNft}
                      </FormLabel>
                    )}
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      className="round-button"
                      sx={{
                        marginRight: "auto",
                        backgroundColor: "#000000de",
                        color: "#61f359",
                      }}
                      // onClick={handleRewardModal}
                    >
                      {t("selectReward")}
                    </Button>
                  </Box>
                )
              }
            </Grid>
          </Grid>

          <MilestoneSet
            headerText="Edit Milestones"
            milestoneOne={milestoneOne}
            milestoneTwo={milestoneTwo}
            invalidDateOne={invalidDateOne}
            invalidDateTwo={invalidDateTwo}
            setMilestoneOne={setMilestoneOne}
            setMilestoneTwo={setMilestoneTwo}
            invalidDefinitionOne={invalidDefinitionOne}
            invalidDefinitionTwo={invalidDefinitionTwo}
            invalidTitleOne={invalidTitleOne}
            invalidTitleTwo={invalidTitleTwo}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "50px",
              marginBottom: "60px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              {t("isCampaignaReforestationProject")}
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Button
                id="basic-button2"
                aria-controls={open2 ? "basic-menu2" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleClick2}
                sx={{ color: "#000", border: "1px solid gray" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {monitoringStatus.title !== ""
                  ? monitoringStatus.title
                  : t("pleaseSelectOption")}
              </Button>
              <Menu
                id="basic-menu2"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button2",
                }}
              >
                <Box sx={{ width: "200px" }}>
                  <MenuItem
                    onClick={() => {
                      setMonitoringStatus({ title: "Yes", status: true });
                      handleClose2();
                    }}
                  >
                    {t("yes")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMonitoringStatus({ title: "No", status: false });
                      handleClose2();
                    }}
                  >
                    {t("no")}
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
            {invalidMonitoringOption && (
              <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                {errorInvalidMonitoringTitle}
              </FormHelperText>
            )}
          </Box>
          {monitoringStatus.title === "Yes" && (
            <Box sx={CampaigngForm}>
              <Typography variant="h4" mt={3} align="center" mb={3}>
                {t("monitoringDetails")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel component="legend">Project Output</FormLabel>
                  <TextField
                    value={YearlyOutput}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    sx={{ marginBottom: 3 }}
                    hiddenLabel
                    type="number"
                    placeholder={t("yearlyOutput") || ""}
                    onChange={(e) => {
                      setYearlyOutput(e.target.value);
                    }}
                    error={invalidYearlyOutput}
                    helperText={invalidYearlyOutput && errorYearlyOutput}
                  />

                  <Box sx={{ marginBottom: 3 }}>
                    <FormLabel component="legend">Location</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={locOption}
                      onChange={(e) => setLocOption(e.target.value)}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {campaignInitialData.location && (
                          <FormControlLabel
                            value="location"
                            control={<Radio />}
                            label={t("searchLocation")}
                            style={{ marginRight: "2rem" }}
                          />
                        )}
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {campaignInitialData.location === null && (
                          <FormControlLabel
                            value="coordinates"
                            control={<Radio />}
                            label={t("enterLocation")}
                            style={{ marginRight: "2rem" }}
                          />
                        )}
                      </div>
                    </RadioGroup>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {locOption === "location" &&
                      campaignInitialData.location ? (
                        <PlacesAutocomplete
                          value={location}
                          onChange={(newValue) => {
                            setLocation(newValue);
                          }}
                          // onChange={(e: any) => {
                          //   setLocation(e.target.value);
                          // }}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div style={{ width: "100%" }}>
                              {/* <p>lat : {coordinates.lat}</p>
      <p>lat : {coordinates.lng}</p>  */}

                              <TextField
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: "location-search-input",
                                })}
                                error={invalidLocation}
                                helperText={invalidLocation && errorLocation}
                                // ref={locationRef}
                                sx={{ marginTop: 2 }}
                                required
                                fullWidth
                                hiddenLabel
                                type={"text"}
                                variant="outlined"
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      ) : locOption === "coordinates" &&
                        campaignInitialData.location === null ? (
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                              // value={coordinates.lat || campaignInitialData.lat}
                              value={
                                coordinates && coordinates.lat
                                  ? coordinates.lat
                                  : campaignInitialData.lat
                              }
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              sx={{ marginBottom: 1 }}
                              hiddenLabel
                              type="number"
                              // ref={coordinatesRef}
                              placeholder={"Latitude" || ""}
                              onChange={(e: any) => {
                                setCoordinates({
                                  ...coordinates,
                                  lat: e.target.value,
                                });
                              }}
                              error={invalidlatitude}
                              helperText={
                                invalidlatitude && t("pleaseEnterLatitude")
                              }
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                              // value={coordinates.lng || campaignInitialData.lng}
                              value={
                                coordinates && coordinates.lng
                                  ? coordinates.lng
                                  : campaignInitialData.lng
                              }
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              // ref={coordinatesRef}
                              sx={{ marginBottom: 3 }}
                              hiddenLabel
                              type="number"
                              placeholder={"Longitude" || ""}
                              onChange={(e: any) => {
                                setCoordinates({
                                  ...coordinates,
                                  lng: e.target.value,
                                });
                              }}
                              error={invalidlongitude}
                              helperText={
                                invalidlongitude && t("pleaseEnterLongitude")
                              }
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel component="legend">CostPerUnit</FormLabel>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    sx={{ marginBottom: 3 }}
                    hiddenLabel
                    type="number"
                    placeholder={t("costPerUnit($)") || ""}
                    onChange={(e) => {
                      setCostPerUnit(e.target.value);
                    }}
                    value={CostPerUnit}
                    error={invalidCostPerUnit}
                    helperText={invalidCostPerUnit && errorCostPerUnit}
                  />

                  <Box sx={{ marginBottom: 3 }}>
                    <FormLabel component="legend">Expected Results</FormLabel>
                    <TextField
                      multiline
                      type={"text"}
                      variant="outlined"
                      margin="normal"
                      minRows={3}
                      fullWidth
                      required
                      sx={{ marginBottom: 3 }}
                      hiddenLabel
                      placeholder={t("expectedResults") || ""}
                      onChange={(e) => {
                        setResults(e.target.value);
                      }}
                      value={Results}
                      error={invalidResults}
                      helperText={invalidResults && errorResults}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "50px",
              marginBottom: "60px",
            }}
          >
            <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
              {t("isYourCampaignAlignedWithAnyGoal")}
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Button
                disabled
                sx={{ color: "#000", border: "1px solid gray" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {campaignInitialData?.isEsgEnabled ? "Yes" : "No"}
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 3,
              paddingBottom: "20px",
              gap: "60px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              className="round-button"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("back")}
            </Button>
            <>
              <Button
                variant="contained"
                color="primary"
                className="round-button"
                onClick={handleShowWalletOptions}
              >
                {t("submit")}
              </Button>
              {showWalletButtons && (
                <Wallets
                  open={showWalletButtons}
                  handleClose={() => setShowWalletButtons(false)}
                  handleSubmit={handleCreateCampaign}
                />
              )}
            </>
            {campaignInitialData?.isEsgEnabled && (
              <Button
                variant="contained"
                color="primary"
                className="round-button"
                onClick={() => {
                  const valid = validateForm();
                  const validMilestone = validateMilestoneData();
                  const validMonitoring = validateMonitoringData();

                  if (!valid || !validMilestone || !validMonitoring) {
                    return;
                  }
                  setFormSteps("GOAL");
                }}
              >
                {t("next")}
              </Button>
            )}
          </Box>
        </Box>
      ) : formSteps === "GOAL" ? (
        <EditGoalPage
          handleSteps={(vl: string) => setFormSteps(vl)}
          goalIds={goalIds}
          isMatchFund={campaignInitialData?.matchFunding}
          isAdditionalInfo={campaignInitialData?.additionalInfo}
          handleCreateCampaign={() => handleCreateCampaign()}
          handleGoalIds={(item: any) =>
            goalIds.some((el) => el === item?.goalId)
              ? setGoalIds(goalIds.filter((el) => el !== item?.goalId))
              : setGoalIds((prev) => [...prev, item?.goalId])
          }
        />
      ) : (
        <EditAdditionalInfo
          handleSteps={(vl: string) => setFormSteps(vl)}
          additionalData={additionalData}
          handleCreateCampaign={() => handleCreateCampaign()}
          consentStatus={campaignInitialData?.additionalInfoId?.consent}
          reachoutStatus={
            campaignInitialData?.additionalInfoId?.reachoutRegardingSubmission
          }
          handleCountry={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, country: vl }))
          }
          handleImage={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, infoImage: vl }))
          }
          handleContactName={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, contactName: vl }))
          }
          handleCompanyName={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, companyName: vl }))
          }
          handleContactEmail={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, contactEmail: vl }))
          }
          handleContactPhone={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, contactPhone: vl }))
          }
          handleWebsite={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, website: vl }))
          }
          handleProjectPitch={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, projectPitch: vl }))
          }
          handleProblems={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, problems: vl }))
          }
          handlePreviousSuccessfulProject={(vl: any) =>
            setAdditionalData((prev: any) => ({
              ...prev,
              previousSuccessfulProject: vl,
            }))
          }
          handleHq={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, Hq: vl }))
          }
          handleImpact={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, impact: vl }))
          }
          handlePartnership={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, partnership: vl }))
          }
          handleYearOfEstablishment={(vl: any) =>
            setAdditionalData((prev: any) => ({
              ...prev,
              yearOfEstablishment: vl,
            }))
          }
          handleStageDesc={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, stageDesc: vl }))
          }
          handleAdditionalInfo={(vl: any) =>
            setAdditionalData((prev: any) => ({ ...prev, additionalInfo: vl }))
          }
        />
      )}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: "99999999999999",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          flexDirection: "column",
        }}
        open={preLoading}
      >
        <CircularProgress color="inherit" />
        <h3>{t("updatingCampaign")}</h3>
      </Backdrop>
    </Box>
  );
}

export default EditCampaign;

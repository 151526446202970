import { SessionWallet } from "algorand-session-wallet";
import algosdk, { Transaction, waitForConfirmation } from "algosdk";
import { PeraWalletConnect } from "@perawallet/connect";

const token = {
  "X-API-Key": "xZyKnhTs7T705p4Eys2qB5rrTMJDaUvO4gd4SWth",
};
const host = "https://testnet-algorand.api.purestake.io/ps2";
const port = "";

export const client = new algosdk.Algodv2(token, host, port);
// export const walletClient = new SessionWallet(
//   "TestNet",
//   undefined,
//   "my-algo-connect"
// );

export const peraWallet = new PeraWalletConnect({
  shouldShowSignTxnToast: true,
  // network: 'testnet',
  chainId: 416002,
});

// export async function sendWait(txns: any[]) {
//   const { txId } = await client.sendRawTransaction(txns).do();
//   const result = await waitForConfirmation(client, txId, 4);
//   return result;
// }
// export async function getSuggested() {
//   const suggested = await client.getTransactionParams().do();
//   // suggested.lastRound = suggested.firstRound + 10;
//   return suggested;
// }
// export async function getWalletAddress() {
//   let peraConnect;
//   const isAlreadyConnectedSession = await peraWallet.reconnectSession();
//   if (!!isAlreadyConnectedSession.length) {
//     peraConnect = isAlreadyConnectedSession[0];
//   } else {
//     const connectRes = await peraWallet.connect();
//     peraConnect = connectRes[0];
//   }
//   // peraWallet?.connector?.on("disconnect", peraWallet.disconnect());

//   if (!!peraConnect) {
//     await client.accountInformation(peraConnect).do();
//   }
//   return peraConnect.toString();
// }
// export function getPayTxn(
//   suggested: any,
//   senderAddr: string,
//   reciverAddr: string,
//   amount: string
// ): Transaction {
//   const txnobj = {
//     from: senderAddr,
//     type: "pay",
//     to: reciverAddr,
//     amount: BigInt(amount),
//     ...suggested,
//   };
//   return new Transaction(txnobj);
// }

import { Grid, Typography, Stack, Button, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { campaign } from "./HomeCampaignContainer";
import { LinkBox, Range, UploadBox } from "./style";
import moment from "moment";
import { useTranslation } from "react-i18next";

const URL = process.env.REACT_APP_URL;

interface campaignCardProps {
  data: any;
}

function CampaignCard({ data }: campaignCardProps) {
  const { t } = useTranslation();
  const [daysRemaining, setDaysRemainig] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [bgImg, setBgImg] = useState<string>("");
  const card = useRef<HTMLDivElement>(null);

  const loadBackground = () => {
    const src = `${URL}/api/file/image/${data.images[0]}`;

    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      card.current?.classList.remove("animate");
      setBgImg(src);
    };
  };

  useEffect(() => {
    loadBackground();
    const today = moment();
    const endDate = moment(data.endDate);
    setDaysRemainig(endDate.diff(today, "days"));
    const collected = parseInt(data.collectedAmount);
    const totalGoal = parseInt(data.goalAmount);
    const per = ((collected / totalGoal) * 100).toPrecision(2);
    setPercentage(parseFloat(per));
  }, []);
  return (
    <Grid
      item
      xl={window.location.pathname.includes("user/match-funds") ? 3 : 3}
      lg={window.location.pathname.includes("user/match-funds") ? 4 : 3}
      md={window.location.pathname.includes("user/match-funds") ? 6 : 4}
      sm={6}
      xs={12}
    >
      <Box sx={LinkBox}>
        <Link
          className="LinkBox"
          style={{ textDecoration: "none", color: "inherit" }}
          to={`/campaign/${data.campaignId}`}
        >
          <Box
            sx={UploadBox}
            component={"div"}
            className="animate"
            ref={card}
            style={
              bgImg.length > 1
                ? {
                    backgroundImage: `url('${bgImg}')`,
                    // backgroundImage: `url('${URL}/api/file/image/${data.images[0]}')`,
                    display: "flex",
                    justifyContent: "end",
                  }
                : {}
            }
          >
            {data?.isEsgEnabled && data?.rating >= 0 && (
              <span
                style={{
                  height: "36px",
                  width: "42px",
                  backgroundColor: "#4caf50",
                  borderRadius: "50%",
                  fontSize: "17px",
                  marginTop: "17px",
                  marginRight: "17px",
                  color: "#ffffff",
                  lineHeight: "36px",
                }}
                title={"Cashdillo SDG Campaign Rating Score"}
              >
                {data.rating}
              </span>
            )}
          </Box>
          <Box sx={{ height: "35px" }}>
            {data?.isEsgEnabled && (
              <Stack direction="row" spacing={2}>
                {data?.goalIds.filter((goal: any) => {
                  return goal.category === "environmental";
                }).length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      height: "30px",
                      backgroundColor: "#8bc34a7d",
                      fontSize: "10px",
                      padding: "10px 38px",
                      margin: "0 3px",
                    }}
                  >
                    {t("environmental")}
                  </Button>
                )}
                {data?.goalIds.filter((goal: any) => {
                  return goal.category === "social";
                }).length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      height: "30px",
                      backgroundColor: "#ffeb3b99",
                      fontSize: "10px",
                      padding: "10px 38px",
                      margin: "0 3px",
                    }}
                  >
                    {t("social")}
                  </Button>
                )}
                {data?.goalIds.filter((goal: any) => {
                  return goal.category === "governance";
                }).length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      height: "30px",
                      backgroundColor: "#2196f3a3",
                      fontSize: "10px",
                      padding: "10px 38px",
                      margin: "0 3px",
                    }}
                  >
                    {t("governance")}
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid ",
              marginBottom: "15px",
              borderColor: "secondary.light",
            }}
          >
            <Typography
              sx={{
                color: "initial",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginTop: "10px",
                textTransform: "uppercase",
              }}
              variant="h6"
              align="left"
              mb={0}
              className="bold"
            >
              {data.title}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              mb={1}
              sx={{
                color: "#9d9d9c",
                wordBreak: "break-word",
                minHeight: "38px",
              }}
            >
              {data.description.length < 100
                ? data.description
                : data.description.slice(0, 100) + "..."}
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={20}
            style={{
              marginTop: "0",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#000000e3",
                    fontSize: "16px",
                    padding: "18px",
                    maxWidth: "320px",
                    maxHeight: "420px",
                    textAlign: "justify",
                    whiteSpace: "pre-line",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "& .MuiTooltip-arrow": {
                      color: "#ffffff",
                    },
                  },
                },
              }}
              title={data.description}
              placement="top-start"
            >
              <Button
                variant="contained"
                style={{
                  height: "30px",
                  backgroundColor: "#61f359",
                  fontSize: "10px",
                  padding: "10px 20px",
                  margin: "0px 2px",
                }}
              >
                {t("quickInfo")}
              </Button>
            </Tooltip>
            <Button
              variant="contained"
              style={{
                height: "30px",
                backgroundColor: "#61f359",
                fontSize: "10px",
                padding: "10px 20px",
                margin: "0px 2px",
              }}
            >
              {t("viewDetails")}
            </Button>
          </Stack>
          <Box sx={Range}>
            <Box className="RangeBox">
              <div
                className="rangecolor"
                style={{
                  width: `${
                    isNaN(percentage) ? 0 : percentage > 100 ? 100 : percentage
                  }%`,
                }}
              ></div>
            </Box>
            <Box className="valuebox">
              <Typography variant="body2" className="percentage semibold">
                {isNaN(percentage) ? 0 : percentage > 100 ? 100 : percentage}%
              </Typography>
              <Typography variant="body2" className="days semibold">
                {daysRemaining} {t("days")}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
    </Grid>
  );
}

export default CampaignCard;

import { Modal, Box } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  previewImage,
  setPreviewImageModal,
} from "../../../store/slices/ModalSlice";
import CloseIcon from "@mui/icons-material/Close";

function PreviewImageModal() {
  const open = useAppSelector((state) => state.modalReducer.previewImage);
  const url = useAppSelector((state) => state.modalReducer.imageUrl);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    const payload: previewImage = {
      imageUrl: "",
      previewImage: false,
    };
    dispatch(setPreviewImageModal(payload));
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          color: "#fff",
          zIndex: "99999999999999",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          flexDirection: "row",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            width: "100%",
            justifyContent: "center",
            maxHeight: "70%",
          }}
        >
          <img
            style={{ maxWidth: "70%", maxHeight: "70%" }}
            // src={`https://wallpaperaccess.com/full/3547002.jpg`}
            src={url}
            alt="preview"
          />
          <CloseIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default PreviewImageModal;

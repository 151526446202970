import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { userRole } from "../../../constants/common-enum";
import { useAppSelector } from "../../../store";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const GrantApplicantSideNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const navigations = ["application-list", "chat"];

  const location = useLocation();
  const userslice = useAppSelector((state) => state.userReducer);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    navigate(`/user/${navigations[newValue]}`);
  };

  useEffect(() => {
    if (location.pathname === "/user") {
      setValue(0);
      navigate(`/user/${navigations[0]}`);
    } else {
      setValue(
        navigations.indexOf(location.pathname.split("/user")[1].split("/")[1])
      );
    }
  }, [location.pathname]);

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: "divider", marginBottom: "40px" }}
    >
      {userslice.role === userRole.GRANT_APPLICANT && (
        <Tab label={t("manageApplications")} {...a11yProps(0)} />
      )}
      {userslice.role === userRole.GRANT_APPLICANT && (
        <Tab label={t("chat")} {...a11yProps(1)} />
      )}
    </Tabs>
  );
};

export default GrantApplicantSideNav;

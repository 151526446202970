export enum userRole {
  GRANT_CREATOR = "grant-creator",
  GRANT_MANAGER = "grant-manager",
  GRANT_APPLICANT = "applicant",
  ADMIN = "admin",
  CREATOR = "creator",
  INVESTOR = "investor",
  INSTITUTIONAL_DONOR = "institutional-donor",
}

export const fileTypes = [".pdf", ".txt", ".doc", ".docx"];

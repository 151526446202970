import { Box, Typography, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: "#f1f2f3", marginTop: "30px" }}>
      <Container fixed>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Want to know more? Have Questions?
          </Typography>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            How does Cashdillo decentralised crowdfunding work?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You start your Cashdillo Campaign by telling your story and setting
            a goal, the more information you provide (video, photos, project
            background etc) the higher the chances for your campaign to
            successfully get funded. You will then receive a campaign page to
            accept donations and share your fundraiser. Signing up is easy. We
            encourage campaigns that address environmental and social impact
            causes and align with the UN’s Sustainable Development Goals (SDGs).
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Cashdillo makes it incredibly easy to raise money online for the
            things that matter to your social impact project / cause/ activities
            the most. In just a few minutes, you’ll be able to personalise your
            campaign and share it with potential funders and those that have
            supported you in the past. At its core Cashdillo uses Cryptocurrency
            and Blockchain technology to govern the relationship between funders
            and receivers (if you want to know more about this please see the
            sections below).
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            In short, the campaign creator's wallet and the funder's wallet
            transact between each other - Cashdillo is not a centralised
            platform holding anybody's funds. The funds are held in an ESROW
            wallet that no centralised authority has access to. If the campaign
            is successful, the funds are released, if it is not successful the
            funds are returned to the funder's wallet. The same applies to the
            project milestone that each campaign must reach for additional
            campaign fund disbursements. Cashdillo charges a 7% platform /
            matching fee on all successful campaigns.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We’re here to help. Our Customer Care team will support you with
            customer queries every step of the way.
          </p>

          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            What can I raise money for?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We see people and organizations use Cashdillo to raise money for
            projects that address the most urgent needs facing our planet and
            the people and animals living on it. All campaigns are encouraged to
            align with the UN’s Sustainable Development Goals (SDGs).
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            How does Milestone based project funding work on Cashdillo?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Cashdillo is a platform built on trust with a focus on project
            deliverance. This is why campaigns are disbursed in three parts:
            First third of the budget when the campaign has been raised
            successfully; second third of the budget when milestone one is
            delivered; third and final payment when the second milestone has
            been completed.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            What is a cryptocurrency wallet?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            A crypto wallet refers to either a software application or a
            physical device designed to store and facilitate the transfer of
            various cryptocurrencies and digital assets. It is important to
            understand how it works prior to creating or funding a campaign with
            Cashdillo.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            How can I get a wallet?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            For beginners, the recommended wallet for both mobile and browser
            use is the Pera Wallet. However, individuals seeking more advanced
            features may consider the Defly Wallet. Pera Wallet is available as
            a mobile application and a website. You can download the Pera Wallet
            app from the Website, Apple Store, or Google Play Store.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            How do crypto wallets operate?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            When creating a crypto wallet you receive both a public key (wallet
            address) and a private key (seed phrase). Think of the public key as
            an email address you can share with others, similar to copying and
            pasting it when someone requests your email address. The private key
            serves as the password to access your wallet and should remain
            confidential. In Web 3, a seed phrase typically consists of 24
            different words, which may need to be recorded if you wish to sign
            in to your wallet on a new device. However, some wallets offer the
            option of using QR codes.
          </p>

          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            Which wallets does Cashdillo support and why do I need it?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Currently, Cashdillo accepts several wallets including Pera Wallet,
            DeFly, and Wallet Connect. You will need it to create a campaign/
            grant or fund a campaign. The wallet acts as your cryptographic
            identity and also as either end of the transaction (send/ receive).
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            What are ALGOs & How do I buy ALGOs?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            ALGOs are the digital currency Cashdillo uses as a transfer
            currency. They are the native token of the Algorand Blockchain, the
            technology Cashdillo is built on. You need ALGOs to create and fund
            campaigns on Cashdillo. You have the option to purchase ALGOs
            directly from your Pera Wallet using a debit card or Apple Pay.
            There is no requirement to utilize exchanges like Coinbase or Kraken
            if you prefer not to.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            How can I ensure the safety of my funds?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We cannot retrieve your assets if you lose access to your wallet or
            if it becomes compromised. In case you forget your password, you can
            regain access to your wallet by using your seed phrase, which is a
            series of words associated with all wallet addresses. It is
            advisable to write down and securely store your seed phrase in
            multiple locations.
            <br />
            <br />
            Here are some important measures to keep your funds secure:
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>
              Never share your seed phrase (a list of 25 words) with anyone, as
              anyone in possession of it can immediately access your funds.
            </li>
            <li>
              Conduct thorough research before investing in a project. Seek
              answers to your questions on platforms like Twitter, Discord, and
              Reddit, and ensure that you trust the team behind the project.
              Just like in real life, there are individuals with malicious
              intent who may try to take your funds.
            </li>
            <li>
              Always verify the wallet address before sending funds.
              Double-check that it is accurate.
            </li>
            <li>
              ASAs (Algorand Standard Assets) are cryptocurrencies built on the
              Algorand blockchain. Each ASA has a unique identification number
              known as the ASA ID, which you should always verify to ensure its
              correctness. Look for verification tick marks and official sources
              that authenticate the ID.
            </li>
          </ul>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            What is Blockchain?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            A blockchain is a distributed public ledger or file that contains
            transactional data. It is maintained across multiple computers,
            known as nodes, within a network. All nodes collaborate using the
            same software and rules to validate transactions and add them to the
            finalized ledger.
            <br />
            <br />
            To illustrate the contrast, consider a traditional ledger of
            transactional data that resides in a single database accessible to a
            limited group of people. In comparison, a blockchain allows for
            decentralized transaction processing. For instance, a woman can
            place a bid on a house using software built on a decentralized
            blockchain, as opposed to relying on a private agency.
            <br />
            <br />
            The term "block" in blockchain refers to a set of transactions that
            undergo verification by other nodes and are subsequently added to
            the ledger. Each block also contains a cryptographic hash, which
            serves as proof of the previous block's content. This chaining
            process extends back to the genesis block, establishing a publicly
            verifiable and tamperproof record of all transactions.
            <br />
            <br />
            Practically, any attempt to alter a record within the blockchain's
            history will be detected and rejected by the network nodes. This
            provides a safeguard against manipulation, unlike traditional
            ledgers that can be easily changed within a limited group.
            <br />
            <br />
            The process of adding blocks to the chain relies on each node
            running software that guides them in verifying transactions and
            appending new blocks. These guidelines, collectively known as the
            "consensus protocol," vary across different blockchains and
            contribute to their distinct characteristics.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            What is the Algorand Blockchain?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Algorand is a blockchain platform that revolves around the core
            concept of ownership. Similar to real-world scenarios where
            ownership is established through documents like real estate titles
            or car deeds, Algorand operates in a similar manner. However,
            instead of physical documents, proof of ownership is stored on a
            publicly accessible ledger called the blockchain.
            <br />
            <br />
            Many barriers encountered in both the digital and real-world space
            arise due to intermediaries overseeing and facilitating
            transactions. For example: these intermediaries include banks that
            hold and lend your money, foreign currency exchange services that
            might not offer fair rates, or mortgage-approval algorithms that may
            be influenced by hidden biases. To simplify the concept, think of
            the blockchain as a secure and tamper-resistant stored database,
            which can be trusted without being controlled by a single entity.
            <br />
            <br />
            What makes Algorand exceptional is its ability to eliminate the need
            for intermediaries entirely. On Algorand, transactions can be
            executed synchronously, without the need to trust the other party
            involved. This same principle applies to how Cashdillo operates
            within the Algorand ecosystem.
            <br />
            <br />
            For example, in the Algorand space, you might possess a token that
            represents the right to attend specific sport games. This token,
            stored in your virtual wallet, serves as immutable proof of your
            ownership. You can easily access and present this proof whenever
            needed. The functionality of the virtual wallet resembles that of a
            physical wallet, allowing you to view your assets and conveniently
            send them to others.
            <br />
            <br />
            Contrary to a common misconception, interacting with the blockchain
            does not necessarily require in-depth technical knowledge. Just like
            using the internet, you can engage with the blockchain using common
            sense and familiar digital practices. In fact, many times you might
            not even realize you are utilizing blockchain technology.
            <br />
            <br />
            To get started with Algorand, all you need is an internet
            connection, a wallet (available for download as an app or browser
            feature), and a basic understanding of how digital systems work.
            Unlike other blockchain networks such as Ethereum, Algorand offers a
            user-friendly experience with simple wallet setups and minimal
            transaction fees. Despite its beginner-friendly approach, Algorand
            boasts advanced and sophisticated technology behind the scenes.
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            How does Cashdillo’s blockchain technology contribute to social and
            environmental impact initiatives?
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Cryptocurrency and blockchain technology represent transformative
            innovations that hold the power to create a positive change in the
            world. These groundbreaking technologies are poised to disrupt
            various facets of our lives in the upcoming decades, including the
            internet, financial systems, supply chains, healthcare, and more.
            <br />
            <br />
            From a social impact perspective, blockchain stands as the most
            significant advancement since the internet, presenting the potential
            to revolutionize multiple areas. It can reshape how aid is
            distributed, facilitate fundraising for nonprofits and charitable
            causes, empower communities and collectives to self-govern and
            collaborate, enable effective tracking and management of CO2
            emissions, revolutionize financial services for the unbanked
            population of nearly 2 billion people worldwide, and much more.
            Cashdillo plays a vital role in bridging this technology to impact
            projects.
          </p>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQ;

import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  style,
  titleStyle,
  roles,
  FooterBtn,
} from "../../../components/auth/selectRoleStyle";

function ActivityModal({ handleSubmit, handleClose }: any) {
  const { t } = useTranslation();
  const [role, setRole] = useState<string>("");

  return (
    <Box
      sx={{
        height: "100%",
        outline: " 0px",
        overflow: "hidden auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "@media(max-height:700px)": {
          alignItems: "baseline",
        },
      }}
    >
      <Box
        sx={style}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box sx={titleStyle}>
          <Typography variant="h4" align="center" mb={2}>
            {t("chooseAcitvityType")}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="caption">
              {t("weWillTakeYouToRespectiveDashboard")}
            </Typography>
          </Box>
        </Box>
        <Box sx={roles}>
          <p
            className={role === "MATCHFUND" ? "selected" : ""}
            onClick={() => {
              setRole("MATCHFUND");
            }}
          >
            {t("createMatchFund")}
          </p>
          <p>
            {t("createGrantProgram")}
            <span>({t("comingSoon")})</span>
          </p>
          <p>
            {t("createBoth")}
            <span>({t("comingSoon")})</span>
          </p>
        </Box>
        <Box sx={FooterBtn}>
          <Box sx={{ textAlign: "center" }}>
            <Button
              disabled={role === ""}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {t("continue")}
            </Button>
          </Box>
          <Button
            sx={{ color: "initial" }}
            variant="text"
            onClick={handleClose}
          >
            {t("doItLater")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ActivityModal;

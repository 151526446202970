import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridWrap, footer } from "./style";
import React, { useEffect, useState } from "react";
import {
  getInvestedCampaignTable,
  getMyCampaigns,
} from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { setDeleteCampaignModal } from "../../../store/slices/ModalSlice";
import { useNavigate } from "react-router-dom";
import CustomNoRowsOverlay from "../../common/tables/CustomNoRowsOverlay";
import algo from "../../../assets/images/algo.png";
import { useTranslation } from "react-i18next";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

function MyCampaignInvestor() {
  const { t } = useTranslation();
  const [campaign, setCampaign] = useState<Array<any>>([]);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deleteCampaignModalState = useAppSelector(
    (state) => state.modalReducer.deleteCampaignModal
  );

  const columns: GridColDef[] = [
    {
      field: "Title",
      headerName: "Title",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "Goal",
      headerName: "Goal",
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.Goal}{" "}
            {params.row?.PaymentType === "ALGO" && (
              <Avatar
                style={{
                  width: "18px",
                  height: "18px",
                  marginLeft: "5px",
                  borderRadius: 0,
                }}
                src={algo}
              />
            )}
            {params.row?.PaymentType === "USDC" && (
              <MonetizationOnIcon
                style={{
                  marginBottom: "-2px",
                  fontSize: "23px",
                  color: "black",
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "FundRaised",
      headerName: "Fund Raised",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.FundRaised}{" "}
            {params.row?.PaymentType === "ALGO" && (
              <Avatar
                style={{
                  width: "18px",
                  height: "18px",
                  marginLeft: "5px",
                  borderRadius: 0,
                }}
                src={algo}
              />
            )}
            {params.row?.PaymentType === "USDC" && (
              <MonetizationOnIcon
                style={{
                  marginBottom: "-2px",
                  fontSize: "23px",
                  color: "black",
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Typography
            variant="h6"
            sx={{
              color: params.row.Status === "completed" ? "#00FF00" : "#000",
              textTransform: "capitalize",
            }}
          >
            {params.row.Status}
          </Typography>
        );
      },
    },
    {
      field: "Deadline",
      headerName: "Deadline",
      sortable: false,
      minWidth: 110,
      flex: 1,
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/user/my-campaign/${params.row.campaignId}`);
        };
        return (
          <Box>
            <IconButton onClick={handleView}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const fetchMyCampaigns = async () => {
    setloading(true);
    const data = {
      page: page,
      limit: 5,
    };
    const res = await getInvestedCampaignTable(data);
    setloading(false);
    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    setEndPage(res.data.totalPages);
    let tempCampaign: Array<any> = [];

    res.data.donations.map((campaign: any, index: number) => {
      const payload: any = {
        Action: "Edit",
        id: index,
        Goal: `${campaign.goalAmount}`,
        FundRaised: `${campaign.collectedAmount}`,
        Title: campaign.title,
        Deadline: moment(campaign.endDate).format("DD-MMM-YYYY"),
        Status: campaign.status,
        campaignId: campaign.campaignId,
        PaymentType: `${campaign.paymentType}`,
      };

      tempCampaign.push(payload);
    });

    setCampaign([...tempCampaign]);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchMyCampaigns();
  }, [page]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  useEffect(() => {
    if (!deleteCampaignModalState) {
      fetchMyCampaigns();
    }
  }, [deleteCampaignModalState]);

  return (
    <>
      <Box>
        <Typography variant="h4" align="left" mb={2}>
          {t("donatedCampaigns")}
        </Typography>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={campaign}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyCampaignInvestor;

import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { DetailUser } from "./styles";
import {
  fetchLoggedInUser,
  saveEditedProfile,
} from "../../../requests/authRequest";
import { loggedInUser, setUserData } from "../../../store/slices/UserSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import moment from "moment";
import MuiPhoneNumber from "material-ui-phone-number";
import countryList from "./countrylist";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { setUserVerificationModal } from "../../../store/slices/ModalSlice";
import { userRole } from "../../../constants/common-enum";
import { useTranslation } from "react-i18next";
import { color, style } from "@mui/system";

function UserProfile() {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // these are related to editing
  const [editName, setEditName] = useState<string>(user.name);
  const [editEmail, setEditEmail] = useState<string>(user.email);
  const [editAddress, setEditAddress] = useState<string>(user.address);
  const [editGender, setEditGender] = useState<string>(user.gender);
  const [editCountry, setEditCountry] = useState<string>(user.country);
  const [editMobile, setEditMobile] = useState<
    string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  >(user.phone);

  const handleNameEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditName(e.target.value);
  };

  const handleEmailEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditEmail(e.target.value);
  };

  const handleAddressEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditAddress(e.target.value);
  };

  const handleGenderEdit = (e: SelectChangeEvent<string>) => {
    setEditGender(e.target.value);
  };

  const handleEditPhone = (
    e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEditMobile(e);
  };

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    setEditCountry(e.target.value);
  };

  const fetchUser = async () => {
    dispatch(toggleAppLoading(true));
    const res = await fetchLoggedInUser();
    if (res.success) {
      const payload: loggedInUser = {
        status: res.data.status,
        name:
          user.role === userRole.GRANT_MANAGER
            ? res.data.fullName
            : res.data.name,
        email: res.data.email,
        userId: res.data.userId ? res.data.userId : res.data._id,
        createdAt: res.data.createdAt,
        address: res.data.address ? res.data.address : "",
        phone: res.data.phone ? res.data.phone : "",
        country: res.data.country ? res.data.country : "",
        gender: res.data.gender ? res.data.gender : "",
        role: res.data.role ? res.data.role : "",
        userAppId: res.data.userAppId ? res.data.userAppId : "",
        organisationName: res.data.organisationName
          ? res.data.organisationName
          : "",
        organisationOwner: res.data.organisationOwner
          ? res.data.organisationOwner
          : "",
        orgnisationRole: res.data.orgnisationRole
          ? res.data.orgnisationRole
          : "",
      };
      dispatch(setUserData(payload));
      dispatch(toggleAppLoading(false));
      setEditMode(false);
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleCancel = () => {
    setEditName(user.name);
    setEditEmail(user.email);
    setEditGender(user.gender);
    setEditMobile(user.phone);
    setEditAddress(user.address);
    setEditCountry(user.country);
    setEditMode(false);
  };

  const verifyData = () => {
    let res = true;
    // this is for name
    if (editName.trim().length <= 0) {
      res = false;
    }

    //  this is for gender
    if (editGender && editGender.trim().length <= 0) {
      res = false;
    }

    //  this is for phone
    if (
      typeof editMobile === "string" &&
      editMobile.length <= 0 &&
      !/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[843210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(
        editMobile
      )
    ) {
      res = false;
    }

    // this is for address
    if (editAddress && editAddress.trim().length <= 0) {
      res = false;
    }

    // this is for country
    if (editCountry && editCountry.trim().length <= 0) {
      res = false;
    }

    return res;
  };

  const saveInfo = async () => {
    // save info logic here

    dispatch(toggleAppLoading(true));

    const valid = verifyData();

    if (!valid) {
      dispatch(toggleAppLoading(false));
      return;
    }
    // if (user.name !== editName) {
    // 	const blockchainRes = await saveInfoInBlockchain();
    // 	if (!blockchainRes?.success) {
    // 		return;
    // 	}
    // }
    const data = {
      userId: user.userId,
      name: editName,
      gender: editGender.toLowerCase(),
      phone: editMobile,
      address: editAddress,
      country: editCountry,
    };

    const res = await saveEditedProfile(data);
    if (res.success) {
      await fetchUser();
      const payload = {
        active: true,
        text: "Profile updated successfully",
        success: true,
      };
      dispatch(setToaster(payload));
    } else {
      const payload = {
        active: true,
        text: res.data.message,
        success: false,
      };
      dispatch(setToaster(payload));
    }
    dispatch(toggleAppLoading(false));
    setEditMode(!editMode);
  };

  const handleKyc = () => {
    dispatch(setUserVerificationModal(true));
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    setEditName(user.name);
    setEditEmail(user.email);
    setEditGender(user.gender);
    setEditMobile(user.phone);
    setEditAddress(user.address);
    setEditCountry(user.country);
  }, [user]);

  return (
    <Box sx={{ marginTop: "-24px" }}>
      <Stack spacing={2}>
        {/* users name */}
        <Box sx={DetailUser}>
          <div className="label">{t("name")}:</div>
          {editMode ? (
            <TextField
              sx={{ marginBottom: 3 }}
              hiddenLabel
              type={"text"}
              value={editName}
              onChange={handleNameEdit}
              placeholder={t("name") || ""}
              variant="standard"
            />
          ) : (
            <div className="value">{user.name}</div>
          )}
        </Box>

        {/* users email */}
        <Box sx={DetailUser}>
          <div className="label">{t("email")}:</div>
          {false ? (
            <TextField
              sx={{ marginBottom: 3 }}
              hiddenLabel
              value={editEmail}
              onChange={handleEmailEdit}
              type={"text"}
              placeholder={t("email") || ""}
              variant="standard"
            />
          ) : (
            <div className="value">{user.email}</div>
          )}
        </Box>

        {/* users gender */}
        <Box sx={DetailUser}>
          <div className="label">{t("gender")}:</div>
          {editMode ? (
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="gender"
              variant="standard"
              value={editGender}
              onChange={handleGenderEdit}
            >
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          ) : (
            <div className="value" style={{ textTransform: "capitalize" }}>
              {user.gender}
            </div>
          )}
        </Box>

        {/* users phone */}
        <Box sx={DetailUser}>
          <div className="label">{t("phone")}:</div>
          {editMode ? (
            <div style={{ width: "200px" }}>
              <MuiPhoneNumber
                defaultCountry={"us"}
                value={editMobile}
                onChange={handleEditPhone}
              />
            </div>
          ) : (
            <div className="value">{user.phone}</div>
          )}
        </Box>

        {/* users address */}
        <Box sx={DetailUser}>
          <div className="label">{t("address")}:</div>
          {editMode ? (
            <TextField
              sx={{ marginBottom: 3 }}
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("address") || ""}
              variant="standard"
              value={editAddress}
              onChange={handleAddressEdit}
            />
          ) : (
            <div className="value">{user.address}</div>
          )}
        </Box>

        {/* users country */}
        <Box sx={DetailUser}>
          <div className="label">{t("country")}:</div>
          {editMode ? (
            <Select
              value={editCountry}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              variant="standard"
              onChange={handleCountryChange}
            >
              {countryList.map((country) => {
                return (
                  <MenuItem value={country.toLocaleLowerCase()} key={country}>
                    {country}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <div className="value" style={{ textTransform: "capitalize" }}>
              {user.country}
            </div>
          )}
        </Box>

        {/* user created at */}
        <Box sx={DetailUser}>
          <div className="label">{t("createdAt")}:</div>
          <div className="value">
            {moment(user.createdAt).format("hh:mma DD MMM YYYY")}
          </div>
        </Box>

        {/* user Status */}
        <Box sx={DetailUser}>
          <div className="label">{t("status")}</div>
          {/* <div className="label" >{user.status}</div> */}
          {user.status === "blocked" ? (
            <div className="label" style={{ color: "red" }}>
              {" "}
              {user.status}
            </div>
          ) : (
            <div className="label">{user.status}</div>
          )}
          {/* <div className="value">{t("active")}</div> */}
        </Box>
        {/* account delete */}
        <Box sx={DetailUser}>
          <div className="label">{t("accountDeletion")}</div>
          <div className="value border-value">{t("pleaseDeleteMyAccount")}</div>
        </Box>
        {/* kyc Status */}
        <Box sx={DetailUser}>
          <div className="label">{t("kycStatus")}:</div>
          <div className="value">{user.kyc}</div>
        </Box>
      </Stack>
      {editMode ? (
        <Box sx={{ gap: "20px", display: "flex", mb: "20px" }}>
          <Button
            sx={{ marginTop: "40px", float: "left" }}
            variant="contained"
            color="primary"
            className="round-button"
            onClick={saveInfo}
          >
            {t("save")}
          </Button>
          <Button
            sx={{ marginTop: "40px", float: "left" }}
            variant="contained"
            color="error"
            className="round-button"
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
        </Box>
      ) : (
        <Button
          sx={{ marginTop: "40px", float: "left" }}
          variant="contained"
          color="primary"
          className="round-button"
          onClick={toggleEditMode}
        >
          {t("edit")}
        </Button>
      )}
      {user && (user.kyc === "pending" || user.kyc === "rejected") && (
        <Button
          sx={{ marginTop: "40px", float: "left", marginLeft: "20px" }}
          variant="contained"
          color="primary"
          className="round-button"
          onClick={handleKyc}
        >
          {t("verify")}
        </Button>
      )}
    </Box>
  );
}

export default UserProfile;

export const IdRegEx: any = {
  GH: {
    DRIVERS_LICENSE: `^[a-zA-Z0-9]{6,10}$`,
    PASSPORT: `^(?i)G[a-zA-Z0-9]{7,9}$`,
    SSNIT: `^[a-zA-Z]{1}[a-zA-Z0-9]{12,14}$`,
    VOTER_ID: `^[0-9]{10,12}$`,
    NEW_VOTER_ID: `^[0-9]{10,12}$`,
  },
  KE: {
    ALIEN_CARD: `^[0-9]{6,9}$`,
    NATIONAL_ID: `^[0-9]{1,9}$`,
    PASSPORT: `^[A-Z0-9]{7,9}$`,
  },
  NG: {
    BVN: `^[0-9]{11}$`,
    NIN: `^[0-9]{11}$`,
    NIN_SLIP: `^[0-9]{11}$`,
    DRIVERS_LICENSE: `^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$`,
    PHONE_NUMBER: `^[0-9]{11}$`,
    VOTER_ID: `^[a-zA-Z0-9 ]{9,20}$`,
  },
  ZA: {
    NATIONAL_ID: `^[0-9]{13}$`,
    NATIONAL_ID_NO_PHOTO: `^[0-9]{13}$`,
  },
  UG: {
    NATIONAL_ID_NO_PHOTO: `^[A-Z0-9]{14}$`,
  },
};

export const countryDropdownData = [
  { name: "Ghana", id: "GH" },
  { name: "Kenya", id: "KE" },
  { name: "Nigeria", id: "NG" },
  { name: "South Africa", id: "ZA" },
  { name: "Uganda", id: "UG" },
];

export const idTypes = {
  GH: ["DRIVERS_LICENSE", "PASSPORT", "SSNIT", "VOTER_ID", "NEW_VOTER_ID"],
  KE: ["ALIEN_CARD", "NATIONAL_ID", "PASSPORT"],
  NG: ["BVN", "NIN", "NIN_SLIP", "DRIVERS_LICENSE", "PHONE_NUMBER", "VOTER_ID"],
  ZA: ["NATIONAL_ID", "NATIONAL_ID_NO_PHOTO"],
  UG: ["NATIONAL_ID_NO_PHOTO"],
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

interface notificationState {
  notifSocket: any | Socket;
}

const initialState: notificationState = {
  notifSocket: undefined,
};

export const socketSlice = createSlice({
  name: "SocketSlice",
  initialState,
  reducers: {
    setNotifSocket: (state, action: PayloadAction<Socket | undefined>) => {
      state.notifSocket = action.payload;
    },
  },
});

export const { setNotifSocket } = socketSlice.actions;

export default socketSlice.reducer;

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setRewardType } from "../../../store/slices/CreateCampaignSlice";
import Preview from "../preview/preview";
// import CreateCampaign from "./CreateCampaign";
import CampaignMainPage from "./campaignMainPage/CampaignMainPage";

function CreateCampaignWrapper() {
  const previewCampaign = useAppSelector(
    (state) => state.campaignPreviewReducer.previewEnabled
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      const nftData = {
        rewardNftId: "",
        rewardType: "",
      };
      dispatch(setRewardType(nftData));
    };
  }, []);
  return <CampaignMainPage />;
}

export default CreateCampaignWrapper;

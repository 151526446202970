import { Box, Grid, Typography, Container, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridValueGetterParams } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/common/tables/CustomNoRowsOverlay";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getWhaleTank } from "../../../requests/authRequest";
import { transactionContainer, walletAdress } from "./style";
import FileCopy from "@mui/icons-material/FileCopy";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch } from "../../../store";
import {
  DataGridWrap,
  footer,
} from "../../../components/user/grantManager/style";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function WhaleTank() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [donations, setDonations] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 10 },
    {
      field: "name",
      headerName: "Full name",
      minWidth: 200,
      sortable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.donatedBy.name,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      sortable: false,
      minWidth: 50,
      flex: 1,
    },
    {
      field: "totalDonations",
      headerName: "Donations Count",
      sortable: false,
      minWidth: 50,
      flex: 1,
      valueFormatter: (params: any) => {
        return !params.value ? 0 : params.value;
      },
    },
    {
      field: "email",
      headerName: "Email Id",
      minWidth: 200,
      sortable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.donatedBy.email,
    },
    {
      field: "userId",
      headerName: "User Id",
      minWidth: 200,
      sortable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.donatedBy.userId,
    },
    {
      field: "walletAddress",
      headerName: "Wallet Address",
      sortable: false,
      minWidth: 300,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row._id,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = {
          page: page,
          limit: 8,
        };
        const res = await getWhaleTank(data);
        setLoading(false);

        if (res.data.totalPages === page) {
          setDisableNext(true);
        } else {
          setDisableNext(false);
        }

        if (page === 1) {
          setDisableBack(true);
        } else {
          setDisableBack(false);
        }

        if (page === 1 && endPage === 1) {
          setDisableNext(true);
          setDisableBack(true);
        }

        if (res.data.totalPages > page) {
          setDisableNext(false);
        }
        setEndPage(res.data.totalPages);

        if (res && res.success) {
          console.log(res, "res");
          const listedDonations = res.data.data.sort(
            (a: any, b: any) => b.totalAmount - a.totalAmount
          );
          const sortedDonations = listedDonations.map(
            (item: any, index: any) => {
              return {
                id: index + 1 + (page - 1) * 8,
                ...item,
              };
            }
          );
          console.log(sortedDonations, "sortedDOnations");

          setDonations(sortedDonations);
        } else {
          setDonations([]);
        }
      } catch (error: any) {
        let returnRes = {
          success: false,
          data: error.res
            ? error.res.data
            : { message: "Error: Network Error" },
        };
        return returnRes;
      }
    };

    fetchData();
  }, [page]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  const handleCopyAddress = (address: any) => {
    navigator.clipboard.writeText(address);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    if (endPage && endPage > page) {
      setDisableNext(false);
    }
  }, [endPage]);
  console.log(donations.length, "length");
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          {donations.length > 0 && (
            <Grid container spacing={2}>
              {donations.map((item, index) => {
                return (
                  index < 3 && (
                    <Grid item xs={4} key={index}>
                      <Box sx={transactionContainer}>
                        <Typography
                          variant="h4"
                          sx={{ justifyContent: "center", color: "#61f359" }}
                        >
                          Rank {index + 1}
                        </Typography>
                        <Box sx={walletAdress}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: 900,
                              color: "#61f359",
                            }}
                            mb={1}
                          >
                            Donor Name
                          </Typography>
                          <Typography sx={{ color: "#000" }} mb={1}>
                            {item.donatedBy.name}
                          </Typography>
                        </Box>
                        <Box sx={walletAdress}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: 900,
                              color: "#61f359",
                            }}
                            mb={1}
                          >
                            Wallet Address
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000",
                              fontSize: "11.7px",
                              wordBreak: "break-word",
                            }}
                            mb={1}
                          >
                            {item._id}
                          </Typography>

                          <Typography
                            sx={{
                              color: "#4c4b4b",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Copy Address
                            <IconButton
                              aria-label="Copy Address"
                              onClick={() => handleCopyAddress(item._id)}
                              size="small"
                            >
                              <FileCopy style={{ fontSize: "18px" }} />
                            </IconButton>
                          </Typography>
                        </Box>
                        <Box sx={walletAdress}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: 900,
                              color: "#61f359",
                            }}
                            mb={1}
                          >
                            Total Amount Donated
                          </Typography>
                          <Typography sx={{ color: "#000" }} mb={1}>
                            {item.totalAmount}
                          </Typography>
                        </Box>
                        <Box sx={walletAdress}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: 900,
                              color: "#61f359",
                            }}
                            mb={1}
                          >
                            Donations Count
                          </Typography>
                          <Typography sx={{ color: "#000" }} mb={1}>
                            {item.totalDonations}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  )
                );
              })}
            </Grid>
          )}
        </Box>

        {donations.length === 0 && (
          <Typography
            variant="h4"
            sx={{ justifyContent: "center", color: "#61f359" }}
          >
            No Donation Found
          </Typography>
        )}
        {donations.length > 0 && (
          <Box sx={{ marginTop: "50px" }}>
            <Typography variant="h4" align="center">
              {t("listOfAlldonors")}
            </Typography>
            <Box
              mb={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={DataGridWrap}>
                <DataGrid
                  rows={donations}
                  columns={columns}
                  checkboxSelection={false}
                  disableSelectionOnClick={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </Box>

            <Box sx={footer}>
              <p>
                {page} - {endPage}
              </p>
              <IconButton onClick={handleBack} disabled={disableBack}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton onClick={handleNext} disabled={disableNext}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
}

export default WhaleTank;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  MenuItem,
  FormLabel,
  FormHelperText,
  Typography,
  IconButton,
  Tooltip,
  Select,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { InputFiles } from "typescript";
import UploadImage from "../user/createCampaign/UploadImage";
import UploadVideo from "../user/createCampaign/UploadVideo";
import {
  createGrant,
  uploadCampaignImage,
  uploadCampaignVideo,
  updateGrant,
} from "../../requests/authRequest";
import { PeraOnramp } from "@perawallet/onramp";
import UploadPdf from "../user/createCampaign/UploadPdf";
import {
  setToaster,
  toggleAppLoading,
  setAppLoadingText,
} from "../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import countryList from "../user/createCampaign/countrylist";
import { CampaigngForm } from "../user/createCampaign/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { fileTypes, userRole } from "../../constants/common-enum";
import {
  createGrantBlockchain,
  payMatchFund,
  updateGrantBlockchain,
} from "../../blockchain";
import algosdk from "algosdk";
import { client, peraWallet } from "../../wallets/algorand";
import { useWallet } from "@txnlab/use-wallet";
import algo from "../../assets/images/algo.png";
import moment from "moment";
import fileIcon from "../../assets/images/fileIcon.png";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import Wallets from "../../wallets";

const Step2 = ({ goalIds, selectedGrant }: any) => {
  const { activeAccount, providers, signTransactions } = useWallet();

  const peraOnramp = new PeraOnramp();
  const { t } = useTranslation();
  const [values, setValues] = useState<any>({});
  let tomorrow = new Date().setDate(new Date().getDate() + 1);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const endDateRef = useRef(null);
  const paymentRef = useRef(null);
  const [endDate, setEndDate] = React.useState<Date | string>(
    new Date(tomorrow)
  );
  const [paymentList, setPaymentList] = useState(["ALGO", "USDC"]);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState("0");
  // const handlePaymentType = (event: any) => {
  //   const selectedIndex = event.target.value;
  //   setSelectedPaymentIndex(selectedIndex.toString()); // Convert index to string
  //   // You can update the backend with the selected payment type using selectedIndex
  // };
  const [paymentTypeBlockchain, setPaymentTypeBlockchain] =
    useState<string>("ALGO");

  const handlePaymentType = (vl: string) => setPaymentTypeBlockchain(vl);

  const [image, setImage] = useState<any | undefined>(
    selectedGrant?.images || []
  );
  const [imageName, setImageName] = useState<any>(
    selectedGrant ? selectedGrant.images : []
  );
  const [video, setVideo] = useState<InputFiles | undefined>();
  const [videoName, setVideoName] = useState<string>("");
  const [videoSelected, setVideoSelected] = useState<boolean>(false);
  const [imageErrorMsg, setImageErrorMsg] = useState<boolean>(false);
  const [selectedImageId, setSelectedImageId] = useState<number>(0);
  const [imageSelected, setImageSelected] = useState<boolean>(false);
  const imageRef = useRef<HTMLElement>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [pdf, setPdf] = useState<any | undefined>();
  const [paymentType, setPaymentType] = useState<string>("");
  const dispatch = useAppDispatch();
  const userslice = useAppSelector((state) => state.userReducer);
  const accountAddress =
    "API26TUUCSJT7U5F2FJTEPYP5YVYHMNLM26NLSODIYKQW5S7QIDFTOHIMY";

  const validationSchema = () => {
    return Yup.object().shape({
      title: Yup.string().trim().required("* Please enter grant title."),
      shortDescription: Yup.string()
        .trim()
        .max(10000, "* Maximum 10000 characters are allowed.")
        .required("* Please enter short description."),
      longDescription: Yup.string()
        .trim()
        .required("* Please enter long description."),
      preferredLocation: Yup.string()
        .trim()
        .required("* Please enter preferred location."),
      totalBudget: Yup.number()
        .typeError("* Please enter Total Budget")
        .min(
          Yup.ref("maximumGrant"),
          "* Total budget should be greater than or equal to Maximum grant."
        )
        .required("* Please enter Total Budget"),
      minimumGrant: Yup.number()
        .typeError("* Please enter minimum grants")
        .min(0, "* Please enter minimum grants.")
        .required("* Please enter minimum grants."),
      totalGrants: Yup.number()
        .typeError("* Please enter Total grants.")
        .min(1, "* Please enter minimum grants.")
        .required("* Please enter minimum grants."),
      maximumGrant: Yup.number()
        .typeError("* Please enter maximum grants")
        .min(1, "* Please enter maximum grants.")
        .moreThan(
          Yup.ref("minimumGrant"),
          "Maximum grant should be greater than minimum grant"
        )
        .required("* Please enter maximum grants."),
      duration: Yup.string().required("* Please select duration."),

      target: Yup.string().required("* Please select target."),
    });
  };
  const validate = (getValidationSchema: any) => {
    return (values: any) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError: any) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors: any, error: any) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const getInitialValues = () => {
    const initialValues = {
      title: selectedGrant?.title || "",
      duration: selectedGrant?.duration || "",
      paymentTypeBlockchain: selectedGrant?.paymentType || "",
      shortDescription: selectedGrant?.shortDescription || "",
      longDescription: selectedGrant?.longDescription || "",
      preferredLocation: selectedGrant?.preferredLocation || "",
      minimumGrant: selectedGrant?.minimumGrant || 0,
      maximumGrant: selectedGrant?.maximumGrant || 0,
      totalGrants: selectedGrant?.totalGrants || 0,
      totalBudget: selectedGrant?.totalBudget || 0,
      target: selectedGrant?.target || "",
      endDate: selectedGrant?.endDate || "",
      youTubeLink: selectedGrant?.youTubeLink || "",
    };
    return initialValues;
  };

  const handleImageUpload = async () => {
    if (image?.length > 0) {
      const res: any = await uploadCampaignImage(image);
      if (res.success) {
        return res?.data;
      } else {
        return false;
      }
    }
  };

  const handleVideoUpload = async () => {
    if (videoSelected) {
      const res = await uploadCampaignVideo(video);
      if (res.success) {
        return res.data.fileId;
      } else {
        return false;
      }
    }
  };

  const handleDocumentUpload = async () => {
    if (pdf) {
      const res: any = await uploadCampaignImage([pdf]);
      if (res.success) {
        return res?.data;
      } else {
        return false;
      }
    }
  };

  const handleCreateGrantBlockchain = async (values: any) => {
    const grantInfo = {
      wallet_address: activeAccount?.address,
      user_app_id: parseInt(userslice.userAppId),
      title: values.title,
      paymentType: paymentTypeBlockchain, //blockchain data
      duration: 604800, //moment.utc(values.duration).startOf("day").unix(),
      min_grant: values.minimumGrant,
      max_grant: values.maximumGrant,
      total_grants: values.totalGrants,
      total_budget: parseInt(values.totalBudget),
      grant_end_date: moment(new Date(endDate)).startOf("day").unix(),
      ESG: goalIds.length > 0 ? 1 : 0,
    };
    const unsignedTxn = await createGrantBlockchain(grantInfo);
    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));
      const signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      const transactionId =
        paymentTypeBlockchain === "ALGO"
          ? transactionArr[1].txn.txID()
          : transactionArr[3].txn.txID();
      let grantDataBlockchain = await algosdk.waitForConfirmation(
        client,
        transactionId,
        //algo 1
        // transactionArr[3].txn.txID(),
        4
      );

      // dispatch(setAppLoadingText(""));
      const grantAppId =
        grantDataBlockchain["inner-txns"][0]["application-index"];

      if (grantAppId) {
        return {
          success: true,
          grantAppId,
        };
      } else {
        return {
          success: true,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleUpdateGrantBlockchain = async (values: any) => {
    const grantInfo = {
      wallet_address: activeAccount?.address,
      user_app_id: parseInt(userslice.userAppId),
      title: values.title,
      paymentType: paymentTypeBlockchain, //update blockchain
      duration: 604800, //moment.utc(values.duration).startOf("day").unix(),
      min_grant: values.minimumGrant,
      max_grant: values.maximumGrant,
      total_grants: values.totalGrants,
      total_budget: parseInt(values.totalBudget),
      grant_end_date: moment(new Date(endDate)).startOf("day").unix(),
      grant_app_id: values.grantAppId,
    };

    const unsignedTxn = await updateGrantBlockchain(grantInfo);
    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));
      const signed = [];
      signed.push(signedTxn[0]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      // dispatch(setAppLoadingText(""));
      return {
        success: true,
        grantAppId: values.grantAppId,
      };
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleSubmit = async (values: any) => {
    setValues(values);
    if (paymentType === "algo") {
      setShowWalletButtons(true);
    } else {
      peraOnramp
        .addFunds({
          accountAddress, // A valid Algorand account address
        })
        .then((abcd) => {
          console.log(accountAddress, abcd, "good");
          // Successfully added funds
        })
        .catch(() => {
          console.log("error");
          // Failed to add funds
        });
    }
  };
  const handleSubmitOption = async () => {
    if (!selectedGrant && imageName?.length === 0) {
      setImageErrorMsg(true);
      return;
    }
    // dispatch(toggleAppLoading(true));
    const blockchainRes = selectedGrant
      ? await handleUpdateGrantBlockchain({ ...values, ...selectedGrant })
      : await handleCreateGrantBlockchain(values);

    if (!blockchainRes.success) {
      return;
    }
    dispatch(toggleAppLoading(true));
    let images = [];
    let video = "";
    let document = "";
    let grantType = "";
    if (selectedGrant) {
      images = selectedGrant?.images;
      video = selectedGrant?.video;
      document = selectedGrant?.document;
      grantType = selectedGrant?.grantType;
    } else {
      images = await handleImageUpload();
      video = await handleVideoUpload();
      document = await handleDocumentUpload();
      grantType = location.pathname.includes("custom") ? "custom" : "standard";
    }
    let payload = {
      ...values,
      grantType: grantType,
      endDate,
      paymentTypeBlockchain,
      images: !images ? [] : images,
      video: !video ? "" : video,
      document: !document ? "" : document[0],
      grantAppId: blockchainRes.grantAppId,
    };

    if (grantType.includes("standard")) {
      payload.goals = goalIds;
    }
    if (selectedGrant) {
      payload.grantId = selectedGrant.grantId;
    }
    payload.organisationOwner =
      userslice.role === userRole.GRANT_CREATOR
        ? userslice.userId
        : userslice.organisationOwner;

    const res = selectedGrant
      ? await updateGrant(payload)
      : await createGrant(payload);
    if (res.success) {
      const toastPaylaod = {
        text: t("grantCreatedSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
    dispatch(toggleAppLoading(false));
    dispatch(setAppLoadingText(t("")));
  };

  // const handleOpenWyreCheckout = async () => {
  // 	dispatch(toggleAppLoading(true));
  // 	const referrerAccountData = await submitWyreAuthToken();
  // 	if (referrerAccountData.success) {
  // 		let accountId = referrerAccountData.data.authenticatedAs.split(":")[1];
  // 		const reservationData = await createReservation(accountId);
  // 		if (reservationData.success) {
  // 			// this lines opens the session in a new tab uncomment it if you want that functionality
  // 			// window.open(reservationData.data.url, "_blank");

  // 			if (window && window.Wyre) {
  // 				var widget = new window.Wyre({
  // 					env: "test",
  // 					reservation: reservationData.data.reservation,
  // 					/*A reservation id is mandatory. In order for the widget to open properly you must use a new, unexpired reservation id. Reservation ids are only valid for 1 hour. A new reservation must also be made if a transaction fails.*/
  // 					operation: {
  // 						type: "debitcard-hosted-dialog",
  // 					},
  // 				});

  // 				widget.on("paymentSuccess", async (e: any) => {
  // 					dispatch(toggleAppLoading(true));
  // 					const res = await fetchOrderData(e.data.data.orderId);
  // 					if (res.success) {
  // 						return;
  // 					}
  // 					// dispatch(toggleAppLoading(false));
  // 					// refreshData();
  // 				});

  // 				widget.open();
  // 			}
  // 		}
  // 	}
  // 	// dispatch(toggleAppLoading(false));
  // };

  useEffect(() => {
    if (selectedGrant?.endDate) {
      setEndDate(new Date(selectedGrant.endDate));
    }
  }, [selectedGrant?.endDate]);
  useEffect(() => {
    if (selectedGrant?.paymentTypeBlockchain) {
      setPaymentTypeBlockchain(selectedGrant.paymentTypeBlockchain);
    }
  }, [selectedGrant?.paymentTypeBlockchain]);

  // const formik = useFormik({
  // 	initialValues: getInitialValues(),
  // 	validationSchema: validationSchema,
  // 	onSubmit: handleSubmit,
  // 	// enableReinitialize: true,
  // });

  return (
    <div>
      <Box sx={CampaigngForm}>
        <Typography align="center" variant="h4" mb={3} mt={3}>
          {location.pathname.includes("custom")
            ? t("createCustomGrantProgram")
            : t("additionalInformation")}
        </Typography>
        <Formik
          initialValues={getInitialValues()}
          validate={validate(validationSchema)}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          render={({ errors, handleChange, handleSubmit, values, touched }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} sx={{ textAlign: "left" }}>
                  <Grid item lg={4}>
                    <FormLabel component="legend">{t("grantTitle")}</FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      type={"text"}
                      placeholder={t("enterGrantTitle") || ""}
                      variant="outlined"
                      name="title"
                      value={values.title}
                      onChange={handleChange("title")}
                      error={errors.title && touched.title ? true : false}
                      helperText={
                        errors.title && touched.title ? errors.title : ""
                      }
                    />
                    <FormLabel component="legend">
                      {t("shortDescription")}
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      multiline
                      minRows={4}
                      type={"text"}
                      placeholder={t("enterShortDescription") || ""}
                      variant="outlined"
                      name="shortDescription"
                      value={values.shortDescription}
                      onChange={handleChange("shortDescription")}
                      error={
                        errors.shortDescription && touched.shortDescription
                          ? true
                          : false
                      }
                      helperText={
                        errors.shortDescription && touched.shortDescription
                          ? errors.shortDescription
                          : ""
                      }
                    />
                    <FormLabel component="legend">
                      {t("longDescription")}
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      multiline
                      minRows={4}
                      type={"text"}
                      placeholder={t("enterDescription") || ""}
                      variant="outlined"
                      name="longDescription"
                      value={values.longDescription}
                      onChange={handleChange("longDescription")}
                      error={
                        errors.longDescription && touched.longDescription
                          ? true
                          : false
                      }
                      helperText={
                        errors.longDescription && touched.longDescription
                          ? errors.longDescription
                          : ""
                      }
                    />

                    <FormLabel component="legend">{t("image")}</FormLabel>
                    {selectedGrant ? (
                      <Box>
                        <Box
                          sx={{
                            maxWidth: "300px",
                            maxHeight: "300px",
                            width: "100%",
                            height: "300px",
                            backgroundImage: `url(${
                              process.env.REACT_APP_URL
                            }/api/file/image/${
                              selectedGrant?.images &&
                              selectedGrant?.images[selectedImageId]
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundColor: "#fff",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: "300px",
                            width: "100%",
                          }}
                        >
                          {selectedGrant?.images?.length > 0 &&
                            selectedGrant?.images?.map(
                              (item: any, index: any) => (
                                <IconButton
                                  key={index}
                                  onClick={() => setSelectedImageId(index)}
                                >
                                  <Box
                                    sx={{
                                      width: "10px",
                                      height: "10px",
                                      borderRadius: "5px",
                                      backgroundColor:
                                        index === selectedImageId
                                          ? "#000"
                                          : "gray",
                                      marginX: "2.5px",
                                    }}
                                  />
                                </IconButton>
                              )
                            )}
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <UploadImage
                          image={image}
                          setImage={(vl: any | undefined) => setImage(vl)}
                          imageName={imageName}
                          imageSelected={imageSelected}
                          setImageName={(vl: any | undefined) =>
                            setImageName(vl)
                          }
                          setImageSelected={setImageSelected}
                          imageRef={imageRef}
                          imageId={selectedImageId}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: "300px",
                            width: "100%",
                          }}
                        >
                          {imageName?.length > 1 &&
                            imageName?.map((item: any, index: any) => (
                              <IconButton
                                key={index}
                                onClick={() => setSelectedImageId(index)}
                              >
                                <Box
                                  sx={{
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "5px",
                                    backgroundColor:
                                      index === selectedImageId
                                        ? "#000"
                                        : "gray",
                                    marginX: "2.5px",
                                  }}
                                />
                              </IconButton>
                            ))}
                        </Box>
                        {imageErrorMsg && imageName.length === 0 && (
                          <FormHelperText
                            sx={{ marginBottom: 3 }}
                            className="css-l8pll-MuiFormHelperText-root Mui-error"
                          >
                            * {t("pleaseSelectAnImage")}
                          </FormHelperText>
                        )}
                        {imageName.length > 5 && (
                          <FormHelperText
                            sx={{ marginBottom: 3 }}
                            className="css-l8pll-MuiFormHelperText-root Mui-error"
                          >
                            {t("youCanNotUploadMoreThanFiveImages")}
                          </FormHelperText>
                        )}
                      </>
                    )}

                    <FormLabel component="legend">
                      {t("video")} ({t("optional")})
                    </FormLabel>
                    {selectedGrant ? (
                      <Box
                        sx={{
                          maxWidth: "300px",
                          maxHeight: "300px",
                          width: "100%",
                          height: "300px",

                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundColor: "#fff",
                        }}
                        style={{ position: "relative" }}
                      >
                        <video
                          style={
                            selectedGrant?.video ? {} : { display: "none" }
                          }
                          src={`${process.env.REACT_APP_URL}/api/file/video/${selectedGrant?.video}`}
                          controls
                          width={"100%"}
                          height={"100%"}
                          autoPlay={false}
                        />
                      </Box>
                    ) : (
                      <UploadVideo
                        setVideo={(vl) => setVideo(vl)}
                        video={video}
                        setVideoName={(vl: any | undefined) => setVideoName(vl)}
                        setVideoSelected={(vl) => setVideoSelected(vl)}
                        videoName={videoName}
                        videoSelected={videoSelected}
                        videoRef={videoRef}
                        inputRef={inputRef}
                      />
                    )}
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="legend">
                      {t("duration")} ({t("inMonths")})
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      select
                      placeholder={t("selectDuration") || ""}
                      variant="outlined"
                      name="duration"
                      value={values.duration}
                      onChange={handleChange("duration")}
                      error={errors.duration && touched.duration ? true : false}
                      helperText={
                        errors.duration && touched.duration
                          ? errors.duration
                          : ""
                      }
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
                        return (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <FormLabel component="legend">{t("location")}</FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      select
                      fullWidth
                      hiddenLabel
                      type={"text"}
                      placeholder={t("selectPreferredLocation") || ""}
                      variant="outlined"
                      name="preferredLocation"
                      value={values.preferredLocation}
                      onChange={handleChange("preferredLocation")}
                      error={
                        errors.preferredLocation && touched.preferredLocation
                          ? true
                          : false
                      }
                      helperText={
                        errors.preferredLocation && touched.preferredLocation
                          ? errors.preferredLocation
                          : ""
                      }
                    >
                      {countryList.map((country) => {
                        return (
                          <MenuItem
                            value={country.toLocaleLowerCase()}
                            key={country}
                          >
                            {country}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <FormLabel component="legend">
                      {t("totalBudget")}(
                      {paymentTypeBlockchain === "ALGO" && (
                        <img
                          style={{ width: "16px", marginBottom: "-3px" }}
                          src={algo}
                          alt="ALGO"
                        />
                      )}
                      {paymentTypeBlockchain === "USDC" && (
                        <MonetizationOnIcon
                          style={{
                            marginBottom: "-4px",
                            fontSize: "20px",
                            color: "black",
                          }}
                        />
                      )}
                      ){" "}
                      <Tooltip
                        title="Total budget of grant"
                        placement="top-start"
                        sx={{ marginBottom: "-7px" }}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      type={"number"}
                      placeholder={t("enterTotalBudget") || ""}
                      variant="outlined"
                      name="totalBudget"
                      value={values.totalBudget}
                      onChange={handleChange("totalBudget")}
                      error={
                        errors.totalBudget && touched.totalBudget ? true : false
                      }
                      helperText={
                        errors.totalBudget && touched.totalBudget
                          ? errors.totalBudget
                          : ""
                      }
                    />
                    <FormLabel component="legend">
                      {t("mainTargetDemographicForImpact")}
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      select
                      placeholder={t("selectTarget") || ""}
                      variant="outlined"
                      name="target"
                      value={values.target}
                      onChange={handleChange("target")}
                      error={errors.target && touched.target ? true : false}
                      helperText={
                        errors.target && touched.target ? errors.target : ""
                      }
                    >
                      {countryList.map((country) => {
                        return (
                          <MenuItem
                            value={country.toLocaleLowerCase()}
                            key={country}
                          >
                            {country}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <FormLabel component="legend">
                      {t("youtubelink")} ({t("optional")})
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      type={"text"}
                      placeholder={t("youtubelink") || ""}
                      variant="outlined"
                      name="youTubeLink"
                      value={values.youTubeLink}
                      onChange={handleChange("youTubeLink")}
                      error={
                        errors.youTubeLink && touched.youTubeLink ? true : false
                      }
                      helperText={
                        errors.youTubeLink && touched.youTubeLink
                          ? errors.youTubeLink
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="legend">
                      {t("minimumGrant")}(
                      {paymentTypeBlockchain === "ALGO" && (
                        <img
                          style={{ width: "16px", marginBottom: "-3px" }}
                          src={algo}
                          alt="ALGO"
                        />
                      )}
                      {paymentTypeBlockchain === "USDC" && (
                        <MonetizationOnIcon
                          style={{
                            marginBottom: "-4px",
                            fontSize: "20px",
                            color: "black",
                          }}
                        />
                      )}
                      ){" "}
                      <Tooltip
                        title="Minimum amount which applicant can apply for"
                        placement="top-start"
                        sx={{ marginBottom: "-7px" }}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      type={"number"}
                      placeholder={t("enterMinimumGrant") || ""}
                      variant="outlined"
                      name="minimumGrant"
                      value={values.minimumGrant}
                      onChange={handleChange("minimumGrant")}
                      error={
                        errors.minimumGrant && touched.minimumGrant
                          ? true
                          : false
                      }
                      helperText={
                        errors.minimumGrant && touched.minimumGrant
                          ? errors.minimumGrant
                          : ""
                      }
                    />
                    <FormLabel component="legend">
                      {t("maximumGrant")}(
                      {paymentTypeBlockchain === "ALGO" && (
                        <img
                          style={{ width: "16px", marginBottom: "-3px" }}
                          src={algo}
                          alt="ALGO"
                        />
                      )}
                      {paymentTypeBlockchain === "USDC" && (
                        <MonetizationOnIcon
                          style={{
                            marginBottom: "-4px",
                            fontSize: "20px",
                            color: "black",
                          }}
                        />
                      )}
                      ){" "}
                      <Tooltip
                        title="Maximum amount which applicant can apply for"
                        placement="top-start"
                        sx={{ marginBottom: "-7px" }}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      type={"number"}
                      placeholder={t("enterMaximumGrant") || ""}
                      variant="outlined"
                      name="maximumGrant"
                      value={values.maximumGrant}
                      onChange={handleChange("maximumGrant")}
                      error={
                        errors.maximumGrant && touched.maximumGrant
                          ? true
                          : false
                      }
                      helperText={
                        errors.maximumGrant && touched.maximumGrant
                          ? errors.maximumGrant
                          : ""
                      }
                    />
                    <FormLabel component="legend">
                      {t("totalGrants")}{" "}
                      <Tooltip
                        title="Total number of applications you can accept"
                        placement="top-start"
                        sx={{ marginBottom: "-7px" }}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </FormLabel>
                    <TextField
                      sx={{ marginBottom: 3 }}
                      fullWidth
                      hiddenLabel
                      type={"number"}
                      placeholder={t("enterTotalGrants") || ""}
                      variant="outlined"
                      name="totalGrants"
                      value={values.totalGrants}
                      onChange={handleChange("totalGrants")}
                      error={
                        errors.totalGrants && touched.totalGrants ? true : false
                      }
                      helperText={
                        errors.totalGrants && touched.totalGrants
                          ? errors.totalGrants
                          : ""
                      }
                    />

                    <Box sx={{ marginBottom: 3 }}>
                      <FormLabel component="legend">
                        {t("grantEndDate")}
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DatePicker
                            ref={endDateRef}
                            inputFormat="YYYY-MM-DD"
                            value={endDate}
                            onChange={(vl: any) => setEndDate(vl)}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                id={"campaignEndingDate"}
                              />
                            )}
                            minDate={moment().add(1, "d")}
                          />
                        </Stack>
                      </LocalizationProvider>
                      {!endDate && (
                        <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                          * {t("pleaseSelectGrantEndDate")}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box sx={{ marginBottom: 3 }}>
                      <FormLabel component="legend">
                        {t("paymentType")}(
                        {paymentTypeBlockchain === "ALGO" && (
                          <img
                            style={{ width: "16px", marginBottom: "-3px" }}
                            src={algo}
                            alt="ALGO"
                          />
                        )}
                        {paymentTypeBlockchain === "USDC" && (
                          <MonetizationOnIcon
                            style={{
                              marginBottom: "-4px",
                              fontSize: "20px",
                              color: "black",
                            }}
                          />
                        )}
                        )
                      </FormLabel>

                      <Select
                        disabled={
                          selectedGrant?.paymentTypeBlockchain ? true : false
                        }
                        ref={paymentRef}
                        fullWidth
                        value={paymentTypeBlockchain}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          handlePaymentType(e.target.value);
                        }}
                      >
                        {paymentList.length > 0 &&
                          paymentList.map((paymentItem, index) => {
                            return (
                              <MenuItem key={index} value={paymentItem}>
                                {paymentItem}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {!paymentTypeBlockchain && (
                        <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                          {t("pleaseSelectPaymentType")}
                        </FormHelperText>
                      )}
                    </Box>

                    <FormLabel component="legend">
                      {!selectedGrant?.document ? (
                        <p>
                          {t("uploadAnySupportingDocument")} (
                          {`${fileTypes.join(", ")}`})
                        </p>
                      ) : (
                        <p>{t("uploadedDocument")}</p>
                      )}
                    </FormLabel>
                    {selectedGrant ? (
                      <Typography variant="h6">
                        {selectedGrant?.document ? (
                          <a
                            href={`${process.env.REACT_APP_URL}/api/file/${selectedGrant.document}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <img
                              style={{
                                width: "16px",
                                marginBottom: "-3px",
                              }}
                              alt="file"
                              src={fileIcon}
                            ></img>
                            <span style={{ marginLeft: "8px" }}>View File</span>
                          </a>
                        ) : (
                          <>
                            <p>{t("noFileUploaded")}</p>
                            <UploadPdf
                              key="uploadingPdf"
                              pdf={pdf}
                              setPdf={setPdf}
                              optional={true}
                              disabled={Boolean(selectedGrant)}
                            />
                          </>
                        )}
                      </Typography>
                    ) : (
                      <UploadPdf
                        key="uploadingPdf"
                        pdf={pdf}
                        setPdf={setPdf}
                        optional={true}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid container justifyContent={"flex-start"} sx={{ mt: 5 }}>
                  <Button
                    style={{
                      marginLeft: 20,
                    }}
                    color="error"
                    variant="contained"
                    className="round-button"
                    onClick={() => navigate(-1)}
                  >
                    {t("back")}
                  </Button>
                  <>
                    <Button
                      style={{
                        marginLeft: 20,
                      }}
                      color="primary"
                      variant="contained"
                      className="round-button"
                      type="submit"
                      onClick={() => {
                        setPaymentType("algo");
                        setShowWalletButtons(true);
                      }}
                    >
                      {t("algorandWallet")}
                    </Button>

                    {showWalletButtons && (
                      <Wallets
                        open={showWalletButtons}
                        handleClose={() => setShowWalletButtons(false)}
                        handleSubmit={handleSubmitOption}
                      />
                    )}
                  </>
                  <Button
                    style={{
                      marginLeft: 20,
                    }}
                    color="primary"
                    variant="contained"
                    className="round-button"
                    type="submit"
                    onClick={() => {
                      setPaymentType("debit");
                      // handleSubmit();
                    }}
                  >
                    {t("debitOrCreditCard")}
                  </Button>
                </Grid>
              </form>
            );
          }}
        />
      </Box>
    </div>
  );
};

export default Step2;

import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Button,
  Modal,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getAllCampaign,
  getCampaignByCategory,
  getCampaignCategories,
} from "../../../requests/simpleRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setToaster } from "../../../store/slices/LoadinAndNotifSlice";
import MatchFundFilter from "../../user/matchFunds/MatchFundFilter";
import CampaignCard from "./CampaignCard";
import FilterModal from "./filterModal/FilterModal";
import { CategoryTab, HeadingBox, PreviewGrid } from "./style";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import GrantCard from "../grants/GrantCard";
import { t } from "i18next";

interface category {
  title: string;
  slug: string;
  categoryId: string;
}

export interface campaign {
  campaignId: string;
  category: string;
  currency: string;
  description: string;
  endDate: string | Date;
  fundingCategory: string;
  goalAmount: number;
  images: Array<string>;
  rewardBased: boolean;
  status: string;
  title: string;
  isEsgEnabled: boolean;
}

let newCategories: { categoryId: number; slug: string; title: string }[] = [
  {
    categoryId: 1,
    slug: "All Campaigns",
    title: t("allCampaigns"),
  },
  {
    categoryId: 2,
    slug: "ESG Campaigns",
    title: t("esgCampaigns"),
  },
  {
    categoryId: 3,
    slug: "Non-ESG Campaigns",
    title: t("nonEsgCampaigns"),
  },
];
let filterData: any = {
  limit: 0,
  page: 0,
  campaign: "",
  goal: "",
  categories: [],
  funding: [],
  paymentType: [],
  goalAmountMin: "",
  goalAmountMax: "",
  country: "",
  endDate: "",
  campaignNoOlderThan: "",
};

function HomeCampaignContainer() {
  const [value, setValue] = useState<string>("0");
  const [categories, setCategories] = useState<Array<category>>([]);
  const [campaignList, setCampaignList] = useState<Array<campaign>>([]);
  const [page, setPage] = useState<number>(1);
  const [loadmore, setLoadmore] = useState<boolean>(true);
  const [filterStatus, setFilterStatus] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = React.useState<boolean>(false);

  const userslice = useAppSelector((state) => state.userReducer);

  newCategories =
    userslice.loggedIn && userslice.role === "institutional-donor"
      ? [
          {
            categoryId: 1,
            slug: "All",
            title: t("all"),
          },
          {
            categoryId: 2,
            slug: "Environmental",
            title: t("environmental"),
          },
          {
            categoryId: 3,
            slug: "Social",
            title: t("social"),
          },
          {
            categoryId: 4,
            slug: "Governance",
            title: t("governance"),
          },
        ]
      : [
          {
            categoryId: 1,
            slug: "All Campaigns",
            title: t("allCampaigns"),
          },
          {
            categoryId: 2,
            slug: "ESG Campaigns",
            title: t("esgCampaigns"),
          },
          {
            categoryId: 3,
            slug: "Non-ESG Campaigns",
            title: t("nonEsgCampaigns"),
          },
        ];

  const loadMoreRef = useRef<boolean>();
  const campaignListRef = useRef<Array<campaign>>();

  const pageRef = useRef<number>(0);

  const dispatch = useAppDispatch();
  const { categorySlug } = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (userslice.loggedIn && userslice.role === "institutional-donor") {
      if (newValue === "Environmental") {
        filterCampaign("esg", "environmental");
      } else if (newValue === "Social") {
        filterCampaign("esg", "social");
      } else if (newValue === "Governance") {
        filterCampaign("esg", "governance");
      } else {
        filterCampaign("esg");
      }
    } else {
      if (newValue === "ESG Campaigns") {
        filterCampaign("esg");
      } else if (newValue === "Non-ESG Campaigns") {
        filterCampaign("non-esg");
      } else {
        filterCampaign();
      }
    }
  };

  const fetchCategories = async () => {
    const res2: any = await getCampaignCategories();
    if (res2.success) {
      setCategories(res2.data);
    } else {
      const toastPaylaod = {
        text: t("unableToFetchCategories"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  const fetchAllCampaign = async (
    pageno: number,
    campaign = userslice.loggedIn && userslice.role === "institutional-donor"
      ? "esg"
      : "all"
  ) => {
    const data = {
      limit: 8,
      page: pageno,
      campaign: campaign,
      goal: filterData?.goal ? filterData.goal : "",
      categories: filterData?.categories ? filterData.categories : [],
      funding: filterData?.funding ? filterData.funding : [],
      paymentType: filterData?.paymentType ? filterData.paymentType : [],
      goalAmountMin: filterData?.goalAmountMin ? filterData.goalAmountMin : "",
      goalAmountMax: filterData?.goalAmountMax ? filterData.goalAmountMax : "",
      country: filterData?.country ? filterData.country : "",
      endDate: filterData?.endDate ? filterData.endDate : "",
      campaignNoOlderThan: filterData?.campaignNoOlderThan
        ? filterData.campaignNoOlderThan
        : "",
    };
    setPage(pageno);
    const res = await getAllCampaign(data);
    if (res.success) {
      if (res.data.totalPages > pageno) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      if (campaignListRef.current) {
        setCampaignList([...campaignListRef.current, ...res.data.campaigns]);
      } else {
        setCampaignList([...res.data.campaigns]);
      }
    }
  };

  const filterCampaign = async (campaign = "all", goal = "") => {
    const data = {
      limit: 8,
      page: pageRef.current,
      campaign: campaign,
      goal: goal,
    };
    setPage(pageRef.current);
    const res = await getAllCampaign(data);

    setFilterData(res);
  };

  const filter = async (filters: any) => {
    const data = {
      limit: 8,
      page: 1,
      campaign:
        userslice.loggedIn && userslice.role === "institutional-donor"
          ? "esg"
          : "All",
      goal: filters.goal,
      categories: filters.categories,
      funding: filters.funding,
      paymentType: filters.paymentType,
      goalAmountMin: filters.goalAmountMin,
      goalAmountMax: filters.goalAmountMax,
      country: filters.country,
      endDate: filters.endDate,
      campaignNoOlderThan: filters.campaignNoOlderThan,
    };
    setPage(1);
    filterData = data;
    const res = await getAllCampaign(data);
    setFilterData(res);
    userslice.loggedIn && userslice.role === "institutional-donor"
      ? setValue("All")
      : setValue("All Campaigns");
    setFilterStatus(false);
  };

  const setFilterData = (res: any) => {
    if (res.success) {
      setCampaignList([]);
      if (res.data?.totalPages > pageRef.current) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      setCampaignList([...res.data?.campaigns]);
    } else if (!res.success && !res.data?.campaigns) {
      setCampaignList([]);
    }
  };

  const fetchCategoryCampaign = async (pageno: number) => {
    const data = {
      limit: 8,
      page: pageno,
      category: categorySlug,
    };
    setPage(pageno);
    const res = await getCampaignByCategory(data);
    if (res.success) {
      if (res.data?.totalPages > pageno) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      if (campaignListRef.current) {
        setCampaignList([...campaignListRef.current, ...res.data?.campaigns]);
      } else {
        setCampaignList([...res.data?.campaigns]);
      }
    }
  };

  const handleLoadMore = () => {
    if (
      document &&
      document.scrollingElement &&
      Math.floor(window.innerHeight + document.documentElement.scrollTop) >=
        Math.floor(document.scrollingElement.scrollHeight) - 1
    ) {
      // Do load more content here!

      if (
        loadMoreRef.current &&
        campaignListRef.current &&
        pageRef.current &&
        campaignListRef.current.length > 0
      ) {
        if (categorySlug && categorySlug !== undefined) {
          fetchCategoryCampaign(pageRef.current + 1);
        } else {
          fetchAllCampaign(pageRef.current + 1);
        }
      }
    }
  };

  const handleClearFilter = () => {
    filterData = {
      limit: 8,
      page: 1,
      campaign: "",
      goal: "",
      categories: [],
      funding: [],
      paymentType: [],
      goalAmountMin: "",
      goalAmountMax: "",
      country: "",
      endDate: "",
      campaignNoOlderThan: "",
    };
    filter(filterData);
  };

  const handleScroll = () => {
    let doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top < 470) {
      let scroll = 470 - top;
      window.scrollBy(0, scroll);
    }
  };

  useEffect(() => {
    filterData = {
      limit: 8,
      page: 1,
      campaign: "",
      goal: "",
      categories: [],
      funding: [],
      paymentType: [],
      goalAmountMin: "",
      goalAmountMax: "",
      country: "",
      endDate: "",
      campaignNoOlderThan: "",
    };
    fetchCategories();
    userslice.loggedIn && userslice.role === "institutional-donor"
      ? setValue("All")
      : setValue("All Campaigns");
    if (categorySlug !== undefined) {
      fetchCategoryCampaign(1);
    } else {
      fetchAllCampaign(1);
    }
    setCampaignList([]);
    window.addEventListener("scroll", handleLoadMore);
    return () => window.removeEventListener("scroll", handleLoadMore);
  }, [categorySlug, userslice.role]);

  // useEffect(() => {
  // 	if (categorySlug !== undefined) {
  // 		setCampaignList([]);
  // 		fetchCategoryCampaign(1);
  // 	}
  // }, [categorySlug]);

  useEffect(() => {
    loadMoreRef.current = loadmore;
    campaignListRef.current = campaignList;
    pageRef.current = page;
  }, [loadmore, campaignList, page]);

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={CategoryTab}>
          <Container fixed>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <Box
                sx={{
                  width: { md: "300px", xs: "120px" },
                  textAlign: "left",
                }}
              >
                <Typography variant="h4">{t("categories")}</Typography>
              </Box> */}
              <Box
                sx={{
                  width: {
                    md: "calc(100% - 300px)",
                    xs: "calc(100% - 120px)",
                  },
                }}
              >
                <TabList onChange={handleChange}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {newCategories &&
                      newCategories.length > 0 &&
                      newCategories?.map((cat) => {
                        return (
                          //   <Link
                          //     to={`/category/${cat.slug}`}
                          //     style={{ textDecoration: "none" }}
                          //   >
                          <Tab
                            key={cat?.categoryId}
                            label={
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                              >
                                {cat?.title}
                              </Typography>
                            }
                            value={cat?.slug}
                            onClick={handleScroll}
                          />
                          //   </Link>
                        );
                      })}
                  </Tabs>
                </TabList>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box>
          <Grid item xs={4}>
            <Box
              sx={{
                marginTop: "20px",
                marginRight: "50px",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className="round-button"
                onClick={() => setFilterStatus(true)}
              >
                {t("filters")}
              </Button>
              {((filterData?.categories && filterData?.categories.length > 0) ||
                (filterData?.funding && filterData?.funding.length > 0) ||
                (filterData?.paymentType &&
                  filterData?.paymentType.length > 0) ||
                filterData?.goal ||
                filterData?.goalAmountMin ||
                filterData?.goalAmountMax ||
                filterData?.country ||
                filterData?.endDate ||
                filterData?.campaignNoOlderThan) && (
                <Tooltip title="Reset filter">
                  <FilterAltOffIcon
                    color="primary"
                    onClick={() => handleClearFilter()}
                  >
                    <FilterAltIcon />
                  </FilterAltOffIcon>
                </Tooltip>
              )}
              {userslice.loggedIn && userslice.role === "institutional-donor" && (
                <Button
                  variant="contained"
                  color="primary"
                  className="round-button"
                  style={{ marginLeft: "5px" }}
                  onClick={() => setModalStatus(true)}
                >
                  {t("matchFundEsgCampaign")}
                </Button>
              )}
            </Box>
          </Grid>
          <Container fixed>
            <Typography sx={HeadingBox}>All Campaigns</Typography>
            <Grid mt={3} mb={3} container spacing={4}>
              {campaignList &&
                campaignList?.length > 0 &&
                campaignList?.map((camp) => {
                  return <CampaignCard data={camp} />;
                })}
            </Grid>
          </Container>
          {campaignList && campaignList.length == 0 && (
            <Container fixed>
              <Grid>
                <Typography
                  sx={{
                    fontWeight: 900,
                    fontSize: "23px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {t("noDataFound")}
                </Typography>
              </Grid>
            </Container>
          )}

          <Typography sx={HeadingBox}>Successfully Funded Campaigns</Typography>
          <Container fixed>
            <Grid mt={3} mb={3} container spacing={4}>
              {campaignList &&
                campaignList?.length > 0 &&
                campaignList
                  .filter((item) => item.status === "completed")
                  ?.map((camp) => {
                    return <CampaignCard data={camp} />;
                  })}
            </Grid>
          </Container>
          {(campaignList && campaignList.length == 0) ||
            (campaignList.filter((item) => item.status === "completed")
              .length === 0 && (
              <Container fixed>
                <Grid>
                  <Typography
                    sx={{
                      fontWeight: 900,
                      fontSize: "23px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {t("noDataFound")}
                  </Typography>
                </Grid>
              </Container>
            ))}
          {/* ------ filter modal ----------- */}

          <Modal
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            open={filterStatus}
            onClose={() => setFilterStatus(false)}
          >
            <FilterModal
              filterCategories={categories}
              filterData={filterData}
              handleClose={() => setFilterStatus(false)}
              handleFilter={(el: any) => filter(el)}
            />
          </Modal>
        </Box>
      </TabContext>
      <Modal open={modalStatus}>
        <MatchFundFilter handleClose={() => setModalStatus(false)} />
      </Modal>
    </Box>
  );
}

export default HomeCampaignContainer;

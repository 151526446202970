import { Box, Button, Typography } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import React, { useEffect, useRef, useState } from "react";
import NotificationRow from "./NotificationRow";
import { getCreatorNotifications } from "../../../requests/authRequest";
import { useTranslation } from "react-i18next";

interface notifRow {
  isRead: boolean;
  to: string;
  type: string;
  campaign: {
    title: string;
    campaignId: string;
  };
  investor: {
    name: string;
    investorId: string;
  };
  donatedAmount: number;
  createdAt: string | Date;
  notificationId: string;
  donationId: string;
}

function CreatorNotification() {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<Array<notifRow>>([]);
  const [page, setPage] = useState<number>(1);
  const [loadmore, setLoadmore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const loadMoreRef = useRef<boolean>();
  const notifListRef = useRef<Array<any>>();
  const pageRef = useRef<number>();

  const fetchAllNotifications = async (pageno: number) => {
    const data = {
      limit: 8,
      page: pageno,
    };
    setPage(pageno);
    setLoading(true);
    const res = await getCreatorNotifications(data);
    setLoading(false);
    if (res.success) {
      if (res.data.totalPages > pageno) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      if (notifListRef.current && pageno !== 1) {
        setNotifications([...notifListRef.current, ...res.data.notifications]);
      } else {
        setNotifications([...res.data.notifications]);
      }
    }
  };

  const handleLoadMore = () => {
    if (pageRef && pageRef.current) {
      fetchAllNotifications(pageRef.current + 1);
    }
  };

  useEffect(() => {
    fetchAllNotifications(1);
  }, []);

  useEffect(() => {
    loadMoreRef.current = loadmore;
    notifListRef.current = notifications;
    pageRef.current = page;
  }, [loadmore, notifListRef, page]);

  return (
    <>
      <Box sx={{ pb: 3 }}>
        <Typography variant="h4" align="left" mb={2}>
          {t("notifications")}
        </Typography>
        {notifications &&
          notifications.length > 0 &&
          notifications.map((notif, index) => {
            return <NotificationRow data={notif} key={index} />;
          })}

        {loadmore && !loading && (
          <Button
            onClick={handleLoadMore}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {t("loadMore")}
          </Button>
        )}
      </Box>
    </>
  );
}

export default CreatorNotification;

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getGrantById } from "../../../requests/authRequest";
import GoalsSelection from "../../../components/grants/GoalsSelection";
import Step2 from "../../../components/grants/Step2";
import { useAppDispatch } from "../../../store";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";

const CreateGrant = () => {
  const location = useLocation();
  const { grantId } = useParams();
  const dispatch = useAppDispatch();

  const [step, setStep] = React.useState<string>("step1");
  const [goalIds, setGoalIds] = React.useState<number[]>([]);
  const [goals, setGoals] = React.useState<any[]>([]);
  const [selectedGrant, setSelectedGrant] = React.useState<any>();

  const handleGoalIds = (item: any) => {
    goals.some((el) => el.goalId === item?.goalId)
      ? setGoals(goals.filter((el) => el.goalId !== item?.goalId))
      : setGoals([...goals, item]);
    goalIds.some((el) => el === item?.goalId)
      ? setGoalIds(goalIds.filter((el) => el !== item?.goalId))
      : setGoalIds((prev) => [...prev, item?.goalId]);
  };

  const handleGrant = async () => {
    dispatch(toggleAppLoading(true));
    const res = await getGrantById(grantId);
    if (res.success) {
      const tempGoals = res.data.grant.goals;
      if (tempGoals.length && tempGoals.length > 0) {
        tempGoals.map((item: any) => handleGoalIds(item));
      }
      setSelectedGrant(res.data.grant);
    }
    dispatch(toggleAppLoading(false));
  };
  useEffect(() => {
    if (grantId) {
      handleGrant();
    }
  }, [grantId]);

  return (
    <>
      {(location.pathname.includes("standard") && step === "step1") ||
      (selectedGrant &&
        selectedGrant.grantType === "standard" &&
        step === "step1") ? (
        <GoalsSelection
          handleGoalIds={handleGoalIds}
          goalIds={goalIds}
          setStep={setStep}
          goals={goals}
          selectedGrant={selectedGrant}
        />
      ) : (
        <Step2 goalIds={goalIds} selectedGrant={selectedGrant} />
      )}
    </>
  );
};

export default CreateGrant;

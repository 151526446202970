import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { deleteGrant } from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";

import { setDeleteGrantModal } from "../../../store/slices/ModalSlice";
import DangerousIcon from "@mui/icons-material/Dangerous";
import {
  modalContainer,
  modalWrapper,
  titleStyle,
} from "./deleteCampaignStyles";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../wallets/algorand";
import { deleteGrantBlockchain } from "../../../blockchain";
import algosdk from "algosdk";
import { useTranslation } from "react-i18next";
import Wallets from "../../../wallets";

function DeleteGrantModal() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const open = useAppSelector((state) => state.modalReducer.deleteGrantModal);
  const dispatch = useAppDispatch();
  const grantId = useAppSelector((state) => state.modalReducer.deleteGrantId);

  const deleteGrantAppId = useAppSelector(
    (state) => state.modalReducer.deleteGrantAppId
  );
  const paymentTypeId = useAppSelector(
    (state) => state.modalReducer.paymentTypeBlockchain
  );
  // reducer data of paymentTYpe
  const [showWalletButtons, setShowWalletButtons] =
    React.useState<boolean>(false);
  const userslice = useAppSelector((state) => state.userReducer);

  const handleClose = () => {
    const payload = {
      deleteGrantModal: false,
      deleteGrantId: "",
      deleteGrantAppId: "",
      paymentTypeBlockchain: "",
    };
    dispatch(setDeleteGrantModal(payload));
  };

  const handleDeleteBlockchain = async () => {
    dispatch(toggleAppLoading(true));
    const info = {
      wallet_address: activeAccount?.address,
      grant_app_id: deleteGrantAppId,
      user_app_id: parseInt(userslice.userAppId),
      paymentType: paymentTypeId,
    };
    dispatch(setAppLoadingText(t("sendingDataToBlockchain")));
    const unsignedRes = await deleteGrantBlockchain(info);

    if (!unsignedRes.success) {
      const toastPaylaod = {
        text: unsignedRes.data,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedRes.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));
      const signed = [];
      signed.push(signedTxn[0]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      // dispatch(setAppLoadingText(""));

      return {
        success: true,
      };
    } catch (e) {
      const toastPaylaod = {
        text: t("unableToDeleteGrant"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleDelete = async () => {
    // dispatch(toggleAppLoading(true));

    const blockchainDelete = await handleDeleteBlockchain();
    if (!blockchainDelete.success) {
      return;
    }
    if (grantId.length > 0) {
      const data = {
        grantId: grantId,
      };
      const res = await deleteGrant(data);
      if (res.success) {
        const toastPayload = {
          active: true,
          text: t("grantDeletedSuccessfully"),
          success: true,
        };

        dispatch(setToaster(toastPayload));
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };

        dispatch(setToaster(toastPayload));
      }
    } else {
      const toastPayload = {
        active: true,
        text: t("selectedGrantDoNotExist"),
        success: false,
      };

      dispatch(setToaster(toastPayload));
    }
    dispatch(toggleAppLoading(false));
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={modalWrapper}>
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={modalContainer}
          style={{ maxWidth: "320px" }}
        >
          <Box sx={{ textAlign: "center" }}>
            <DangerousIcon sx={{ fontSize: "50px" }} color="error" />
          </Box>
          <Box sx={titleStyle} mb={3} mt={2}>
            <Typography variant="h6" align="center" mb={0} fontWeight={600}>
              {t("areYouSureYouWantToDeleteThisGrant")}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                textAlign: "center",
                justifyContent: "space-around",
                display: "flex",
              }}
              mt={2}
            >
              <>
                <Button
                  onClick={handleShowWalletOptions}
                  variant="contained"
                  color="error"
                >
                  {t("delete")}
                </Button>
                {showWalletButtons && (
                  <Wallets
                    open={showWalletButtons}
                    handleClose={() => setShowWalletButtons(false)}
                    handleSubmit={handleDelete}
                  />
                )}
              </>
              <Button
                onClick={handleClose}
                variant="contained"
                color="inherit"
                sx={{
                  backgroundColor: "primary.grey",
                }}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default DeleteGrantModal;

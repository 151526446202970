import { Box, Container, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const HowItsWork = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: "#f1f2f3", marginTop: "30px" }}>
      <Container fixed>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            {t("howItWorks")}
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksOne")}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksTwo")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksThree")}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksFour")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksFive")}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksSix")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksSeven")}
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>{t("allocationOfGrantMoney")}</li>
            <li>{t("reviewingMilestonesOfTheAwardedProjects")}</li>
            <li>{t("communityGeoAirdrops")}</li>
          </ul>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksEight")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksNine")}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksTen")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("howItWorksEleven")}
          </p>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItsWork;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SessionWallet } from "algorand-session-wallet";

interface walletState {
  wallet: SessionWallet;
  connected: boolean;
  accounts: string[];
}

const initialState: walletState = {
  wallet: new SessionWallet("TestNet", undefined, "my-algo-connect"),
  connected: false,
  accounts: [],
};

export const walletSlice = createSlice({
  name: "WalletSlice",
  initialState,
  reducers: {
    setWalletInfo: (state, action: PayloadAction<walletState>) => {
      state.wallet = action.payload.wallet;
      state.connected = action.payload.connected;
      state.accounts = action.payload.accounts;
    },
  },
});

export const { setWalletInfo } = walletSlice.actions;

export default walletSlice.reducer;

import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: "#f1f2f3", marginTop: "30px" }}>
      <Container fixed>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            {t("about")}
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>{t("aboutOne")}</p>
          <Typography
            variant="h6"
            sx={{ textAlign: { md: "left", xs: "left", fontSize: "20px" } }}
          >
            Cashdillo:
          </Typography>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>{t("aboutTwo")}</li>
            <li>{t("aboutThree")}</li>
            <li>{t("aboutFour")}</li>
            <li>{t("aboutFive")}</li>
          </ul>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;

import { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import { ChatView } from "./components/user/chat";
import CreateCampaignWrapper from "./components/user/createCampaign/CreateCampaignWrapper";
import DraftCampaign from "./components/user/draftCampaign/DraftCampaign";
import DraftMainPage from "./components/user/draftCampaign/DraftMainPage";
import CreateGrantManager from "./components/user/grantManager/CreateGrantManager";
import GrantManager from "./components/user/grantManager/GrantManager";
import GrantManagerList from "./components/user/grantManager/GrantManagerList";
import CreateGrant from "./views/app/grants/CreateGrant";
import GrantList from "./views/app/grants/GrantsList";
import GrantView from "./views/app/grants/GrantView";
import MatchFunds from "./components/user/matchFunds/MatchFunds";
import {
  EditCampaign,
  MyCampaign,
  SubmitCreatorMilestoneReport,
} from "./components/user/myCampaign";
import MyCampaignInvestor from "./components/user/myCampaign/MyCampaignInvestor";
import ViewMyCampaign from "./components/user/myCampaign/ViewMyCampaign";
import ViewMyInvestedCampaign from "./components/user/myCampaign/ViewMyInvestedCampaign";
import { CreatorNotification } from "./components/user/notification";
import { UserProfile } from "./components/user/userProfile";
import LoadingScreen from "./loaders/LoadingScreen";
import { useAppSelector } from "./store";
import About from "./views/app/about/About";
import Campaign from "./views/app/campaign/Campaign";
import EsgCllient from "./views/app/esg/EsgCllient";
import FAQ from "./views/app/faq/FAQ";
import TermsConditions from "./views/app/termsConditions/TermsConditions";
import FundCampaign from "./views/app/fundCampaign/FundCampaign";
import Home from "./views/app/home/Home";
import HowItsWork from "./views/app/howItWorks/HowItsWork";
import {
  TransactionFailed,
  TransactionSuccessful,
} from "./views/app/transaction";
import User from "./views/app/user/User";
import WhaleTank from "./views/app/whaleTank/WhaleTank";
import { ResetPassword } from "./views/auth/resetPassword";
import GrantApplication from "./views/app/grantApplication/GrantApplication";
import GrantApplicationList from "./views/app/grantApplicantions/grantApplicationList";
import { userRole } from "./constants/common-enum";
import GrantApplicationView from "./views/app/grantApplicantions/GrantAplicationView";
import GrantMilestoneReport from "./views/app/grantApplicantions/GrantMilestoneReport";
import GrantMilestoneView from "./views/app/grantApplicantions/GrantMilestoneView";
import PrivacyPolicy from "./views/app/privacyPolicy/PrivacyPolicy";

function RouteConfig() {
  const loggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const role = useAppSelector((state) => state.userReducer.role);
  const kyc = useAppSelector((state) => state.userReducer.kyc);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="esg-client" element={<EsgCllient />} />
          <Route path="whale-tank" element={<WhaleTank />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="terms-of-use" element={<TermsConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="how-its-works" element={<HowItsWork />} />
          <Route path="category/:categorySlug" element={<Home />} />
          <Route
            path="user"
            element={loggedIn ? <User /> : <Navigate to={"/"} replace />}
          >
            <Route path="profile" element={<UserProfile />} />
            <Route path="fund-campaign" element={<FundCampaign />} />
            <Route
              path="create-campaign"
              element={
                // kyc === "approved" && role === userRole.CREATOR ? (
                <CreateCampaignWrapper />
                // ) : (
                // 	<Navigate to={"/user"} />
                // )
              }
            />
            <Route path="match-funds" element={<MatchFunds />} />
            <Route
              path="my-campaign"
              element={
                role === userRole.CREATOR ? (
                  <MyCampaign />
                ) : (
                  <MyCampaignInvestor />
                )
              }
            />
            <Route
              path="my-campaign/:campaignId"
              element={
                role === userRole.CREATOR ? (
                  <ViewMyCampaign />
                ) : (
                  <ViewMyInvestedCampaign />
                )
              }
            />
            <Route
              path="my-campaign/:campaignId/submit-report/:milestoneId"
              element={
                role !== userRole.CREATOR ? (
                  <Navigate to={"/"} replace />
                ) : (
                  <SubmitCreatorMilestoneReport />
                )
              }
            />
            <Route
              path="edit-campaign/:campaignId"
              element={<EditCampaign />}
            />
            <Route
              path="edit-draft-campaign/:campaignId"
              element={<DraftMainPage />}
            />
            <Route path="draft-campaign" element={<DraftCampaign />} />
            <Route
              path="payment-setting"
              element={<h1>This is payment settings</h1>}
            />
            <Route
              path="notification"
              element={
                role === userRole.CREATOR ? (
                  <CreatorNotification />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="chat"
              element={
                role === userRole.CREATOR ||
                role === userRole.INVESTOR ||
                role === userRole.INSTITUTIONAL_DONOR ||
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ||
                role === userRole.GRANT_APPLICANT ? (
                  // <InvestorCreatorChat />
                  <ChatView />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="grant-managers"
              element={
                role === userRole.GRANT_CREATOR ? (
                  <GrantManagerList />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="create-grant-manager"
              element={
                role === userRole.GRANT_CREATOR ? (
                  <CreateGrantManager />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            ></Route>
            <Route
              path="manager-view/:managerId"
              element={
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ? (
                  <GrantManager />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="edit-manager/:managerId"
              element={
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ? (
                  <CreateGrantManager />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="grant-list"
              element={
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ? (
                  <GrantList />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="edit-grant/:grantId"
              element={
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ? (
                  <CreateGrant />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="create-standard-grant"
              element={
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ? (
                  <CreateGrant />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="create-custom-grant"
              element={
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ? (
                  <CreateGrant />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="application-list"
              element={
                role === userRole.GRANT_APPLICANT ? (
                  <GrantApplicationList />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="view-application/:applicationId"
              element={
                role === userRole.GRANT_APPLICANT ||
                role === userRole.GRANT_CREATOR ||
                role === userRole.GRANT_MANAGER ? (
                  <GrantApplicationView />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="view-application/:applicationId/submit-report/:milestoneId"
              element={
                role === userRole.GRANT_APPLICANT ? (
                  <GrantMilestoneReport />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
          </Route>

          <Route
            path="grant-application/:grantId/:grantAppId"
            element={
              role === userRole.GRANT_APPLICANT ? (
                <GrantApplication />
              ) : (
                <Navigate to={"/"} replace />
              )
            }
          />
          <Route
            path="grant-milestone-report/:grantApplicationId/:applicationAppId"
            element={
              role === userRole.GRANT_APPLICANT ||
              role === userRole.GRANT_CREATOR ||
              role === userRole.GRANT_MANAGER ? (
                <GrantMilestoneView />
              ) : (
                <Navigate to={"/"} replace />
              )
            }
          />
          {/* <Route
            path="edit-application/:applicationId"
            element={
              role === userRole.GRANT_APPLICANT ? (
                <GrantApplication />
              ) : (
                <Navigate to={"/"} replace />
              )
            }
          /> */}

          <Route path="grant/:grantId" element={<GrantView />} />
          <Route path="view-campaign/:id" element={<Home />}></Route>
          <Route path="campaign/:campaignId" element={<Campaign />}></Route>
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Route>
        <Route path="/password-reset" element={<ResetPassword />} />
        {/* <Route path="/" element={<Navigate to={"/institution"} />} /> */}
        <Route
          path="/transaction-succcess/:campaignId"
          element={<TransactionSuccessful />}
        />
        <Route path="/transaction-fail" element={<TransactionFailed />} />
        <Route path="*" element={<p>You have hit a 404 not found</p>} />
      </Routes>
    </Suspense>
  );
}

export default RouteConfig;

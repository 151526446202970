import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

interface chatState {
  chatSocket: any | Socket;
  currentActiveChat: currentActiveChatInterface | undefined;
  recentChats: any;
}

export interface currentActiveChatInterface {
  channelId: string;
  type: string;
  name: string;
  unreadMessageCount: number;
  participants: [
    {
      lastReadAt: string | Date;
      userId: string;
    },
    {
      lastReadAt: string | Date;
      userId: string;
    }
  ];
  createdAt: string | Date;
  updatedAt: string | Date;
}

const initialState: chatState = {
  chatSocket: undefined,
  currentActiveChat: undefined,
  recentChats: undefined,
};

export const chatSlice = createSlice({
  name: "ChatSlice",
  initialState,
  reducers: {
    setChatSocket: (state, action: PayloadAction<Socket | undefined>) => {
      state.chatSocket = action.payload;
    },
    setCurrentActiveChat: (state, action: PayloadAction<any>) => {
      state.currentActiveChat = action.payload;
    },
    setRecentChats: (state, action: PayloadAction<any>) => {
      state.recentChats = action.payload;
    },
  },
});

export const { setChatSocket, setCurrentActiveChat, setRecentChats } =
  chatSlice.actions;

export default chatSlice.reducer;

import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { stat } from "fs";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setLoginModal,
  setRegisterModal,
  setSelectRoleModal,
} from "../../store/slices/ModalSlice";
import { setRegisterUserType } from "../../store/slices/UserSlice";
import { style, titleStyle, roles, FooterBtn } from "./selectRoleStyle";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";

function SelectRoleModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) => state.modalReducer.selectRoleModal);
  const registerUserType = useAppSelector(
    (state) => state.userReducer.registerUserType
  );

  const roleArray = [
    "investor",
    "creator",
    "institutional-donor",
    "grant-creator",
    "applicant",
  ];
  const [role, setRole] = useState<number>(
    roleArray.findIndex((item) => item === registerUserType) || 0
  );

  const handleOpenSignIn = () => {
    dispatch(setRegisterModal(false));
    dispatch(setLoginModal(true));
    dispatch(setSelectRoleModal(false));
  };

  const handleClose = (e: any, reason: string) => {
    if (reason && reason == "backdropClick") return;
    dispatch(setSelectRoleModal(false));
  };

  const handleContinue = () => {
    dispatch(setRegisterUserType(roleArray[role]));
    dispatch(setSelectRoleModal(false));
    dispatch(setRegisterModal(true));
  };

  useEffect(() => {
    dispatch(setRegisterUserType(roleArray[role]));
  }, [role]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: "100%",
          outline: " 0px",
          overflow: "hidden auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media(max-height:700px)": {
            alignItems: "baseline",
          },
        }}
        // onClick={handleClose}
      >
        <Box
          sx={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box sx={titleStyle}>
            <CancelIcon
              sx={{ position: "absolute", top: 8, right: 8, cursor: "pointer" }}
              onClick={(e) => handleClose(e, "main")}
            />
            <Typography variant="h4" align="center" mb={2}>
              {t("createNewAccount")}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="caption">
                {t("alreadyHaveAnAccount?")}&nbsp;
              </Typography>
              <Button variant="text" onClick={handleOpenSignIn}>
                {t("signIn")}
              </Button>
            </Box>
          </Box>
          <Box sx={roles}>
            <p
              className={role === 0 ? "selected" : ""}
              onClick={() => {
                setRole(0);
              }}
            >
              {t("individualDonor")}
              <span>({t("fundADream")})</span>
            </p>
            <p
              className={role === 2 ? "selected" : ""}
              onClick={() => {
                setRole(2);
              }}
            >
              {t("institutionalDonor")}
              <span>({t("fundADream")})</span>
            </p>
            <p
              className={role === 1 ? "selected" : ""}
              onClick={() => {
                setRole(1);
              }}
            >
              {t("campaignCreator")}
              <span>({t("fundYourDream")})</span>
            </p>
            <p
              className={role === 3 ? "selected" : ""}
              onClick={() => {
                setRole(3);
              }}
            >
              {t("grantCreator")}
              <span>({t("beRewarded")})</span>
            </p>
            <p
              className={role === 4 ? "selected" : ""}
              onClick={() => {
                setRole(4);
              }}
            >
              {t("grantApplicant")}
              <span>({t("beRewarded")})</span>
            </p>
          </Box>
          <Box sx={FooterBtn}>
            <Box sx={{ textAlign: "center" }}>
              <Button variant="contained" onClick={handleContinue}>
                {t("continue")}
              </Button>
            </Box>
            <Button
              sx={{ color: "initial" }}
              variant="text"
              onClick={(e) => handleClose(e, "main")}
            >
              {t("doItLater")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default SelectRoleModal;

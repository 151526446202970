export const CampaigngForm = {
  marginTop: "-24px",
  paddingBottom: "20px",
  "& .inputfile": {
    display: "none",
  },
  "& .uploadButton": {
    color: "#000",
    fontFamily: "TTNormsRegular",
    marginTop: 1,
    paddingLeft: 0,
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontFamily: "TTNormsMedium",
      borderRadius: "6px",
      textAlign: "left",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
  "& .MuiFormLabel-root": {
    textAlign: "left",
    marginBottom: 2,
  },
};

export const PhotoUpload = {
  maxWidth: "300px",
  maxHeight: "300px",
  width: "100%",
  height: "300px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",

  backgroundColor: "#fff",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: "0px",
    top: "0px",
    zIndex: "2",
  },
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface authState {
  loggedIn: boolean;
  userToken: string;
  loginType: string;
  registerUserType: string;
  userAppId: string;
  kyc: string;
  userId?: string;
  role?: string;
}

export interface loggedInUser {
  status: string;
  name: string;
  email: string;
  userId: string;
  createdAt: string;
  address: string;
  gender: string;
  phone: string;
  country: string;
  role: string;
  userAppId: string;
  organisationOwner: string;
  organisationName: string;
  orgnisationRole: string;
}

// Define the initial state using that type
const initialState: authState & loggedInUser = {
  loggedIn: false,
  userToken: "",
  loginType: "",
  registerUserType: "",
  status: "",
  name: "",
  email: "",
  userId: "",
  createdAt: "",
  address: "",
  gender: "Other",
  phone: "",
  country: "",
  role: "",
  userAppId: "",
  kyc: "",
  organisationOwner: "",
  organisationName: "",
  orgnisationRole: "",
};

export const userSlice = createSlice({
  name: "UserSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<authState>) => {
      state.loggedIn = action.payload.loggedIn;
      state.userToken = action.payload.userToken;
      state.loginType = action.payload.loginType;
      state.userAppId = action.payload.userAppId;
      state.kyc = action.payload.kyc;
      if (action.payload.userId !== undefined) {
        state.userId = action.payload.userId;
      }
      if (action.payload.role !== undefined) {
        state.role = action.payload.role;
      }
    },
    setRegisterUserType: (state, action: PayloadAction<string>) => {
      state.registerUserType = action.payload;
    },
    setUserData: (state, action: PayloadAction<loggedInUser>) => {
      state.status = action.payload.status;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.createdAt = action.payload.createdAt;
      state.userId = action.payload.userId;
      state.address = action.payload.address;
      state.country = action.payload.country;
      state.gender = action.payload.gender;
      state.phone = action.payload.phone;
      state.role = action.payload.role;
      state.userAppId = action.payload.userAppId;
      state.organisationOwner = action.payload.organisationOwner;
      state.organisationName = action.payload.organisationName;
      state.orgnisationRole = action.payload.orgnisationRole;
    },
  },
});

export const { setUserToken, setRegisterUserType, setUserData } =
  userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default userSlice.reducer;

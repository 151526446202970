import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  FormLabel,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Modal,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  getApplicationById,
  submitGrants,
  uploadCampaignImage,
} from "../../../requests/authRequest";
import { useAppDispatch } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
  setAppLoadingText,
} from "../../../store/slices/LoadinAndNotifSlice";
import UploadPdf from "../../../components/user/createCampaign/UploadPdf";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";
import { ContentStyle, titleStyle } from "../../auth/login/style";
import {
  modalContainer,
  modalWrapper,
} from "../../../components/common/modals/algoDonationModalStyles";
import success from "../../../assets/images/success.png";
import { submitGrantAppBlockchain } from "../../../blockchain";
import algosdk from "algosdk";
import { client, peraWallet } from "../../../../src/wallets/algorand";
import { useWallet } from "@txnlab/use-wallet";
import { useAppSelector } from "../../../store";
import { CampaigngForm, grantInfoBoxStyles } from "../user/styles";
import { fileTypes } from "../../../constants/common-enum";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import algoIcon from "../../../assets/images/algo.png";
import { blackBoxHeading } from "../../../components/home/campaigns/milestoneSetStyles";
import Wallets from "../../../wallets";
import { setGrantApplicationData } from "../../../store/slices/ModalSlice";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import algo from "../../../assets/images/algo.png";

const GrantApplication = () => {
  const getInitialValues = () => {
    const initialValues = {
      donorsObjective: "",
      impact: "",
      donorsGoals: "",
      previousExperience: "",
      link: "",
      document: "",
      facebookURL: "",
      instagramURL: "",
      twitterURL: "",
      termAndConditions: false,
      dataPolicy: false,
      paymentInCrypto: false,
      milestonesOneTitle: "",
      milestonesOneDescription: "",
      milestonesOneAmount: "",
      milestonesTwoTitle: "",
      milestonesTwoDescription: "",
      milestonesTwoAmount: "",
    };
    return initialValues;
  };

  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const { grantId, grantAppId } = useParams();
  const paymentTypeId = useAppSelector(
    (state) => state.modalReducer.grants.paymentTypeBlockchain
  );
  const { applicationId } = useParams();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [milestoneOneDeadline, setMilestoneOneDeadline] = useState<
    Date | string
  >(new Date());
  const [milestoneTwoDeadline, setMilestoneTwoDeadline] = useState<
    Date | string
  >(new Date());
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<any>({});
  const [pdf, setPdf] = useState<any | undefined>();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [manager, setManager] = useState(getInitialValues());
  const userslice = useAppSelector((state: any) => state.userReducer);
  const applications = useAppSelector(
    (state) => state.modalReducer.applications
  );
  const grants: any = useAppSelector((state) => state.modalReducer.grants);

  const validationSchema = () => {
    return Yup.object().shape({
      donorsObjective: Yup.string()
        .trim()
        .max(30000, "* Maximum 30000 characters are allowed.")
        .required("* Please describe your solution to Donor's Objective."),
      impact: Yup.string()
        .trim()
        .max(30000, "* Maximum 30000 characters are allowed.")
        .required("* Please describe the impact of your solution."),
      donorsGoals: Yup.string()
        .trim()
        .max(30000, "* Maximum 30000 characters are allowed.")
        .required("* Please describe the sustainable development goals."),
      previousExperience: Yup.string()
        .trim()
        .max(30000, "* Maximum 30000 characters are allowed.")
        .required("* Please describe your previous experience."),
      termAndConditions: Yup.bool()
        .required("* The terms and conditions must be accepted.")
        .oneOf([true], "* The terms and conditions must be accepted."),
      dataPolicy: Yup.bool()
        .required("* The data policy must be accepted.")
        .oneOf([true], "* The data policy must be accepted."),
      milestonesOneTitle: Yup.string().trim().required("* Please enter title."),
      milestonesOneDescription: Yup.string()
        .trim()
        .required("* Please enter description."),
      milestonesOneAmount: Yup.number()
        .required("* Please enter amount.")
        .typeError("* Please enter number only."),
      milestonesTwoTitle: Yup.string().trim().required("* Please enter title."),
      milestonesTwoDescription: Yup.string()
        .trim()
        .required("* Please enter description."),
      milestonesTwoAmount: Yup.number()
        .required("* Please enter amount.")
        .typeError("* Please enter number only."),
    });
  };

  const validate = (getValidationSchema: any) => {
    return (values: any) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError: any) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors: any, error: any) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const handleSubmitGrantApplicationBlockchain = async (values: any) => {
    const grantInfo = {
      wallet_address: activeAccount?.address,
      user_app_id: parseInt(userslice.userAppId),
      mile1: `${moment(new Date(milestoneOneDeadline)).startOf("day").unix()}/${
        values.milestonesOneAmount
      }`,
      mile2: `${moment(new Date(milestoneTwoDeadline)).startOf("day").unix()}/${
        values.milestonesTwoAmount
      }`,
      req_funds:
        parseInt(values.milestonesOneAmount) +
        parseInt(values.milestonesTwoAmount),
      grant_app_id: grantAppId ? parseInt(grantAppId) : "",
      paymentType: paymentTypeId,
    };
    const unsignedTxn = await submitGrantAppBlockchain(grantInfo);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });

      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });

      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));

      const signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      signed.push(signedTxn[2]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      let appData1Blockchain = await algosdk.waitForConfirmation(
        client,
        transactionArr[1].txn.txID(),
        4
      );
      let appData2Blockchain = await algosdk.waitForConfirmation(
        client,
        transactionArr[2].txn.txID(),
        4
      );

      dispatch(setAppLoadingText(""));

      const applicationAppId =
        appData1Blockchain["inner-txns"][0]["application-index"];
      if (applicationAppId) {
        return {
          success: true,
          applicationAppId,
        };
      } else {
        return {
          success: true,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleSubmit = async (values: any) => {
    setValues(values);
    setShowWalletButtons(true);
    setGrantApplicationData(values);
  };

  const handleSubmitOption = async () => {
    if (await validateMilestone(values)) {
      dispatch(toggleAppLoading(true));
      const blockchainRes = await handleSubmitGrantApplicationBlockchain(
        values
      );
      if (!blockchainRes.success) {
        return;
      }
      const document = await handleDocumentUpload();
      const payload = {
        donorsObjective: values.donorsObjective,
        impact: values.impact,
        donorsGoals: values.donorsGoals,
        previousExperience: values.previousExperience,
        link: values.link,
        document: !document ? "" : document[0],
        facebookURL: values.facebookURL,
        instagramURL: values.instagramURL,
        twitterURL: values.twitterURL,
        termAndConditions: values.termAndConditions,
        dataPolicy: values.dataPolicy,
        paymentInCrypto: values.paymentInCrypto,
        ...(grantId ? { grantId: grantId } : {}),
        ...(applicationId ? { grantApplicationId: applicationId } : {}),
        paymentTypeBlockchain: paymentTypeId,
        milestones: [
          {
            title: values.milestonesOneTitle,
            description: values.milestonesOneDescription,
            deadline: milestoneOneDeadline,
            amount: values.milestonesOneAmount,
          },
          {
            title: values.milestonesTwoTitle,
            description: values.milestonesTwoDescription,
            deadline: milestoneTwoDeadline,
            amount: values.milestonesTwoAmount,
          },
        ],
        applicationAppId: blockchainRes.applicationAppId,
      };

      // const res = grantId ? await submitGrants(payload) : await editGrants(payload);
      const res = await submitGrants(payload);
      if (res.success) {
        const toastPaylaod = {
          text: grantId
            ? t("savedToGrantSuccessfully")
            : t("updatedToGrantSuccessfully"),
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        setOpen(true);
      } else {
        const toastPaylaod = {
          text: res.data.message,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
      }
      dispatch(toggleAppLoading(false));
    }
  };

  const validateMilestone = async (values: any) => {
    let validate = true;
    let message = "";
    let totalBudget = 0;
    let noumberOfTotalApps = 0;
    applications.map((item: any) => {
      if (item.status === "accepted") {
        totalBudget += item.requestedFund;
        noumberOfTotalApps += 1;
      }
    });
    totalBudget = grants["totalBudget"] - totalBudget;
    if (
      parseFloat(values.milestonesOneAmount) +
        parseFloat(values.milestonesTwoAmount) <
      grants["minimumGrant"]
    ) {
      message = t("askAmountCantBeLessThanMinimumGrantAmount");
      validate = false;
    } else if (
      parseFloat(values.milestonesOneAmount) +
        parseFloat(values.milestonesTwoAmount) >
      grants["maximumGrant"]
    ) {
      message = t("askAmountCantBeGreaterThanMaximumGrantAmount");
      validate = false;
    } else if (
      parseFloat(values.milestonesOneAmount) +
        parseFloat(values.milestonesTwoAmount) >
      totalBudget
    ) {
      message = t("askAmountCantBeGreaterThanTotalBudget");
      validate = false;
    } else if (noumberOfTotalApps > grants.totalGrants) {
      message = t("numberOfAcceptedApplicationCompleted");
      validate = false;
    } else if (
      !moment(moment(new Date(milestoneOneDeadline)).format()).isAfter(
        grants.endDate,
        "day"
      )
    ) {
      message = t("pleaseSelectFutureDateAfterGrantEndDate");
      validate = false;
    } else if (
      !moment(moment(new Date(milestoneTwoDeadline)).format()).isAfter(
        moment(new Date(milestoneOneDeadline)).format(),
        "day"
      )
    ) {
      message = t("pleaseSelectFutureDateAfterMilestoneOneDeadline");
      validate = false;
    }
    if (!validate) {
      const toastPaylaod = {
        text: message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
    return validate;
  };

  const handleDocumentUpload = async () => {
    if (pdf) {
      const res: any = await uploadCampaignImage([pdf]);
      if (res.success) {
        return res?.data;
      } else {
        return false;
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/user/application-list");
  };

  const getApplicationData = async () => {
    dispatch(toggleAppLoading(true));
    const res: any = await getApplicationById(applicationId);
    if (res.success) {
      let applicationData = {
        donorsObjective: res.data.application.donorsObjective,
        impact: res.data.application.impact,
        donorsGoals: res.data.application.donorsGoals,
        previousExperience: res.data.application.previousExperience,
        link: res.data.application.link,
        document: res.data.application.document,
        facebookURL: res.data.application.facebookURL,
        instagramURL: res.data.application.instagramURL,
        twitterURL: res.data.application.twitterURL,
        termAndConditions: res.data.application.termAndConditions,
        dataPolicy: res.data.application.dataPolicy,
        paymentInCrypto: res.data.application.paymentInCrypto,
        milestonesOneTitle: res.data.application.milestones[0].title,
        milestonesOneDescription:
          res.data.application.milestones[0].description,
        milestonesOneAmount: res.data.application.milestones[0].amount,
        milestonesTwoTitle: res.data.application.milestones[1].title,
        milestonesTwoDescription:
          res.data.application.milestones[1].description,
        milestonesTwoAmount: res.data.application.milestones[1].amount,
      };
      setManager(applicationData);
      formik.values = applicationData;
      setMilestoneOneDeadline(res.data.application.milestones[0].deadline);
      setMilestoneTwoDeadline(res.data.application.milestones[1].deadline);
    }
    dispatch(toggleAppLoading(false));
  };

  const formik = useFormik({
    initialValues: manager,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    applicationId && getApplicationData();
  }, []);

  const calculateFundGranted = () => {
    let totalFundGranted = 0;
    applications?.map((item: any) => {
      if (item.status === "accepted") {
        totalFundGranted += item.requestedFund || 0;
      }
    });
    return totalFundGranted;
  };
  const applicationsApproved = () => {
    let applicationsCount = 0;
    applications?.map((item: any) => {
      if (item.status === "accepted") {
        applicationsCount += 1;
      }
    });

    return applicationsCount;
  };
  const applicationsRejected = () => {
    let applicationsCount = 0;
    applications?.map((item: any) => {
      if (item.status === "rejected") {
        applicationsCount += 1;
      }
    });

    return applicationsCount;
  };
  const applicationsReceived = () => {
    let applicationsCount = 0;
    applications?.map((item: any) => {
      applicationsCount += 1;
    });

    return applicationsCount;
  };

  // rejected
  return (
    <Box sx={CampaigngForm} mt={5}>
      {/* TOP HEADING  */}
      <Typography variant="h4" mt={5} mb={3}>
        {grantId ? t("grantApplicationForm") : t("updateGrantApplicationForm")}
      </Typography>
      <Box sx={grantInfoBoxStyles}>
        <Typography variant="body1" align="center">
          Total Budget: {grants.totalBudget}
          {""}
          {grants?.paymentTypeBlockchain === "ALGO" ? (
            <img
              style={{ width: "16px", marginBottom: "-3px" }}
              src={algo}
              alt="ALGO"
            />
          ) : (
            <MonetizationOnIcon
              style={{ marginBottom: "-5px", fontSize: "20px", color: "black" }}
            />
          )}
        </Typography>
        <Typography variant="body1" align="center">
          Fund Granted: {calculateFundGranted()}
          {grants?.paymentTypeBlockchain === "ALGO" ? (
            <img
              style={{ width: "16px", marginBottom: "-3px" }}
              src={algo}
              alt="ALGO"
            />
          ) : (
            <MonetizationOnIcon
              style={{ marginBottom: "-5px", fontSize: "20px", color: "black" }}
            />
          )}
        </Typography>
        <Typography variant="body1" align="center">
          Fund Remaining: {grants?.totalBudget - calculateFundGranted()}
          {grants?.paymentTypeBlockchain === "ALGO" ? (
            <img
              style={{ width: "16px", marginBottom: "-3px" }}
              src={algo}
              alt="ALGO"
            />
          ) : (
            <MonetizationOnIcon
              style={{ marginBottom: "-5px", fontSize: "20px", color: "black" }}
            />
          )}
        </Typography>
        <Typography variant="body1" align="center">
          Minimum Grant: {grants?.minimumGrant}
        </Typography>
        <Typography variant="body1" align="center">
          Maximum Grant: {grants?.maximumGrant}
        </Typography>
        <Typography variant="body1" align="center">
          Total Applicants: {grants?.totalGrants}
        </Typography>
        <Typography variant="body1" align="center">
          Applicants Received: {applicationsReceived()}
        </Typography>
        <Typography variant="body1" align="center">
          Applicants approved: {applicationsApproved()}
        </Typography>
        <Typography variant="body1" align="center">
          Applicants rejected: {applicationsRejected()}
        </Typography>
      </Box>
      <Grid container justifyContent={"center"}>
        <Grid item xs={8}>
          <Typography variant="h5" my={5} align="center">
            Note: The total amount of both the milestones should be in between
            the given range and not to be greater than the fund remaining.
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ textAlign: "left" }}
        >
          <Grid item lg={3}>
            <FormLabel component="legend">
              {t("howDoesYourApplicationOrSolutionAddressTheDonorsObjectives")}
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("enterDescriptionMaxTwohundred") || ""}
              variant="outlined"
              name="donorsObjective"
              value={formik.values.donorsObjective}
              onChange={formik.handleChange("donorsObjective")}
              helperText={
                formik.errors.donorsObjective && formik.touched.donorsObjective
                  ? formik.errors.donorsObjective
                  : ""
              }
              error={
                formik.errors.donorsObjective && formik.touched.donorsObjective
                  ? true
                  : false
              }
            />
            <FormLabel component="legend">
              {t(
                "howWillYourApplicationOrSolutionEngageWithTheSustainableDevelopmentGoalsTheDonosGrantAspiresTo"
              )}
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("enterDescriptionMaxTwohundred") || ""}
              variant="outlined"
              name="donorsGoals"
              value={formik.values.donorsGoals}
              onChange={formik.handleChange("donorsGoals")}
              helperText={
                formik.errors.donorsGoals && formik.touched.donorsGoals
                  ? formik.errors.donorsGoals
                  : ""
              }
              error={
                formik.errors.donorsGoals && formik.touched.donorsGoals
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item lg={3}>
            <FormLabel component="legend">
              {t("describeTheImpactYourApplicationOrSolutionWillHave")}
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("enterDescriptionMaxTwohundred") || ""}
              variant="outlined"
              name="impact"
              value={formik.values.impact}
              onChange={formik.handleChange("impact")}
              helperText={
                formik.errors.impact && formik.touched.impact
                  ? formik.errors.impact
                  : ""
              }
              error={
                formik.errors.impact && formik.touched.impact ? true : false
              }
            />
            <FormLabel component="legend">
              {t(
                "pleaseDetailAnyRelevantPreviousExperienceTheGrantManagerShouldBeAwareOf"
              )}
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("enterDescriptionMaxTwohundred") || ""}
              variant="outlined"
              name="previousExperience"
              value={formik.values.previousExperience}
              onChange={formik.handleChange("previousExperience")}
              helperText={
                formik.errors.previousExperience &&
                formik.touched.previousExperience
                  ? formik.errors.previousExperience
                  : ""
              }
              error={
                formik.errors.previousExperience &&
                formik.touched.previousExperience
                  ? true
                  : false
              }
            />
            <FormLabel component="legend">
              {t("enterPreviousExperienceLink")}({t("optional")})
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              value={formik.values.link}
              onChange={formik.handleChange("link")}
              placeholder={t("enterLink") || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={3}>
            <FormLabel component="legend">
              {t("pleaseProvideAnyAdditionalDocument")} ({fileTypes.join(", ")})
              {t("requestedByTheGrantManager")}({t("optional")})
            </FormLabel>
            <FormLabel component="legend">
              <UploadPdf pdf={pdf} setPdf={setPdf} />
            </FormLabel>
            <FormLabel component="legend">
              {t("facebookUrl")}({t("optional")})
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              value={formik.values.facebookURL}
              onChange={formik.handleChange("facebookURL")}
              placeholder={t("enterFacebookProfileLink") || ""}
              variant="outlined"
            />
            <FormLabel component="legend">
              {t("instagramUrl")}({t("optional")})
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              value={formik.values.instagramURL}
              onChange={formik.handleChange("instagramURL")}
              placeholder={t("enterInstagramProfileLink") || ""}
              variant="outlined"
            />
            <FormLabel component="legend">
              {t("twitterUrl")}({t("optional")})
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              value={formik.values.twitterURL}
              onChange={formik.handleChange("twitterURL")}
              placeholder={t("enterTwitterProfileLink") || ""}
              variant="outlined"
            />

            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Terms & conditions"
                value={formik.values.termAndConditions}
                onChange={formik.handleChange("termAndConditions")}
                checked={formik.values.termAndConditions}
              />

              {formik.errors.termAndConditions &&
                formik.touched.termAndConditions && (
                  <p style={{ color: "red" }}>
                    {formik.errors.termAndConditions}
                  </p>
                )}
              <FormControlLabel
                control={<Checkbox />}
                label="Accept data policy"
                value={formik.values.dataPolicy}
                onChange={formik.handleChange("dataPolicy")}
                checked={formik.values.dataPolicy}
              />
              {formik.errors.dataPolicy && formik.touched.dataPolicy && (
                <p style={{ color: "red" }}>{formik.errors.dataPolicy}</p>
              )}
              <FormControlLabel
                control={<Checkbox />}
                label="Option for payments in crypto"
                value={formik.values.paymentInCrypto}
                onChange={formik.handleChange("paymentInCrypto")}
                checked={formik.values.paymentInCrypto}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box>
          <Typography variant="h4" my={1} align="center">
            {t("milestonesDetails")}
          </Typography>
          <Grid container justifyContent={"center"}>
            <Grid item xs={8}>
              <Typography variant="h5" my={5} align="center">
                Note: You need to submit one photographic or video evidence and
                one supporting document (.pdf, .doc) while submitting the
                milestones reports in future.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ textAlign: "left" }}
          >
            <Grid item lg={4} sm={12}>
              <Box
                // variant="contained"
                color="primary"
                sx={blackBoxHeading}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {t("milestoneOne")}
              </Box>
              <FormLabel component="legend">{t("Title")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("title") || ""}
                variant="outlined"
                value={formik.values.milestonesOneTitle}
                onChange={formik.handleChange("milestonesOneTitle")}
                helperText={
                  formik.errors.milestonesOneTitle &&
                  formik.touched.milestonesOneTitle
                    ? formik.errors.milestonesOneTitle
                    : ""
                }
                error={
                  formik.errors.milestonesOneTitle &&
                  formik.touched.milestonesOneTitle
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">
                {t("Amount")}(
                {paymentTypeId === "ALGO" && (
                  <img
                    style={{ width: "16px", marginBottom: "-3px" }}
                    src={algoIcon}
                  ></img>
                )}
                {paymentTypeId === "USDC" && (
                  <MonetizationOnIcon
                    style={{
                      marginBottom: "-5px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                )}
                ){" "}
                <Tooltip
                  title="The total amount of both the milestones should be in between the given range and not to be greater than the fund remaining."
                  placement="top-start"
                  sx={{ marginBottom: "-7px" }}
                >
                  <InfoIcon />
                </Tooltip>
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("amount") || ""}
                variant="outlined"
                value={formik.values.milestonesOneAmount}
                onChange={formik.handleChange("milestonesOneAmount")}
                helperText={
                  formik.errors.milestonesOneAmount &&
                  formik.touched.milestonesOneAmount
                    ? formik.errors.milestonesOneAmount
                    : ""
                }
                error={
                  formik.errors.milestonesOneAmount &&
                  formik.touched.milestonesOneAmount
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">{t("Description")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                multiline
                minRows={4}
                type={"text"}
                placeholder={t("description") || ""}
                variant="outlined"
                value={formik.values.milestonesOneDescription}
                onChange={formik.handleChange("milestonesOneDescription")}
                helperText={
                  formik.errors.milestonesOneDescription &&
                  formik.touched.milestonesOneDescription
                    ? formik.errors.milestonesOneDescription
                    : ""
                }
                error={
                  formik.errors.milestonesOneDescription &&
                  formik.touched.milestonesOneDescription
                    ? true
                    : false
                }
              />

              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">
                  {t("deadline")}{" "}
                  <Tooltip
                    title="The deadline should be future date after grant end date."
                    placement="top-start"
                    sx={{ marginBottom: "-7px" }}
                  >
                    <InfoIcon />
                  </Tooltip>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      value={milestoneOneDeadline}
                      onChange={(vl: any) => setMilestoneOneDeadline(vl)}
                      renderInput={(params: any) => (
                        <TextField {...params} id={"milestoneOneendDate"} />
                      )}
                      disablePast
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item lg={4} sm={12}>
              <Box
                // variant="contained"
                color="primary"
                sx={blackBoxHeading}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {t("milestoneTwo")}
              </Box>
              <FormLabel component="legend">{t("title")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("title") || ""}
                variant="outlined"
                value={formik.values.milestonesTwoTitle}
                onChange={formik.handleChange("milestonesTwoTitle")}
                helperText={
                  formik.errors.milestonesTwoTitle &&
                  formik.touched.milestonesTwoTitle
                    ? formik.errors.milestonesTwoTitle
                    : ""
                }
                error={
                  formik.errors.milestonesTwoTitle &&
                  formik.touched.milestonesTwoTitle
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">
                {t("Amount")}(
                {paymentTypeId === "ALGO" && (
                  <img
                    style={{ width: "16px", marginBottom: "-3px" }}
                    src={algoIcon}
                  ></img>
                )}
                {paymentTypeId === "USDC" && (
                  <MonetizationOnIcon
                    style={{
                      marginBottom: "-5px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                )}
                ){" "}
                <Tooltip
                  title="The total amount of both the milestones should be in between the given range and not to be greater than the fund remaining."
                  placement="top-start"
                  sx={{ marginBottom: "-7px" }}
                >
                  <InfoIcon />
                </Tooltip>
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("amount") || ""}
                variant="outlined"
                value={formik.values.milestonesTwoAmount}
                onChange={formik.handleChange("milestonesTwoAmount")}
                helperText={
                  formik.errors.milestonesTwoAmount &&
                  formik.touched.milestonesTwoAmount
                    ? formik.errors.milestonesTwoAmount
                    : ""
                }
                error={
                  formik.errors.milestonesTwoAmount &&
                  formik.touched.milestonesTwoAmount
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">{t("description")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                multiline
                minRows={4}
                type={"text"}
                placeholder={t("description") || ""}
                value={formik.values.milestonesTwoDescription}
                onChange={formik.handleChange("milestonesTwoDescription")}
                helperText={
                  formik.errors.milestonesTwoDescription &&
                  formik.touched.milestonesTwoDescription
                    ? formik.errors.milestonesTwoDescription
                    : ""
                }
                error={
                  formik.errors.milestonesTwoDescription &&
                  formik.touched.milestonesTwoDescription
                    ? true
                    : false
                }
              />

              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">
                  {t("deadline")}{" "}
                  <Tooltip
                    title="The deadline should be future date after grant end date."
                    placement="top-start"
                    sx={{ marginBottom: "-7px" }}
                  >
                    <InfoIcon />
                  </Tooltip>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      value={milestoneTwoDeadline}
                      onChange={(vl: any) => setMilestoneTwoDeadline(vl)}
                      renderInput={(params: any) => (
                        <TextField {...params} id={"milestoneTwoendDate"} />
                      )}
                      disablePast
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* bootam button */}
        <Grid>
          <Button
            color="error"
            variant="contained"
            className="round-button"
            onClick={() => navigate(-1)}
          >
            {t("back")}
          </Button>
          <>
            <Button
              style={{
                marginLeft: "20px",
              }}
              color="primary"
              variant="contained"
              className="round-button"
              type="submit"
            >
              {grantId ? t("apply") : t("update")}
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={handleSubmitOption}
              />
            )}
          </>
        </Grid>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalWrapper}>
          <Box sx={modalContainer}>
            <Box sx={titleStyle}>
              <Typography variant="h4" align="center" mb={2}>
                <img
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "5px",
                  }}
                  src={success}
                ></img>
              </Typography>
              <Typography variant="h4" align="center" mb={2}>
                {t("thankYouForApplying")}
              </Typography>
            </Box>
            <Box sx={ContentStyle}>
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                mt={3}
                mb={3}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="success"
                >
                  {t("goToPage")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default GrantApplication;

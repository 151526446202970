import { Button, Box } from "@mui/material";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import algosdk from "algosdk";
import React, { useEffect } from "react";
// import GoogleLogin from "react-google-login";
import { useTranslation } from "react-i18next";
import GoogleIcon from "../../assets/images/googleicon.png";
import { createAccountBlockchain } from "../../blockchain";
import {
  checkEmailExistance,
  handleGoogleRegister,
} from "../../requests/simpleRequest";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { setLoginModal, setRegisterModal } from "../../store/slices/ModalSlice";
import { setUserToken } from "../../store/slices/UserSlice";
import { client, peraWallet } from "../../wallets/algorand";
import { useWallet } from "@txnlab/use-wallet";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";

import Wallets from "../../wallets";

import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { Backspace } from "@mui/icons-material";
interface DecodedJwt {
  email: string;
  // Other properties from your decoded JWT
}

function GoogleRegisterButton() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [loginResp, setLoginResp] = useState<any>();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userslice = useAppSelector((state) => state.userReducer);

  const handleBlockchainSubmit = async (googleInfo: any) => {
    // here we first chechk if email exist
    let decoded = jwt_decode(googleInfo.credential) as DecodedJwt;
    const checkEmailObj = {
      // email: googleInfo.profileObj.email,
      email: decoded.email,
      // email: googleInfo.data.email,
      // email: googleInfo.email,
    };
    const emailExistance = await checkEmailExistance(checkEmailObj);

    if (!emailExistance.success) {
      const toastPaylaod = {
        text: t("unableToVerifyIfEmailAlreadExist"),
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      const returnObj = {
        success: false,
        userAppId: "",
      };
      return returnObj;
    } else if (emailExistance.data.emailExists) {
      const toastPaylaod = {
        text: emailExistance.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      const returnObj = {
        success: false,
        userAppId: "",
      };
      return returnObj;
    }

    const info = {
      // name: googleInfo.profileObj.name,
      user_type: userslice.registerUserType,
      // email: googleInfo.profileObj.email,
      wallet_address: activeAccount?.address,
    };

    const res = await createAccountBlockchain(info);

    try {
      dispatch(toggleAppLoading(false));
      if (res.success) {
        const transactionArr = res.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));
        // Submit the transaction
        dispatch(setAppLoadingText(t("signingTransaction")));
        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();

        // Wait for confirmation
        dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );

        // get the transaction res
        dispatch(setAppLoadingText(t("fetchingTransactionData")));
        let transactionResponse = await client
          .pendingTransactionInformation(transationRes.txId)
          .do();

        dispatch(setAppLoadingText(""));

        let appId = transactionResponse["application-index"];
        if (transactionResponse["application-index"]) {
          const returnObj = {
            success: true,
            userAppId: appId,
          };
          return returnObj;
        } else {
          const returnObj = {
            success: false,
            userAppId: "",
          };

          const toastPaylaod = {
            text: t("unableToSendDataToBlockchain"),
            success: false,
            active: true,
          };
          dispatch(toggleAppLoading(false));
          dispatch(setToaster(toastPaylaod));
          return returnObj;
        }
      }
      dispatch(toggleAppLoading(false));
    } catch (err) {
      const returnObj = {
        success: false,
        userAppId: "",
      };

      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      return returnObj;
    }
  };

  const handleLogin = async () => {
    if (loginResp && loginResp.error) {
      return;
    }

    const blockchainRes = await handleBlockchainSubmit(loginResp);
    if (!blockchainRes?.success) {
      return;
    }

    const payload = {
      // idToken: loginResp.tokenObj.id_token,
      // idToken: loginResp.accessToken,
      idToken: loginResp.credential,
      role: userslice.registerUserType,
      userAppId: blockchainRes.userAppId,
    };

    const backendRes: any = await handleGoogleRegister(payload);
    //expire 1970
    if (backendRes.success) {
      const payload = {
        loggedIn: true,
        userToken: backendRes.data.token,
        loginType: "google",
        registerUserType: userslice.registerUserType,
        userAppId: backendRes.data.userAppId ? backendRes.data.userAppId : "",
        kyc: backendRes.data.kyc,
        role: backendRes.data.role,
        userId: backendRes.data.userId,
      };
      dispatch(setUserToken(payload));
      setIsLoggedIn(true);
      const toastPaylaod = {
        text: t("registerWithGoogleSuccess"),
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    } else {
      const toastPaylaod = {
        text: backendRes.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    }

    dispatch(setRegisterModal(false));
    dispatch(setLoginModal(false));
  };

  return (
    <>
      <Button
        sx={{ height: "60%", width: "40%" }}>
        <GoogleLogin
          onSuccess={(response: any) => {
            setLoginResp(response);
            setShowWalletButtons(true);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          width="50px"
          text="continue_with"
        />
      </Button>
      {showWalletButtons && (
        <Wallets
          open={showWalletButtons}
          handleClose={() => setShowWalletButtons(false)}
          handleSubmit={handleLogin}
        />
      )}
    </>
  );
}

export default GoogleRegisterButton;

import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMintedNfts } from "../../../../blockchain";
import { getBougthNft } from "../../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  setAppLoadingText,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import ModalNftCard from "./ModalNftCard";

function BoughtNftContainer() {
  const { t } = useTranslation();
  //since the res from the api is uncertain so i am declaring this variable of type any now
  // const [nfts, setNfts] = useState<Array<any>>();
  // const [page, setPage] = useState<number>(1);
  // const [loadmore, setLoadmore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [blockchainNft, setBlockchainNft] = useState<Array<any>>();

  const user = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  // const loadMoreRef = useRef<boolean>();
  // const nftListRef = useRef<Array<any>>();
  // const pageRef = useRef<number>();

  // const fetchAllMyNft = async (pageno: number) => {
  // 	const data = {
  // 		limit: 8,
  // 		page: pageno,
  // 	};
  // 	setPage(pageno);
  // 	setLoading(true);
  // 	const res = await getBougthNft(data);
  // 	setLoading(false);
  // 	if (res.success) {
  // 		if (res.data.totalPages > pageno) {
  // 			setLoadmore(true);
  // 		} else {
  // 			setLoadmore(false);
  // 		}
  // 		if (nftListRef.current && pageno !== 1) {
  // 			setNfts([...nftListRef.current, ...res.data.nfts]);
  // 		} else {
  // 			setNfts([...res.data.nfts]);
  // 		}
  // 	}
  // };

  const fetchAllMyNftBlockchain = async () => {
    const info = {
      app_id: user.userAppId,
    };

    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(""));
    const data = await getMintedNfts(info);
    dispatch(toggleAppLoading(false));
    if (data.success) {
      setBlockchainNft([...data.data]);
    }
  };

  // const handleLoadMore = () => {
  // 	if (pageRef && pageRef.current) {
  // 		fetchAllMyNft(pageRef.current + 1);
  // 	}
  // };

  useEffect(() => {
    // fetchAllMyNft(1);
    fetchAllMyNftBlockchain();
  }, []);

  // useEffect(() => {
  // 	loadMoreRef.current = loadmore;
  // 	nftListRef.current = nfts;
  // 	pageRef.current = page;
  // }, [loadmore, nftListRef, page]);

  return (
    <>
      <Grid mb={3} container spacing={2}>
        {/* {nfts &&
					nfts.length > 0 &&
					nfts.map((nft, index) => {
						return <ModalNftCard data={nft} key={index} />;
					})} */}
        {blockchainNft &&
          blockchainNft.length > 0 &&
          blockchainNft.map((nft, index) => {
            return nft["frozen-state"] || nft["total"] <= 0 ? null : (
              <ModalNftCard data={nft} key={index} />
            );
          })}
        {(!blockchainNft || blockchainNft.length === 0) && (
          <Box
            sx={{
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "400px",
              border: "1px dashed",
              margin: "20px auto 0px",
            }}
          >
            <Typography>{t("pleaseBuySomeNftsBeforeProceeding")}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ textAlign: "center" }}>
          {/* {loadmore && !loading && (
						<Button
							sx={{ margin: "0 auto" }}
							variant="contained"
							onClick={handleLoadMore}
							color="primary"
						>
							Load More
						</Button>
					)} */}
        </Box>
      </Grid>
    </>
  );
}

export default BoughtNftContainer;

import { Box, Typography } from "@mui/material";
import React from "react";
import { messageInterface } from "./InvestorCreatorChat";
import { MessageColWrap, MessageRecieve } from "./styles";
import moment from "moment";

interface myMessage {
  data: messageInterface;
}

function SenderMessage({ data }: myMessage) {
  return (
    <Box sx={MessageColWrap}>
      <Box sx={MessageRecieve} style={{ position: "relative" }}>
        <Box className="messageSent">
          <Typography align="left">{data.content}</Typography>
        </Box>
        <Box className="time">
          <Typography align="left" variant="body2">
            {moment(data.createdAt).format("lll")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SenderMessage;

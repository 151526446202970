import { Box, Typography, Container, Grid, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: "#f1f2f3", marginTop: "30px" }}>
      <Container fixed>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Platform Privacy Policy
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Innofund Limited (“us”, “we”, or “our”) is committed to protecting
            the privacy of its customers. This Privacy Policy describes our
            practices with respect to the privacy of your personally
            identifiable information in connection with use of our Cashdillo
            Website (the “Website”) accessible at{" "}
            <a href="" target="_blank">
              https://cashdillo.com
            </a>{" "}
            and other subdomains and other related services (hereinafter
            referred to as the “Service”)
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            This page informs you of our policies regarding the collection, use
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions, accessible from{" "}
            <a href="" target="_blank">
              https://cashdillo.com
            </a>
            . This Privacy Policy applies regardless of whether you use a
            computer, mobile phone, tablet or other device to access our Service
          </p>
          <p style={{ textAlign: "left", fontSize: "40px" }}>Definitions</p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Service
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Service means{" "}
            <a href="" target="_blank">
              https://cashdillo.com
            </a>
            , including mobile and web application analytics and engagement
            services including any property/attribute used to deliver this
            service, such as our embedded SDKs, dashboards, databases, data
            storage, data query tools, API endpoints, etc., and any systems,
            platforms, software, interfaces, tools, utilities, templates,
            processes, or other technologies used to deliver such Service.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Personal Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Personal Data means data about a living individual who can be identi
            ed from those data (or from those and other information either in
            our possession or likely to come into our possession).
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Usage Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Personal Data means data about a living individual who can be identi
            ed from those data (or from those and other information either in
            our possession or likely to come into our possession).
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Device Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Device Data is data that includes the hardware model, IMEI number
            and other unique device identi ers, MAC address, IP address,
            operating system versions, and settings of the device you use to
            access the Service.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Profile Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Pro le Data is data that includes user preference and con guration
            data which can include acceptance of noti cation preference and
            system con guration information.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Cookies
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Cookies are small les stored on your device (computer or mobile
            device).
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Data Controller
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Data Controller means the natural or legal person who (either alone
            or jointly or in common with other persons) determines the purposes
            for which and the manner in which any personal information are, or
            are to be, processed.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            For the purpose of this Privacy Policy, we are a Data Controller of
            your Personal Data.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Data Processors (or Service Providers)
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Data Processor (or Service Provider) means any natural or legal
            person who processes the data on behalf of the Data Controller.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may use the services of various Service Providers in order to
            process your data more e ectively.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Data Subject (or User)
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Data Subject is any living individual who is using our Service and
            is the subject of Personal Data.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Information Collection and Use
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We collect several di erent types of information for various
            purposes to provide and improve our Service to you.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Types of Data Collected
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Personal Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            While using our Service, we may ask you to provide us with certain
            personally identi able information that can be used to contact or
            identify you (“Personal Data”). Personally identi able information
            may include, but is not limited to:
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>Email address </li>
            <li>First name and last name </li>
            <li>Phone number </li>
            <li>Address, State, Province, ZIP/Postal code, City </li>
            <li>Billing Information </li>
            <li>
              Cookies and Usage Data We may use your Personal Data to provide
              platform features to you and to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or the instructions provided in any email we send.{" "}
            </li>
          </ul>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Usage Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may also collect information on how the Service is accessed and
            used (“Usage Data”). This Usage Data may include information such as
            your computer’s Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Servicethat you visit, the
            time and date of your visit, the time spent on those pages, unique
            device identi ers and other diagnostic data.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Device Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may also collect data from the device you use to access the
            Service, including but not limited to your browser ngerprint,
            hardware model, IMEI number and other unique device identi ers, MAC
            address, IP address, operating system versions, and settings of the
            device you use to access the Service.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Profile Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may also collect data including preference and con guration data
            which can include your acceptance of noti cation preferences and
            system con guration information.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Location Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may use and store information about your location if you give us
            permission to do so (“Location Data”). We use this data to provide
            features of our Service, to improve and customize our Service.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You can enable or disable location services when you use our Service
            at any time by way of your device settings.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Tracking Cookies Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Cookies are les with a small amount of data which may include an
            anonymous unique identi er. Cookies are sent to your browserfrom a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyse our Service.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Examples of Cookies we use:
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>
              <p style={{ textAlign: "left", fontSize: "20px" }}>
                <b>Session Cookies.</b> We use Session Cookies to operate our
                Service.
              </p>
            </li>
            <li>
              <p style={{ textAlign: "left", fontSize: "20px" }}>
                <b>Preference Cookies. </b>We use Preference Cookies to remember
                your preferences and various settings.
              </p>
            </li>
            <li>
              <p style={{ textAlign: "left", fontSize: "20px" }}>
                <b>Security Cookies. </b> We use Security Cookies for security
                purposes.
              </p>
            </li>
          </ul>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Use of Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We use the collected data for various purposes:
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>To provide and maintain our Service</li>
            <li>To notify you about changes to our Service</li>
            <li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>
            <li>To provide customer support</li>
            <li>
              To gather analysis or valuable information so that we can improve
              our Service
            </li>
            <li>To monitor the usage of our Service</li>
            <li>To detect, prevent and address technical issues</li>

            <li>To provide fraud detection and protection</li>
            <li>
              To provide you with news, special o ers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information
            </li>
          </ul>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Legal Basis for Processing Personal Data under the General Data
            Protection Regulation (GDPR)
          </Typography>

          <p style={{ textAlign: "left", fontSize: "20px" }}>
            If you are from the European Economic Area (EEA), our legal basis
            for collecting and using the personal information described in this
            Privacy Policy depends on the Personal Data we collect and the speci
            c context in which we collect it. We may process your Personal Data
            because:
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>We need to perform a contract with you</li>
            <li>You have given us permission to do so</li>
            <li>
              The processing is in our legitimate interests and it is not
              overridden by your rights
            </li>
            <li>For payment processing purposes</li>
            <li>To comply with the law</li>
          </ul>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Retention of Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes and enforce
            our legal agreements and policies.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period of time, except when
            this data is used to strengthen the security or toimprove the
            functionality of our Service, or we are legally obligated to retain
            this data for longer periods.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Transfer of Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may di er from those of your jurisdiction.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Cashdillo will take all the steps reasonably necessary to ensure
            that your data is treated securely and in accordance with this
            Privacy Policy and no transfer of your Personal Data will take place
            to an organisation or a country unless there are adequate controls
            in place including the security of your data and other personal
            information.
          </p>
          <p style={{ textAlign: "left", fontSize: "40px" }}>
            Disclosure of Data
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Disclosure for Law Enforcement
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Under certain circumstances, we may be required to disclose your
            Personal Data if required to do so by law or in response to valid
            requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Legal Requirements
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may disclose your Personal Data in the good faith belief that
            such action is necessary to:
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property</li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </li>
            <li>
              To protect the personal safety of users of the Service or the
              public
            </li>
            <li>To protect against legal liability</li>
          </ul>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Security of Data
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Our Policy on “Do Not Track” Signals under the California Online
            Protection Act (CalOPPA)
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We do not support Do Not Track (“DNT”). Do Not Track is a preference
            you can set in your web browser to inform websites that you do not
            want to be tracked.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Your Data Protection Rights under the General Data Protection
            Regulation (GDPR)
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. We aim to take reasonable steps to
            allow you to correct, amend, delete or limit the use of your
            Personal Data.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            If you wish to be informed about what Personal Data we hold about
            you and if you want it to be removed from our systems, please
            contact us.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            <b>
              The right to access, update or delete the information we have on
              you.
            </b>
            Whenever made possible, you can access, update or request deletion
            of your Personal Data directly within your account settings section.
            If you are unable to perform these actions yourself, please contact
            us to assist you.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            <b>The right of rectification.</b> You have the right to have your
            information recti ed if that information is inaccurate or
            incomplete.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            <b>The right to object.</b> You have the right to object to our
            processing of your Personal Data.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            <b>The right of restriction.</b> You have the right to request that
            we restrict the processing of your personal information.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            <b> The right to data portability.</b> You have the right to be
            provided with a copy of the information we have on you in a
            structured, machine-readable and commonly used format.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            <b>The right to withdraw consent.</b> You also have the right to
            withdraw your consent at any time where we relied on your consent to
            process your personal information.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Please note that we may ask you to verify your identity before
            responding to such requests.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Service Providers
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide the Service on our
            behalf, perform Service-related services or assist us in analysing
            how our Service is used, including KYC/AML veri cation.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Payments
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may provide paid products and/or services within the Service. In
            that case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to
            thestandards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint e ort of brands like Visa, MasterCard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            The payment processors we work with are:
          </p>
          <ul style={{ textAlign: "left", fontSize: "20px" }}>
            <li>
              Triple-A, Perawallet and other cryptocurrency wallet providers
            </li>
          </ul>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Links to Other Sites
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Children’s Privacy
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            Our Service does not address anyone under the age of 18
            (“Children”).
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We do not knowingly collect personally identi able information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from children without veri cation ofparental consent, we take steps
            to remove that information from our servers.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Changes to This Privacy Policy
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming e ective and update the “e
            ective date” at the top of this Privacy Policy.
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are e ective when they are
            posted on this page.
          </p>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Contact Us
          </Typography>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            If you have any questions about this Privacy Policy, please contact
            us by email:hello@cashillo.com{" "}
            {/* <a href="" target="_blank">
              hello@cashillo.com
            </a>{" "} */}
          </p>
        </Grid>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;

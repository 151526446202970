import { List } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRecentChats } from "../../../requests/authRequest";
import { useAppSelector } from "../../../store";
import { messageInterface } from "./InvestorCreatorChat";
import RecentChatRow from "./RecentChatRow";
import { recentChatWrapper } from "./styles";

export interface recentChatRowInterface {
  message: {
    messageId: string;
    content: string;
    sentBy: string;
    type: string;
    createdAt: string | Date;
    channelId: string;
  };
  channelId: string;
  type: string;
  name: string;
  unreadMessageCount: number;
  participants: [
    {
      lastReadAt: string | Date;
      userId: string;
    },
    {
      lastReadAt: string | Date;
      userId: string;
    }
  ];
  createdAt: string | Date;
  updatedAt: string | Date;
}

function RecentChatWrapper() {
  const [recentChats, setRecentChats] = useState<Array<recentChatRowInterface>>(
    []
  );
  const chatSocket = useAppSelector((state) => state.chatReducer.chatSocket);
  const currentActiveChat = useAppSelector(
    (state) => state.chatReducer.currentActiveChat
  );

  const fetchRecentChats = async () => {
    const res = await getRecentChats();
    if (res.success) {
      setRecentChats([...res.data]);
    }
  };

  useEffect(() => {
    fetchRecentChats();
  }, []);

  useEffect(() => {
    if (chatSocket) {
      chatSocket.on("newMessage", (data: messageInterface) => {
        fetchRecentChats();
      });
      chatSocket.on("readMessage", (data: messageInterface) => {
        fetchRecentChats();
      });
    }
  }, [chatSocket, currentActiveChat]);

  return (
    <List sx={recentChatWrapper}>
      {recentChats.length > 0 &&
        recentChats.map((recentChat) => {
          return <RecentChatRow data={recentChat} />;
        })}
    </List>
  );
}

export default RecentChatWrapper;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardNav from "./DashboardNav";
import MainSiteNav from "./MainSiteNav";

function Layout() {
  const location = useLocation();
  const [isDashboard, setIsDasboard] = React.useState(false);

  useEffect(() => {
    if (location.pathname.startsWith("/user")) {
      setIsDasboard(true);
    } else {
      setIsDasboard(false);
    }
  }, [location.pathname]);

  return <>{!isDashboard ? <MainSiteNav /> : <DashboardNav />}</>;
}

export default Layout;

import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { logoutUser } from "../../../requests/authRequest";
import {
  loggedInUser,
  setUserData,
  setUserToken,
} from "../../../store/slices/UserSlice";
import { useTranslation } from "react-i18next";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function InstitutionalNav() {
  const { t } = useTranslation();
  const [value, setValue] = useState(4);
  const navigate = useNavigate();
  const navigations = [
    "match-funds",
    "my-campaign",
    "",
    "",
    "",
    "payment-setting",
    "fund-campaign",
    "chat",
  ];

  const location = useLocation();
  const dispatch = useAppDispatch();
  const userslice = useAppSelector((state) => state.userReducer);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 2) {
      navigate("/");
    }
    if (newValue !== 4) {
      navigate(`/user/${navigations[newValue]}`);
    }
  };

  const handleLogout = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(toggleAppLoading(true));
    const res: any = await logoutUser();
    if (res.success) {
      const payload = {
        loggedIn: false,
        userToken: "",
        loginType: "",
        registerUserType: "",
        userAppId: "",
        kyc: "",
      };
      dispatch(setUserToken(payload));
      const userPayload: loggedInUser = {
        status: "",
        name: "",
        email: "",
        userId: "",
        createdAt: "",
        address: "",
        gender: "Other",
        phone: "",
        country: "",
        role: "",
        userAppId: "",
        organisationOwner: "",
        organisationName: "",
        orgnisationRole: "",
      };
      dispatch(setUserData(userPayload));
      dispatch(toggleAppLoading(false));
      navigate("/");
      const toasterData = {
        active: true,
        text: "User logged out successfully",
        success: true,
      };

      dispatch(setToaster(toasterData));
    } else {
      dispatch(toggleAppLoading(false));

      const toasterData = {
        active: true,
        text: res.data.message,
        success: false,
      };

      dispatch(setToaster(toasterData));
    }
  };

  useEffect(() => {
    if (location.pathname === "/user") {
      setValue(0);

      navigate(`/user/${navigations[0]}`);
    } else {
      setValue(
        navigations.indexOf(location.pathname.split("/user")[1].split("/")[1])
      );
    }
  }, [location.pathname]);

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: "divider", marginBottom: "40px" }}
    >
      {userslice.role === "institutional-donor" && (
        <Tab label={t("matchFunds")} {...a11yProps(0)} />
      )}

      <Tab label={t("myCampaigns")} {...a11yProps(1)} />
      {userslice.role === "institutional-donor" && (
        <Tab disabled label={t("grantProgram")} {...a11yProps(2)} />
      )}
      {userslice.role === "institutional-donor" && (
        <Tab disabled label={t("esgAndSdgReport")} {...a11yProps(3)} />
      )}
      {userslice.role === "institutional-donor" && (
        <Tab disabled label={t("decentralisedVoting")} {...a11yProps(4)} />
      )}

      <Tab label={t("paymentSettings")} {...a11yProps(5)} />
      <Tab label={t("fundCampaigns")} {...a11yProps(6)} />
      <Tab label={t("chat")} {...a11yProps(7)} />
      {/* <Tab label="Logout" onClick={handleLogout} {...a11yProps(4)} /> */}
    </Tabs>
  );
}

export default InstitutionalNav;

import {
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { blackBoxHeading, MilestoneForm } from "./milestoneSetStyles";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

interface milestone {
  title: string;
  defination: string;
  deadline: Date | string;
  delivereble: any;
}

interface milestoneSet {
  milestoneOne: milestone;
  milestoneTwo: milestone;
  // milestoneThree: milestone;
  invalidDateOne: boolean;
  invalidDateTwo: boolean;
  invalidTitleOne: boolean;
  invalidTitleTwo: boolean;
  invalidDefinitionOne: boolean;
  invalidDefinitionTwo: boolean;

  // invalidDateThree: boolean;
  setMilestoneOne: React.Dispatch<React.SetStateAction<milestone>>;
  setMilestoneTwo: React.Dispatch<React.SetStateAction<milestone>>;
  // setMilestoneThree: React.Dispatch<React.SetStateAction<milestone>>;
  headerText?: string;
}

// function MilestoneSet({milestoneOne, milestoneTwo, milestoneThree, setMilestoneOne, setMilestoneTwo, setMilestoneThree}: milestoneSet) {
function MilestoneSet({
  headerText,
  milestoneOne,
  milestoneTwo,
  // milestoneThree,
  invalidDateOne,
  invalidDateTwo,
  // invalidDateThree,
  setMilestoneOne,
  setMilestoneTwo,
  invalidTitleOne,
  invalidTitleTwo,
  invalidDefinitionOne,
  invalidDefinitionTwo,
}: // setMilestoneThree,
milestoneSet) {
  const { t } = useTranslation();
  document
    .getElementById("milOneDeadline")
    ?.setAttribute("disabled", "disabled");
  document
    .getElementById("milTwoDeadline")
    ?.setAttribute("disabled", "disabled");
  const validateMilestoneTitle = (title: string) => {
    let validity = true;
    if (title.trim().length <= 0) {
      validity = false;
    } else if (title.length > 100) {
      validity = false;
    }
    return validity;
  };

  const validateMilestoneDefination = (description: string) => {
    let validity = true;
    if (description.trim().length <= 0) {
      validity = false;
    } else if (description.trim().length > 3000) {
      validity = false;
    }

    return validity;
  };

  return (
    <>
      <Box sx={MilestoneForm}>
        <Typography variant="h4" mt={5} align="center" mb={2}>
          {headerText && headerText.length > 0
            ? headerText
            : t("createMilestones")}
        </Typography>
        <Grid
          container
          sx={{ marginTop: "20px", justifyContent: "center" }}
          spacing={2}
        >
          <Grid
            item
            md={6}
            sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
          >
            <Box
              // variant="contained"
              color="primary"
              sx={blackBoxHeading}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {t("milestoneOne")}
            </Box>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              placeholder={t("title") || ""}
              value={milestoneOne.title}
              onChange={(e) => {
                setMilestoneOne({ ...milestoneOne, title: e.target.value });
              }}
              variant="outlined"
              error={invalidTitleOne}
              helperText={
                invalidTitleOne && t("pleaseEnterTheTitleOflengthOneToHundred")
              }
            />
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("definition") || ""}
              value={milestoneOne.defination}
              onChange={(e) => {
                setMilestoneOne({
                  ...milestoneOne,
                  defination: e.target.value,
                });
              }}
              variant="outlined"
              error={invalidDefinitionOne}
              helperText={
                invalidDefinitionOne &&
                t("pleaseEnterTheDescriptionOfLengthOneToThreethousand")
              }
            />
            <Box sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">Deadline</FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={milestoneOne.deadline}
                    disablePast
                    onChange={(newValue: any) => {
                      if (newValue !== null) {
                        setMilestoneOne({
                          ...milestoneOne,
                          deadline: new Date(newValue),
                        });
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} id={"milOneDeadline"} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              {invalidDateOne && (
                <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                  {t("pleaseSelectFutureDateAfterCampaignEndDate")}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
          >
            <Box
              // variant="contained"
              color="primary"
              sx={blackBoxHeading}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {t("milestoneTwo")}
            </Box>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              placeholder={t("title") || ""}
              value={milestoneTwo.title}
              onChange={(e) => {
                setMilestoneTwo({ ...milestoneTwo, title: e.target.value });
              }}
              variant="outlined"
              error={invalidTitleTwo}
              helperText={
                invalidTitleTwo && t("pleaseEnterTheTitleOflengthOneToHundred")
              }
            />
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("definition") || ""}
              value={milestoneTwo.defination}
              onChange={(e) => {
                setMilestoneTwo({
                  ...milestoneTwo,
                  defination: e.target.value,
                });
              }}
              variant="outlined"
              error={invalidDefinitionTwo}
              helperText={
                invalidDefinitionTwo &&
                t("pleaseEnterTheDescriptionOfLengthOneToThreethousand")
              }
            />
            <Box sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">Deadline</FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={milestoneTwo.deadline}
                    disablePast
                    onChange={(newValue: any) => {
                      if (newValue !== null) {
                        setMilestoneTwo({
                          ...milestoneTwo,
                          deadline: new Date(newValue),
                        });
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} id={"milTwoDeadline"} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              {invalidDateTwo && (
                <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                  {t("pleaseSelectFutureDateAfterMilestoneOneDeadline")}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          {/* <Grid
						item
						lg={4}
						md={6}
						sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
					>
						<Box
							// variant="contained"
							color="primary"
							sx={blackBoxHeading}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							Milestone 3
						</Box>
						<TextField
							sx={{ marginBottom: 3 }}
							fullWidth
							hiddenLabel
							type={"text"}
							placeholder="Title"
							value={milestoneThree.title}
							onChange={(e) => {
								setMilestoneThree({ ...milestoneThree, title: e.target.value });
							}}
							variant="outlined"
							error={!validateMilestoneTitle(milestoneThree.title)}
							helperText={
								!validateMilestoneTitle(milestoneThree.title) &&
								"Please enter the title of length 1 to 100"
							}
						/>
						<TextField
							sx={{ marginBottom: 3 }}
							fullWidth
							hiddenLabel
							multiline
							minRows={4}
							type={"text"}
							placeholder="Definition"
							value={milestoneThree.defination}
							onChange={(e) => {
								setMilestoneThree({
									...milestoneThree,
									defination: e.target.value,
								});
							}}
							variant="outlined"
							error={!validateMilestoneDefination(milestoneThree.defination)}
							helperText={
								!validateMilestoneDefination(milestoneThree.defination) &&
								"Please enter the description of length 1 to 3000"
							}
						/>
						<Box sx={{ marginBottom: 3 }}>
							<FormLabel component="legend">Deadline</FormLabel>
							<LocalizationProvider dateAdapter={DateAdapter}>
								<Stack spacing={3}>
									<DatePicker
										inputFormat="YYYY-MM-DD"
										value={milestoneThree.deadline}
										onChange={(newValue) => {
											if (newValue !== null) {
												setMilestoneThree({
													...milestoneThree,
													deadline: newValue,
												});
											}
										}}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Stack>
							</LocalizationProvider>
							{invalidDateThree && (
								<FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
									Please select a future date after Milestone two deadline
								</FormHelperText>
							)}
						</Box>
					</Grid> */}
        </Grid>
      </Box>
    </>
  );
}

export default MilestoneSet;

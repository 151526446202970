export const TabStyle = {
  "& .MuiTabs-root": {
    border: "none",
    "@media (max-width:1200px) ": {
      marginBottom: "30px",
    },
    "& .MuiButtonBase-root": {
      maxWidth: "312px",
      height: "62px",
      fontSize: "29px",
      textTransform: "capitalize",
      backgroundColor: "#c7c9c9",
      fontFamily: "TTNormsMedium",
      marginBottom: { md: "30px", xs: "10px" },
      borderRadius: "8px",
      color: "black.main",
      "&:last-child": {
        marginBottom: "0px",
      },
      "&.Mui-selected": {
        backgroundColor: "green.main",
      },
      "@media (max-width:1600px) ": {
        fontSize: "24px",
        height: "auto",
      },
      "@media (max-width:1200px) ": {
        fontSize: "18px",
        width: "220px",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
};

import { t } from "i18next";
const URL = process.env.REACT_APP_BLOCKCHAIN_URL;
var axios = require("axios");

export const createAccountBlockchain = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/create_account`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingAccount") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const confirmTransaction = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/get_confirmation`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorConfirmingTheTransaction") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const saveEditedProfileBlockchain = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/update_account`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorSavingInfoToTheBlockchain") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createNftUser = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/create_asset`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingNft") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMintedNfts = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/total_nft/${info.app_id}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorFetchingNft") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getBlockchainNftById = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/nft_info/${info.nftId}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorGettingNft") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createCampaignBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/create_campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingCampaign") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const bindNftToCampaign = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/campaign_nft`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingCampaign") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteCampaignBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/delete_campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorDeletingCampaign") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const updateCampaignBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/update_campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingCampaign") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const startMilestoneOne = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/start_milestone`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingCampaign") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const participateCampaignBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/participating`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingCampaign") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const claimMilestoenOneMoney = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/claim_milestone_1`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("initialPaymentIsAlreadyClaimed") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const checkInitialPaymentClaimed = async (info) => {
  const config = {
    method: "get",
    url: `${URL}/check_initial_payment/${info.campaignAppId}`,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("initialPaymentIsAlreadyClaimed") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};
export const checkInitialFundedPayment = async (info) => {
  const config = {
    method: "get",
    url: `${URL}/campaign_details/${info.campaignAppId}`,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("initialPaymentIsAlreadyClaimed") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const investorClaimNft = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/investor_nft_claimed`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("initialPaymentIsAlreadyClaimed") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const checkInvestorNftClaim = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/investor_nft_claimed/${info.nftId}/${info.userAppId}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("nftIsAlreadyClaimed") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMaxDonationAmount = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/campaign_details/${info.campaignAppId}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("nftIsAlreadyClaimed") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const payMatchFund = async (info) => {
  const data = info;
  var config = {
    method: "post",
    url: `${URL}/multiple_investments`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorConfirmingTheTransaction") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const transferToCampaign = async (info) => {
  const data = info;
  var config = {
    method: "post",
    url: `${URL}/transfer_to_campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorConfirmingTheTransaction") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createCreatorAccountBlockchain = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/grant_creator/create_account`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingAccount") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createApplicantAccountBlockchain = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/grant_applicant/create_account`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingAccount") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const addGrantManagerBlockchain = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/grant_creator/create_manager
		`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingAccount") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createGrantBlockchain = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/create_grant
		`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorCretingAccount") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteGrantBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/delete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorDeletingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const updateGrantBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/edit_grant`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorUpdatingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const submitGrantAppBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_applicant/submit_application`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorUpdatingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteGrantManagerBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/manager/remove`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorDeletingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMarketplaceNft = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/nft_marketplace`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorFetchingNft") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const buyNft = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/nft_marketplace/buy_nft`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorInBuyNft") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getTotalNft = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/total_nft/${info.user_app_id}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorFetchingNft") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const acceptGrantApplicationBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/approve_application`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorUpdatingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const approveGrantMilestone1Blockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/approve_milestone_1`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorUpdatingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const approveGrantMilestone2Blockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/approve_milestone_2`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorUpdatingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const rejectGrantMilestoneBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/milestone_reject`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorUpdatingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const rejectGrantApplicationBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/reject_application`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorUpdatingGrant") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getGrantBarPercentage = async (info) => {
  const config = {
    method: "get",
    url: `${URL}/grant_detail/${info}`,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("fundsFetched") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

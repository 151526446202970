import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { SessionWallet } from "algorand-session-wallet";
import algosdk from "algosdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getMaxDonationAmount,
  participateCampaignBlockchain,
} from "../../../blockchain";
import { donateCampaignDummy } from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { setAlgoDonationModal } from "../../../store/slices/ModalSlice";
import {
  ContentStyle,
  modalContainer,
  modalWrapper,
  titleStyle,
  textArea,
} from "./algoDonationModalStyles";
import { client, peraWallet } from "../../../wallets/algorand";
import { useWallet } from "@txnlab/use-wallet";
import { setCampaignView } from "../../../store/slices/CampaignViewSlice";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import algo from "../../../assets/images/algo.png";

function AlgoDonationModal() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const open = useAppSelector((state) => state.modalReducer.algoDonationModal);
  const donationCampaignAppId = useAppSelector(
    (state) => state.modalReducer.donationCampaignAppId
  );
  const paymentType = useAppSelector(
    (state) => state.campaignViewReducer.paymentType
  );

  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<string>("");
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  const [errorAmount, setErrorAmount] = useState<string>("");
  const userAppId = useAppSelector((state) => state.userReducer.userAppId);
  const [maxDonationAmount, setMaxDonationAmount] = useState(0);

  const { campaignId } = useParams();

  const handleGetMaxDonationAmount = async () => {
    if (donationCampaignAppId) {
      const info = {
        campaignAppId: donationCampaignAppId,
      };
      const res = await getMaxDonationAmount(info);

      if (res.success) {
        const maxAmount =
          (res.data.fundLimit - res.data.totalInvested) / 1000000;
        setMaxDonationAmount(maxAmount);
      }
    }
  };

  const handleClose = () => {
    // handle close logic
    setAmount("");
    setInvalidAmount(false);
    setErrorAmount("");
    const payload = {
      donationCampaignAppId: "",
      algoDonationModal: false,
    };
    dispatch(setAlgoDonationModal(payload));
    // window.location.reload();
  };

  const validateReason = () => {
    let res = true;
    if (isNaN(Number(amount)) || Number(amount) < 0 || Number(amount) === 0) {
      res = false;
      setInvalidAmount(true);
      setErrorAmount(`Please enter a amount greater than 0`);
    } else if (Number(amount) > maxDonationAmount) {
      res = false;
      setInvalidAmount(true);
      setErrorAmount(
        `Entered amount is more than the donation limit - ${maxDonationAmount} ${paymentType} `
      );
    } else {
      setInvalidAmount(false);
      setErrorAmount("");
    }
    return res;
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handlePay = async () => {
    const valid = validateReason();
    if (!valid) {
      return;
    }

    const totalAlgo = parseFloat(amount) * 1000000;
    handleFundBlockchain(totalAlgo.toString());
  };

  const handleFundBlockchain = async (amount: string) => {
    let locationData = window.location.pathname.split("/");

    if (locationData.length < 3) {
      const toastPaylaod = {
        text: t("invalidCampaignUrl"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      handleClose();
      return;
    }

    const info = {
      campaign_app_id: parseInt(donationCampaignAppId),
      amount: amount,
      investor_wallet_address: activeAccount?.address,
      metadata: {
        userAppId: userAppId,
      },
      paymentType: paymentType,
    };
    dispatch(toggleAppLoading(true));
    const unsignedAssignNftTxn = await participateCampaignBlockchain(info);

    if (!unsignedAssignNftTxn.success) {
      const toastPaylaod = {
        text: t("unableToMakePayment"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      handleClose();
      return;
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedAssignNftTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });

      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));
      let signed = [];

      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      dispatch(setAppLoadingText(t("waitingForConfirmation")));
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      let milestoneThreeDataBlockchain = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      // const transactionId = transactionArr[1].txn.txID();
      // const transactionId1 = transactionArr[2].txn.txID();
      let info = {};

      info = {
        campaignId: locationData[2],
        amount: amount,
        transactionId:
          paymentType === "ALGO"
            ? transactionArr[1].txn.txID()
            : transactionArr[2].txn.txID(),
        walletAddress: activeAccount?.address,
        paymentType: paymentType,
      };

      const res = await donateCampaignDummy(info);
      if (!res.success) {
        const toastPaylaod = {
          text: t("unableToStoreDataInBackendBlockchainTransactionIsSuccess"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        handleClose();
        return;
      }

      dispatch(setAppLoadingText(""));
      const toastPaylaod = {
        text: t("donationToCampaignWasSuccessfull"),
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      handleClose();
      window.location.reload();
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      handleClose();
    }
  };

  useEffect(() => {
    if (!campaignId || donationCampaignAppId === "") {
      handleClose();
    }
  }, [campaignId]);

  useEffect(() => {
    handleGetMaxDonationAmount();
  }, [donationCampaignAppId]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={modalWrapper}>
        <Box onClick={(e) => e.stopPropagation()} sx={modalContainer}>
          <Box sx={titleStyle}>
            <Typography variant="h4" align="center" mb={2}>
              {t("enterTheAmount")}({paymentType})
              {/* {paymentType === "ALGO" && (
                <img style={{ width: "16px", marginBottom: "-3px" }} src={algo} alt="ALGO" />
              )}
              {paymentType === "USDC" && (
                <MonetizationOnIcon style={{ marginBottom: "-4px", fontSize: "28px", color: "black" }} />
              )} */}
            </Typography>
          </Box>
          <Box sx={ContentStyle}>
            <TextField
              fullWidth
              hiddenLabel
              type={"number"}
              placeholder={t("enterDonationAmount") || ""}
              variant="outlined"
              onChange={(e) => {
                if (parseInt(e.target.value) > 1000000) {
                  setAmount("1000000");
                } else {
                  setAmount(e.target.value);
                }
              }}
              value={amount}
              error={invalidAmount}
              helperText={invalidAmount && errorAmount}
            />

            <Box
              sx={{
                textAlign: "center",
                justifyContent: "space-around",
                display: "flex",
              }}
              mt={3}
              mb={3}
            >
              <>
                <Button
                  onClick={() => handlePay()}
                  variant="contained"
                  color="primary"
                >
                  {t("submit")}
                </Button>
                {/* {showWalletButtons && (
                  <Wallets
                    open={showWalletButtons}
                    handleClose={() => setShowWalletButtons(false)}
                    handleSubmit={handlePay()}
                  />
                )} */}
              </>
              <Button
                onClick={() => handleClose()}
                variant="contained"
                color="error"
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default AlgoDonationModal;

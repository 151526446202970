import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { getAllGoals } from "../../requests/authRequest";
import NotificationToast from "../../loaders/NotificationToast";
import { useNavigate } from "react-router-dom";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch } from "../../store";
import { useTranslation } from "react-i18next";

function GoalsSelection({ goalIds, handleGoalIds, setStep, goals }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [allGoals, setAllGoals] = useState<
    { category: string; goalId: string; goalName: string; status: string }[]
  >([]);
  const navigate = useNavigate();

  const fetchGoals = async () => {
    const res = await getAllGoals();
    setAllGoals(res?.data?.goals);
    dispatch(toggleAppLoading(false));
  };

  useEffect(() => {
    dispatch(toggleAppLoading(true));
    fetchGoals();
  }, []);

  const handleGoalsSelection = () => {
    if (goalIds.length > 3) {
      const toastPaylaod = {
        text: t("pleaseSelectAtleastOneGoalOrMaxThreeGoals"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    } else {
      setStep("step2");
    }
  };

  return (
    <div>
      <Typography align="left" variant="h4">
        {t("buildYourPerfectEsgAlignedCsrProgram")}
      </Typography>
      <Typography align="left" variant="h6">
        {t("pleaseChooseMinimumOneAndMaximumThreeGoals")}
      </Typography>
      <Box sx={{ p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        {allGoals?.filter((el) => el?.category === "environmental").length !==
          0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("environmental")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "environmental")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
        {allGoals?.filter((el) => el?.category === "social").length !== 0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("social")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "social")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
        {allGoals?.filter((el) => el?.category === "governance").length !==
          0 && (
          <Typography variant="h3" align="left" mt={3} mb={3}>
            {t("governance")}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "governance")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.goalName}
                  </Typography>
                }
              />
            ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className="round-button"
          disabled={
            // (goals.findIndex((item: any) => item.category === "environmental") ===
            // 	-1 ||
            // 	goals.findIndex((item: any) => item.category === "social") === -1 ||
            // 	goals.findIndex((item: any) => item.category === "governance") === -1) &&
            goalIds.length === 0
          }
          onClick={() => handleGoalsSelection()}
        >
          {t("submit")}
        </Button>
      </Box>
      <NotificationToast />
    </div>
  );
}
export default GoalsSelection;

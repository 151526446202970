import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { campaignStateView } from "../ConstantTypes";

const initialState: campaignStateView = {
  rewardNftId: 0,
  reward: "",
  collectedAmount: 0,
  goalAmount: 0,
  video: "",
  youTubeLink: "",
  rewardType: "",
  campaignId: "",
  rating: "",
  campaignAppId: 0,
  location: "",
  expectedYearlyoutput: 0,
  expectedResults: 0,
  expectedCostPerUnit: 0,
  lat: "",
  lng: "",
  createdBy: {
    name: "",
    userId: "",
  },
  videoUrl: "",
  endDate: "",
  fundingCategory: {
    title: "",
    fundingCategoryId: "",
  },
  category: {
    title: "",
    categoryId: "",
  },
  description: "",
  title: "",
  paymentType: "",
  isReforestationProject: undefined,
  goalIds: [],
  isEsgEnabled: undefined,
  milestones: [
    {
      status: "",
      title: "",
      definition: "",
      deadline: "",
      deliverable: "",
      milestoneAppId: 0,
      milestoneId: "",
    },
  ],
  reviews: [],
  reviewers: [],
  donors: [],
  rewardBased: undefined,
  status: "",
  images: [],
};

export const campaignViewSlice = createSlice({
  name: "campaignViewSlice",
  initialState,
  reducers: {
    setCampaignView: (state, action: PayloadAction<campaignStateView>) => {
      return { ...action.payload }; // Create a new state object with updated values
    },
  },
});
export const { setCampaignView } = campaignViewSlice.actions;

export default campaignViewSlice.reducer;

import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { logoutUser } from "../../../requests/authRequest";
import {
  loggedInUser,
  setUserData,
  setUserToken,
} from "../../../store/slices/UserSlice";
import { setRewardType } from "../../../store/slices/CreateCampaignSlice";
import { useTranslation } from "react-i18next";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function CreatorSideNav() {
  const { t } = useTranslation();
  const [value, setValue] = useState(4);
  const navigate = useNavigate();
  const navigations = [
    "create-campaign",
    "my-campaign",
    "draft-campaign",
    "payment-setting",
    "notification",
    "user",
    "chat",
  ];
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue !== 7) {
      navigate(`/user/${navigations[newValue]}`);
    }
    if (newValue === 0) {
      const nftData = {
        rewardNftId: "",
        rewardType: "",
      };
      dispatch(setRewardType(nftData));
    }
  };

  const handleLogout = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(toggleAppLoading(true));
    const res: any = await logoutUser();
    if (res.success) {
      const payload = {
        loggedIn: false,
        userToken: "",
        loginType: "",
        registerUserType: "",
        userAppId: "",
        kyc: "",
      };
      dispatch(setUserToken(payload));
      const userPayload: loggedInUser = {
        status: "",
        name: "",
        email: "",
        userId: "",
        createdAt: "",
        address: "",
        gender: "Other",
        phone: "",
        country: "",
        role: "",
        userAppId: "",
        organisationOwner: "",
        organisationName: "",
        orgnisationRole: "",
      };
      dispatch(setUserData(userPayload));
      dispatch(toggleAppLoading(false));
      navigate("/");
      const toasterData = {
        active: true,
        text: "User logged out successfully",
        success: true,
      };

      dispatch(setToaster(toasterData));
    } else {
      dispatch(toggleAppLoading(false));

      const toasterData = {
        active: true,
        text: res.data.message,
        success: false,
      };

      dispatch(setToaster(toasterData));
    }
  };

  useEffect(() => {
    if (location.pathname === "/user") {
      setValue(0);
      navigate(`/user/${navigations[0]}`);
    } else {
      setValue(
        navigations.indexOf(location.pathname.split("/user")[1].split("/")[1])
      );
    }
  }, [location.pathname]);

  // value = 4 takes u time /user

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: "divider" }}
    >
      <Tab label={t("createCampaigns")} {...a11yProps(0)} />
      <Tab label={t("myCampaigns")} {...a11yProps(1)} />
      <Tab label={t("draftCampaigns")} {...a11yProps(2)} />
      <Tab label={t("paymentSettings")} {...a11yProps(3)} />
      <Tab label={t("notification")} {...a11yProps(4)} />
      <Tab label={t("user")} sx={{ display: "none" }} {...a11yProps(5)} />
      <Tab label={t("chat")} {...a11yProps(6)} />
    </Tabs>
  );
}

export default CreatorSideNav;

import { TabContext, TabList } from "@mui/lab";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HomeCampaignContainer from "../../../components/home/campaigns/HomeCampaignContainer";
import HomeGrantContainer from "../../../components/home/grants/HomeGrantContainer";
import Hero from "../../../components/home/hero/Hero";
import { CategoryTab } from "./styles";

function Home() {
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>("Campaigns");
  let tabs: { categoryId: number; slug: string; title: string }[] = [
    {
      categoryId: 1,
      slug: "Campaigns",
      title: t("campaigns"),
    },
    {
      categoryId: 2,
      slug: "Grants",
      title: t("grants"),
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  return (
    <div>
      <Hero />
      <Box>
        <TabContext value={tab}>
          <Box sx={CategoryTab}>
            <Container fixed>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: {
                      md: "calc(100% - 300px)",
                      xs: "calc(100% - 120px)",
                    },
                  }}
                >
                  <TabList onChange={handleChange}>
                    <Tabs
                      value={tab}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      {tabs &&
                        tabs.length > 0 &&
                        tabs?.map((cat) => {
                          return (
                            //   <Link
                            //     to={`/category/${cat.slug}`}
                            //     style={{ textDecoration: "none" }}
                            //   >
                            <Tab
                              key={cat?.categoryId}
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  {cat?.title}
                                </Typography>
                              }
                              value={cat?.slug}
                              // onClick={handleScroll}
                            />
                            //   </Link>
                          );
                        })}
                    </Tabs>
                  </TabList>
                </Box>
              </Box>
            </Container>
          </Box>
        </TabContext>
      </Box>
      {tab === "Campaigns" ? <HomeCampaignContainer /> : <HomeGrantContainer />}
    </div>
  );
}

export default Home;

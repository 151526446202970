export const resetPasswordWapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

export const resetPasswordForm = {
  width: "400px",
  maxWidth: "98%",
  margin: "0 auto",
};

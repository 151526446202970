import {
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import { DataGridWrap, footer } from "../../../components/user/grants/styles";
import CustomNoRowsOverlay from "../../../components/common/tables/CustomNoRowsOverlay";
import { getMyGrants } from "../../../requests/authRequest";
import { setDeleteGrantModal } from "../../../store/slices/ModalSlice";
import { userRole } from "../../../constants/common-enum";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import algo from "../../../assets/images/algo.png";
interface MyGrantRow {
  id: number;
  title: string;
  category: string;
  preferredLocation: string;
  amount: number;
  applicants: number;
  endDate: string;
  status: string;
  Action: string;
  Goal: string;
  createdBy: string;
  //   FundRaised: string;

  //   Deadline: string;

  //   campaignId: string;
  //   deleteCampaignAppId: string;
  //   deleteCampaignNftId: string;
  //   milestones: any;
}

const GrantList = () => {
  const { t } = useTranslation();
  const [grants, setGrants] = useState<Array<MyGrantRow>>([]);
  const [showGrantOptions, setShowGrantOptions] = useState<boolean>(false);
  const [selectedGrantType, setSelectedGrantType] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deleteGrantModalState = useAppSelector(
    (state) => state.modalReducer.deleteGrantModal
  );
  const userslice = useAppSelector((state) => state.userReducer);

  const isGrantEndDateExpired = (grantEndDate: any) => {
    const currentDate = new Date();
    return new Date(grantEndDate) < currentDate;
  };
  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   maxWidth: 60,
    //   sortable: false,
    //   flex: 1,
    //   // align: "center",
    // },
    {
      field: "title",
      headerName: "Title",
      // minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "grantType",
      headerName: "Category",
      sortable: false,
      // minWidth: 110,
      flex: 1,
    },
    {
      field: "preferredLocation",
      headerName: "Country",
      sortable: false,
      // minWidth: 110,
      flex: 1,
      valueFormatter: (params: any) => {
        if (params.value == null) {
          return "";
        }
        return params.value
          .toString()
          .replace(
            params.value.toString().charAt(0),
            params.value.toString().charAt(0).toUpperCase()
          );
      },
    },
    {
      field: "totalBudget",
      headerName: "Amount",
      sortable: false,
      // minWidth: 140,
      flex: 1,
    },
    {
      field: "totalGrants",
      headerName: "Applicants",
      sortable: false,
      // minWidth: 130,
      flex: 1,
      align: "center",
    },
    {
      field: "endDate",
      headerName: "End Date",
      sortable: false,
      minWidth: 160,
      flex: 1,
      valueFormatter: (params: any) => {
        if (params.value == null) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD");
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Typography
            variant="h6"
            sx={{
              color:
                params.row.status === "completed" ||
                params.row.status === "active"
                  ? "#00FF00"
                  : params.row.status === "rejected" ||
                    params.row.status === "blocked"
                  ? "#ff0000"
                  : params.row.status === "pending" ||
                    params.row.status === "incomplete"
                  ? "#FFA500"
                  : "#000",
              textTransform: "capitalize",
            }}
          >
            {params.row.status}
          </Typography>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      // minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        const handleEdit = (e: any) => {
          e.stopPropagation();
          navigate(`/user/edit-grant/${params.id}`);
        };

        const handleDelete = (e: any) => {
          e.stopPropagation();
          const payload = {
            deleteGrantModal: true,
            deleteGrantId: params.row.grantId,
            deleteGrantAppId: params.row.grantAppId,
            paymentTypeBlockchain: params.row.paymentTypeBlockchain,
          };
          dispatch(setDeleteGrantModal(payload));
        };

        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/grant/${params.id}`);
        };

        return (
          <Box>
            {params.row.status === "pending" &&
            userslice.userId === params.row.createdBy ? (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleView}>
                <VisibilityIcon />
              </IconButton>
            )}
            {(params.row.status === "pending" ||
              params.row.status === "rejected" ||
              // params.row.status === "completed" ||
              params.row.status === "incomplete") &&
              userslice.userId === params.row.createdBy &&
              isGrantEndDateExpired(params.row.createdAt) && (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
          </Box>
        );
      },
    },
  ];

  const fetchGrants = async () => {
    setloading(true);
    const data: any = {
      page: page,
      limit: 10,
    };
    if (userslice.role === userRole.GRANT_CREATOR) {
      data.organisationOwner = userslice.userId;
    }

    const res = await getMyGrants(data);
    setloading(false);

    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    setEndPage(res.data.totalPages);
    const finalGrants = res.data.grants?.map((grant: any, index: number) => {
      return { ...grant, id: index + 1 + (res.data.currentPage - 1) * 10 };
    });
    setloading(false);
    setGrants(finalGrants || []);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchGrants();
  }, [page]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  useEffect(() => {
    if (!deleteGrantModalState) {
      fetchGrants();
    }
  }, [deleteGrantModalState]);
  const handleClose = () => {
    setShowGrantOptions(false);
  };

  return (
    <>
      <Box>
        <Box
          mb={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" align="left">
            {t("listOfAllGrants")}
          </Typography>

          <Button
            onClick={() => setShowGrantOptions(true)}
            variant="contained"
            color="primary"
            className="round-button"
            sx={{
              marginLeft: "10px",
              "& .btnText": {
                display: "none",
              },
              "&:hover": {
                "& .btnText": {
                  display: "block",
                },
              },
            }}
          >
            <Typography className="btnText">{t("createGrant")}</Typography>
            <AddIcon sx={{ color: "#000" }} />
          </Button>
        </Box>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={grants}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            getRowId={(row) => row.grantId}
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Dialog
        className="create-grant"
        open={showGrantOptions}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              bgcolor: "#eef0f0",
              borderRadius: "12px",
              margin: "32px",
              padding: 4,
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" align="center" mb={2}>
            {t("createNewGrant")}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="caption">
              {t("selectTheTypeToCreateNewGrant")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <List sx={{ pt: 0 }}>
            <ListItem
              sx={{
                border: "#cbcbcb 2px solid",
                padding: "10px",
                cursor: "pointer",
                borderRadius: "10px",
                mb: "15px",
              }}
              button
              onClick={() => setSelectedGrantType("standard")}
              key="standard"
              selected={selectedGrantType === "standard"}
            >
              <ListItemText primary={"Use Standard Grant Template "} />
            </ListItem>
            <ListItem
              sx={{
                border: "#cbcbcb 2px solid",
                padding: "10px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              button
              onClick={() => setSelectedGrantType("custom")}
              key="custom"
              selected={selectedGrantType === "custom"}
            >
              <ListItemText primary={"Create Custom Grant Program"} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button
            variant="contained"
            disabled={!selectedGrantType}
            onClick={() =>
              navigate(
                selectedGrantType === "standard"
                  ? "/user/create-standard-grant"
                  : "/user/create-custom-grant"
              )
            }
          >
            {t("continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GrantList;

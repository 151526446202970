export const UploadBox = {
  height: "230px",
  width: "100%",
  //   bgcolor: "white.main",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  borderBottom: "1px solid",
  borderColor: "secondary.light",
  marginBottom: 1,
};
export const Range = {
  "& .RangeBox": {
    height: "15px",
    width: "100%",
    border: "1px solid",
    borderColor: "secondary.light",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "flex-start",
    "& .rangecolor": {
      height: "10.3px",
      width: "40%",
      bgcolor: "green.main",
      borderRadius: "8px",
      maxWidth: "calc(100% - 4px)",
      marginLeft: "2px",
      position: "relative",
      //   "&:after": {
      //     content: "$100",
      //     position: "absolute",
      //     right: "0px",
      //     fontFamily: "TTNormsMedium",
      //     fontSize: "12px",
      //     top: "20px",
      //   },
    },
  },
  "& .valuebox": {
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
export const LinkBox = {
  backgroundColor: "white.main",
  display: " block",
  padding: " 10px",
  borderRadius: " 8px",
  borderTop: "4px solid",
  borderColor: "green.main",
  boxShadow: "0px 0px 5px 4px #eaeaea",
  transition: "transform .2s",
  "&:hover": {
    transform: " scale(1.05)",
  },
};
export const HeadingBox = {
  color: "#000",
  marginTop: "20px",
  padding: "8.25px 0px",
  display: "inline-block",
  borderRadius: "4px",
  minWidth: "20%",
  fontSize: "25px",
  textTransform: "uppercase",
  fontWeight: "600",
  fontFamily: "TTNormsMedium",
  "&:after": {
    content: '""',
    borderBottom: "7px solid",
    borderColor: "green.main",
    display: "block",
    borderRadius: " 8px",
    width: " 100px",
    margin: "7px auto -25px",
  },
};
export const CategoryTab = {
  backgroundColor: "#fff",
  marginTop: "-7px",
  padding: "8.25px 0px",
  "& .MuiTabs-root": {
    width: "100%",
    "& .MuiTab-root": {
      fontFamily: "TTNormsRegular",
      fontSize: "26px",
      textTransform: "none",
      minWidth: "20%",
      color: "black.main",
      "&.Mui-selected": {
        color: "black.main",
        backgroundColor: "#50c749",
      },
      "@media (max-width:1200px) ": {
        fontSize: "20px",
      },
      "@media (max-width:992px) ": {
        fontSize: "16px",
      },
      "@media (max-width:767px) ": {
        minWidth: "inherit",
      },
    },
    "@media (max-width:599px) ": {
      "& .MuiTabScrollButton-root": {
        display: "inline-flex",
      },
    },
  },
};
export const PreviewGrid = {
  padding: "60px 0px",
};

export const CommentInputWrapper = {
  display: "flex",
  flexDirection: "column",
};

export const UploadBoxRewardNft = {
  height: "150px",
  width: "100%",
  bgcolor: "white.main",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  marginBottom: 1,
};

import React, { useEffect, useRef, useState } from "react";
import { Stack, Button, Box, IconButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { PhotoUpload } from "./styles";
import { InputFiles } from "typescript";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setToaster } from "../../../store/slices/LoadinAndNotifSlice";
import { useTranslation } from "react-i18next";

interface inputProps {
  image: any | undefined;
  setImage: React.Dispatch<React.SetStateAction<any | undefined>>;
  imageSelected: boolean;
  setImageSelected: React.Dispatch<React.SetStateAction<boolean>>;
  imageName: any;
  setImageName: React.Dispatch<React.SetStateAction<any>>;
  imageRef: React.MutableRefObject<HTMLElement | undefined>;
  imageId: number;
}

function UploadImage({
  image,
  setImage,
  imageName,
  setImageName,
  imageSelected,
  setImageSelected,
  imageRef,
  imageId,
}: inputProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const imgTempUrl = useAppSelector(
    (state) => state.campaignPreviewReducer.imageUrl
  );

  const handleUploadChange = (e: any) => {
    const file = e.target.files;
    let fileType = file[0].name.split(".")[file[0].name.split(".").length - 1];
    if (
      fileType.toLowerCase() !== "png" &&
      fileType.toLowerCase() !== "jpeg" &&
      fileType.toLowerCase() !== "jpg"
    ) {
      setImageSelected(false);
      setImageName([]);
      setImage([]);
      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectPngJpgOrJpegImageFile"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (file[0].size / 1000000 > 5) {
      setImageSelected(false);
      setImageName([]);
      setImage([]);
      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectImageFileSmallerThanFiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (
      file[0] !== undefined &&
      file[0].name.length > 0 &&
      Math.floor(file[0].size / 1000000) < 5
    ) {
      setImageSelected(true);

      image?.length < 5
        ? setImage((prev: any) => [...prev, file[0]])
        : setImageSelected(false);

      if (imageRef && imageRef.current) {
        // imageRef.current.style.backgroundImage = imageName[imageId];
        // imageRef.current.style.backgroundImage = `url(${URL.createObjectURL(
        //   e.target.files[0]
        // )})`;
        if (imageName.length < 5) {
          setImageName((prev: any) => [
            ...prev,
            `url(${URL.createObjectURL(e.target.files[0])})`,
          ]);
        } else {
          const toastPaylaod = {
            text: t("youCanNotUploadMoreThanFiveImages"),
            success: false,
            active: true,
          };
          dispatch(setToaster(toastPaylaod));
        }
      }
    }
  };

  const clearImageData = () => {
    setImage(image.filter((el: any) => el !== image[imageId]));
    setImageName(imageName.filter((el: any) => el !== imageName[imageId]));
    setImageSelected(false);
    if (imageRef && imageRef.current) {
      imageRef.current.style.backgroundImage =
        imageName.filter((el: any) => el !== imageName[imageId]).length > 0
          ? imageName[0]
          : "none";
    }
  };

  useEffect(() => {
    if (image && image.length > 0 && imageRef && imageRef.current) {
      imageRef.current.style.backgroundImage =
        imageName[imageId]?.search("blob:") === -1
          ? `url(${process.env.REACT_APP_URL}/api/file/image/${imageName[imageId]})`
          : imageName[imageId];
    }
  }, [imageName, imageId]);

  return (
    <>
      <Box ref={imageRef} sx={PhotoUpload} style={{ position: "relative" }}>
        {imageName?.length > 0 && (
          <IconButton onClick={clearImageData}>
            <CancelIcon />
          </IconButton>
        )}
      </Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <label htmlFor="contained-button-file">
          <input
            className="inputfile"
            accept=".jpeg, .png"
            id="contained-button-file"
            onChange={handleUploadChange}
            multiple={false}
            type="file"
          />
          <Button
            variant="text"
            component="span"
            className="uploadButton"
            startIcon={<FileUploadIcon />}
          >
            {t("imageUpload")}
          </Button>
        </label>
      </Stack>
    </>
  );
}

export default UploadImage;

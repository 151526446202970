export const mainContainer = {
  backgroundColor: "#fff",
  paddingX: "50px",
  paddingY: "25px",
  width: "90%",
  borderRadius: "5px",
};
export const filterMainTitle = {
  color: "black",
  fontSize: "25px",
  fontWeight: 900,
  marginBottom: "30px",
  textAlign: "center", // Add this line to center-align the text
};
export const filterTitle = {
  color: "black",
  fontSize: "20px",
  fontWeight: 700,
  marginBottom: "15px",
};

export const selectorStyle = {
  color: "#000",
  border: "1px solid #EAEDED",
  width: "200px",
};

import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { MilestoneForm, PhotoUpload } from "./style";
import { InputFiles } from "typescript";
import { useAppDispatch } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate, useParams } from "react-router-dom";
import {
  submitGrantMilestoneReport,
  submitMilestoneReport,
} from "../../../requests/authRequest";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.min.js";

const GrantMilestoneReport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // related to object met
  const [objectiveMet, setObjectiveMet] = useState<string>("true");

  // related to nft description
  const [description, setDescription] = useState<string>("");
  const [invalidDescription, setInvalidDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");

  // related to image upload
  const [file, setFile] = useState<InputFiles | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const fileRef = useRef<HTMLElement>();
  const [evidenceOneError, setEvidenceOneError] = useState<boolean>(false);
  // related to pdf upload
  const [filePdf, setFilePdf] = useState<InputFiles | undefined>();
  const [filePdfName, setFilePdfName] = useState<string>("");
  const [filePdfSelected, setFilePdfSelected] = useState<boolean>(false);
  const [filePdfUrl, setFilePdfUrl] = useState<string>("");
  const [evidenceTwoError, setEvidenceTwoError] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [pdfError, setPdfError] = useState<boolean>(false);

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const { applicationId, milestoneId } = useParams();

  const previousPage = (e: any) => {
    e.preventDefault();
    if (pageNumber === 1) {
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const nextPage = (e: any) => {
    e.preventDefault();
    if (pageNumber === numPages) {
      return;
    }
    setPageNumber(pageNumber + 1);
  };

  const handleUploadChangePdf = (e: any) => {
    clearPdfData();
    if (!evidenceTwoError) {
      setEvidenceTwoError(true);
    }
    const tempFile = e.target.files;
    let tempFileType: string = tempFile[0].name
      .split(".")
      [tempFile[0].name.split(".").length - 1].toLowerCase();

    // this part set the file type to display
    if (tempFileType && tempFileType.length > 0) {
      if (tempFileType !== "pdf") {
        clearPdfData();
        const toastPaylaod = {
          text: t("pleaseSelectPdfFile"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        return;
      }
    }
    // this part actually accpet and set the file data for the form
    if (
      tempFile[0] !== undefined &&
      tempFile[0].name.length > 0 &&
      Math.floor(tempFile[0].size / 1000000) < 25
    ) {
      setPdfError(false);
      setFilePdfSelected(true);
      setFilePdf(tempFile[0]);
      setFilePdfName(tempFile[0].name);
      setFilePdfUrl(`${URL.createObjectURL(tempFile[0])}`);
    }

    // this one checks for a valid size 25mb
    if (tempFile[0].size / 1000000 > 25) {
      clearPdfData();
      const toastPaylaod = {
        text: t("pleaseSelectFileLessThanTwentyfiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }
  };

  const handleUploadChangeImage = (e: any) => {
    clearImageData();
    if (!evidenceOneError) {
      setEvidenceOneError(true);
    }
    const tempFile = e.target.files;
    let tempFileType: string = tempFile[0].name
      .split(".")
      [tempFile[0].name.split(".").length - 1].toLowerCase();
    let isImage: boolean = false;
    // this part set the file type to display
    if (tempFileType && tempFileType.length > 0) {
      if (
        tempFileType === "jpeg" ||
        tempFileType === "jpg" ||
        tempFileType === "png" ||
        tempFileType === "gif" ||
        tempFileType === "svg"
      ) {
        setFileType("image");
        isImage = true;
      } else if (tempFileType === "mp4" || tempFileType === "webm") {
        setFileType("video");
        isImage = false;
      } else {
        clearImageData();
        const toastPaylaod = {
          text: t("pleaseSelectJpegJpgPngGifSvgMp4WebmFile"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        return;
      }
    }
    // this part actually accpet and set the file data for the form
    if (
      tempFile[0] !== undefined &&
      tempFile[0].name.length > 0 &&
      Math.floor(tempFile[0].size / 1000000) < 25
    ) {
      setFileError(false);
      setFileSelected(true);
      setFile(tempFile[0]);
      setFileName(tempFile[0].name);
      if (isImage && fileRef && fileRef.current) {
        fileRef.current.style.backgroundImage = `url(${URL.createObjectURL(
          tempFile[0]
        )})`;
      } else {
        setVideoUrl(URL.createObjectURL(tempFile[0]));
      }
    }

    // this one checks for a valid size 25mb
    if (tempFile[0].size / 1000000 > 25) {
      clearImageData();
      const toastPaylaod = {
        text: t("pleaseSelectFileLessThanTwentyfiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }
  };

  const clearImageData = () => {
    setFile(undefined);
    setFileName("");
    setFileSelected(false);
    setFileType("");
    if (fileRef && fileRef.current) {
      fileRef.current.style.backgroundImage = "none";
    }
  };
  const clearPdfData = () => {
    setFilePdf(undefined);
    setFilePdfName("");
    setFilePdfSelected(false);
    setFilePdfUrl("");
  };

  const handleRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    setObjectiveMet(e.target.value);
  };

  const validateForm = () => {
    let validity = true;

    if (!fileSelected) {
      setFileError(true);
    }
    if (!filePdfSelected) {
      setPdfError(true);
    }
    // for description
    if (description.trim().length <= 0 || description.trim().length > 3000) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription("Please enter the description of length 1 to 3000");
    } else {
      setInvalidDescription(false);
      setErrorDescription("");
    }

    // for file selected
    if (!fileSelected || file === undefined) {
      validity = false;
    }

    // for pdf file selected
    if (!filePdfSelected || filePdf === undefined) {
      validity = false;
    }

    return validity;
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    // setPageNumber(1);
  };
  const submitReport = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    const info = {
      milestoneId: milestoneId,
      objectiveMet: objectiveMet,
      imageFile: file,
      description: description,
      pdfFile: filePdf,
    };

    const res = await submitGrantMilestoneReport(info);

    if (res.success) {
      const toastPaylaod = {
        text: t("milestoneReportSubmitted"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      handleGoBack();
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
      return;
    }
  };
  const handleGoBack = () => {
    navigate(`/user/view-application/${applicationId}`);
  };
  return (
    <Box sx={MilestoneForm}>
      <Typography variant="h4" align="center" mb={2}>
        {t("milestonesStandardReport")}
      </Typography>
      <Grid container sx={{ marginTop: "20px" }} spacing={2}>
        <ol>
          <li>{t("didYouMeetYourMilestoneObjective")}</li>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={objectiveMet}
            name="radio-buttons-group"
            onChange={handleRadioButton}
          >
            <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
            <FormControlLabel value={"false"} control={<Radio />} label="No" />
          </RadioGroup>
          <li>{t("pleaseUploadPhotographicOrVideoEvidence")}</li>
          <Box ref={fileRef} sx={PhotoUpload} style={{ position: "relative" }}>
            {fileSelected && (
              <IconButton onClick={clearImageData}>
                <CancelIcon />
              </IconButton>
            )}
            {fileType === "video" && file !== undefined && (
              <video width={"100%"} src={videoUrl} controls />
            )}
          </Box>
          {fileError && (
            <FormHelperText
              sx={{ marginBottom: 3 }}
              className="css-l8pll-MuiFormHelperText-root Mui-error"
            >
              {t("pleaseSelectImageOrVideoToUpload")}
            </FormHelperText>
          )}
          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="evidence-one">
              <input
                className="inputfile"
                accept="video/mp4,video/x-m4v,video/*, .jpeg, .png"
                id="evidence-one"
                onChange={handleUploadChangeImage}
                multiple={false}
                // ref={inputRef}
                type="file"
              />
              <Button
                variant="text"
                component="span"
                className="uploadButton"
                startIcon={<FileUploadIcon />}
              >
                {t("upload")}
              </Button>
            </label>
          </Stack>
          <li>{t("comments")}</li>
          <TextField
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            multiline
            minRows={4}
            type={"text"}
            placeholder={t("description") || ""}
            variant="outlined"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            error={invalidDescription}
            helperText={invalidDescription && errorDescription}
          />

          <li>{t("additionalSupportingDocs")}</li>
          {filePdfSelected && (
            <>
              <Document
                file={{
                  url: filePdfUrl,
                  // url: "https://4.tcp.ngrok.io:13022/api/file/62b9b624e507ba1c4e4495c2",
                }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <div>
                <p>
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </p>
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  style={{ marginRight: "20px" }}
                >
                  {t("previous")}
                </button>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  {t("next")}
                </button>
              </div>
            </>
          )}

          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="evidence-two">
              <input
                className="inputfile"
                accept=".pdf"
                id="evidence-two"
                onChange={handleUploadChangePdf}
                multiple={false}
                // ref={inputRef}
                type="file"
              />
              <Button
                variant="text"
                component="span"
                className="uploadButton"
                startIcon={<FileUploadIcon />}
              >
                {t("upload")}
              </Button>
            </label>
          </Stack>
          {pdfError && (
            <FormHelperText
              sx={{ marginBottom: 3 }}
              className="css-l8pll-MuiFormHelperText-root Mui-error"
            >
              {t("pleaseSelectPdfToUpload")}
            </FormHelperText>
          )}
        </ol>
      </Grid>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "40px",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={handleGoBack}
        >
          {t("back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={submitReport}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
};

export default GrantMilestoneReport;

import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { deleteCampaign } from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useState } from "react";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { setDeleteCampaignModal } from "../../../store/slices/ModalSlice";
import DangerousIcon from "@mui/icons-material/Dangerous";

import {
  modalContainer,
  modalWrapper,
  titleStyle,
} from "./deleteCampaignStyles";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../wallets/algorand";
import { deleteCampaignBlockchain } from "../../../blockchain";
import algosdk from "algosdk";
import { useTranslation } from "react-i18next";
import Wallets from "../../../wallets";

function DeleteCampaignModal() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const open = useAppSelector(
    (state) => state.modalReducer.deleteCampaignModal
  );
  const dispatch = useAppDispatch();
  const campaignId = useAppSelector(
    (state) => state.modalReducer.deleteCampaignId
  );

  const deleteCampaignAppId = useAppSelector(
    (state) => state.modalReducer.deleteCampaignAppId
  );
  const deleteCampaignNftId = useAppSelector(
    (state) => state.modalReducer.deleteCampaignNftId
  );

  const milestones = useAppSelector((state) => state.modalReducer.milestones);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const handleClose = () => {
    const payload = {
      deleteCampaignModal: false,
      deleteCampaignId: "",
      deleteCampaignAppId: "",
      deleteCampaignNftId: undefined,
      milestones: [],
    };
    dispatch(setDeleteCampaignModal(payload));
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleDeleteBlockchain = async () => {
    dispatch(toggleAppLoading(true));
    ///not function
    if (!deleteCampaignAppId) {
      return {
        success: true,
      };
    }

    // case for an old campaign without milestones
    if (deleteCampaignAppId && milestones.length === 0) {
      const toastPaylaod = {
        text: t("unableToProvideMilestonesToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    if (!deleteCampaignNftId) {
      const info = {
        campaign_app_id: deleteCampaignAppId,
        nft_id: 0,
        milestone_app_id: {
          "1": milestones[0].milestoneAppId,
          "2": milestones[1].milestoneAppId,
          // "3": milestones[2].milestoneAppId,
        },
      };
      dispatch(setAppLoadingText(t("sendingDataToBlockchain")));
      const unsignedRes = await deleteCampaignBlockchain(info);

      if (!unsignedRes.success) {
        const toastPaylaod = {
          text: unsignedRes.data,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return {
          success: false,
        };
      }

      try {
        dispatch(setAppLoadingText(t("signingTransaction")));
        dispatch(toggleAppLoading(false));
        const transactionArr = unsignedRes.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));

        const signed = [];
        signed.push(signedTxn[0]);
        signed.push(signedTxn[1]);
        signed.push(signedTxn[2]);
        // signed.push(signedTxns[3].blob);

        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();

        dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );
        // dispatch(setAppLoadingText(""));

        return {
          success: true,
        };
      } catch (e) {
        const toastPaylaod = {
          text: t("unableToDeleteNftFromBlockchain"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return {
          success: false,
        };
      }
    } else {
      const info = {
        campaign_app_id: deleteCampaignAppId,
        nft_id: deleteCampaignNftId,
        milestone_app_id: {
          "1": milestones[0].milestoneAppId,
          "2": milestones[1].milestoneAppId,
          // "3": milestones[2].milestoneAppId,
        },
      };
      dispatch(setAppLoadingText(t("sendingDataToBlockchain")));
      const unsignedRes = await deleteCampaignBlockchain(info);

      if (!unsignedRes.success) {
        const toastPaylaod = {
          text: unsignedRes.data?.message,
          success: false,
          active: true,
        };

        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return {
          success: false,
        };
      }

      try {
        dispatch(setAppLoadingText(t("signingTransaction")));
        dispatch(toggleAppLoading(false));
        const transactionArr = unsignedRes.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);

        dispatch(toggleAppLoading(true));
        const signed = [];

        if (signedTxn.length === 3) {
          signed.push(signedTxn[0]);
          signed.push(signedTxn[1]);
          signed.push(signedTxn[2]);
        } else {
          signed.push(signedTxn[0]);
          signed.push(signedTxn[1]);
          signed.push(signedTxn[2]);
          signed.push(signedTxn[3]);
        }

        // signed.push(signedTxns[4].blob);
        const transationRes = await client
          .sendRawTransaction(
            signed.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();

        dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );

        dispatch(setAppLoadingText(""));
        return {
          success: true,
        };
      } catch (e) {
        const toastPaylaod = {
          text: t("unableToBindNftWithCampaign"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return {
          success: false,
        };
      }
    }
  };

  const handleDelete = async () => {
    // dispatch(toggleAppLoading(true));
    handleShowWalletOptions();

    const blockchainDelete = await handleDeleteBlockchain();
    if (!blockchainDelete.success) {
      return;
    }
    if (campaignId.length > 0) {
      const data = {
        campaignId: campaignId,
      };
      const res = await deleteCampaign(data);
      if (res.success) {
        const toastPayload = {
          active: true,
          text: t("campaignDeletedSuccessfully"),
          success: true,
        };

        dispatch(setToaster(toastPayload));
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };

        dispatch(setToaster(toastPayload));
      }
    } else {
      const toastPayload = {
        active: true,
        text: t("selectedCampaignDoNotxist"),
        success: false,
      };

      dispatch(setToaster(toastPayload));
    }
    dispatch(toggleAppLoading(false));
    handleClose();
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box onClick={handleClose} sx={modalWrapper}>
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={modalContainer}
            style={{ maxWidth: "320px" }}
          >
            <Box sx={{ textAlign: "center" }}>
              <DangerousIcon sx={{ fontSize: "50px" }} color="error" />
            </Box>
            <Box sx={titleStyle} mb={3} mt={2}>
              <Typography variant="h6" align="center" mb={0} fontWeight={600}>
                {t("areYouSureYouWantToDeleteThisCampaign")}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                mt={2}
              >
                <Button
                  onClick={handleShowWalletOptions}
                  variant="contained"
                  color="error"
                >
                  {t("delete")}
                </Button>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="inherit"
                  sx={{
                    backgroundColor: "primary.grey",
                  }}
                >
                  {t("cancel")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {showWalletButtons && (
        <Wallets
          open={showWalletButtons}
          handleClose={() => setShowWalletButtons(false)}
          handleSubmit={handleDelete}
        />
      )}
    </>
  );
}

export default DeleteCampaignModal;

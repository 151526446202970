import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "../../../assets/images/facebook.svg";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  PreviewWrap,
  UploadBox,
  Range,
  DescriptionTabs,
  SocialIcon,
} from "./style";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setPreview } from "../../../store/slices/CreateCampaignSlice";
import moment from "moment";
import {
  previewImage,
  setPreviewImageModal,
} from "../../../store/slices/ModalSlice";
import CloseIcon from "@mui/icons-material/Close";
import { getBlockchainNftById } from "../../../blockchain";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
const URL = process.env.REACT_APP_URL;

var getYouTubeID = require("get-youtube-id");

function Preview() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  // i am setting the any here because the the type of reward will change in the future
  const [rewardInfo, setRewardInfo] = useState<any>();
  const dispatch = useAppDispatch();
  const previewState = useAppSelector((state) => state.campaignPreviewReducer);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const closePreview = () => {
    dispatch(setPreview(false));
  };

  const handleThumbnailPreview = (e: React.MouseEvent<HTMLElement>) => {
    const payload: previewImage = {
      imageUrl: e.currentTarget.id,
      previewImage: true,
    };
    dispatch(setPreviewImageModal(payload));
  };

  const fetchRewardNFT = async () => {
    if (previewState.rewardNftId) {
      const info = {
        nftId: previewState.rewardNftId,
      };
      // const res = await getNftById(previewState.rewardNftId);
      const res = await getBlockchainNftById(info);
      if (res.success) {
        setRewardInfo(res.data);
      } else {
        setRewardInfo({});
      }
    }
  };

  useEffect(() => {
    fetchRewardNFT();
  }, []);
  const id = getYouTubeID(previewState.youTubeLink);

  return (
    <>
      <Box
        style={{ backgroundColor: "#fff", overflowY: "scroll", height: "100%" }}
        sx={PreviewWrap}
      >
        <Container fixed>
          <Grid
            container
            columnSpacing={{ xs: 2, md: 6, xl: 9 }}
            sx={{ marginTop: "40px" }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                onClick={closePreview}
                variant="h3"
                className="semibold"
                align="left"
                mb={3}
              >
                <CloseIcon sx={{ cursor: "pointer" }} />
              </Typography>
              <Typography variant="h3" className="semibold" align="left" mb={3}>
                {previewState.title}
              </Typography>
              <Box
                sx={{
                  height: "534px",
                  width: "100%",
                }}
              >
                <Carousel
                  swipeable={previewState.video ? true : false}
                  draggable={false}
                  showDots={previewState.video ? true : false}
                  responsive={responsive}
                  ssr={false} // means to render carousel on server-side.
                  infinite={false}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container view-campaign"
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {previewState.video && (
                    <div style={previewState.video ? {} : { display: "none" }}>
                      <video
                        style={
                          previewState?.videoUrl ? {} : { display: "none" }
                        }
                        src={previewState?.videoUrl}
                        controls
                        width={"100%"}
                        height={"100%"}
                        autoPlay={false}
                      />
                    </div>
                  )}
                  {previewState.youTubeLink && (
                    <YouTube
                      videoId={id}
                      opts={{
                        height: "100%",
                        width: "100%",
                        playerVars: {
                          autoplay: 0,
                        },
                      }}
                      style={
                        previewState.youTubeLink
                          ? { maxWidth: "99%", height: "75%" }
                          : { display: "none" }
                      }
                    />
                  )}
                  <div
                    style={{
                      height: "100%",
                    }}
                  >
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={
                        previewState?.imageUrl?.length > 1 ? true : false
                      }
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container preview-campaign"
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {previewState?.imageUrl?.map((item: any) => (
                        <Box
                          sx={{
                            backgroundImage: `${item}`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "100%",
                            height: "500px",
                            cursor: "pointer",
                          }}
                          id={
                            item.length > 0
                              ? item.split("(")[1].split(")")[0]
                              : ""
                          }
                          onClick={handleThumbnailPreview}
                        />
                      ))}
                    </Carousel>
                  </div>
                </Carousel>
              </Box>
              {/* <Box sx={UploadBox}></Box> */}
              <Box sx={Range}>
                <Box className="RangeBox">
                  <div className="rangecolor"></div>
                </Box>
                <Box className="valuebox">
                  <Typography variant="body2" className="percentage semibold">
                    0%
                  </Typography>
                  <Typography variant="body2" className="days semibold">
                    {moment(previewState?.endDate).diff(moment(), "days")} days
                    {previewState?.isEsgEnabled}
                  </Typography>
                </Box>
              </Box>
              {previewState?.isEsgEnabled && previewState?.goalIds.length > 0 && (
                <Box
                  sx={{ mb: 3 }}
                  style={{ textAlign: "left", marginTop: "60px" }}
                >
                  <Typography
                    variant="h4"
                    align="left"
                    mb={3}
                    fontWeight={600}
                    style={{
                      backgroundColor: "#dfe1df",
                      borderRadius: "7px",
                      marginTop: "20px",
                      height: "50px",
                      padding: "10px",
                    }}
                  >
                    Goals
                  </Typography>
                  {previewState.goalIds?.filter(
                    (el: any) => el?.category === "environmental"
                  ).length > 0 && (
                    <Typography variant="h5" align="left" mt={3} mb={3}>
                      {t("environmentalGoal")}
                    </Typography>
                  )}
                  <Grid
                    container
                    spacing={2}
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                      fontSize: "20px",
                    }}
                  >
                    {previewState.goalIds
                      ?.filter((el: any) => el?.category === "environmental")
                      ?.map((item: any) => (
                        <Grid
                          xs={12}
                          style={{ textAlign: "left", paddingLeft: "20px" }}
                        >
                          <Box>
                            <p>{item.goalName}</p>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                  {previewState.goalIds?.filter(
                    (el: any) => el?.category === "social"
                  ).length > 0 && (
                    <Typography variant="h5" align="left" mt={3} mb={3}>
                      {t("socialGoal")}
                    </Typography>
                  )}
                  <Grid
                    container
                    spacing={2}
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                      fontSize: "20px",
                    }}
                  >
                    {previewState.goalIds
                      ?.filter((el: any) => el?.category === "social")
                      ?.map((item: any) => (
                        <Grid
                          xs={12}
                          style={{ textAlign: "left", paddingLeft: "20px" }}
                        >
                          <Box>
                            <Box>
                              <p>{item.goalName}</p>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                  {previewState.goalIds?.filter(
                    (el: any) => el?.category === "governance"
                  ).length > 0 && (
                    <Typography variant="h5" align="left" mt={3} mb={3}>
                      {t("governanceGoal")}
                    </Typography>
                  )}
                  <Grid
                    container
                    spacing={2}
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                      fontSize: "20px",
                    }}
                  >
                    {previewState.goalIds
                      ?.filter((el: any) => el?.category === "governance")
                      ?.map((item: any) => (
                        <Grid
                          xs={12}
                          style={{ textAlign: "left", paddingLeft: "20px" }}
                        >
                          <Box>
                            <Box>
                              <p>{item.goalName}</p>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {previewState?.isEsgEnabled && (
                <Stack direction="row" spacing={2}>
                  {previewState?.goalIds.filter((goal: any) => {
                    return goal.category === "environmental";
                  }).length > 0 && (
                    <Button
                      variant="contained"
                      style={{
                        height: "30px",
                        backgroundColor: "#8bc34a7d",
                        cursor: "auto",
                      }}
                    >
                      {t("environmental")}
                    </Button>
                  )}
                  {previewState?.goalIds.filter((goal: any) => {
                    return goal.category === "social";
                  }).length > 0 && (
                    <Button
                      variant="contained"
                      style={{
                        height: "30px",
                        backgroundColor: "#ffeb3b99",
                        cursor: "auto",
                      }}
                    >
                      {t("social")}
                    </Button>
                  )}
                  {previewState?.goalIds.filter((goal: any) => {
                    return goal.category === "governance";
                  }).length > 0 && (
                    <Button
                      variant="contained"
                      style={{
                        height: "30px",
                        backgroundColor: "#2196f3a3",
                        cursor: "auto",
                      }}
                    >
                      {t("governance")}
                    </Button>
                  )}
                </Stack>
              )}
              <Typography variant="h5" className="semibold" align="left" mt={2}>
                {t("fundingPeriod")}
              </Typography>
              <Typography variant="h6" className="light" align="left">
                {moment().format("MMM Do")} -{" "}
                {moment(previewState.endDate).format("MMM Do YYYY")}
              </Typography>
              <Box sx={DescriptionTabs} style={{ minHeight: "auto" }}>
                <Typography
                  variant="h5"
                  className="semibold"
                  align="left"
                  style={{ paddingTop: "14px" }}
                >
                  {t("description")}
                </Typography>
                <p
                  style={{
                    textAlign: "left",
                    paddingBottom: "15px",
                    whiteSpace: "pre-line",
                  }}
                >
                  {previewState.description}
                </p>
              </Box>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{
                  flexDirection: { lg: "inherit", xs: "row-reverse" },
                  width: "100%",
                }}
              >
                <Grid item xs={12} lg={6} xl={5}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{
                      flexDirection: { lg: "inherit", xs: "row-reverse" },
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="darkButton"
                        style={{ cursor: "auto" }}
                      >
                        {t("fundNow")}
                      </Button>
                      <Box>
                        <IconButton sx={SocialIcon} style={{ cursor: "auto" }}>
                          <img src={FacebookIcon} alt="facebook" />
                        </IconButton>
                        <IconButton sx={SocialIcon} style={{ cursor: "auto" }}>
                          <InstagramIcon />
                        </IconButton>
                        <IconButton sx={SocialIcon} style={{ cursor: "auto" }}>
                          <TwitterIcon />
                        </IconButton>
                        <IconButton sx={SocialIcon} style={{ cursor: "auto" }}>
                          <ChatBubbleIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
export default Preview;

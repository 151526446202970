import { Stack, Button } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useAppDispatch } from "../../../store";
import { setToaster } from "../../../store/slices/LoadinAndNotifSlice";
import { fileTypes } from "../../../constants/common-enum";
import { useTranslation } from "react-i18next";

function UploadPdf({ pdf, setPdf, optional, disabled }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handlePdfUploadChange = (e: any) => {
    const file = e.target.files;
    let fileType = file[0].name.split(".")[file[0].name.split(".").length - 1];
    if (!fileTypes.includes(`.${fileType.toLowerCase()}`)) {
      setPdf(null);
      const toastPaylaod = {
        text:
          t("pleaseSelectOneOfTheFollowingFileTypes") +
          `:  ${fileTypes.join(", ")}`,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (file[0].size / 1000000 > 5) {
      setPdf("");

      const toastPaylaod = {
        text: t("pleaseSelectFileSmallerThanFiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (
      file[0] !== undefined &&
      file[0].name.length > 0 &&
      Math.floor(file[0].size / 1000000) < 5
    ) {
      setPdf(file[0]);
    }
  };

  return (
    <>
      <Stack
        sx={disabled ? { opacity: " 0.5" } : {}}
        key="pdfStack"
        direction="column"
        alignItems="center"
        spacing={2}
      >
        {pdf && pdf.name && <p>{pdf?.name}</p>}
        <label aria-disabled={disabled} htmlFor="contained-button-file">
          <Button
            variant="text"
            component="label"
            className="uploadButton"
            key="pdfUpload"
            startIcon={<FileUploadIcon />}
          >
            {t("fileUpload")} {optional ? "(" + t("optional") + ")" : ""}
            <input
              className="inputfile"
              accept={fileTypes.join(", ")}
              id="contained-button-file"
              onChange={handlePdfUploadChange}
              multiple={false}
              type="file"
              style={{ display: "none" }}
              key="pdfFile"
              disabled={disabled}
            />
          </Button>
        </label>
      </Stack>
    </>
  );
  {
    pdf && pdf.name && <p>{pdf?.name}</p>;
  }
}

export default UploadPdf;

import {
  Box,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Stack,
  MenuItem,
  Avatar,
  ListItemIcon,
  Menu,
  Tooltip,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled, useTheme } from "@mui/material/styles";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "../../assets/images/logo.svg";
import { colors } from "../../theme/theme";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { Logout } from "@mui/icons-material";
import {
  loggedInUser,
  setUserData,
  setUserToken,
} from "../../store/slices/UserSlice";
import { logoutUser, saveEditedProfile } from "../../requests/authRequest";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  zIndex: 2,
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    zIndex: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
  "& .MuiButton-text": {
    fontSize: "16px",
    color: colors.black.main,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const langList = ["en"];

function DashboardNav() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { kyc, userId, loggedIn } = useAppSelector(
    (state) => state.userReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProfileMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");

  const handleLogout = async () => {
    dispatch(toggleAppLoading(true));
    const res: any = await logoutUser();
    if (res.success) {
      const payload = {
        loggedIn: false,
        userToken: "",
        loginType: "",
        role: "",
        registerUserType: "",
        userAppId: "",
        kyc: "",
        userId: "",
      };
      dispatch(setUserToken(payload));
      const userPayload: loggedInUser = {
        status: "",
        name: "",
        email: "",
        userId: "",
        createdAt: "",
        address: "",
        gender: "Other",
        phone: "",
        country: "",
        role: "",
        userAppId: "",
        organisationName: "",
        organisationOwner: "",
        orgnisationRole: "",
      };
      dispatch(setUserData(userPayload));
      dispatch(toggleAppLoading(false));
      navigate("/");
      const toasterData = {
        active: true,
        text: "User logged out successfully",
        success: true,
      };

      dispatch(setToaster(toasterData));
    } else {
      dispatch(toggleAppLoading(false));

      const toasterData = {
        active: true,
        text: res.data?.message,
        success: false,
      };

      dispatch(setToaster(toasterData));
    }
  };

  const handleChangeLang = (e: SelectChangeEvent) => {
    setSelectedLanguage(e.target.value);
    i18next.changeLanguage(e.target.value);
    loggedIn && saveEditedProfile({ language: e.target.value, userId });
  };

  // useEffect(() => {
  // 	if (location.pathname === "/user/create-campaign" && kyc !== "approved") {
  // 		let message = "";
  // 		switch (kyc) {
  // 			case "pending":
  // 				message = "Please verify your profile first";
  // 				break;
  // 			case "under-process":
  // 				message = "Your verification is under process wait for it to finish";
  // 				break;
  // 			case "rejected":
  // 				message =
  // 					"Ypur profile was not verified last timne, please try again";
  // 				break;
  // 			default:
  // 				message = "Your verification is under process wait for it to finish";
  // 				break;
  // 		}
  // 		const toastPaylaod = {
  // 			text: message,
  // 			success: false,
  // 			active: true,
  // 		};
  // 		dispatch(setToaster(toastPaylaod));
  // 	}
  // }, [location.pathname]);
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <AppBar
        sx={{
          boxShadow: 0,
          height: "75px",
          "@media(max-width:900px)": {
            marginRight: "0px",
            width: "100%",
          },
        }}
      >
        <Container fixed>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link to={"/"}>
              <img src={Logo} alt="logo" height={60} />
            </Link>

            <Box
              sx={{ display: { md: "flex", xs: "none" }, alignItems: "center" }}
            ></Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: { md: "flex", xs: "none" },
              }}
            >
              <Button
                onClick={() =>
                  window.open("https://www.site.cashdillo.com/what-we-do")
                }
                variant="text"
                sx={{ textTransform: "capitalize", padding: "0px" }}
              >
                {t("about")}
              </Button>

              <Button
                onClick={() => navigate("/esg-client")}
                variant="text"
                sx={{ textTransform: "capitalize", padding: "0px" }}
              >
                {t("esgClients")}
              </Button>

              <Button
                onClick={() => navigate("/how-its-works")}
                variant="text"
                sx={{ textTransform: "capitalize", padding: "0px" }}
              >
                {t("howItWorks")}
              </Button>
              <Button
                onClick={() => navigate("/faq")}
                variant="text"
                sx={{ textTransform: "capitalize", padding: "0px" }}
              >
                {t("faq")}
              </Button>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button
                  variant="text"
                  sx={{ textTransform: "capitalize", padding: "0px" }}
                >
                  {t("visitSite")}
                </Button>
              </Link>
              <Button
                onClick={() => navigate("/whale-tank")}
                variant="text"
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#61f359",
                  padding: "0px",
                }}
              >
                {t("whaleTank")}
              </Button>
              <Select
                value={selectedLanguage}
                onChange={handleChangeLang}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "35px",
                  textTransform: "capitalize",
                  marginTop: "5px !important",
                }}
              >
                {langList.map((item: any) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      //   sx={{ ml: 2 }}
                      aria-controls={
                        openProfileMenu ? "account-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openProfileMenu ? "true" : undefined}
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openProfileMenu}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={() => navigate("/user/profile")}>
                    <Avatar /> {t("profile")}
                  </MenuItem>
                  <MenuItem onClick={() => handleLogout()}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    {t("logout")}
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
            <IconButton
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{
                color: "black.main",
                ...(open && { display: "none" }),
                "@media(min-width:900px)": {
                  display: "none",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Container>
      </AppBar>
      <Main
        open={open}
        sx={{
          marginTop: "75px",
          minHeight: "calc(100vh - 180px)",
          bgcolor: "primary.background",
          overflow: "hidden",
          "@media(max-width:900px)": {
            marginRight: `-${drawerWidth}px`,
            width: "100%",
          },
        }}
      >
        <Box sx={{ bgcolor: "primary.main", height: "100%" }}>
          <Outlet />
        </Box>
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link to={"/user"} style={{ textDecoration: "none" }}>
            <ListItem button key={"User"}>
              <ListItemText primary={"User"} />
            </ListItem>
          </Link>
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <ListItem button key={"Visit Site"}>
              <ListItemText primary={"Visit Site"} />
            </ListItem>
          </Link>
          <ListItem button key={"FAQ"}>
            <ListItemText primary={"FAQ"} />
          </ListItem>
          <ListItem button key={"How it works"}>
            <ListItemText primary={"How it works"} />
          </ListItem>
          <ListItem button key={"About"}>
            <ListItemText primary={"About"} />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

export default DashboardNav;

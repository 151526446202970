import { Box, Button, Link, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DetailUser } from "./style";
import moment from "moment";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getGrantManagerById } from "../../../requests/authRequest";
import { userRole } from "../../../constants/common-enum";
import { useTranslation } from "react-i18next";

export interface Manager {
  fullName: string;
  displayName: string;
  website: string;
  description: string;
  email: string;
  phone: string;
  image: string;
  facebookURL: string;
  instagramURL: string;
  twitterURL: string;
  clubhouseURL: string;
  youtubeURL: string;
  grantCreatorId: string;
  role: string;
  userAppId: string;
  createdAt: string;
}

const getInitialvalues = (): Manager => {
  const initialvalues = {
    fullName: "",
    displayName: "",
    email: "",
    description: "",
    website: "",

    facebookURL: "",
    instagramURL: "",
    twitterURL: "",
    clubhouseURL: "",
    youtubeURL: "",
    createdAt: "",
    grantCreatorId: "",
    role: "",
    userAppId: "",
    image: "",
    phone: "",
  };
  return initialvalues;
};

const GrantManager = () => {
  const { t } = useTranslation();
  const { managerId } = useParams();

  const [manager, setManager] = useState<Manager>(getInitialvalues());
  const [loading, setloading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.userReducer);
  const fetchGrantManager = async () => {
    try {
      setloading(true);
      const data = { managerId };
      const res = await getGrantManagerById(data);

      if (res.success) {
        setManager(res?.data);
      } else {
        const toastPlayload = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toastPlayload));
      }
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchGrantManager();
  }, []);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  const goBack = () => navigate("/user/grant-managers");
  return (
    <Box sx={{ marginTop: "-2px" }}>
      <Stack spacing={2}>
        <Box sx={DetailUser}>
          <div className="label">{t("fullName")}:</div>

          <div className="value">{manager.fullName}</div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">{t("displayName")}:</div>

          <div className="value">{manager.displayName}</div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">{t("email")}:</div>

          <div className="value">{manager.email}</div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">
            {t("phone")} ({t("optional")}):
          </div>

          <div className="value">{manager.phone}</div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">{t("description")}:</div>

          <div className="value">{manager.description}</div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">{t("website")}:</div>

          <Link
            href={
              manager.website.includes("http")
                ? manager.website
                : `https://${manager.website}`
            }
            target={"_blank"}
            underline="none"
            rel="noreferrer"
          >
            <p className="value">{manager.website}</p>
          </Link>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">{t("facebookUrl")}:</div>
          <div>
            <Link
              href={
                manager.facebookURL.includes("http")
                  ? manager.facebookURL
                  : `https://${manager.facebookURL}`
              }
              target={"_blank"}
              underline="none"
              rel="noreferrer"
            >
              <p className="value">{manager.facebookURL}</p>
            </Link>
          </div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">({t("instagramUrl")}):</div>

          <div>
            <Link
              href={
                manager.instagramURL.includes("http")
                  ? manager.instagramURL
                  : `https://${manager.instagramURL}`
              }
              target={"_blank"}
              underline="none"
              rel="noreferrer"
            >
              <p className="value">{manager.instagramURL}</p>
            </Link>
          </div>
        </Box>
        <Box sx={DetailUser}>
          <div className="label">{t("twitterUrl")}:</div>

          <div>
            <Link
              href={
                manager.twitterURL.includes("http")
                  ? manager.twitterURL
                  : `https://${manager.twitterURL}`
              }
              target={"_blank"}
              underline="none"
              rel="noreferrer"
            >
              <p className="value">{manager.twitterURL}</p>
            </Link>
          </div>
        </Box>
        <Box sx={DetailUser}>
          <div className="label">{t("clubhouseUrl")}:</div>

          <div>
            <Link
              href={
                manager.clubhouseURL.includes("http")
                  ? manager.clubhouseURL
                  : `https://${manager.clubhouseURL}`
              }
              target={"_blank"}
              underline="none"
              rel="noreferrer"
            >
              <p className="value">{manager.clubhouseURL}</p>
            </Link>
          </div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">{t("youtubeUrl")}:</div>

          <div>
            <Link
              href={
                manager.youtubeURL.includes("http")
                  ? manager.youtubeURL
                  : `https://${manager.youtubeURL}`
              }
              target={"_blank"}
              underline="none"
              rel="noreferrer"
            >
              <p className="value">{manager.youtubeURL}</p>
            </Link>
          </div>
        </Box>

        <Box sx={DetailUser}>
          <div className="label">{t("createdAt")}:</div>
          <div className="value">
            {moment(manager.createdAt).format("hh:mma DD MMM YYYY")}
          </div>
        </Box>
      </Stack>

      {currentUser.role === userRole.GRANT_CREATOR && (
        <Button
          sx={{ marginTop: "40px", float: "left", marginLeft: "20px" }}
          variant="contained"
          color="primary"
          className="round-button"
          onClick={goBack}
        >
          {t("back")}
        </Button>
      )}
      {currentUser.role === userRole.GRANT_MANAGER && (
        <>
          <Button
            sx={{ marginTop: "40px", float: "left", marginLeft: "20px" }}
            variant="contained"
            color="primary"
            className="round-button"
            onClick={() => navigate(`/user/edit-manager/${currentUser.userId}`)}
          >
            {t("editProfile")}
          </Button>
          <Button
            sx={{ marginTop: "40px", float: "left", marginLeft: "20px" }}
            variant="contained"
            color="primary"
            className="round-button"
            onClick={() => navigate("/user/grant-list")}
          >
            {t("back")}
          </Button>
        </>
      )}
    </Box>
  );
};

export default GrantManager;

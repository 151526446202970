import { t } from "i18next";
const axios = require("axios");
const CryptoJS = require("crypto-js");

const wyreApiUrl = "https://api.testwyre.com";
// const wyreApiUrl = "https://api.testwyre.com";

const secretKey = process.env.REACT_APP_WYRE_SECRET_KEY;
const apiKey = process.env.REACT_APP_WYRE_API_KEY;

const signature = (url) => {
  const dataToSign = url;
  const token = CryptoJS.enc.Hex.stringify(
    CryptoJS.HmacSHA256(dataToSign.toString(CryptoJS.enc.Utf8), secretKey)
  );
  return token;
};

export const submitWyreAuthToken = async () => {
  const data = JSON.stringify({ secretKey: secretKey });

  var config = {
    method: "post",
    url: `${wyreApiUrl}/v2/sessions/auth/key`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createReservation = async (account, campaignId) => {
  const timestamp = new Date().getTime();
  const url = `${wyreApiUrl}/v3/orders/reserve?timestamp=${timestamp}`;
  const headers = {};
  const data = JSON.stringify({
    destCurrency: "ALGO",
    dest: "algorand:YNTJWML243WMTZNV5HEHBXG77P7MJAGNM2M6FPX4UJDXB4C6BVADAF4424",
    // redirectUrl: `${window.location.origin}/transaction-succcess/${campaignId}`,
    failureRedirectUrl: `${window.location.origin}/transaction-fail`,
    referrerAccountId: account,
    lockFields: ["dest", "destCurrency"],
  });

  headers["X-Api-Key"] = apiKey;
  headers["X-Api-Signature"] = signature(url + data);
  headers["Content-type"] = "application/json";

  const config = {
    method: "post",
    url: url,
    headers: headers,
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const fetchOrderData = async (orderId) => {
  var config = {
    method: "get",
    url: `${wyreApiUrl}/v3/orders/${orderId}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const modalWrapper = {
  height: "100%",
  outline: " 0px",
  overflow: "hidden auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  "@media(max-height:700px)": {
    alignItems: "baseline",
  },
};

export const modalContainer = {
  maxWidth: "512px",
  width: "98%",
  bgcolor: "#eef0f0",
  borderRadius: "12px",
  margin: "32px",
  position: "relative",
  overflowY: "auto",
  display: "inline-block",
  verticalAlign: "middle",
  textAlign: "left",
  padding: 4,
};
export const titleStyle = {
  "& .MuiButton-text": {
    padding: "0px",
    fontSize: "14px",
    color: "green.main",
    height: "auto",
    minWidth: "auto",
  },
};
export const ContentStyle = {
  marginTop: { xl: "40px", xs: "20px" },
  "& .MuiTextField-root": {
    marginBottom: 3,
  },
  "& .MuiOutlinedInput-root": {
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: { xl: "16px", xs: "14px" },
      padding: { xl: "19.5px 20px", xs: "12.5px 15px" },
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
};

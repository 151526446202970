import { Box, Typography, Container, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function EsgCllient() {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: "#f1f2f3", marginTop: "30px" }}>
      <Container fixed>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h3"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            {t("esgClients")}
          </Typography>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsOne")}{" "}
            <a href="https://sdgs.un.org/goals" target="_blank">
              {t("sustainableDevelopmentGoals")}
            </a>{" "}
            {t("esgClientsTwo")}
          </p>
          <p style={{ fontWeight: "600", textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsThree")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsFour")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsFive")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsSix")}{" "}
            <a href="https://sdgs.un.org/goals" target="_blank">
              {t("sustainableDevelopmentGoals")}
            </a>{" "}
            {t("esgClientsSeven")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsEight")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsNine")}
          </p>
          <p style={{ textAlign: "left", fontSize: "20px" }}>
            {t("esgClientsTen")}
          </p>
        </Grid>
      </Container>
    </Box>
  );
}

export default EsgCllient;

import { FormControlLabel, Checkbox } from "@mui/material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { setRegisterModal } from "../../../store/slices/ModalSlice";

const Permission = ({ checked, setChecked }: any) => {
  const dispatch = useDispatch();

  const handleRedirect = () => {
    dispatch(setRegisterModal(false));
  };
  return (
    <div style={{ marginTop: "10px", fontSize: "20px" }}>
      <FormControlLabel
        control={<Checkbox onChange={(e) => setChecked(e.target.checked)} />}
        label={
          <div style={{ fontSize: "15px" }}>
            I have accepted&nbsp;
            <Button
              onClick={handleRedirect}
              component={RouterLink}
              to="/terms-of-use"
              target="_blank"
              sx={{
                textDecoration: "underline",
                color: "blue",
                p: 0,
              }}
            >
              {""} Terms of use
            </Button>
            <span style={{ color: "blue" }}>&nbsp;&amp;&nbsp;</span>
            <Button
              onClick={handleRedirect}
              component={RouterLink}
              to="/privacy-policy"
              target="_blank"
              sx={{
                textDecoration: "underline",
                color: "blue",
                p: 0,
              }}
            >
              {""}Privacy Policy
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default Permission;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Box, Button, IconButton, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import { DataGridWrap, footer } from "./style";
import CustomNoRowsOverlay from "../../common/tables/CustomNoRowsOverlay";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { getGrantManager } from "../../../requests/authRequest";
import { setDeleteGrantManagerModal } from "../../../store/slices/ModalSlice";
import { useTranslation } from "react-i18next";

interface grantManagerRow {
  id: number;
  fullName: string;
  email: string;
  grantsCreated: number;
  status: string;
  createdAt: string;
  action: string;
  userAppId: number;
}

const GrantManagerList = () => {
  const { t } = useTranslation();
  const [grantManagers, setGrantManagers] = useState<Array<grantManagerRow>>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deleteGrantManagerModalState = useAppSelector(
    (state) => state.modalReducer.deleteGrantManagerModal
  );
  const userslice = useAppSelector((state) => state.userReducer);

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Full name",
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Contact Email",
      sortable: false,
      minWidth: 300,
      flex: 1,
    },
    {
      field: "totalCreatedGrants",
      headerName: "Grants Created",
      sortable: false,
      minWidth: 140,
      flex: 1,
      valueFormatter: (params: any) => {
        return !params.value ? 0 : params.value;
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 84,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Typography
            variant="h6"
            sx={{
              color:
                params.row.status === "active"
                  ? "#00FF00"
                  : params.row.status === "deleted"
                  ? "#ff0000"
                  : params.row.status === "pending"
                  ? "#FFA500"
                  : "#000",
              textTransform: "capitalize",
            }}
          >
            {params.row.status}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created On",
      sortable: false,
      minWidth: 150,
      flex: 1,
      valueFormatter: (params: any) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = moment(params.value).format("YYYY-MM-DD");
        return `${valueFormatted}`;
      },
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        const handleEdit = (e: any) => {
          e.stopPropagation();
          navigate(`/user/edit-manager/${params.row.id}`);
        };

        const handleDelete = (e: any) => {
          e.stopPropagation();
          const payload = {
            deleteGrantManagerModal: true,
            deleteGrantManagerId: params.row.id,
            deleteGrantManagerAppId: params.row.userAppId,
          };
          dispatch(setDeleteGrantManagerModal(payload));
        };

        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/user/manager-view/${params.row.id}`);
        };
        return (
          <Box>
            {params.row.status != "deleted" ? (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleView}>
                <VisibilityIcon />
              </IconButton>
            )}
            {!params.row.hasActiveGrant && params.row.status !== "deleted" && (
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  const fetchManagers = async () => {
    setloading(true);
    const data = {
      page: page,
      limit: 5,
      status: ["active"],
    };
    const res: any = await getGrantManager(data);

    setloading(false);
    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    setEndPage(res.data.totalPages);
    setGrantManagers([...res?.data?.grantManagers]);
    setloading(false);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchManagers();
  }, [page]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  useEffect(() => {
    if (!deleteGrantManagerModalState) {
      fetchManagers();
    }
  }, [deleteGrantManagerModalState]);

  return (
    <>
      <Box>
        <Box
          mb={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" align="left">
            {t("listOfAllGrantManagers")}
          </Typography>
          {userslice.role === "grant-creator" && (
            <Button
              onClick={() => navigate(`/user/create-grant-manager`)}
              variant="contained"
              color="primary"
              className="round-button"
              sx={{
                marginLeft: "10px",
                "& .btnText": {
                  display: "none",
                },
                "&:hover": {
                  "& .btnText": {
                    display: "block",
                  },
                },
              }}
            >
              <Typography className="btnText">{t("createManager")}</Typography>
              <AddIcon sx={{ color: "#000" }} />
            </Button>
          )}
        </Box>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={grantManagers}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GrantManagerList;

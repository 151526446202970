import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMyGrantApplications } from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import { Box, IconButton, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CustomNoRowsOverlay from "../../../components/common/tables/CustomNoRowsOverlay";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { DataGridWrap, footer } from "./style";
import { useTranslation } from "react-i18next";

interface GrantApplicationRow {
  id: number;
  grantTitle: string;
  grantCreatorName: string;
  requestedFunds: number;
  country: string;
  status: string;
  action: string;
}

const GrantApplicationList = () => {
  const { t } = useTranslation();
  const [grantApplications, setGrantApplications] = useState<
    Array<GrantApplicationRow>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>(0);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userSlice = useAppSelector((state) => state.userReducer);
  const columns: GridColDef[] = [
    {
      field: "grantTitle",
      headerName: "Grant Title",
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      sortable: false,
      flex: 1,
      valueFormatter: (params: any) => {
        if (params.value == null) {
          return "";
        }
        return params.value
          .toString()
          .replace(
            params.value.toString().charAt(0),
            params.value.toString().charAt(0).toUpperCase()
          );
      },
    },
    {
      field: "grantCreatorName",
      headerName: "Grant Creator Name",
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "requestedFunds",
      headerName: "Funds",
      sortable: false,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: any) => {
        return !params.value ? 0 : params.value;
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 57,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Typography
            variant="h6"
            sx={{
              color:
                params.row.status === "accepted" ||
                params.row.status === "completed" ||
                params.row.status === "active"
                  ? "#00FF00"
                  : params.row.status === "rejected" ||
                    params.row.status === "suspended"
                  ? "#ff0000"
                  : params.row.status === "pending" ||
                    params.row.status === "in-complete"
                  ? "#FFA500"
                  : "#000",
              textTransform: "capitalize",
            }}
          >
            {params.row.status}
          </Typography>
        );
      },
    },
    {
      field: "grantStatus",
      headerName: "Grant Status",
      sortable: false,
      minWidth: 57,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Typography
            variant="h6"
            sx={{
              color:
                params.row.grantStatus === "accepted" ||
                params.row.grantStatus === "completed" ||
                params.row.grantStatus === "active"
                  ? "#00FF00"
                  : params.row.grantStatus === "rejected" ||
                    params.row.grantStatus === "suspended"
                  ? "#ff0000"
                  : params.row.grantStatus === "pending" ||
                    params.row.grantStatus === "in-complete"
                  ? "#FFA500"
                  : "#000",
              textTransform: "capitalize",
            }}
          >
            {params.row.grantStatus}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        const handleEdit = (e: any) => {
          e.stopPropagation();
          navigate(`/user/edit-application/${params.row.id}`);
        };

        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/user/view-application/${params.row.id}`);
        };
        return (
          <Box>
            {/* <IconButton onClick={handleEdit}>
							<EditIcon />
						</IconButton> */}
            <IconButton onClick={handleView}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const fetchApplication = async () => {
    try {
      setLoading(true);
      const payload = {
        page: 1,
        limit: 8,
        userId: userSlice.userId,
      };
      const res = await getMyGrantApplications(payload);

      if (res.data.totalPages === page) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }

      if (page === 1) {
        setDisableBack(true);
      } else {
        setDisableBack(false);
      }

      if (page === 1 && endPage === 1) {
        setDisableNext(true);
        setDisableBack(true);
      }
      setEndPage(res.data.totalPages);
      setGrantApplications(res.data.allApplications);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchApplication();
  }, [page]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);
  //setAppLoadingText
  return (
    <>
      <Box>
        <Box
          mb={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" align="left">
            {t("allApplications")}
          </Typography>
        </Box>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={grantApplications || []}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GrantApplicationList;

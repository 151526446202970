import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SelectCashdilloNftModal from "../../components/user/createCampaign/adminCreatedNft/SelectCashdilloNftModal";

interface CounterState {
  loginModal: boolean;
  registerModal: boolean;
  selectRoleModal: boolean;
  reqResetPasswordModal: boolean;
}

export interface previewImage {
  previewImage: boolean;
  imageUrl: string;
}

interface deleteCampaign {
  deleteCampaignId: string;
  deleteCampaignModal: boolean;
  deleteCampaignAppId: string | undefined;
  deleteCampaignNftId: string | undefined;
  milestones: Array<milestone>;
}

interface deleteGrant {
  deleteGrantId: string;
  deleteGrantModal: boolean;
  deleteGrantAppId: string;
  paymentTypeBlockchain: string;
}

interface deleteGrantManager {
  deleteGrantManagerId: string;
  deleteGrantManagerModal: boolean;
  deleteGrantManagerAppId: string;
}
interface selesctRewardType {
  selectRewardTypeModal: boolean;
}
interface selectCashdilloNft {
  selectCashdilloNftModal: boolean;
}

interface selectCreatorNft {
  selectCreatorNftModal: boolean;
}

interface milestone {
  deadline: string;
  definition: string;
  deliverable: string;
  milestoneAppId: string;
  milestoneId: string;
  status: string;
  title: string;
}

interface algoDonation {
  donationCampaignAppId: string;
  algoDonationModal: boolean;
}

interface userVerification {
  userVerificationModal: boolean;
}

interface grantApplicationData {
  applications: [];
  grants: {
    paymentTypeBlockchain: string;
  };
}

// Define the initial state using that type
const initialState: CounterState &
  previewImage &
  deleteCampaign &
  deleteGrant &
  deleteGrantManager &
  selesctRewardType &
  selectCashdilloNft &
  selectCreatorNft &
  userVerification &
  grantApplicationData &
  algoDonation = {
  loginModal: false,
  registerModal: false,
  selectRoleModal: false,
  previewImage: false,
  reqResetPasswordModal: false,
  imageUrl: "",
  deleteCampaignId: "",
  deleteCampaignModal: false,
  deleteCampaignAppId: "",
  deleteCampaignNftId: "",
  selectRewardTypeModal: false,
  selectCashdilloNftModal: false,
  selectCreatorNftModal: false,
  algoDonationModal: false,
  milestones: [],
  donationCampaignAppId: "",
  userVerificationModal: false,
  deleteGrantId: "",
  paymentTypeBlockchain: "",
  deleteGrantModal: false,
  deleteGrantAppId: "",
  deleteGrantManagerId: "",
  deleteGrantManagerModal: false,
  deleteGrantManagerAppId: "",
  applications: [],
  grants: {
    paymentTypeBlockchain: "",
  },
};

export const modalSlice = createSlice({
  name: "ModalSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoginModal: (state, action: PayloadAction<boolean>) => {
      state.loginModal = action.payload;
    },
    setRegisterModal: (state, action: PayloadAction<boolean>) => {
      state.registerModal = action.payload;
    },
    setSelectRoleModal: (state, action: PayloadAction<boolean>) => {
      state.selectRoleModal = action.payload;
    },
    setPreviewImageModal: (state, action: PayloadAction<previewImage>) => {
      state.previewImage = action.payload.previewImage;
      state.imageUrl = action.payload.imageUrl;
    },
    setReqResetPasswordModal: (state, action: PayloadAction<boolean>) => {
      state.reqResetPasswordModal = action.payload;
    },
    setDeleteCampaignModal: (state, action: PayloadAction<deleteCampaign>) => {
      state.deleteCampaignId = action.payload.deleteCampaignId;
      state.deleteCampaignModal = action.payload.deleteCampaignModal;
      state.deleteCampaignAppId = action.payload.deleteCampaignAppId;
      state.deleteCampaignNftId = action.payload.deleteCampaignNftId;
      state.milestones = action.payload.milestones;
    },
    setSelectRewardTypeModal: (state, action: PayloadAction<boolean>) => {
      state.selectRewardTypeModal = action.payload;
    },
    setSelectCashdilloNftModal: (state, action: PayloadAction<boolean>) => {
      state.selectCashdilloNftModal = action.payload;
    },
    setSelectCreatorNftModal: (state, action: PayloadAction<boolean>) => {
      state.selectCreatorNftModal = action.payload;
    },
    setAlgoDonationModal: (state, action: PayloadAction<algoDonation>) => {
      state.algoDonationModal = action.payload.algoDonationModal;
      state.donationCampaignAppId = action.payload.donationCampaignAppId;
    },
    setUserVerificationModal: (state, action: PayloadAction<boolean>) => {
      state.userVerificationModal = action.payload;
    },
    setDeleteGrantModal: (state, action: PayloadAction<deleteGrant>) => {
      state.deleteGrantId = action.payload.deleteGrantId;
      state.paymentTypeBlockchain = action.payload.paymentTypeBlockchain;
      state.deleteGrantModal = action.payload.deleteGrantModal;
      state.deleteGrantAppId = action.payload.deleteGrantAppId;
    },
    setDeleteGrantManagerModal: (
      state,
      action: PayloadAction<deleteGrantManager>
    ) => {
      state.deleteGrantManagerId = action.payload.deleteGrantManagerId;
      state.deleteGrantManagerModal = action.payload.deleteGrantManagerModal;
      state.deleteGrantManagerAppId = action.payload.deleteGrantManagerAppId;
    },
    setGrantApplicationData: (
      state,
      action: PayloadAction<grantApplicationData>
    ) => {
      state.applications = action.payload.applications;
      state.grants = action.payload.grants;
      state.grants.paymentTypeBlockchain =
        action.payload.grants.paymentTypeBlockchain;
    },
  },
});

export const {
  setLoginModal,
  setRegisterModal,
  setSelectRoleModal,
  setPreviewImageModal,
  setReqResetPasswordModal,
  setDeleteCampaignModal,
  setSelectRewardTypeModal,
  setSelectCashdilloNftModal,
  setSelectCreatorNftModal,
  setAlgoDonationModal,
  setUserVerificationModal,
  setDeleteGrantModal,
  setDeleteGrantManagerModal,
  setGrantApplicationData,
} = modalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default modalSlice.reducer;

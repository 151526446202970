export const transactionContainer = {
  background: "#343434",
  color: "#FFFFFF",
  paddingTop: "20px;",
  paddingBottom: "12px;",
  paddingLeft: "10px;",
  paddingRight: "10px;",
  borderRadius: "12px;",
};
export const walletAdress = {
  marginTop: "10px",
  background: "#FFFFFF",
  textAlign: "start",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "10px",
  paddingRIght: "10px",
  borderRadius: "5px",
};

import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import CreatedLogo from "../../../assets/images/logo_animal.png";
import { donateCampaignDummy } from "../../../requests/authRequest";
import { useAppDispatch } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { fetchOrderData } from "../../../wyre/services";
import { transactionContainer } from "./styles";
import { useWallet } from "@txnlab/use-wallet";

interface OrderData {
  id: string;
  createdAt: string | Date | number;
  owner: string;
  status: string;
  orderType: string;
  sourceAmount: number;
  purchaseAmount: number;
  sourceCurrency: string;
  destCurrency: string;
  transferId: string;
  dest: string;
  authCodesRequested: boolean;
  blockchainNetworkTx: any;
  accountId: string;
  paymentMethodName: string;
}

function TransactionSuccessful() {
  const { t } = useTranslation();
  const { activeAccount, providers, signTransactions } = useWallet();
  const { campaignId } = useParams();
  const [query, setQuery] = useSearchParams();
  const [invalidId, setInvalidId] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderData>();
  const dispatch = useAppDispatch();

  const handlePay = async (amount: number) => {
    const info = {
      campaignId: campaignId,
      amount: amount,
      walletAddress: activeAccount?.address,
    };
    const res = await donateCampaignDummy(info);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPayload = {
        active: true,
        text: t("donatedSuccessfully"),
        success: true,
      };
      dispatch(setToaster(toastPayload));
    } else {
      const toastPayload = {
        active: true,
        text: res.data.message,
        success: false,
      };
      dispatch(setToaster(toastPayload));
    }
  };

  const handleOrderData = async () => {
    dispatch(toggleAppLoading(true));
    if (query.get("orderId")) {
      const res = await fetchOrderData(query.get("orderId"));
      if (res.success) {
        setOrderData(res.data);
        await handlePay(res.data.purchaseAmount);
      } else {
        setInvalidId(true);
      }
    } else {
      setInvalidId(true);
    }
    dispatch(toggleAppLoading(false));
  };

  useEffect(() => {
    handleOrderData();
  }, []);

  return (
    <Box sx={transactionContainer}>
      <img
        src={CreatedLogo}
        style={{ maxWidth: "450px", width: "100%" }}
        alt="logo"
      />
      {!invalidId ? (
        <>
          <Typography variant="h2" mt={2} mb={3}>
            {t("transactionSuccessful")}
          </Typography>
          <Typography variant="h4" className="light">
            {t("yourDonationToTheCampaignIsSuccessfulYouCanNowCloseThisWindow")}
          </Typography>
          <Typography variant="h4" mt={3} mb={3}>
            {t("transactionInfo")}
          </Typography>
          <pre style={{ overflow: "auto" }}>
            {JSON.stringify(orderData, null, 2)}
          </pre>
        </>
      ) : (
        <>
          <Typography variant="h4" className="light">
            {t("youHaveInvalidDonationData")}
          </Typography>
        </>
      )}
    </Box>
  );
}

export default TransactionSuccessful;

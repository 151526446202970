import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setUserVerificationModal } from "../../../store/slices/ModalSlice";
import {
  ContentStyle,
  modalContainer,
  modalWrapper,
  titleStyle,
} from "./userVerificationModalStyles";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import SmartWebCamera from "./SmartWebCamera";
import { verifyUserSmile } from "../../../requests/authRequest";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import {
  countryDropdownData,
  IdRegEx,
  idTypes,
} from "./userVerificationConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
// import SmartWebCamera from "@smile_identity/smart-camera-web";

type selectedCountryType = "GH" | "KE" | "NG" | "ZA" | "UG";

function UserVerificationModal() {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] =
    useState<selectedCountryType>("GH");

  const [documentId, setDocumentId] = useState<string>("");
  const [invalidDocumentId, setInvalidDocumentId] = useState<boolean>(false);

  const [documentType, setDocumentType] = useState<string>(idTypes[`GH`][0]);

  const [firstName, setFirstName] = useState<string>("");
  const [invalidFirstName, setInvalidFirstName] = useState<boolean>(false);

  const [lastName, setLastName] = useState<string>("");
  const [invalidLastName, setInvalidLastName] = useState<boolean>(false);

  const [dob, setDOb] = useState<Date | string>(new Date());
  const [invalidDob, setInvalidDob] = useState<boolean>(false);

  const [imageSelected, setImageSelected] = useState<boolean>(false);
  const [selectedImageData, setSelectedImageData] = useState<any>({});

  const open = useAppSelector(
    (state) => state.modalReducer.userVerificationModal
  );
  const dispatch = useAppDispatch();

  const clearState = () => {
    setDocumentId("");
    setInvalidDocumentId(false);
    setFirstName("");
    setLastName("");
    setInvalidFirstName(false);
    setInvalidLastName(true);
    setInvalidDob(false);
    setImageSelected(false);
    setSelectedImageData({});
  };

  const handleClose = () => {
    clearState();
    dispatch(setUserVerificationModal(false));
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    setSelectedCountry(event.target.value as selectedCountryType);
  };

  const handleDocumentTypeChange = (event: SelectChangeEvent) => {
    setDocumentType(event.target.value as string);
  };

  const handleDobChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      setDOb(new Date(newValue));
    }
  };

  const verifyFormData = () => {
    let validity = true;
    // for firstName
    if (firstName.trim().length <= 0) {
      validity = false;
      setInvalidFirstName(true);
    } else if (firstName.length > 100) {
      validity = false;
      setInvalidFirstName(true);
    } else {
      setInvalidFirstName(false);
    }

    // for lastName
    if (lastName.trim().length <= 0) {
      validity = false;
      setInvalidLastName(true);
    } else if (lastName.length > 100) {
      validity = false;
      setInvalidLastName(true);
    } else {
      setInvalidLastName(false);
    }

    // for id number
    let stringRegex: string = IdRegEx[`${selectedCountry}`][`${documentType}`];

    let re = new RegExp(`${stringRegex}`, "i");

    if (documentId.trim().length <= 0) {
      setInvalidDocumentId(true);
      validity = false;
    } else if (!re.test(documentId)) {
      setInvalidDocumentId(true);
      validity = false;
    } else {
      setInvalidDocumentId(false);
    }

    // for date of birth
    if (!moment(dob).isBefore(new Date(), "day")) {
      validity = false;
      setInvalidDob(true);
    } else {
      setInvalidDob(false);
    }

    // for image selection
    if (!imageSelected) {
      validity = false;
    }
    return validity;
  };

  const handleVerifyUser = async () => {
    let res = verifyFormData();
    if (!res) {
      return;
    }
    dispatch(toggleAppLoading(true));
    const info = {
      ...selectedImageData,
      first_name: firstName,
      last_name: lastName,
      country: selectedCountry,
      id_type: documentType,
      id_number: documentId,
      dob: moment(dob).format("YYYY-MM-DD"),
    };
    const verificationRes = await verifyUserSmile(info);
    dispatch(toggleAppLoading(false));

    if (!verificationRes.success) {
      const toastPaylaod = {
        text: t("somethingWrongWithTheVerificationRightNowTryAgainLater"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      handleClose();
      return;
    } else {
      const toastPaylaod = {
        text: verificationRes.data.message,
        success: verificationRes.data.result === "Rejected" ? false : true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      handleClose();
      return;
    }
  };

  useEffect(() => {
    setDocumentType(idTypes[`${selectedCountry}`][0]);
  }, [selectedCountry]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={modalWrapper}>
        <Box onClick={(e) => e.stopPropagation()} sx={modalContainer}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" align="center" fontWeight={600}>
              {t("smileUserVerification")}
            </Typography>
          </Box>

          <Box sx={ContentStyle}>
            {open && (
              <SmartWebCamera
                setImageSelected={setImageSelected}
                setSelectedImageData={setSelectedImageData}
              />
            )}
            {!imageSelected && (
              <FormHelperText
                sx={{ marginTop: "0px", marginBottom: "20px" }}
                className="css-l8pll-MuiFormHelperText-root Mui-error"
              >
                {t("pleaseInputYourImageViaWebcam")}
              </FormHelperText>
            )}

            <Grid container spacing={1.2}>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  hiddenLabel
                  type={"text"}
                  placeholder={t("firstName") || ""}
                  variant="outlined"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName}
                  error={invalidFirstName}
                  helperText={invalidFirstName && "Please input first name"}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  hiddenLabel
                  type={"text"}
                  placeholder={t("lastName") || ""}
                  variant="outlined"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  value={lastName}
                  error={invalidLastName}
                  helperText={invalidLastName && "Please input last name"}
                />
              </Grid>
              <Grid item sm={6}>
                <InputLabel
                  variant="standard"
                  htmlFor="verification-country-select"
                  sx={{ marginBottom: "10px" }}
                >
                  {t("documentId")}
                </InputLabel>
                <TextField
                  fullWidth
                  hiddenLabel
                  type={"text"}
                  placeholder={t("idNumber") || ""}
                  variant="outlined"
                  onChange={(e) => {
                    setDocumentId(e.target.value);
                  }}
                  value={documentId}
                  error={invalidDocumentId}
                  helperText={
                    invalidDocumentId && "Please enter a valid document ID"
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <Box>
                  <FormLabel
                    component="legend"
                    sx={{ width: "100%", marginBottom: "10px" }}
                  >
                    {t("dateOfBirth")}
                  </FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DatePicker
                        inputFormat="YYYY-MM-DD"
                        value={dob}
                        onChange={handleDobChange}
                        renderInput={(params: any) => <TextField {...params} />}
                        disableFuture
                      />
                    </Stack>
                  </LocalizationProvider>
                  {invalidDob && (
                    <FormHelperText
                      sx={{ marginTop: "-20px" }}
                      className="css-l8pll-MuiFormHelperText-root Mui-error"
                    >
                      {t("pleaseEnterValidDateOfBirth")}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item sm={7}>
                <InputLabel
                  variant="standard"
                  htmlFor="verification-country-select"
                  sx={{ marginBottom: "10px" }}
                >
                  {t("documentType")}
                </InputLabel>
                <Select
                  value={documentType}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleDocumentTypeChange}
                  sx={{ width: "100%", marginBottom: "20px" }}
                >
                  {idTypes[`${selectedCountry}`].map((data) => {
                    return <MenuItem value={data}>{data}</MenuItem>;
                  })}
                </Select>
              </Grid>
              <Grid item sm={5}>
                <InputLabel
                  variant="standard"
                  htmlFor="verification-country-select"
                  sx={{ marginBottom: "10px" }}
                >
                  {t("countryOfResidence")}
                </InputLabel>
                <Select
                  value={selectedCountry}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleCountryChange}
                  sx={{ width: "100%", marginBottom: "20px" }}
                >
                  {countryDropdownData.map((data) => {
                    return <MenuItem value={data.id}>{data.name}</MenuItem>;
                  })}
                </Select>
              </Grid>
            </Grid>

            <Grid container mt={2} spacing={1.2}>
              <Grid item sm={6}>
                <Button
                  onClick={handleVerifyUser}
                  fullWidth
                  variant="contained"
                  sx={{
                    width: "100%",
                  }}
                >
                  {t("verify")}
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  fullWidth
                  onClick={handleClose}
                  variant="contained"
                  color="inherit"
                  sx={{
                    backgroundColor: "primary.grey",
                  }}
                >
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default UserVerificationModal;

import React from "react";
import { Box } from "@mui/material";
import { TabStyle } from "./styles";
import { useAppSelector } from "../../../store";

import CreatorSideNav from "./CreatorSideNav";
import DonorSideNav from "./DonorSideNav";
import InstitutionalNav from "./InstitutionalNav";
import GrantOrganizationSideNav from "./GrantOrganizationSideNav";
import GrantManagerSideNav from "./GrantManagerSideNav";
import { userRole } from "../../../constants/common-enum";
import GrantApplicantSideNav from "./GrantApplicantSideNav";

function UserSideNav() {
  const user = useAppSelector((state) => state.userReducer);

  return (
    <Box sx={TabStyle}>
      {user.role === userRole.CREATOR ? (
        <CreatorSideNav />
      ) : user.role === userRole.GRANT_APPLICANT ? (
        <GrantApplicantSideNav />
      ) : user.role === userRole.INVESTOR ? (
        <DonorSideNav />
      ) : user.role === userRole.INSTITUTIONAL_DONOR ? (
        <InstitutionalNav />
      ) : user.role === userRole.GRANT_CREATOR ? (
        <GrantOrganizationSideNav />
      ) : user.role === userRole.GRANT_MANAGER ? (
        <GrantManagerSideNav />
      ) : (
        ""
      )}
    </Box>
  );
}

export default UserSideNav;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setRewardType } from "../../../../store/slices/CreateCampaignSlice";
import { UploadBox } from "../selectRewardTypeModalStyle";

interface modalNftCard {
  data: any;
}

function ModalNftCard({ data }: modalNftCard) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedNftId = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardNftId
  );

  const handleSelect = () => {
    if (selectedNftId === `${data["asset-id"]}`) {
      const nftData = {
        rewardNftId: "",
      };
      dispatch(setRewardType(nftData));
    } else {
      const nftData = {
        rewardNftId: `${data["asset-id"]}`,
      };
      dispatch(setRewardType(nftData));
    }
  };
  return (
    <Grid item xl={3} lg={4} sm={6} xs={12}>
      <div
        style={
          selectedNftId === `${data["asset-id"]}`
            ? {
                background: "#d4d4d4",
                padding: "16px",
                borderRadius: "10px",
                cursor: "pointer",
              }
            : {
                background: "inherit",
                padding: "16px",
                borderRadius: "10px",
                cursor: "pointer",
              }
        }
        onClick={handleSelect}
      >
        <Box sx={UploadBox}>
          <video
            style={{ objectFit: "cover" }}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            poster={data.url}
          >
            <source src={data.url} type="video/mp4" />
            <source src={data.url} type="video/ogg" />
            {t("yourBrowserDoesNotSupportTheVideoTag")}
          </video>
        </Box>
        <Typography
          sx={{
            color: "initial",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          variant="h6"
          align="left"
          mb={0}
          className="bold"
        >
          {data["unit-name"]}
        </Typography>
        <Typography
          sx={{
            color: "initial",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          variant="h6"
          align="left"
          mb={0}
        >
          {data["asset-id"]}
        </Typography>
        <Typography>
          {data["asset-name"].length > 85
            ? `${data["asset-name"].slice(0, 85)}...`
            : data["asset-name"]}
        </Typography>
        <Typography>
          {" "}
          {/* <b>Price</b> - ${data.Price / 1000000} ALGO */}
        </Typography>
      </div>
    </Grid>
  );
}

export default ModalNftCard;

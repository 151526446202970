import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Typography,
  Box,
  InputAdornment,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setLoginModal,
  setRegisterModal,
} from "../../../store/slices/ModalSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import style from "./styles";
import { titleStyle, ContentStyle, SocialLinks, FooterBtn } from "./styles";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import {
  checkEmailExistance,
  registerIndividualUser,
} from "../../../requests/simpleRequest";
import GoogleRegisterButton from "../../../components/auth/GoogleRegisterButton";
import FacebookRegisterButton from "../../../components/auth/FacebookRegisterButton";
import {
  createAccountBlockchain,
  createCreatorAccountBlockchain,
  createApplicantAccountBlockchain,
} from "../../../blockchain";
import algosdk from "algosdk";
import { client } from "../../../wallets/algorand";
import countryList from "../../../components/user/createCampaign/countrylist";
import ActivityModal from "./ActivityModal";
import { useTranslation } from "react-i18next";
import { useWallet } from "@txnlab/use-wallet";
import Wallets from "../../../wallets";
import Permission from "./permission";

const Register = () => {
  const { t } = useTranslation();
  const { activeAccount, signTransactions, providers } = useWallet();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
  const [invalidConfirmPassword, setInvalidConfirmPassword] =
    useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [invalidName, setInvalidName] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>("");
  const [invalidPhone, setInvalidPhone] = useState<boolean>(false);

  const [profession, setProfession] = useState<string>("");
  const [invalidProfession, setInvalidProfession] = useState<boolean>(false);

  const [education, setEducation] = useState<string>("");
  const [invalidEducation, setInvalidEducation] = useState<boolean>(false);

  const open = useAppSelector((state) => state.modalReducer.registerModal);
  const dispatch = useAppDispatch();
  const userslice = useAppSelector((state) => state.userReducer);
  const [country, setCountry] = useState<string>("");
  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(e.target.value);
  };
  const [institutionalDonarData, setInstitutionalDonarData] = useState<{
    organisationName: string;
    organisationRole: string;
  }>({
    organisationName: "",
    organisationRole: "",
  });
  const [interestGoal, setInterestGoal] = useState<string[]>([]);
  const goalFieldsList = ["Environmental", "Social", "Governance"];
  const [formStep, setFormStep] = useState<boolean>(false);

  const resetFormState = () => {
    setName("");
    setInvalidName(false);
    setPassword("");
    setInvalidPassword(false);
    setEmail("");
    setInvalidEmail(false);
    setShowPassword(false);
    setConfirmPassword("");
    setCountry("");
    setInstitutionalDonarData({
      organisationName: "",
      organisationRole: "",
    });
    setInterestGoal([]);
    setFormStep(false);
  };

  const handleClose = (e: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    resetFormState();
    dispatch(setRegisterModal(false));
  };

  const handleOpenSignIn = () => {
    resetFormState();
    dispatch(setRegisterModal(false));
    dispatch(setLoginModal(true));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateInputs = () => {
    let res = true;
    //for name
    if (name.trim().length <= 0) {
      setInvalidName(true);
      res = false;
    } else {
      setInvalidName(false);
    }

    if (userslice.registerUserType === "applicant") {
      //for phone
      if (phone.trim().length <= 0) {
        setInvalidPhone(true);
        res = false;
      } else {
        setInvalidPhone(false);
      }

      //for education
      if (education.trim().length <= 0) {
        setInvalidEducation(true);
        res = false;
      } else {
        setInvalidEducation(false);
      }

      //for profession
      if (profession.trim().length <= 0) {
        setInvalidProfession(true);
        res = false;
      } else {
        setInvalidProfession(false);
      }
    }
    //for email
    if (email.trim().length <= 0) {
      // error = 'Please enter your email address';
      setInvalidEmail(true);
      res = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      // error = 'Invalid email address';
      setInvalidEmail(true);
      res = false;
    } else {
      setInvalidEmail(false);
    }

    // for passwordd
    if (
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[^A-Za-z0-9]/.test(password) &&
      password.trim().length >= 8
    ) {
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
      res = false;
    }
    // for passwordd
    if (
      /[A-Z]/.test(confirmPassword) &&
      /[a-z]/.test(confirmPassword) &&
      /[0-9]/.test(confirmPassword) &&
      /[^A-Za-z0-9]/.test(confirmPassword) &&
      confirmPassword.trim().length >= 8
    ) {
      setInvalidConfirmPassword(false);
    } else {
      setInvalidConfirmPassword(true);
      res = false;
    }

    if (password === confirmPassword) {
      setInvalidConfirmPassword(false);
    } else {
      setInvalidConfirmPassword(true);
      res = false;
    }
    return res;
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();
    const everythinValid = validateInputs();
    if (everythinValid) {
      setShowWalletButtons(true);
    }
  };

  const handleBlockchainSubmit = async () => {
    const checkEmailObj = {
      email: email,
    };
    const emailExistance = await checkEmailExistance(checkEmailObj);
    if (!emailExistance.success) {
      const toastPaylaod = {
        text: t("unableToVerifyIfEmailAlreadExist"),
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      const returnObj = {
        success: false,
        userAppId: "",
      };
      return returnObj;
    } else if (emailExistance.data.emailExists) {
      const toastPaylaod = {
        text: emailExistance.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      const returnObj = {
        success: false,
        userAppId: "",
      };
      return returnObj;
    }

    // dispatch(toggleAppLoading(false));
    let info;
    if (userslice.registerUserType === "grant-creator") {
      info = {
        user_type: userslice.registerUserType,
        wallet_address: activeAccount?.address,
        organisation_name: institutionalDonarData.organisationName,
        organisation_role: institutionalDonarData.organisationRole,
      };
    } else {
      info = {
        // name: name,
        user_type: userslice.registerUserType,
        // email: email,
        wallet_address: activeAccount?.address,
      };
    }
    const res =
      userslice.registerUserType === "grant-creator"
        ? await createCreatorAccountBlockchain(info)
        : userslice.registerUserType === "applicant"
        ? await createApplicantAccountBlockchain(info)
        : await createAccountBlockchain(info);
    try {
      if (res.success) {
        dispatch(toggleAppLoading(false));
        const transactionArr = res.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });

        let signedTxn: any = [];
        // try {
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));
        // await currentProvider?.reconnect();
        // }

        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();
        // dispatch(toggleAppLoading(false));
        // dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );
        // get the transaction res
        // dispatch(setAppLoadingText(t("fetchingTransactionData")));
        let transactionResponse = await client
          .pendingTransactionInformation(transationRes.txId)
          .do();
        dispatch(setAppLoadingText(""));
        let appId = transactionResponse["application-index"];
        if (transactionResponse["application-index"]) {
          const returnObj = {
            success: true,
            userAppId: appId,
          };
          return returnObj;
        } else {
          const returnObj = {
            success: false,
            userAppId: "",
          };

          const toastPaylaod = {
            text: t("unableToSendDataToBlockchain"),
            success: false,
            active: true,
          };

          dispatch(setToaster(toastPaylaod));
          return returnObj;
        }
        // } catch (err) {
        // 	console.log("err is :", err);
        // }
      }
      dispatch(toggleAppLoading(false));
    } catch (err) {
      const returnObj = {
        success: false,
        userAppId: "",
      };

      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      return returnObj;
    }
  };

  const handleSubmit = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    // e?.preventDefault();
    // const everythinValid = validateInputs();
    // if (everythinValid) {
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    const blockchainRes = await handleBlockchainSubmit();
    if (!blockchainRes?.success) {
      return;
    }
    let payload = {};

    if (
      userslice.registerUserType === "institutional-donor" ||
      userslice.registerUserType === "grant-creator"
    ) {
      payload = {
        name: name,
        email: email,
        password: password,
        role: userslice.registerUserType,
        userAppId: blockchainRes.userAppId,
        organisationName: institutionalDonarData.organisationName,
        roleInOrganisation: institutionalDonarData.organisationRole,
        interest: interestGoal,
        country: country,
      };
      console.log("oaylod", payload, userslice);
    } else if (userslice.registerUserType === "applicant") {
      payload = {
        name,
        email,
        password,
        phone,
        education,
        profession,
        role: userslice.registerUserType,
        userAppId: blockchainRes.userAppId,
      };
    } else {
      payload = {
        name: name,
        email: email,
        password: password,
        role: userslice.registerUserType,
        userAppId: blockchainRes.userAppId,
      };
    }

    dispatch(setAppLoadingText(t("savingTransactionData")));
    const res: any = await registerIndividualUser(payload);
    if (res.success) {
      const toastPaylaod = {
        text: res.data.message,
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    }
    dispatch(toggleAppLoading(false));
    dispatch(setAppLoadingText(""));
    resetFormState();
    dispatch(setRegisterModal(false));
    // }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!formStep ? (
          <Box
            sx={{
              height: "100vh",
              outline: " 0px",
              overflowY: "scroll",
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
              paddingTop: "0px",
              "@media(max-height:700px)": {
                alignItems: "baseline",
              },
            }}
            // onClick={handleClose}
          >
            <Box
              sx={style}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Box sx={titleStyle}>
                <CancelIcon
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleClose(e, "main")}
                />

                <Typography variant="h6" align="center" fontWeight={600}>
                  {userslice.registerUserType === "institutional-donor"
                    ? t("institutionalDonor")
                    : userslice.registerUserType === "grant-creator"
                    ? t("grantCreator")
                    : userslice.registerUserType === "applicant"
                    ? t("grantApplicant")
                    : userslice.registerUserType === "creator"
                    ? t("campaignCreator")
                    : t("individualDonor")}
                </Typography>
                <Typography variant="h4" align="center" mb={2}>
                  {t("createNewAccount")}
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="caption">
                    {t("alreadyHaveAnAccount?")}&nbsp;
                  </Typography>
                  <Button variant="text" onClick={handleOpenSignIn}>
                    {t("signIn")}
                  </Button>
                </Box>
                {/* {userslice.registerUserType === "grant-creator" && (
								<Box sx={{ textAlign: "center" }}>
									<Typography variant="caption">
										if you are NGO/Company. please click&nbsp;
									</Typography>
									<Button variant="text">Here</Button>
								</Box>
							)} */}
              </Box>
              <form autoComplete="off">
                <Box sx={ContentStyle}>
                  {(userslice.registerUserType === "institutional-donor" ||
                    userslice.registerUserType === "grant-creator") && (
                    <TextField
                      fullWidth
                      label={t("organisationName")}
                      variant="outlined"
                      type={"text"}
                      id="filled-hidden-label-small"
                      placeholder={t("organisationName") || ""}
                      value={institutionalDonarData.organisationName}
                      onChange={(e) =>
                        setInstitutionalDonarData((prev) => ({
                          ...prev,
                          organisationName: e.target.value,
                        }))
                      }
                      error={invalidName}
                      helperText={
                        invalidName && t("pleaseEnterYourOrganisationName")
                      }
                      onKeyDown={handleKeyDown}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  )}
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={name}
                    type={"text"}
                    id="filled-hidden-label-small"
                    placeholder={t("yourName") || ""}
                    label={t("yourName")}
                    variant="outlined"
                    onChange={handleNameChange}
                    error={invalidName}
                    helperText={invalidName && t("pleaseEnterYourName")}
                    onKeyDown={handleKeyDown}
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  />
                  {(userslice.registerUserType === "institutional-donor" ||
                    userslice.registerUserType === "grant-creator") && (
                    <TextField
                      fullWidth
                      hiddenLabel
                      type={"text"}
                      id="filled-hidden-label-small"
                      placeholder={t("roleInOrganisation") || ""}
                      label={t("roleInOrganisation")}
                      variant="outlined"
                      value={institutionalDonarData.organisationRole}
                      onChange={(e) =>
                        setInstitutionalDonarData((prev) => ({
                          ...prev,
                          organisationRole: e.target.value,
                        }))
                      }
                      error={invalidName}
                      helperText={invalidName && t("pleaseEnterYourRole")}
                      onKeyDown={handleKeyDown}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  )}

                  {(userslice.registerUserType === "institutional-donor" ||
                    userslice.registerUserType === "grant-creator") && (
                    <TextField
                      fullWidth
                      id="filled-select-currency"
                      select
                      label={`${t("country")} (${t("optional")})`}
                      value={country}
                      onChange={handleCountryChange}
                      variant="outlined"
                      // error={invalidName}
                      // helperText={invalidName && "Please select your country"}
                    >
                      {countryList.map((country) => {
                        return (
                          <MenuItem
                            value={country.toLocaleLowerCase()}
                            key={country}
                          >
                            {country}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    // <Box sx={{ marginBottom: 3 }}>
                    // 	<FormLabel component="legend">Country</FormLabel>
                    // 	<Select
                    // 		fullWidth
                    // 		label="Country"
                    // 		value={country}
                    // 		onChange={handleCountryChange}
                    // 		displayEmpty
                    // 		inputProps={{ "aria-label": "Without label" }}
                    // 	>
                    // 		{countryList.map((country) => {
                    // 			return (
                    // 				<MenuItem value={country.toLocaleLowerCase()} key={country}>
                    // 					{country}
                    // 				</MenuItem>
                    // 			);
                    // 		})}
                    // 	</Select>
                    // </Box>
                  )}

                  <TextField
                    fullWidth
                    hiddenLabel
                    type={"email"}
                    id="email"
                    placeholder={t("yourEmail") || ""}
                    label={t("yourEmail")}
                    variant="outlined"
                    onChange={handleEmailChange}
                    error={invalidEmail}
                    helperText={invalidEmail && t("pleaseEnterAValidEmail")}
                    onKeyDown={handleKeyDown}
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    value={email}
                  />
                  {userslice.registerUserType === "applicant" && (
                    <TextField
                      fullWidth
                      hiddenLabel
                      type={"text"}
                      id="phone"
                      placeholder={t("phoneNumber") || ""}
                      label={t("phoneNumber")}
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      error={invalidPhone}
                      helperText={
                        invalidPhone && t("pleaseEnterYourPhoneNumber")
                      }
                      onKeyDown={handleKeyDown}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  )}
                  {userslice.registerUserType === "applicant" && (
                    <TextField
                      fullWidth
                      hiddenLabel
                      type={"text"}
                      id="education"
                      placeholder={t("education") || ""}
                      label={t("education")}
                      variant="outlined"
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                      error={invalidEducation}
                      helperText={
                        invalidEducation && t("pleaseEnterYourEducation")
                      }
                      onKeyDown={handleKeyDown}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  )}
                  {userslice.registerUserType === "applicant" && (
                    <TextField
                      fullWidth
                      hiddenLabel
                      type={"text"}
                      id="filled-hidden-label-small"
                      placeholder={t("profession") || ""}
                      label={t("profession")}
                      variant="outlined"
                      value={profession}
                      onChange={(e) => setProfession(e.target.value)}
                      error={invalidProfession}
                      helperText={
                        invalidProfession && t("pleaseEnterYourProfession")
                      }
                      onKeyDown={handleKeyDown}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  )}

                  <TextField
                    fullWidth
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    error={invalidPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{
                              color: "#d3d3d3",
                            }}
                          >
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("password") || ""}
                    onKeyDown={handleKeyDown}
                    helperText={
                      invalidPassword &&
                      t(
                        "pleaseEnter8CharacterLongPasswordWithAnUppercaseALowercaseASpecialCharacterAndANumber"
                      )
                    }
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    label={t("confirmPassword")}
                    onChange={handleConfirmPasswordChange}
                    error={invalidConfirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{
                              color: "#d3d3d3",
                            }}
                          >
                            {!showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("confirmPassword") || ""}
                    onKeyDown={handleKeyDown}
                    helperText={
                      invalidConfirmPassword &&
                      t("confirmPasswordIsNotMatchingWithPassword")
                    }
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  />

                  {userslice.registerUserType === "institutional-donor" && (
                    <Box sx={{ marginTop: "30px" }}>
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        {t("pleaseSelectPrimaryField(s)OfInterest")}
                      </Typography>
                      {goalFieldsList.map((item: any) => (
                        <FormControlLabel
                          key={item}
                          control={
                            <Checkbox
                              checked={Boolean(
                                interestGoal.some((el) => el === item)
                              )}
                              onChange={() =>
                                interestGoal.some((el) => el === item)
                                  ? setInterestGoal(
                                      interestGoal.filter((el) => el !== item)
                                    )
                                  : setInterestGoal((prev) => [...prev, item])
                              }
                              // name={item?.goalName}
                              color="primary"
                            />
                          }
                          label={item}
                        />
                      ))}
                    </Box>
                  )}

                  <Box sx={{ textAlign: "center" }} mt={3} mb={3}>
                    <Typography
                      variant="caption"
                      sx={{ fontSize: "12px", color: "#817f7f" }}
                    >
                      {t("ORCONTINUEWITH")}
                    </Typography>
                  </Box>
                </Box>
              </form>
              <Box sx={SocialLinks}>
                <GoogleRegisterButton />
                <FacebookRegisterButton />
              </Box>
              {/* <FormControlLabel
                control={
                  <Checkbox onChange={(e) => setChecked(e.target.checked)} />
                }
                label={
                  <>
                    I have accepted &nbsp;

                    <Link to="terms-of-use" target="_blank"> Terms of use</Link> &{" "}
                    <Link to="privacy-policy" target="_blank">Privacy Policy</Link>
                  </>
                }
              /> */}
              {/* <Permission checked={checked} setChecked={setChecked} /> */}
              <Box sx={FooterBtn}>
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (
                        userslice.registerUserType === "institutional-donor"
                      ) {
                        const everythinValid = validateInputs();
                        everythinValid && setFormStep(true);
                      } else {
                        handleShowWalletOptions();
                      }
                    }}
                    // onClick={handleSubmit}
                    // disabled={!checked}
                  >
                    {t("createAccount")}
                  </Button>
                </Box>
                <Button
                  sx={{ color: "initial" }}
                  variant="text"
                  onClick={(e) => handleClose(e, "main")}
                >
                  {t("doItLater")}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <ActivityModal
            handleSubmit={() => {
              // handleSubmit();
              setFormStep(false);
              handleShowWalletOptions();
            }}
            handleClose={() => {
              dispatch(setRegisterModal(false));
              setFormStep(false);
            }}
          />
        )}
      </Modal>
      {showWalletButtons && (
        <Wallets
          open={showWalletButtons}
          handleClose={() => setShowWalletButtons(false)}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default Register;

import { SessionWallet } from "algorand-session-wallet";
import axios from "axios";
import { axiosInstance } from "./AuthRequestInterceptor";
import { t } from "i18next";
const URL = process.env.REACT_APP_URL;
const BLOCKCHAIN_URL = process.env.REACT_APP_BLOCKCHAIN_URL;

export const logoutUser = async () => {
  var config = {
    method: "post",
    url: `${URL}/api/auth/logout`,
  };
  // try catch for the wallet disconnect
  try {
    const sw = new SessionWallet("TestNet", undefined, "my-algo-connect");
    if (sw.connected()) {
      sw.disconnect();
    }
  } catch (e) {}

  // try catch for the user disconnect
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createCampaign = async (campData) => {
  var data = JSON.stringify(campData);
  var config = {
    method: "post",
    url: `${URL}/api/campaign`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const saveToDraft = async (campData) => {
  var data = JSON.stringify(campData);
  var config = {
    method: "post",
    url: `${URL}/api/campaign/draft`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const updateDraft = async (campData) => {
  var data = JSON.stringify(campData);
  var config = {
    method: "post",
    url: `${URL}/api/campaign/update-campaign`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const uploadCampaignImage = async (file) => {
  var data = new FormData();
  // eslint-disable-next-line array-callback-return
  file?.map((item) => {
    data.append("files", item);
  });

  var config = {
    method: "post",
    url: `${URL}/api/file/upload/files`,
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res?.data?.fileIds,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const uploadCampaignVideo = async (video) => {
  var data = new FormData();
  data.append("video", video);
  var config = {
    method: "post",
    url: `${URL}/api/file/upload/video`,
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const fetchLoggedInUser = async () => {
  var config = {
    method: "get",
    url: `${URL}/api/user`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMyCampaigns = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/user/campaigns?page=${data.page}&limit=${
      data.limit
    }&status=${JSON.stringify(data.status)}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getWhaleTank = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/get-whale-tank?page=${data.page}&limit=${data.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const saveEditedProfile = async (data) => {
  const payload = JSON.stringify(data);

  var config = {
    method: "put",
    url: `${URL}/api/user/`,
    data: payload,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteCampaign = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "delete",
    url: `${URL}/api/campaign/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getEditCampaignDetails = async (campaignId) => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/edit?campaignId=${campaignId}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const updatePendingCampaignData = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "put",
    url: `${URL}/api/campaign/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const donateCampaignDummy = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/campaign/donate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const donateMatchFundCampaign = async (info) => {
  const data = info;
  var config = {
    method: "post",
    url: `${URL}/api/campaign/match-fund-donation`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const postReview = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/campaign/review`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const editInvestorReview = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "put",
    url: `${URL}/api/campaign/review`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteInvestorReview = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "delete",
    url: `${URL}/api/campaign/review`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getInvestedCampaignTable = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/user/donations?page=${info.page}&limit=${info.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getInvestedCampaignDetails = async (id) => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/investor?campaignId=${id}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getCreatorNotifications = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/notification?page=${info.page}&limit=${info.limit}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const markNotificationRead = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/notification/read`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const initiateChat = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/chat/channel?limit=1000&participantId=${info.participant}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getChatMessage = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/chat/messages?channelId=${info.channelId}&limit=1000`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const sendMessage = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/chat/message`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getRecentChats = async () => {
  var config = {
    method: "get",
    url: `${URL}/api/chat/channels`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getAdminNft = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/reward/nft?isAdmin=true&page=${info.page}&limit=${info.limit}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const buyDummyNft = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/reward/buy-nft`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getBougthNft = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/reward/nft?isMine=true&page=${info.page}&limit=${info.limit}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getNftById = async (id) => {
  var config = {
    method: "get",
    url: `${URL}/api/reward/nft-by-id?nftId=${id}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const mintNft = async (info) => {
  var data = new FormData();
  data.append("media", info.file);
  data.append("title", info.title);
  data.append("price", info.price);
  data.append("description", info.description);

  var config = {
    method: "post",
    url: `${URL}/api/reward/nft`,
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const updateMilestone = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "put",
    url: `${URL}/api/campaign/milestone`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const submitMilestoneReport = async (info) => {
  let data = new FormData();

  data.append("milestoneId", info.milestoneId);
  data.append("objectiveMeet", info.objectiveMet);
  data.append("evidenceFiles", info.imageFile);
  data.append("comments", info.description);
  data.append("supportingDocs", info.pdfFile);
  data.append("expectedResults", info.expectedResults);
  data.append("expectedYearlyoutput", info.expectedYearlyoutput);
  data.append("expectedCostPerUnit", info.expectedCostPerUnit);
  data.append("isReforestationProject", info.isReforestationProject);
  var config = {
    method: "post",
    url: `${URL}/api/campaign/milestone/submit-standard-report`,
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const verifyUserSmile = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/smile/submit`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const fetchCampaignReward = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "get",
    url: `${BLOCKCHAIN_URL}/nft_info/${data}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const handleIPFSUpload = async (fileData) => {
  let data = new FormData();
  data.append("file", fileData.file);
  data.append("userAppId", fileData.userAppId);

  try {
    const res = await axios.post(`${BLOCKCHAIN_URL}/ipfs`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getAllGoals = async () => {
  try {
    var config = {
      method: "get",
      url: `${URL}/api/admin/goal`,
    };
    const res = await axiosInstance(config);

    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const submitMatchFunds = async (fundData) => {
  try {
    let uri = "";
    if (fundData.payment === "full") {
      uri = `${URL}/api/campaign/get-match-fund-campaign?goalCategories=${fundData.goalCategories}&paymentCategories=${fundData.paymentCategories}&ratingFrom=${fundData.ratingFrom}&ratingTo=${fundData.ratingTo}&payment=${fundData.payment}&action=${fundData.action}`;
    } else {
      uri =
        fundData.action === "matchCurrentContribution"
          ? `${URL}/api/campaign/get-match-fund-campaign?goalCategories=${fundData.goalCategories}&paymentCategories=${fundData.paymentCategories}&ratingFrom=${fundData.ratingFrom}&ratingTo=${fundData.ratingTo}&payment=${fundData.payment}&action=${fundData.action}&currentContributionPercent=${fundData.currentContributionPercent}`
          : `${URL}/api/campaign/get-match-fund-campaign?goalCategories=${fundData.goalCategories}&paymentCategories=${fundData.paymentCategories}&ratingFrom=${fundData.ratingFrom}&ratingTo=${fundData.ratingTo}&payment=${fundData.payment}&action=${fundData.action}`;
    }

    var config = {
      method: "get",
      url: uri,
    };
    const res = await axiosInstance(config);

    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMyGrants = async (data) => {
  let params = "";
  if (data.organisationOwner) {
    params = `&organisationOwner=${data.organisationOwner}`;
  }
  var config = {
    method: "get",
    url: `${URL}/api/grant/all?page=${data.page}&limit=${data.limit}${params}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getGrantManager = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/grantmanager/getGrantManager?page=${data.page}&limit=${data.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      returnRes.data.grantManagers = returnRes.data.grantManagers.map((val) => {
        return {
          id: val._id,
          fullName: val.fullName,
          email: val.email,
          grantsCreated: val?.grantCount,
          status: val.status,
          createdAt: val.createdAt,
          userAppId: val.userAppId,
          totalCreatedGrants: val.totalCreatedGrants,
          action: "",
        };
      });
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createGrantManager = async (data) => {
  var config = {
    method: "post",
    url: `${URL}/api/grantmanager/createGrantManager`,
    data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getGrantManagerById = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/grantmanager/getParticularGrantManager?grantManagerId=${data.managerId}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const createGrant = async (data) => {
  const payload = JSON.stringify(data);
  const config = {
    method: "post",
    url: `${URL}/api/grant/create`,
    data: payload,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const updateGrantManager = async (data) => {
  var config = {
    method: "post",
    url: `${URL}/api/grantmanager/editGrantManager`,
    data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getGrantById = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/grant/?grantId=${data}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const updateGrant = async (data) => {
  var config = {
    method: "post",
    url: `${URL}/api/grant/edit`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const submitGrants = async (grantData) => {
  var data = JSON.stringify(grantData);
  var config = {
    method: "post",
    url: `${URL}/api/grant/application-for-grant`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const editGrants = async (grantData) => {
  var data = JSON.stringify(grantData);
  var config = {
    method: "post",
    url: `${URL}/api/grant/editGrantApplication`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteGrant = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/grant/delete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMyGrantApplications = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/grant/getApplications?page=${data.page}&limit=${data.limit}&userId=${data.userId}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      returnRes.data.allApplications = returnRes.data.allApplications.map(
        (val) => {
          return {
            id: val?.grantApplicationId,
            grantTitle: val?.grantId?.title,
            grantCreatorName: val?.grantId?.name,
            requestedFunds: val?.requestedFund,
            status: val?.status,
            country: val?.grantId?.preferredLocation,
            action: "string;",
            grantStatus: val?.grantId?.status,
          };
        }
      );
      returnRes.data.totalPages = res?.data?.totalPages || 1;
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMyGrantApplication = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/grant/getApplication?grantApplicationId=${data.grantApplicationId}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getApplicationById = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/grant/getApplication?grantApplicationId=${data}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const approveGrantApplication = async (grantData) => {
  var data = JSON.stringify(grantData);
  var config = {
    method: "post",
    url: `${URL}/api/grant/approve-grant-application`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteGrantApplication = async (grantData) => {
  var data = JSON.stringify(grantData);
  var config = {
    method: "post",
    url: `${URL}/api/grant/reject-grant-application`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const deleteGrantManager = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "delete",
    url: `${URL}/api/grantmanager/deleteGrantManager`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getMilestoneDetails = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/grant/getMilestoneReport?milestoneId=${data.milestoneId}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const approveGrantMilestone = async (grantData) => {
  var data = JSON.stringify(grantData);
  var config = {
    method: "post",
    url: `${URL}/api/grant/approve-milestone-report`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const rejectGrantMilestone = async (grantData) => {
  var data = JSON.stringify(grantData);
  var config = {
    method: "post",
    url: `${URL}/api/grant/reject-milestone-report`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const submitGrantMilestoneReport = async (info) => {
  let data = new FormData();

  data.append("milestoneId", info.milestoneId);
  data.append("objectiveMeet", info.objectiveMet);
  data.append("evidenceFiles", info.imageFile);
  data.append("comments", info.description);
  data.append("supportingDocs", info.pdfFile);

  var config = {
    method: "post",
    url: `${URL}/api/grant/submit-standard-report`,
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: t("errorLoggingOutData") },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

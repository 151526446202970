import { Button, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { requestResetPassword } from "../../requests/simpleRequest";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { setReqResetPasswordModal } from "../../store/slices/ModalSlice";
import { style, titleStyle, FooterBtn } from "./selectRoleStyle";

function RequestResetPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const open = useAppSelector(
    (state) => state.modalReducer.reqResetPasswordModal
  );

  const handleClose = () => {
    setEmail("");
    dispatch(setReqResetPasswordModal(false));
  };

  const handleReset = async () => {
    let res = true;
    if (email.trim().length <= 0) {
      // error = 'Please enter your email address';
      setInvalidEmail(true);
      res = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      // error = 'Invalid email address';
      setInvalidEmail(true);
      res = false;
    } else {
      setInvalidEmail(false);
    }

    if (!res) {
      return;
    } else {
      dispatch(toggleAppLoading(true));
      const info = {
        email: email,
      };
      const res = await requestResetPassword(info);
      dispatch(toggleAppLoading(false));
      if (res.success) {
        const toastPlayload = {
          active: true,
          text: t("emailWithPasswordResetLinkHaveBeenSentToYourMail"),
          success: true,
        };
        dispatch(setToaster(toastPlayload));
        handleClose();
      } else {
        const toastPlayload = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toastPlayload));
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleReset();
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: "100%",
          outline: " 0px",
          overflow: "hidden auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media(max-height:700px)": {
            alignItems: "baseline",
          },
        }}
        onClick={handleClose}
      >
        <Box
          sx={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box sx={titleStyle}>
            <Typography variant="h4" align="center" mb={2}>
              {t("resetPassword")}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="caption">
                {t("pleaseEnterYourVerifiedEmailHereToGetTheResetLink")}
              </Typography>
            </Box>
          </Box>
          <Box mt={3}>
            <TextField
              fullWidth
              hiddenLabel
              type={"email"}
              id="filled-hidden-label-small"
              placeholder={t("yourEmail") || ""}
              variant="outlined"
              onChange={handleEmailChange}
              error={invalidEmail}
              helperText={invalidEmail && "Please enter a valid Email"}
              onKeyDown={handleKeyDown}
            />
          </Box>
          <Box sx={FooterBtn}>
            <Box sx={{ textAlign: "center" }}>
              <Button variant="contained" onClick={handleReset}>
                {t("resetPassword")}
              </Button>
            </Box>
            <Button
              sx={{ color: "initial" }}
              variant="text"
              onClick={handleClose}
            >
              {t("doItLater")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default RequestResetPassword;

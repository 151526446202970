import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
  Button,
  Menu,
  FormLabel,
  Stack,
  SelectChangeEvent,
  Modal,
  IconButton,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Wallets from "../../../../wallets";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  getAllGoals,
  saveToDraft,
  uploadCampaignImage,
} from "../../../../requests/authRequest";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PhotoUpload } from "../styles";
import countryList from "../countrylist";
import NotificationToast from "../../../../loaders/NotificationToast";
import {
  campaignState,
  setCampaignPreview,
  setPreview,
} from "../../../../store/slices/CreateCampaignSlice";
import Preview from "../../preview/preview";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";

function AdditionalInfo({
  handleSteps,
  currentCampaignId,
  handleCampaignCreate,
  contactName,
  handleContactName,
  contactEmail,
  handleContactEmail,
  contactPhone,
  handleContactPhone,
  companyName,
  handleCompanyName,
  website,
  handleWebsite,
  projectPitch,
  handleProjectPitch,
  problems,
  handleProblems,
  previousSuccessfulProject,
  handlePreviousSuccessfulProject,
  Hq,
  handleHq,
  country,
  handleCountry,
  impact,
  handleImpact,
  partnership,
  handlePartnership,
  yearOfEstablishment,
  handleYearOfEstablishment,
  stageDesc,
  handleStageDesc,
  infoImage,
  handleInfoImage,
  additionalInfo,
  handleAdditionalInfo,
  consentStatus,
  handleConsentStatus,
  reachoutStatus,
  handleReachoutStatus,
  title,
  description,
  category,
  fundingCategory,
  endDate,
  goalIds,
  campaigncountry,
  goalAmount,
  image,
  video,
  videoSelected,
  videoName,
  youTubeLink,
  imageName,
  ESG_SDG_status,
  paymentType,
}: any) {
  const { t } = useTranslation();
  document
    .getElementById("yearOfEstablishment")
    ?.setAttribute("disabled", "disabled");
  const dispatch = useAppDispatch();
  const kyc = useAppSelector((state) => state.userReducer.kyc);

  const imageRef = React.useRef<HTMLElement>();
  const [imageValid, setImageValid] = React.useState<boolean>(false);
  const handleCountryChange = (e: SelectChangeEvent) => {
    handleCountry(e.target.value);
  };
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  /**
   * handle image upload
   */

  const handleUploadChange = (e: any) => {
    const file = e.target.files;
    let fileType = file[0].name.split(".")[file[0].name.split(".").length - 1];
    if (
      fileType.toLowerCase() !== "png" &&
      fileType.toLowerCase() !== "jpeg" &&
      fileType.toLowerCase() !== "jpg"
    ) {
      setImageValid(false);

      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectPngJpgOrJpegImageFile"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (file[0].size / 1000000 > 5) {
      setImageValid(false);

      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectImageFileSmallerThanFiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (
      file[0] !== undefined &&
      file[0].name.length > 0 &&
      Math.floor(file[0].size / 1000000) < 5
    ) {
      setImageValid(true);

      handleInfoImage(file[0]);

      if (imageRef && imageRef.current) {
        // imageRef.current.style.backgroundImage = imageName[imageId];
        imageRef.current.style.backgroundImage = `url(${URL.createObjectURL(
          e.target.files[0]
        )})`;
      }
    }
  };

  React.useEffect(() => {
    if (infoImage) {
      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = `url(${URL.createObjectURL(
          infoImage
        )})`;
      }
    }
  }, [infoImage]);

  /**
   *
   * menu
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (consentStatus.title === "Yes") {
      handleContactName("");
      handleContactEmail("");
      handleContactPhone("");
      handleCompanyName("");
      handleWebsite("");
      handleProjectPitch("");
      handleProblems("");
      handleImpact("");
      handleStageDesc("");
      handleCountry("");
      handlePreviousSuccessfulProject("");
      handleHq("");
      handlePartnership("");
      handleYearOfEstablishment(null);
      handleStageDesc("");
      handleAdditionalInfo("");
      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      handleInfoImage("");
    }
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  /**
   *
   * menu
   */

  const handleChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      handleYearOfEstablishment(newValue);
    }
  };

  const handleImageUpload = async () => {
    if (infoImage) {
      const res: any = await uploadCampaignImage([infoImage]);
      if (res.success) {
        return res?.data;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const handlePreview = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    const res: any = await getAllGoals();
    dispatch(toggleAppLoading(false));
    dispatch(setAppLoadingText(t("savingToDraft")));
    let goals: any = [];
    if (goalIds.length) {
      goalIds.forEach((id: any) => {
        res.data.goals.forEach((goal: any) => {
          if (goal.goalId === id) goals.push(goal);
        });
      });
    }
    const payload: any = {
      title: title,
      description: description,
      paymentType: paymentType,
      campaignCategory: category,
      fundingCategory: fundingCategory,
      endDate: endDate,
      country: campaigncountry,
      goalAmount: goalAmount,
      youTubeLink: youTubeLink,
      socialVideoUrl: "",
      videoUrl: videoName,
      imageUrl: imageName,
      previewEnabled: true,
      video: video,
      image: image,
      imageNames: imageName,
      imageSelected: true,
      videoSelected: videoSelected,
      goalIds: goals,
      isEsgEnabled: ESG_SDG_status.status,
    };
    dispatch(setCampaignPreview(payload));
    dispatch(setPreview(true));
  };

  const previewCampaign = useAppSelector(
    (state) => state.campaignPreviewReducer.previewEnabled
  );

  /**
   * handle save to draft function
   */

  const handleSaveToDraft = async (submitStatus: boolean) => {
    if (consentStatus.title === "") {
      const toastPaylaod = {
        text: t("pleaseSelectYourConsent"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }
    // const MAX_WORD_LIMIT = 500;
    // // const wordCount = projectPitch.trim().split(/\s+/).length;
    // const wordCount1 = problems.trim().split(/\s+/).length;
    // const wordCount2 = impact.trim().split(/\s+/).length;
    // const wordCount3 = stageDesc.trim().split(/\s+/).length;
    // const wordCount4 = partnership.trim().split(/\s+/).length;
    // const wordCount5 = additionalInfo.trim().split(/\s+/).length;

    if (consentStatus.title === "Yes") {
      if (
        contactName === "" ||
        contactEmail === "" ||
        contactPhone === "" ||
        companyName === "" ||
        website === "" ||
        projectPitch === "" ||
        problems === "" ||
        impact === "" ||
        stageDesc === "" ||
        country === ""
      ) {
        const toastPaylaod = {
          text: t("pleaseFillAllTheRequiredFields"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(contactEmail)) {
        const toastPaylaod = {
          text: t("pleaseFillValidEmailAddress"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      if (
        !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
          contactPhone
        )
      ) {
        const toastPaylaod = {
          text: t("contactNumberIsNotValid"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
      if (projectPitch.trim().length > 30000) {
        const toastPayload = {
          text: "Length Exceeds Limit",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPayload));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
      if (problems.trim().length > 30000) {
        const toastPayload = {
          text: "Length Exceeds Limit",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPayload));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
      if (impact.trim().length > 30000) {
        const toastPayload = {
          text: "Length Exceeds Limit",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPayload));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
      if (stageDesc.trim().length > 30000) {
        const toastPayload = {
          text: "Length Exceeds Limit",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPayload));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      if (partnership.trim().length > 30000) {
        const toastPayload = {
          text: "Length Exceeds Limit",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPayload));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      if (additionalInfo.trim().length > 30000) {
        const toastPayload = {
          text: "Length Exceeds Limit",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPayload));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      // if (isNaN(Number(contactPhone))) {
      //   const toastPaylaod = {
      //     text: "Contact number should only contains digits",
      //     success: false,
      //     active: true,
      //   };
      //   dispatch(setToaster(toastPaylaod));
      //   dispatch(setAppLoadingText(""));
      //   dispatch(toggleAppLoading(false));
      //   return;
      // }

      let regex =
        /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
      if (!regex.test(website)) {
        const toastPaylaod = {
          text: t("pleaseFillValidWebsite"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      if (reachoutStatus.title === "") {
        const toastPaylaod = {
          text: t("pleaseSelectDoYouWantUsToReachoutToYou"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
    }

    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("savingToDraft")));

    const image = await handleImageUpload();

    let payload: any = {};

    if (currentCampaignId === "") {
      dispatch(toggleAppLoading(false));
      return;
    } else {
      payload = {
        campaignId: currentCampaignId,
        paymentType: paymentType,
        consent: consentStatus.status,
        contactName: contactName,
        contactEmail: contactEmail,
        contactPhone: contactPhone,
        companyOrProjectName: companyName,
        website: website,
        projectPitch: projectPitch,
        problems: problems,
        previousSuccessfulProjects: previousSuccessfulProject,
        hq: Hq,
        impact: impact,
        partnerships: partnership,
        yearOfEstablishment: yearOfEstablishment,
        image: image ? image[0] : "",
        companyBio: stageDesc,
        reachoutRegardingSubmission: reachoutStatus.status,
        geographies: country,
        consentForCall: reachoutStatus.status,
        additionalInformation: additionalInfo,
      };
    }

    const res = await saveToDraft(payload);

    if (res.success) {
      const toastPaylaod = {
        text: t("savedToDraftSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(""));

      if (submitStatus) {
        dispatch(toggleAppLoading(true));
        dispatch(setAppLoadingText(t("creatingCampaign")));
        handleCampaignCreate(
          res.data?.campaign?.milestones[0]?.milestoneId,
          res.data?.campaign?.milestones[1]?.milestoneId,
          res.data?.campaign?._id
        );
      }
      dispatch(setAppLoadingText(t("")));
      // cleanFormState();
      // navigationStatus && navigate("/user/my-campaign");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(""));
    }
    dispatch(toggleAppLoading(false));
    dispatch(setAppLoadingText(""));
    localStorage.removeItem("milestones");
  };

  const clearImageData = () => {
    handleInfoImage("");
    if (imageRef && imageRef.current) {
      imageRef.current.style.backgroundImage = "none";
    }
  };

  /**
   * handle save to draft function end
   */

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "15px",
        marginBottom: "20px",
        borderRadius: "5px",
      }}
    >
      <Typography style={{ textAlign: "left", fontSize: "2rem" }} variant="h3">
        {t("additionalCampaignInformation")}
      </Typography>
      <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Typography
          style={{ textAlign: "left", fontSize: "1rem" }}
          variant="h5"
        >
          {t("privacyAndDataCollection")}
        </Typography>
        <Typography variant="body1" style={{ textAlign: "left" }}>
          {t("additionalInfoTextOne")}
        </Typography>

        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("pleaseGiveYourConsent")}
          </InputLabel>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {/* {consentStatus.title !== ""
              ? consentStatus.title
              : "Please Select Option"} */}

            {consentStatus.title || "Please Select Option"}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  handleConsentStatus({ title: "Yes", status: true });
                  handleClose();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleConsentStatus({ title: "No", status: false });
                  handleClose();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Box>
      <Box
        sx={{ bgcolor: "#fff", p: 2, mt: 2, width: "100%" }}
        style={{ textAlign: "left" }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <TextField
                // disabled={consentStatus.title === "No" ? true : false}
                disabled={consentStatus.title === "No" || !consentStatus.title}
                id="outlined-basic"
                label="Contact name"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={contactName}
                onChange={(e) => handleContactName(e.target.value)}
              />
              <TextField
                disabled={consentStatus.title === "No" || !consentStatus.title}
                id="outlined-basic"
                label="Contact email"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={contactEmail}
                onChange={(e) => handleContactEmail(e.target.value)}
              />
              <TextField
                disabled={consentStatus.title === "No" || !consentStatus.title}
                id="outlined-basic"
                label="Contact phone"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={contactPhone}
                onChange={(e) => handleContactPhone(e.target.value)}
              />
              <TextField
                disabled={consentStatus.title === "No" || !consentStatus.title}
                id="outlined-basic"
                label="Company / Project / Organisation name"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={companyName}
                onChange={(e) => handleCompanyName(e.target.value)}
              />
              <TextField
                disabled={consentStatus.title === "No" || !consentStatus.title}
                id="outlined-basic"
                label="Website "
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={website}
                onChange={(e) => handleWebsite(e.target.value)}
              />
              <p style={{ marginTop: "10px" }}>{t("projectPitch")}</p>
              <TextareaAutosize
                disabled={consentStatus.title === "No" || !consentStatus.title}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("enterProjectPitch") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={projectPitch}
                onChange={(e) => handleProjectPitch(e.target.value)}
              />
              <p style={{ marginTop: "10px" }}>{t("problemsYouAreSolving")}</p>
              <TextareaAutosize
                disabled={consentStatus.title === "No" || !consentStatus.title}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("problems") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={problems}
                onChange={(e) => handleProblems(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <TextField
                disabled={consentStatus.title === "No" || !consentStatus.title}
                id="outlined-basic"
                label="Previous Successful Projects (Optional)"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={previousSuccessfulProject}
                onChange={(e) =>
                  handlePreviousSuccessfulProject(e.target.value)
                }
              />
              <TextField
                disabled={consentStatus.title === "No" || !consentStatus.title}
                id="outlined-basic"
                label="HQ (Optional)"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={Hq}
                onChange={(e) => handleHq(e.target.value)}
              />
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">{t("country")}</FormLabel>
                <Select
                  disabled={
                    consentStatus.title === "No" || !consentStatus.title
                  }
                  fullWidth
                  value={country}
                  onChange={handleCountryChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {countryList.map((country) => {
                    return (
                      <MenuItem
                        value={country.toLocaleLowerCase()}
                        key={country}
                      >
                        {country}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <p style={{ marginTop: "10px" }}>
                {t("whatDoYouHopeToAchieveWithThisProject")}
              </p>
              <TextareaAutosize
                disabled={consentStatus.title === "No" || !consentStatus.title}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("impact") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={impact}
                onChange={(e) => handleImpact(e.target.value)}
              />
              <p style={{ marginTop: "10px" }}>
                {t("partnerships")} ({t("optional")})
              </p>
              <TextareaAutosize
                disabled={consentStatus.title === "No" || !consentStatus.title}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("enterPartnershipsDetails") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={partnership}
                onChange={(e) => handlePartnership(e.target.value)}
              />
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">
                  {t("yearOfEstablishment")}({t("optional")})
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      disabled={
                        consentStatus.title === "No" || !consentStatus.title
                      }
                      inputFormat="YYYY-MM-DD"
                      value={yearOfEstablishment}
                      onChange={handleChange}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          error={false}
                          id={"yearOfEstablishment"}
                        />
                      )}
                      disableFuture
                    />
                  </Stack>
                </LocalizationProvider>
                {/* {invalidEndDate && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {errorEndDate}
                  </FormHelperText>
                )} */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <p style={{ marginTop: "10px" }}>{t("stageDescription")}</p>
              <TextareaAutosize
                disabled={consentStatus.title === "No" || !consentStatus.title}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("stageDescription") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={stageDesc}
                onChange={(e) => handleStageDesc(e.target.value)}
              />

              <Box
                style={{
                  position: "relative",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <FormLabel component="legend">
                  {t("imageUpload")} ({t("optional")})
                </FormLabel>
                <Box
                  ref={imageRef}
                  sx={PhotoUpload}
                  style={{ position: "relative" }}
                >
                  {infoImage && (
                    <IconButton onClick={clearImageData}>
                      <CancelIcon />
                    </IconButton>
                  )}
                </Box>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="contained-button-file">
                    <input
                      className="inputfile"
                      accept=".jpeg, .png"
                      id="contained-button-file"
                      onChange={handleUploadChange}
                      multiple={false}
                      type="file"
                      style={{ display: "none" }}
                    />
                    <Button
                      disabled={
                        consentStatus.title === "No" || !consentStatus.title
                      }
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        marginBottom: "-28px",
                      }}
                      variant="text"
                      component="span"
                      className="uploadButton"
                      startIcon={<FileUploadIcon />}
                    >
                      {t("imageUpload")} ({t("optional")})
                    </Button>
                  </label>
                </Stack>
              </Box>
              <p style={{ marginTop: "10px" }}>
                {t("additionalInformationOptional")}
              </p>
              <TextareaAutosize
                disabled={consentStatus.title === "No" || !consentStatus.title}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("additionalInformationOptional") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalInfo}
                onChange={(e) => handleAdditionalInfo(e.target.value)}
              />
              {/* <FormHelperText>This is a hint text to user</FormHelperText> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("additionalInfoTextTwo")}
          </InputLabel>
          <Button
            disabled={consentStatus.title === "No" || !consentStatus.title}
            id="basic-button2"
            aria-controls={open2 ? "basic-menu2" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {reachoutStatus.title !== ""
              ? reachoutStatus.title
              : "Please Select Option"}
          </Button>
          <Menu
            id="basic-menu2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button2",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  handleReachoutStatus({ title: "Yes", status: true });
                  handleClose2();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleReachoutStatus({ title: "No", status: false });
                  handleClose2();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => {
            handleSteps();
          }}
        >
          {t("back")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={handlePreview}
        >
          {t("preview")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={() => {
            handleSaveToDraft(false);
          }}
        >
          {t("saveDraft")}
        </Button>
        {kyc === "approved" && (
          <>
            <Button
              variant="contained"
              color="primary"
              className="round-button"
              onClick={() => setShowWalletButtons(true)}
            >
              {t("submit")}
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={() => handleSaveToDraft(true)}
              />
            )}
          </>
        )}
      </Box>
      <NotificationToast />
      <Modal open={previewCampaign}>
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "80%",
            height: "98vh",
            marginX: "auto",
          }}
        >
          <Preview />
        </Box>
      </Modal>
    </Box>
  );
}
export default AdditionalInfo;

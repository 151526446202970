import { Box, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { UserSideNav } from "../../../components/user/userSideNav";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import {
  campaignState,
  setCampaignPreview,
} from "../../../store/slices/CreateCampaignSlice";

function User() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const cleanFormState = () => {
    const payload: any = {
      title: "",
      description: "",
      campaignCategory: "",
      fundingCategory: "",
      endDate: "",
      country: "",
      goalAmount: "",
      socialVideoUrl: "",
      videoUrl: "",
      imageUrl: "",
      previewEnabled: false,
      video: undefined,
      image: undefined,
      imageNames: undefined,
      videoSelected: false,
      imageSelected: false,
      goalIds: [],
      isEsgEnabled: undefined,
    };
    localStorage.removeItem("milestones");
    dispatch(setCampaignPreview(payload));
  };

  useEffect(() => {
    if (location.pathname.split("/")[2] !== "create-campaign") {
      cleanFormState();
    }

    return () => {
      cleanFormState();
    };
  }, [location.pathname]);

  useEffect(() => {
    cleanFormState();
  }, []);
  return (
    <>
      <Box sx={{ bgcolor: "green.main", height: "100%", overflow: "hidden" }}>
        <Box
          sx={{
            bgcolor: "#eef0f0",
            borderRadius: "50px 50px 0px 0px",
            paddingTop: "60px",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Container fixed>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <UserSideNav />
              </Grid>
              <Grid item md={9} xs={12}>
                <Outlet />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default User;

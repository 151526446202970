import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getMarketplaceNft } from "../../../../blockchain";
import { getAdminNft } from "../../../../requests/authRequest";
import { useAppDispatch } from "../../../../store";
import {
  setAppLoadingText,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import ModalNftCard from "./ModalNftCard";
import ModalNftCardBuy from "./ModalNftCardBuy";

interface buyNftContainer {
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

function BuyNftContainer({ setValue }: buyNftContainer) {
  const { t } = useTranslation();
  //since the res from the api is uncertain so i am declaring this variable of type any now
  const [nfts, setNfts] = useState<Array<any>>([]);
  const [page, setPage] = useState<number>(1);
  const [loadmore, setLoadmore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const loadMoreRef = useRef<boolean>();
  const nftListRef = useRef<Array<any>>();
  const pageRef = useRef<number>();
  const dispatch = useAppDispatch();

  const fetchAllAdminNft = async (pageno: number) => {
    const data = {
      limit: 8,
      page: pageno,
    };
    setPage(pageno);
    setLoading(true);
    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(""));
    const res = await getMarketplaceNft(data);
    dispatch(toggleAppLoading(false));
    setLoading(false);
    if (res.success) {
      if (res.data.totalPages > pageno) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      if (nftListRef.current && pageno !== 1) {
        setNfts([...nftListRef.current, ...res.data]);
      } else {
        setNfts([...res.data]);
      }
    }
  };

  const handleLoadMore = () => {
    if (pageRef && pageRef.current) {
      fetchAllAdminNft(pageRef.current + 1);
    }
  };

  useEffect(() => {
    fetchAllAdminNft(1);
  }, []);

  useEffect(() => {
    loadMoreRef.current = loadmore;
    nftListRef.current = nfts;
    pageRef.current = page;
  }, [loadmore, nftListRef, page]);

  return (
    <>
      <Grid mb={3} container spacing={2}>
        {nfts &&
          nfts.length > 0 &&
          nfts.map((nft, index) => {
            return (
              <ModalNftCardBuy setValue={setValue} data={nft} key={index} />
            );
          })}
        {(!nfts || nfts.length === 0) && (
          <Box
            sx={{
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "400px",
              border: "1px dashed",
              margin: "20px auto 0px",
            }}
          >
            <Typography>{t("thereAreNoNftsForSale")}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ textAlign: "center" }}>
          {loadmore && !loading && (
            <Button
              sx={{ margin: "0 auto" }}
              variant="contained"
              onClick={handleLoadMore}
              color="primary"
            >
              {t("loadMore")}
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );
}

export default BuyNftContainer;

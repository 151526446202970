import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Stack,
  Modal,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { getGrantById } from "../../../requests/authRequest";
import { getGrantData } from "../../../requests/simpleRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
  setAppLoadingText,
} from "../../../store/slices/LoadinAndNotifSlice";
import { DescriptionTabs, PreviewWrap, SocialIcon } from "../campaign/style";
import moment, { duration } from "moment";
import {
  previewImage,
  setGrantApplicationData,
  setPreviewImageModal,
} from "../../../store/slices/ModalSlice";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "../../../assets/images/facebook.svg";
import algo from "../../../assets/images/algo.png";
import appIcon from "../../../assets/images/application.png";
import accept from "../../../assets/images/accept-green.png";
import acceptGray from "../../../assets/images/accept-gray.png";
import acceptGreen from "../../../assets/images/accept-green.png";
import reject from "../../../assets/images/reject-red.png";
import rejectGray from "../../../assets/images/reject-gray.png";
import chat from "../../../assets/images/chat.png";
import {
  approveGrantApplication,
  deleteGrantApplication,
  initiateChat,
} from "../../../requests/authRequest";
import {
  modalContainer,
  modalWrapper,
  titleStyle,
} from "../../../components/common/modals/algoDonationModalStyles";
import DangerousIcon from "@mui/icons-material/Dangerous";
import React from "react";
import { setCurrentActiveChat } from "../../../store/slices/ChatSlice";
import { ChatView } from "../../../components/user/chat";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  acceptGrantApplicationBlockchain,
  rejectGrantApplicationBlockchain,
} from "../../../blockchain";
import algosdk from "algosdk";
import { client, peraWallet } from "../../../../src/wallets/algorand";
import { useWallet } from "@txnlab/use-wallet";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/common/tables/CustomNoRowsOverlay";
import { MilestoneGrid } from "./style";
import { useTranslation } from "react-i18next";
import Wallets from "../../../wallets";
import fileIcon from "../../../assets/images/fileIcon.png";
import YouTube from "react-youtube";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const URL = process.env.REACT_APP_URL;
var getYouTubeID = require("get-youtube-id");

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function GrantView() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const { grantId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [previewState, setPreviewState] = useState<any>({});
  const [applications, setApplications] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const userslice = useAppSelector((state) => state.userReducer);
  const [open, setOpen] = React.useState(false);
  const [applicationId, setApplicationId] = useState<string>("0");
  const loggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const userId = useAppSelector((state) => state.userReducer.userId);
  const token = useAppSelector((state) => state.userReducer.userToken);
  const [budgetLeft, setBudgetLeft] = useState<number>(0);
  const [openChat, setOpenChat] = React.useState(false);
  const [showAcceptWalletButtons, setShowAcceptWalletButtons] =
    useState<boolean>(false);
  const [showRejectWalletButtons, setShowRejectWalletButtons] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const fetchGrantData = async () => {
    const res = !token
      ? await getGrantData(grantId)
      : await getGrantById(grantId);
    if (res.success) {
      setPreviewState({
        ...res.data.grant,
        alreadyAppliedForGrant: res.data.alreadyAppliedForGrant,
      });
      setApplications(res.data.applications || []);

      setBudgetLeft(
        res.data.grant.totalBudget -
          (res.data.applications || []).reduce(
            (total: any, application: any) =>
              (total +=
                application.status === "accepted"
                  ? application.requestedFund
                  : 0),
            0
          )
      );
    } else {
      const toastPaylaod = {
        text: t("grantDoesNotExist"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate("/");
    }
    setLoading(false);
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowAcceptWalletButtons(true);
  };
  const handleApplyForGrants = async () => {
    const payload = {
      applications: applications,
      grants: previewState,
    };
    dispatch(setGrantApplicationData(payload));
    navigate(`/grant-application/${grantId}/${previewState.grantAppId}`);
  };

  const handleThumbnailPreview = (e: React.MouseEvent<HTMLElement>) => {
    const payload: previewImage = {
      imageUrl: e.currentTarget.id,
      previewImage: true,
    };
    dispatch(setPreviewImageModal(payload));
  };

  const handleGrantApplicationActionBlockchain = async (
    app: any,
    action: string
  ) => {
    const grantInfo = {
      wallet_address: activeAccount?.address,
      user_app_id: parseInt(userslice.userAppId),
      application_app_id: parseInt(app.applicationAppId),
      grant_app_id: previewState.grantAppId
        ? parseInt(previewState.grantAppId)
        : "",
      paymentType: previewState.paymentTypeBlockchain,
      //paymentTYpe
    };
    const unsignedTxn =
      action === "accept"
        ? await acceptGrantApplicationBlockchain(grantInfo)
        : await rejectGrantApplicationBlockchain(grantInfo);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });

      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });

      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));
      const signed = [];
      signed.push(signedTxn[0]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      dispatch(setAppLoadingText(""));

      if (transationRes?.txId) {
        return {
          success: true,
          // transactionId: transationRes.txId,
          transactionId:
            previewState.paymentTypeBlockchain === "ALGO" || action !== "accept"
              ? transationRes.txId
              : transactionArr[2].txn.txID(),
        };
      } else {
        return {
          success: true,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleApplicationAction = async (application: any, action: string) => {
    dispatch(toggleAppLoading(true));
    const blockchainRes = await handleGrantApplicationActionBlockchain(
      application,
      action
    );
    if (!blockchainRes.success) {
      return;
    }
    const payload = {
      grantApplicationId: application._id,
      transactionId: blockchainRes.transactionId,
      acceptedAmount: application.requestedFund,
      paymentTypeBlockchain: previewState.paymentTypeBlockchain,
      //paymentTYpe
    };
    const res =
      action === "accept"
        ? await approveGrantApplication(payload)
        : await deleteGrantApplication(payload);
    if (res.success) {
      const toastPaylaod = {
        text:
          action === "accept"
            ? t("approveGrantApplicationSuccessfully")
            : t("grantApplicationRejected"),
        success: true,
        active: true,
      };
      fetchGrantData();
      handleClose();
      dispatch(setToaster(toastPaylaod));
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
    dispatch(toggleAppLoading(false));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRejectApplication = async (applicationId: any) => {
    setApplicationId(applicationId);
    setOpen(true);
  };

  const handleDelete = async () => {
    const response = applications.filter(
      (application: any) => application._id === applicationId
    );
    if (response.length > 0) {
      handleApplicationAction(response[0], "reject");
    }
  };

  const handleInitiateChat = async (applicationData: any) => {
    if (applicationData?.createdBy?._id) {
      if (!loggedIn) {
        const toastPayload = {
          active: true,
          text: t("pleaseLoginToChat"),
          success: false,
        };
        dispatch(setToaster(toastPayload));
        return;
      }
      if (applicationData.createdBy._id === userId) {
        const toastPayload = {
          active: true,
          text: t("youAreTheCreatorOfThisApplication"),
          success: false,
        };
        dispatch(setToaster(toastPayload));
      } else {
        dispatch(toggleAppLoading(true));
        const info = {
          participant: applicationData.createdBy._id,
          limit: 10,
        };
        const res = await initiateChat(info);
        dispatch(setCurrentActiveChat(res.data.chatChannel));
        setOpenChat(true);
        dispatch(toggleAppLoading(false));
      }
    } else {
      const toastPayload = {
        active: true,
        text: t("somethingWentWrong"),
        success: false,
      };
      dispatch(setToaster(toastPayload));
    }
  };

  const handleCloseChat = () => {
    setOpenChat(false);
  };

  useEffect(() => {
    setLoading(true);
    if (grantId) {
      fetchGrantData();
    }
  }, [grantId]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  const handleDeleteApplication = async (applicationId: any) => {
    setApplicationId(applicationId);
    setOpen(true);
  };

  const grantDetailsColDef: GridColDef[] = [
    {
      field: "applications",
      headerName: "Applications",
      sortable: false,
      flex: 1,
    },
    {
      field: "totalGrants",
      headerName: "Total Grants",
      sortable: false,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p>{params.row.amount} </p>{" "}
            {previewState?.paymentTypeBlockchain === "ALGO" && (
              <img
                style={{ width: "16px", marginBottom: "-3px" }}
                src={algo}
                alt="ALGO"
              />
            )}
            {previewState?.paymentTypeBlockchain === "USDC" && (
              <MonetizationOnIcon
                style={{
                  marginBottom: "-2px",
                  fontSize: "22px",
                  color: "black",
                }}
              />
            )}{" "}
          </>
        );
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      sortable: false,
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => (
        <p style={{ textTransform: "capitalize" }}>{params.row.country} </p>
      ),
    },
    {
      field: "duration",
      headerName: "Duration",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => (
        <p>
          {params.row.duration} {t("month")}(s){" "}
        </p>
      ),
    },
    {
      field: "supportingDocument",
      headerName: "Supporting Document",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return params.row.supportingDocument ? (
          <a
            href={`${URL}/api/file/${params.row.supportingDocument}`}
            target="_blank"
          >
            <img
              style={{
                width: "16px",
              }}
              src={fileIcon}
              alt="Uploaded File"
            ></img>
          </a>
        ) : (
          <p style={{ fontWeight: "700" }}>{t("notProvided")}</p>
        );
      },
    },
  ];

  const goalsColDef: GridColDef[] = [
    {
      field: "goalName",
      headerName: "Environmental Goals",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return params.row.category === "environmental"
          ? params.row.goalName
          : "-";
      },
    },
    {
      field: "goalName1",
      headerName: "Social Goals",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return params.row.category === "social" ? params.row.goalName : "-";
      },
    },
    {
      field: "goalName2",
      headerName: "Governance Goals",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return params.row.category === "governance" ? params.row.goalName : "-";
      },
    },
  ];

  const applicationsColDef: GridColDef[] = [
    {
      field: "applicants",
      headerName: "Applicants",
      maxWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return params?.row?.createdBy?.name || "-";
      },
    },
    {
      field: "requestedFund",
      headerName: "Requested Funds",
      maxWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: "application",
      headerName: "Application",
      maxWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        const handleGrantApplication = (id: any) => {
          navigate(`/user/view-application/${id}`);
        };
        return (
          <img
            style={{
              width: "20px",
              marginRight: "10px",
              cursor: "pointer",
            }}
            src={appIcon}
            onClick={() => handleGrantApplication(params.row._id)}
            alt="action"
          ></img>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      maxWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        const item = params.row;

        return (
          <>
            {item.requestedFund <= budgetLeft && item.status === "in-complete" && (
              <>
                <Tooltip
                  title={
                    item.status !== "in-complete"
                      ? item?.status.toUpperCase()
                      : ""
                  }
                  placement="top-start"
                >
                  <img
                    style={{
                      width: "20px",
                      marginRight: "10px",
                      cursor:
                        item.status === "in-complete" ? "pointer" : "auto",
                    }}
                    src={item.status === "accepted" ? acceptGreen : acceptGray}
                    onClick={() => {
                      const currentItem = item;
                      setSelectedItem(currentItem);
                      setShowAcceptWalletButtons(true);
                    }}
                    alt="accept"
                  ></img>
                </Tooltip>
                {showAcceptWalletButtons && (
                  <Wallets
                    open={showAcceptWalletButtons}
                    handleClose={() => setShowAcceptWalletButtons(false)}
                    handleSubmit={() => {
                      if (item.status === "in-complete") {
                        handleApplicationAction(selectedItem, "accept");
                      }
                    }}
                  />
                )}
              </>
            )}
            {item.status === "accepted" && (
              <>
                <Tooltip
                  title={
                    item.status !== "in-complete"
                      ? item?.status.toUpperCase()
                      : ""
                  }
                  placement="top-start"
                >
                  <img
                    style={{
                      width: "20px",
                      marginRight: "10px",
                      cursor:
                        item.status === "in-complete" ? "pointer" : "auto",
                    }}
                    src={item.status === "accepted" ? acceptGreen : acceptGray}
                    alt="reject"
                  ></img>
                </Tooltip>
              </>
            )}
            {item.status !== "accepted" && (
              <>
                <Tooltip
                  title={
                    item.status !== "in-complete"
                      ? item?.status.toUpperCase()
                      : ""
                  }
                  placement="top-start"
                >
                  <img
                    style={{
                      width: "20px",
                      marginRight: "10px",
                      cursor:
                        item.status === "in-complete" ? "pointer" : "auto",
                    }}
                    src={item.status === "rejected" ? reject : rejectGray}
                    onClick={() =>
                      item.status === "in-complete" &&
                      handleDeleteApplication(item._id)
                    }
                    alt="reject"
                  ></img>
                </Tooltip>
                {showRejectWalletButtons && (
                  <Wallets
                    open={showRejectWalletButtons}
                    handleClose={() => setShowRejectWalletButtons(false)}
                    handleSubmit={handleDelete}
                  />
                )}
              </>
            )}
            <img
              style={{
                width: "20px",
                cursor: "pointer",
              }}
              src={chat}
              onClick={() => handleInitiateChat(item)}
              alt="chat"
            ></img>
          </>
        );
      },
    },
    {
      field: "milestone1Status",
      headerName: "Milestone 1 Status",
      maxWidth: 250,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        const item = params.row;
        return (
          <p style={{ textTransform: "capitalize" }}>
            {" "}
            {item.milestones[0].status}
          </p>
        );
      },
    },
    {
      field: "milestone2Status",
      headerName: "Milestone 2 Status",
      maxWidth: 250,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        const item = params.row;
        return (
          <p style={{ textTransform: "capitalize" }}>
            {" "}
            {item.milestones[1].status}
          </p>
        );
      },
    },
    {
      field: "view",
      headerName: "View Milestones Report",
      maxWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        const item = params.row;
        return (
          <VisibilityIcon
            onClick={() =>
              item.milestones[0].status === "report-submitted" ||
              item.milestones[0].status === "completed" ||
              item.milestones[0].status === "rejected"
                ? navigate(
                    `/grant-milestone-report/${item._id}/${item.applicationAppId}`
                  )
                : {}
            }
            sx={{
              cursor:
                item.milestones[0].status === "report-submitted" ||
                item.milestones[0].status === "completed" ||
                item.milestones[0].status === "rejected"
                  ? "pointer"
                  : "",
            }}
          />
        );
      },
    },
  ];
  const getGrantDetailsForTable = () => {
    const rows = [];
    if (previewState) {
      rows.push({
        applications: applications.length || 0,
        totalGrants: previewState?.totalGrants,
        amount: previewState?.totalBudget,
        endDate: moment(previewState.endDate).format("DD-MM-YYYY"),
        country: previewState?.preferredLocation,
        duration: previewState?.duration,
        supportingDocument: previewState.document,
        rowId: previewState.grantId,
      });
    }
    return rows;
  };

  // const getGoalsForTable = () => {
  // 	const rows = [];
  // 	if (previewState && previewState.goals) {
  // 		const envGoals = previewState.goals.filter(
  // 			(item: any) => item.category === "environmental"
  // 		);
  // 		const socialGoals = previewState.goals.filter(
  // 			(item: any) => item.category === "social"
  // 		);
  // 		const govGoals = previewState.goals.filter(
  // 			(item: any) => item.category === "governance"
  // 		);

  // 		rows.push({envGoals:,
  //     socialGoals:,
  //     govGoals:
  //     });
  // 	}
  // 	return rows;
  // };
  var id = getYouTubeID(previewState.youTubeLink);
  return (
    <>
      {!loading && (
        <Box sx={PreviewWrap}>
          <Container fixed>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 6, xl: 9 }}
              sx={{ marginTop: "40px" }}
            >
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    height: "400px",
                    width: "100%",
                  }}
                >
                  <Carousel
                    swipeable={
                      previewState.video !== "" ||
                      previewState.youTubeLink !== ""
                        ? true
                        : false
                    }
                    draggable={false}
                    showDots={
                      previewState.video !== "" ||
                      previewState.youTubeLink !== ""
                        ? true
                        : false
                    }
                    responsive={responsive}
                    ssr={false}
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container view-campaign"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {previewState.video !== "" && (
                      <video
                        src={`${URL}/api/file/video/${previewState.video}`}
                        controls
                        width={"100%"}
                        height={"100%"}
                        autoPlay={false}
                      />
                    )}
                    {previewState.youTubeLink !== "" && (
                      <YouTube
                        videoId={getYouTubeID(previewState.youTubeLink)}
                        opts={{
                          height: "300px",
                          width: "100%",
                          playerVars: {
                            autoplay: 0,
                          },
                        }}
                      />
                    )}

                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={previewState?.images?.length > 1 ? true : false}
                      responsive={responsive}
                      ssr={false}
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container preview-campaign"
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {previewState?.images?.map((item: any) => (
                        <Box
                          sx={{
                            backgroundImage: `url('${URL}/api/file/image/${item}')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "90%",
                            height: "300px",
                            cursor: "pointer",
                          }}
                          id={
                            item.length > 0
                              ? `${URL}/api/file/image/${item}`
                              : ""
                          }
                          onClick={handleThumbnailPreview}
                        />
                      ))}
                    </Carousel>
                  </Carousel>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h3"
                  className="semibold"
                  align="left"
                  mb={3}
                >
                  {previewState?.title}
                </Typography>
                {previewState?.grantType === "standard" && (
                  <Stack direction="row" spacing={2}>
                    {previewState?.goals.filter((goal: any) => {
                      return goal.category === "environmental";
                    }).length > 0 && (
                      <Button
                        variant="contained"
                        style={{
                          height: "30px",
                          backgroundColor: "#8bc34a7d",
                          cursor: "auto",
                        }}
                      >
                        {t("environmental")}
                      </Button>
                    )}
                    {previewState?.goals.filter((goal: any) => {
                      return goal.category === "social";
                    }).length > 0 && (
                      <Button
                        variant="contained"
                        style={{
                          height: "30px",
                          backgroundColor: "#ffeb3b99",
                          cursor: "auto",
                        }}
                      >
                        {t("social")}
                      </Button>
                    )}
                    {previewState?.goals.filter((goal: any) => {
                      return goal.category === "governance";
                    }).length > 0 && (
                      <Button
                        variant="contained"
                        style={{
                          height: "30px",
                          backgroundColor: "#2196f3a3",
                          cursor: "auto",
                        }}
                      >
                        {t("governance")}
                      </Button>
                    )}
                  </Stack>
                )}
                {previewState.status === "rejected" && (
                  <Box sx={DescriptionTabs} style={{ minHeight: "auto" }}>
                    <Typography
                      variant="h5"
                      className="semibold"
                      align="left"
                      style={{ paddingTop: "14px", color: "#ff0000" }}
                    >
                      {t("thisGrantHasBeenRejected")}
                    </Typography>
                    <p
                      style={{
                        textAlign: "left",
                        // paddingBottom: "15px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      Reason: {previewState?.comment}
                    </p>
                  </Box>
                )}
                {previewState.status === "blocked" && (
                  <Box sx={DescriptionTabs} style={{ minHeight: "auto" }}>
                    <Typography
                      variant="h5"
                      className="semibold"
                      align="left"
                      style={{ paddingTop: "14px", color: "#ff0000" }}
                    >
                      {t("thisGrantHasBeenBlocked")}
                    </Typography>
                    <p
                      style={{
                        textAlign: "left",
                        // paddingBottom: "15px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      Reason: {previewState?.comment}
                    </p>
                  </Box>
                )}
                <Box sx={DescriptionTabs} style={{ minHeight: "auto" }}>
                  <Typography variant="body1" className="semibold" align="left">
                    {previewState.shortDescription}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{ paddingTop: "14px" }}
                  >
                    {previewState.longDescription}
                  </Typography>
                </Box>

                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ marginTop: "20px" }}
                >
                  <Grid item xs={12} lg={6} xl={5}>
                    <Box
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      {previewState.status === "active" &&
                        userslice.loggedIn &&
                        userslice.role === "applicant" && (
                          <Button
                            onClick={() => handleApplyForGrants()}
                            disabled={
                              previewState.alreadyAppliedForGrant ||
                              previewState.totalGrants === applications.length
                            }
                            variant="contained"
                            className="darkButton"
                          >
                            {previewState.alreadyAppliedForGrant
                              ? "Already Applied !"
                              : previewState.totalGrants === applications.length
                              ? "Applications Limit Reached !"
                              : "Apply for grant"}
                          </Button>
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Container fixed>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 6, xl: 9 }}
              sx={{ marginTop: "40px" }}
            >
              <Grid xs={12} md={12} style={{ marginTop: "55px" }}>
                <Box sx={MilestoneGrid}>
                  <DataGrid
                    columns={grantDetailsColDef}
                    rows={getGrantDetailsForTable()}
                    checkboxSelection={false}
                    disableSelectionOnClick={true}
                    hideFooterPagination={true}
                    hideFooter={true}
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    getRowId={(row) => row.rowId}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 6, xl: 9 }}
              sx={{ marginTop: "40px" }}
            >
              {previewState?.grantType === "standard" && (
                <Grid xs={12} md={12} style={{ marginTop: "55px" }}>
                  <Box sx={MilestoneGrid}>
                    <DataGrid
                      columns={goalsColDef}
                      rows={previewState.goals}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                      hideFooterPagination={true}
                      hideFooter={true}
                      components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                      }}
                      getRowId={(row) => row.goalId}
                      autoHeight={true}
                      style={{ fontSize: "12px" }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 6, xl: 9 }}
              sx={{ marginTop: "40px" }}
            >
              {previewState.status !== "pending" &&
                userslice.loggedIn &&
                (userslice.role === "grant-creator" ||
                  userslice.role === "grant-manager") &&
                userslice.userId === previewState.createdBy && (
                  <Grid xs={12} md={12} style={{ marginTop: "55px" }}>
                    <Box sx={MilestoneGrid}>
                      <DataGrid
                        columns={applicationsColDef}
                        rows={applications}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        hideFooterPagination={true}
                        hideFooter={true}
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        getRowId={(row) => row.applicationAppId}
                      />
                    </Box>
                  </Grid>
                )}
            </Grid>

            {openChat && (
              <Grid>
                <IconButton
                  style={{
                    float: "right",
                    marginBottom: "-40px",
                    zIndex: "99",
                  }}
                  onClick={handleCloseChat}
                >
                  <CancelIcon />
                </IconButton>
                <ChatView />
              </Grid>
            )}
          </Container>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box onClick={handleClose} sx={modalWrapper}>
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={modalContainer}
                style={{ maxWidth: "320px" }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <DangerousIcon sx={{ fontSize: "50px" }} color="error" />
                </Box>
                <Box sx={titleStyle} mb={3} mt={2}>
                  <Typography
                    variant="h6"
                    align="center"
                    mb={0}
                    fontWeight={600}
                  >
                    {t("areYouSureYouWantToRejectThisGrantApplication")}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      justifyContent: "space-around",
                      display: "flex",
                    }}
                    mt={2}
                  >
                    <Button
                      onClick={() => setShowRejectWalletButtons(true)}
                      variant="contained"
                      color="error"
                    >
                      {t("reject")}
                    </Button>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      sx={{
                        backgroundColor: "primary.grey",
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
}
export default GrantView;

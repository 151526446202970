import {
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { recentChats, chatSection, messageAreaWrapper } from "./styles";
import SendIcon from "@mui/icons-material/Send";
import InvestorCreatorChat from "./InvestorCreatorChat";
import CloseIcon from "@mui/icons-material/Close";
import RecentChatWrapper from "./RecentChatWrapper";
import { useAppSelector } from "../../../store";
import { useTranslation } from "react-i18next";

const ChatView = () => {
  const { t } = useTranslation();
  // these are for the mobile view managment
  const [isChatSelected, setIsChatSelected] = useState<boolean>(false);
  const recentChatRef = useRef<any>();
  const currentActiveChat = useAppSelector(
    (state) => state.chatReducer.currentActiveChat
  );

  useEffect(() => {
    if (!isChatSelected && recentChatRef.current) {
      recentChatRef.current.style.left = "0";
    } else if (isChatSelected && recentChatRef.current) {
      recentChatRef.current.style.left = "-100%";
    }
  }, [isChatSelected]);

  const openSideNav = () => {
    setIsChatSelected(false);
  };
  const closeSideNav = () => {
    setIsChatSelected(true);
  };

  useEffect(() => {
    if (currentActiveChat && currentActiveChat.channelId) {
      setIsChatSelected(true);
    }
  }, [currentActiveChat]);

  return (
    <div>
      <Grid container component={Paper} sx={chatSection}>
        <Grid item ref={recentChatRef} xs={3} sx={recentChats}>
          <List>
            <ListItem button key="RemySharp">
              {/* <ListItemIcon>
								<Avatar
									alt="Remy Sharp"
									src="https://material-ui.com/static/images/avatar/1.jpg"
								/>
							</ListItemIcon>
							<ListItemText primary="John Wick"></ListItemText> */}
              <Typography variant="h5" className="header-message">
                {t("chat")}
              </Typography>
              {currentActiveChat && currentActiveChat.channelId && (
                <IconButton
                  sx={{
                    "@media(min-width:900px)": {
                      display: "none",
                    },
                    marginLeft: "auto",
                  }}
                  onClick={closeSideNav}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </ListItem>
          </List>
          <Divider />
          <RecentChatWrapper />
        </Grid>
        <Grid item sx={messageAreaWrapper} xs={9}>
          {currentActiveChat && currentActiveChat.channelId ? (
            <InvestorCreatorChat openSideNav={openSideNav} />
          ) : (
            <Box
              sx={{
                height: "92%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "90%",
                border: "1px dashed",
                margin: "20px auto 20px",
                gap: "10px",
              }}
            >
              <Typography>{t("selectChatToStart")}</Typography>
              <SendIcon />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatView;

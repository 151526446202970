import { Button } from "@mui/material";
import React from "react";
// import GoogleLogin from "react-google-login";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import GoogleIcon from "../../assets/images/googleicon.png";
import { handleGoogleLogin } from "../../requests/simpleRequest";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { setLoginModal, setRegisterModal } from "../../store/slices/ModalSlice";
import {
  loggedInUser,
  setUserData,
  setUserToken,
} from "../../store/slices/UserSlice";
// import { useGoogleLogin } from "@react-oauth/google";
import { flow } from "lodash";
import { GoogleLogin } from "@react-oauth/google";

function GoogleLoginButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userslice = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const handleLogin = async (res: any) => {
    if (res.error) {
      return;
    }
    const payload = {
      // idToken: res.tokenId,
      idToken: res.credential,
    };
    dispatch(toggleAppLoading(true));
    const backendRes: any = await handleGoogleLogin(payload);
    if (backendRes.success) {
      const payload = {
        loggedIn: true,
        userToken: backendRes.data.token,
        loginType: "google",
        registerUserType: userslice.registerUserType,
        userAppId: backendRes.data.userAppId ? backendRes.data.userAppId : "",
        kyc: backendRes.data.kyc,
        userId: backendRes.data.userId,
      };
      dispatch(setUserToken(payload));

      const toastPaylaod = {
        text: t("loginWithGoogleSuccess"),
        success: true,
        active: true,
      };
      const userPayload: loggedInUser = {
        status: backendRes.data.status ? backendRes.data.status : "",
        name: backendRes.data.name ? backendRes.data.name : "",
        email: backendRes.data.email ? backendRes.data.email : "",
        userId: backendRes.data.userId ? backendRes.data.userId : "",
        createdAt: backendRes.data.createdAt ? backendRes.data.createdAt : "",
        address: backendRes.data.address ? backendRes.data.address : "",
        phone: backendRes.data.phone ? backendRes.data.phone : "",
        country: backendRes.data.country ? backendRes.data.country : "",
        gender: backendRes.data.gender ? backendRes.data.gender : "",
        role: backendRes.data.role ? backendRes.data.role : "",
        userAppId: backendRes.data.userAppId ? backendRes.data.userAppId : "",
        organisationName: backendRes.data.organisationName
          ? backendRes.data.organisationName
          : "",
        organisationOwner: backendRes.data.organisationOwner
          ? backendRes.data.organisationOwner
          : "",
        orgnisationRole: backendRes.data.orgnisationRole
          ? backendRes.data.orgnisationRole
          : "",
      };
      dispatch(setUserData(userPayload));
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      // navigate("/user");
    } else {
      const toastPaylaod = {
        text: backendRes.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
    }
    dispatch(setRegisterModal(false));
    dispatch(setLoginModal(false));
  };

  return (
    <Button
      sx={{ height: "60%", width: "40%" }}>
      <GoogleLogin
        onSuccess={(response: any) => {
          handleLogin(response);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        width="50px"
        text="continue_with"
      />
    </Button>
  );
}

export default GoogleLoginButton;

const style = {
  maxWidth: "900px",
  width: "98%",
  bgcolor: "#eef0f0",
  borderRadius: "12px",
  margin: "32px",
  position: "relative",
  overflowY: "auto",
  display: "inline-block",
  verticalAlign: "middle",
  textAlign: "left",
  padding: 4,
};

const titleStyle = {
  "& .MuiButton-text": {
    padding: "0px",
    fontSize: "14px",
    color: "green.main",
    fontFamily: "TTNormsRegular",
    height: "auto",
    minWidth: "auto",
  },
};

const rewardTypes = {
  "& p": {
    border: "white 2px solid",
    padding: "10px",
    cursor: "pointer",
    borderRadius: "10px",
    fontSize: "18px",
    "& span": {
      color: "#c6c6c6",
      marginLeft: "4px",
    },
  },
  "& .selected": {
    backgroundColor: "#ffffff",
  },
};

const FooterBtn = {
  margin: "20px auto 0px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiButton-contained": {
    backgroundColor: "green.main",
    borderRadius: "20px",
    fontSize: "16px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "green.dark",
      color: "black.main",
    },
  },
  "& .MuiButton-text": {
    padding: "0px",
    fontSize: "16px",
    fontFamily: "TTNormsRegular",
    height: "auto",
    minWidth: "auto",
    display: "block",
    // margin: "25px auto 0px",
  },
};
export const UploadBox = {
  height: "150px",
  width: "100%",
  bgcolor: "white.main",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  marginBottom: 1,
};
export { style, titleStyle, rewardTypes, FooterBtn };

import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";
import HeroImage from "../../../assets/images/homebanner.png";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { BannerImage } from "./style";
import CampaignCard from "../campaigns/CampaignCard";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function Hero() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleScroll = () => {
    let doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    let scroll = 470 - top;
    window.scrollBy(0, scroll);
  };
  return (
    <div>
      <Box sx={{ backgroundColor: "#f1f2f3" }}>
        <Container fixed>
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
              flexDirection: { md: "inherit", xs: "column-reverse" },
              paddingBottom: { md: "0px", xs: "50px" },
            }}
          >
            <Grid item xs={12} md={4}>
              <Typography
                variant="h2"
                sx={{ textAlign: { md: "left", xs: "center" } }}
              >
                {t("findFinancingAndFundTransformativeIdeas")}
              </Typography>
              <Box
                sx={{
                  textAlign: { md: "left", xs: "center" },
                  marginTop: 2,
                  paddingBottom: "10px",
                }}
              >
                <Button
                  onClick={() => navigate("/how-its-works")}
                  variant="contained"
                  sx={{
                    bgcolor: "green.main",
                    width: { lg: "200px", xs: "172px" },
                    marginRight: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {t("learnMore")}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "green.main",
                    width: { lg: "200px", xs: "172px" },
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={handleScroll}
                >
                  {t("exploreCampaigns")}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box sx={BannerImage}>
                <img src={HeroImage} alt="Hero" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default Hero;

export const MilestoneForm = {
  marginTop: "-24px",
  "& .inputfile": {
    display: "none",
  },
  "& .uploadButton": {
    color: "#000",
    fontFamily: "TTNormsRegular",
    marginTop: 1,
    paddingLeft: 0,
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontFamily: "TTNormsMedium",
      borderRadius: "6px",
      textAlign: "left",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
  "& .MuiFormLabel-root": {
    textAlign: "left",
    marginBottom: 2,
  },
  "& ol": {
    "& li": {
      textAlign: "left",
      margin: "20px 0 10px",
    },
  },
};

export const blackBoxHeading = {
  marginRight: "auto",
  backgroundColor: "#000000de",
  color: "#61f359",
  cursor: "default",
  borderRadius: "10px",
  marginBottom: "20px",
  padding: "10px",
  fontWeight: "bold",
  letterSpacing: "1px",
  textAlign: "center",
};
